/**
 * @fileoverview Implements offline view.
 */

import { CloudOff as CloudOffIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

export default function Offline() {
  return (
    <Box sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      <CloudOffIcon sx={{
        color: 'secondary.main',
        height: '512px',
        maxWidth: '50%',
        width: '512px'
      }} />
      <Typography variant="h1">
        Offline
      </Typography>
    </Box>
  )
}
