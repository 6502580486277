/**
 * @fileoverview Implements a "Shop Products" view that lists products
 * available from the specified shop.
 */

import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography
} from '@mui/material'

import InlineSearchField from './inline-search-field'
import SearchResult from '../search/search-result'
import SearchResultSkeleton from '../search/search-result-skeleton'
import Section from '../section'
import PebbleApi from '../../lib/pebble-api'

const PAGE_SIZE = 8

/**
 * A component for searching and displaying search results.
 */
export default function ShopProducts(props) {
  const { color, heading, id, loading: shopLoading, shop } = props

  const mounted = useRef(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [nextCursor, setNextCursor] = useState(null)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  
  let products = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  // Reset query when shop changes
  useEffect(() => {
    setQuery('')
  }, [shop])

  // Load results when query or shop changes
  useEffect(() => {
    setIsLoading(true)

    const searchModel = {
      shops: [{ slug: shop }]
    }

    if (query) {
      searchModel.query = query
    }

    PebbleApi
      .getSearchResultsConnection(searchModel, { first: PAGE_SIZE })
      .then(results => {
        if (mounted.current) {
          const edges = results.edges
          const nodes = edges.map(edge => edge.node)
          const newNextCursor = results.pageInfo.hasNextPage ?
            edges[edges.length - 1].cursor : null

          setSearchResults(nodes)
          setNextCursor(newNextCursor)
          setIsLoading(false)
        }
      })
  }, [query, shop])

  /**
   * @function loadMore
   * Loads additional pages of search results for the current search model.
   */
  const loadMore = useCallback(() => {
    setIsLoadingMore(true)

    const searchModel = {
      shops: [{ slug: shop }]
    }

    if (query) {
      searchModel.query = query
    }

    const pagination = {
      first: PAGE_SIZE,
      after: nextCursor,
    }

    PebbleApi
      .getSearchResultsConnection(searchModel, pagination)
      .then(moreResults => {
        if (mounted.current) {
          const edges = moreResults.edges
          const nodes = edges.map(edge => edge.node)
          const newNextCursor = moreResults.pageInfo.hasNextPage ?
            edges[edges.length - 1].cursor : null

          setSearchResults(oldSearchResults => [...oldSearchResults, ...nodes])
          setNextCursor(newNextCursor)
          setIsLoadingMore(false)
        }
      })
  }, [nextCursor, query, shop])

  /**
   * @function handleSearchFieldSubmit
   * Handles a new query string being submitted for searching the shop's
   * products.
   * @param {string} newQuery - New query string
   */
  const handleSearchFieldSubmit = newQuery => setQuery(newQuery)

  if (isLoading || shopLoading) {
    // Render skeleton search results while loading the products
    const skeletonItemCount = 8
    products = [...Array(skeletonItemCount)].map((_, i) => (
      <Grid
        item
        key={i}
        xs={6}
        md={3}
      >
        <SearchResultSkeleton />
      </Grid>
    ))
  } else if (!searchResults || searchResults.length === 0) {
    // If there are no products, don't render anything at all
    return null
  } else if (searchResults && searchResults.length > 0) {
    products = searchResults.map(product => (
      <Grid
        item
        key={product._id}
        xs={6}
        md={3}
      >
        <SearchResult product={product} />
      </Grid>
    ))

    if (isLoadingMore) {
      products = products.concat(
        [...Array(PAGE_SIZE)].map((_, i) => (
          <Grid
            item
            xs={6}
            md={3}
            key={'loading-more' + i}
          >
            <SearchResultSkeleton />
          </Grid>
        ))
      )
    }

    if (nextCursor && !isLoadingMore) {
      products = products.concat(
        <Grid
          item
          key="view-more"
          xs={12}
        >
          <Box align="center">
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={loadMore}
              endIcon={
                isLoadingMore
                  ? <CircularProgress
                      size={20}
                      sx={{ color: 'common.white' }}
                    />
                  : null
              }
              disabled={isLoadingMore}
            >
              View more
            </Button>
          </Box>
        </Grid>
      )
    }
  }

  return (
    <Section color={color} id={id}>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 2,
        mb: '10px'
      }}>
        {shopLoading
          ? <Skeleton />
          : <>
              <Typography color="secondary" variant="h2">
                {heading ?? 'Products'}
              </Typography>
              <InlineSearchField onSubmit={handleSearchFieldSubmit} />
            </>
        }
      </Box>
      <Grid container spacing={2}>
        {products}
      </Grid>
    </Section>
  )
}
