import { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useMatch } from 'react-router-dom'

import { Box } from '@mui/material'

import About from './about'
import Account from './account/account'
import BlogHome from './blog/blog-home'
import BlogPost from './blog/blog-post'
import Brand from './brand'
import Cart from './cart/cart'
import Checkout from './checkout/checkout'
import CheckoutComplete from './checkout/complete'
import CmsPage from './cms-page'
import Community from './community/community'
import Credits from './credits'
import Footer from './footer'
import Home from './home'
import Item from './item/item'
import Landing from './landing'
import Login from './auth/login'
import Logout from './auth/logout'
import Offline from './offline'
import PrimaryAppBar from './primary-app-bar/primary-app-bar'
import PwaInstallBanner from './pwa-install-banner'
import Search from './search/search'
import Shop from './shop/shop'
import Signup from './auth/signup'
import SkeletonPage from './skeleton-page'
import Partner from './partner/partner'
import ResetPassword from './auth/reset-password'
import SetPassword from './auth/set-password'
import WhatIsPebble from './what-is-pebble/what-is-pebble'

import { SearchProvider } from '../lib/search-context'
import { SnackbarProvider } from '../lib/snackbar-context'
import { UserProvider } from '../lib/user-context'

import '../styles/app.css'

// Lazy-load the Admin view
const Admin = lazy(() => import('./admin/admin'))

/**
 * The root React component for the client app.
 */
export default function App() {
  const location = useLocation()
  const [online, setOnline] = useState(true)

  const handleOnline = () => setOnline(true)
  const handleOffline = () => setOnline(false)

  // Variables tracking community reference
  const isCommunityPage = useMatch('/community/*')
  const isShopPage = useMatch('/shop/*')
  const isItemPage = useMatch('/item/*')
  const isSearchPage = useMatch('/search')

  useEffect(() => {
    // Listen for online and offline events
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  })

  // Track virtual page views for Google Analytics when the route changes
  useEffect(() => {
    window.gtag && window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash
    })
  }, [location])

  // Remove community reference when leaving community workflow
  useEffect(() => {
    if (!isCommunityPage && !isShopPage && !isItemPage && !isSearchPage) {
      sessionStorage.removeItem('community')
    }
  }, [isCommunityPage, isShopPage, isItemPage, isSearchPage])

  if (!online) {
    return <Offline />
  }

  return (
    <SnackbarProvider>
      <UserProvider>
        <SearchProvider>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            backgroundColor: 'common.white'
          }}>
            <PrimaryAppBar excludeSearchRoutes={['/blog', '/blog/:slug']} />
            <Box sx={{ flexGrow: 1 }}>
              <Suspense fallback={<SkeletonPage />}>
                <Routes>
                  <Route path="/about" element={<About />} />
                  <Route path="/account/*" element={<Account />} />
                  <Route path="/admin/*" element={<Admin />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/blog" element={<BlogHome />} />
                  <Route path="/brand" element={<Brand />} />
                  <Route path="/item/:id/*" element={<Item />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/checkout/complete/:id"
                    element={<CheckoutComplete />}
                  />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/community/:slug" element={<Community />} />
                  <Route path="/credits" element={<Credits />} />
                  <Route path="/landing" element={<Landing />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/partner/faq"
                    element={<CmsPage slug="partner-faq" />}
                  />
                  <Route path="/partner" element={<Partner />} />
                  <Route path="/privacy"
                    element={<CmsPage slug="privacy-policy" />}
                  />
                  <Route path="/resetpassword" element={<ResetPassword />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/setpassword/:token" element={<SetPassword />} />
                  <Route path="/service/:id/*" element={<Item />} />
                  <Route path="/shop/:slug" element={<Shop />} />
                  <Route path="/signup/partner"
                    element={<Signup partner={true} />}
                  />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/terms"
                    element={<CmsPage slug="terms-of-use" />}
                  />
                  <Route path="/what-is-pebble" element={<WhatIsPebble />} />
                  <Route path="/" element={<Home />} />
                </Routes>
              </Suspense>
            </Box>
            <Footer />
            <PwaInstallBanner />
          </Box>
        </SearchProvider>
      </UserProvider>
    </SnackbarProvider>
  )
}
