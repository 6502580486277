/**
 * @fileoverview Implements the Reset Password Request view, where the user can
 * enter their email address to request a password reset email.
 */

import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material'

import { useUser } from '../../lib/user-context'
import LinkButton  from '../link-button'
import HelmetWrapper from '../helmet-wrapper'
import Section from '../section'
import PebbleApi from '../../lib/pebble-api'

export default function ResetPassword() {
  const { loadUser } = useUser()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  // Prepopulate the Email field from optional query parameter `email`
  const [email, setEmail] = useState(searchParams.get('email') || '')

  const [formError, setFormError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const mounted = useRef(false)

  useEffect(()=> {
    mounted.current = true
    return () => mounted.current = false
  })

  const handlEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()

    // First clear out all errors
    setFormError(null)
    setEmailError(null)

    let hasError = false

    if (email == null || email.trim().length === 0) {
      setEmailError('Email address is required')
      hasError = true
    }

    // If there's a validation error, bail before talking to the server
    if (hasError) {
      return
    }

    setLoading(true)

    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute('6Ldn0G4bAAAAAOHwhde8AAVfhYpiazRho5Qygg8a', { action: 'submit' })
        .then(token => {
          // First, verify the reCAPTCHA response token through our server
          PebbleApi
            .verifyRecaptcha(token)
            .then(success => {
              if (mounted.current) {
                if (!success) {
                  setLoading(false)
                  setFormError("Oops! Pebble thinks you're a robot. Please try again.")
                  return
                }

                PebbleApi
                  .resetPassword(email)
                  .then(() => {
                    if (mounted.current) {
                      setLoading(false)

                      // Server always returns a 200 OK response

                      // Resetting logs out any current sessions, so reload
                      // current user
                      loadUser()

                      // Success, so show success message
                      setSuccess(true)
                    }
                  })
                }
            })
        })
    })
  }

  if (success) {
    return (
      <>
        <HelmetWrapper title="Reset your password" />
        <Section maxWidth="sm">
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleIcon sx={{
              fontSize: '100px',
              color: 'secondary.main',
            }} />
            <Typography variant="h2" gutterBottom>
              Your password has been reset!
            </Typography>
            <Typography variant="body1">
              Check your email for a link to set your new password.
            </Typography>
          </Box>
        </Section>
      </>
    )
  }

  let loginLink = '/login'
  
  if (email && email.length) {
    loginLink += `?email=${email}`
  }

  return (
    <>
      <HelmetWrapper title="Reset your password" />
      <Section maxWidth="sm">
        <Box pb={4}>
          <Typography variant="h1">
            Reset password
          </Typography>
          <Typography variant="body1">
            Enter your email to reset your Pebble account password.
          </Typography>
        </Box>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {formError && 
            <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
              {formError}
            </Alert>
          }
          <TextField
            autoComplete="email"
            disabled={loading}
            error={Boolean(emailError) || Boolean(formError)}
            helperText={emailError}
            inputProps={{
              autoCapitalize: 'off',
            }}
            label="Email"
            name="email"
            onChange={handlEmailChange}
            required
            sx={{ mb: 2, width: '100%' }}
            value={email}
            variant="outlined"
          />
          <Button
            color="primary"
            disabled={loading}
            endIcon={loading && <CircularProgress size={24} />}
            size="large"
            sx={{ mb: 2, width: '100%' }}
            type="submit"
          >
            Reset password
          </Button>
        </form>
        <Typography variant="body2" gutterBottom>
          Remembered your password?
          {' '}
          <Link to={loginLink}>
            Log in
          </Link>
        </Typography>
        <Box pt={2}>
          <Typography variant="body1" gutterBottom>
            Don't have a Pebble account yet?
          </Typography>
          <LinkButton
            color="primary"
            size="large"
            sx={{ mb: 2, width: '100%' }}
            to={`/signup`}
            variant="outlined"
          >
            Sign up
          </LinkButton>
        </Box>
      </Section>
    </>
  )
}
 