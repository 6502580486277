/**
 * @fileoverview Implements a mobile view of search filters.
 */

import { useState } from 'react'

import TuneIcon from '@mui/icons-material/Tune'
import { Fab, SwipeableDrawer, Zoom } from '@mui/material'

import Filters from './filters'

export default function MobileFilters () {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleOpenDrawer = () => setIsDrawerOpen(true)
  const handleCloseDrawer = () => setIsDrawerOpen(false)

  return (
    <>
      <Zoom in={!isDrawerOpen}>
        <Fab
          aria-label="filter"
          color="secondary"
          onClick={handleOpenDrawer}
          sx={{
            bottom: 2,
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            right: 2
          }}
        >
          <TuneIcon />
        </Fab>
      </Zoom>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        onOpen={handleOpenDrawer}
        sx={{
          '.MuiDrawer-paper': {
            width: '90vw'
          }
        }}
      >
        <Filters onClose={handleCloseDrawer} />
      </SwipeableDrawer>
    </>
  )
}
