/**
 * @fileoverview Implements a view indicating an order has been successfully
 * placed.
 */

import { useParams } from 'react-router-dom'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import LinkButton from '../link-button'

export default function CheckoutComplete() {
  const { id } = useParams()

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <CheckCircleIcon sx={{
          fontSize: '100px',
          color: 'secondary.main',
        }} />
        <Typography variant="h2" gutterBottom>
          Your order has been placed!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Order # {id}
        </Typography>
        <Box mt={2}>
          <LinkButton
            to="/"
            mode="router"
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIcon />}
          >
            Continue shopping
          </LinkButton>
        </Box>
      </Box>
    </Container>
  )
}
