/**
 * @fileoverview Implements content for the Checkout -> Delivery Method step.
 */

import { useCallback, useEffect, useState } from 'react'

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  StepContent,
  Typography
} from '@mui/material'

import CheckoutStepDeliveryShop from './checkout-step-delivery-shop'

export default function CheckoutStepDelivery(props) {
  const {
    checkouts,
    completed,
    isCompleting,
    onChange,
    onClick,
    onComplete,
    ...otherProps
  } = props
  const [deliveryMethods, setDeliveryMethods] = useState([])
  const [formattedDeliveryMethods, setFormattedDeliveryMethods] = useState('')

  useEffect(() => {
    onChange && onChange(deliveryMethods)

    // Build formatted list of delivery methods
    const newFormattedDeliveryMethods = checkouts
      .map(checkout => {
        const deliveryMethod = deliveryMethods
          .filter(m => m.token === checkout.token)

        if (deliveryMethod.length) {
          if (checkout.discount_code?.startsWith('PEBBLELOCALPICKUP_')) {
            return 'Local In-store Pickup'
          }

          const shippingRate = checkout.shipping_rates
            .filter(r => r.id === deliveryMethod[0].shipping_line_handle)

          if (shippingRate.length) {
            return shippingRate[0].title
          }
        }

        return '(Unknown)'
      })
      .join(', ')
    
    setFormattedDeliveryMethods(newFormattedDeliveryMethods)
  }, [checkouts, deliveryMethods, onChange])

  const handleDeliveryMethodChanged = useCallback(newDeliveryMethod => {
    setDeliveryMethods(oldDeliveryMethods => {
      const newDeliveryMethods = [...oldDeliveryMethods]

      if (newDeliveryMethod != null) {
        const index = newDeliveryMethods
          .findIndex(m => m.token === newDeliveryMethod.token)

        if (index > -1) {
          newDeliveryMethods[index] = newDeliveryMethod
        } else {
          newDeliveryMethods.push(newDeliveryMethod)
        }
      }

      return newDeliveryMethods
    })
  }, [])

  const handleCompleted = event => {
    event.stopPropagation()
    onComplete && onComplete()
  }

  const plural = checkouts.length > 1
  
  // Assume that if we have a delivery method object for each checkout, then
  // all delivery methods have been selected
  const nonNullDeliveryMethods = deliveryMethods
    .filter(i => i.shipping_line_handle != null)
  const isValid = checkouts.length > 0 &&
    nonNullDeliveryMethods.length === checkouts.length

  return (
    <Step {...otherProps}>
      <StepButton
        onClick={onClick}
        completed={completed}
      >
        Delivery method
        {completed === true && (
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            {' '}
            &mdash;
            {' '}
            {formattedDeliveryMethods}
          </Typography>
        )}
      </StepButton>
      <StepContent>
        <Box mt={1}>
          <Alert severity="info">
            Each shop provides their own delivery options. Please select how you'd like to get your items from each shop.
          </Alert>
        </Box>
        {checkouts && checkouts.map(checkout =>
          <CheckoutStepDeliveryShop
            key={checkout.token}
            checkout={checkout}
            onChange={handleDeliveryMethodChanged}
            disabled={isCompleting}
          />
        )}
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleCompleted}
            endIcon={isCompleting
              ? <CircularProgress size={20} sx={{ color: 'common.white' }} />
              : null}
            disabled={!isValid || isCompleting}
          >
            Use {plural ? 'these' : 'this'} delivery method{plural ? 's' : ''}
          </Button>
        </Box>
      </StepContent>
    </Step>
  )
}
