import React from 'react'
import { createRoot } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { ThemeProvider } from '@mui/material/styles'

import pebbleTheme from './theme'
import App from './components/app.js'
import ScrollToTop from './components/scroll-to-top'

// Initialize the React app by rendering the root App component in the `root`
// DOM element in /client/public/index.html
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ThemeProvider theme={pebbleTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <App />
      </StyledEngineProvider>
    </ThemeProvider>
  </BrowserRouter>
)
