/**
 * @fileoverview Implements a filter for narrowing which shops are included in
 * the search results. Incoming list of shops is provided by the search results
 * initially, and then the filter outputs a list of which shops to include to
 * further refine the results.
 */

import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

export default function FilterShops(props) {
  const { onChange, selectedShops, shops } = props

  const handleShopSelected = shop => event => {
    // Start by copying existing list of selected shops
    let newSelectedShops = [...selectedShops]

    // See if this shop is already in the list
    const index = selectedShops.findIndex(s => s.slug === shop.slug)
    
    // If the checkbox for this shop was checked
    if (event.target.checked) {
      // Only add it to the array if it's not already there
      if (index === -1) {
        newSelectedShops = [
          ...newSelectedShops,
          shop
        ]
      }
    } else {
      // Checkbox was unchecked, so remove it from the array
      if (index !== -1) {
        newSelectedShops.splice(index, 1)
      }
    }

    // Parent is responsible for managing state so just trigger the event
    onChange && onChange(newSelectedShops)
  }

  // If there are no shops to filter, hide the filter
  if (!shops || shops.length === 0) {
    return null
  }

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend">
        Shops
      </FormLabel>
      <FormGroup>
        {shops.map(shop => (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(selectedShops.find(s => s.slug === shop.slug))}
                onChange={handleShopSelected(shop)}
                value={shop.slug}
              />
            }
            key={shop.slug}
            label={shop.name}
            sx={{
              '& + &': {
                mt: -1.5
              }
            }}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
