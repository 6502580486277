/**
 * @fileoverview Implements a skeleton for more search results, used for lazy-
 * loading more results.
 */

import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'

import SearchResultSkeleton from './search-result-skeleton'

/**
 * @param {object} props - Properties passed to the component
 * @param {function} [props.onLoad] - Function to call when this component
 * enters the viewport
 */
export default function MoreResultsSkeleton(props) {
  const { currentCount, isSearchResults, onLoad } = props
  const { ref, inView } = useInView()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  let count = isSearchResults === false ? 4 : 3

  // Calculate how many skeletons to show
  if (currentCount != null && isSearchResults !== false) {
    const factor = isLarge ? 3 : 2
    const max = isLarge ? 6 : 4
    const remainder = currentCount % factor
    count = max - remainder

    if (count >= max) {
      count -= factor
    }
  }
  
  useEffect(() => {
    if (inView) {
      onLoad && onLoad()
    }
  }, [inView, onLoad])

  let skeletonItems
  
  if (isSearchResults === false) {
    skeletonItems = [...Array(count)].map((_, i) => (
      <Grid
        item
        xs={6}
        md={3}
        key={'MoreResultsSkeleton' + i}
        ref={i === 0 ? ref : null}
      >
        <SearchResultSkeleton />
      </Grid>
    ))
  } else {
    skeletonItems = [...Array(count)].map((_, i) => (
      <Grid
        item
        xs={6}
        lg={4}
        key={'MoreResultsSkeleton' + i}
        ref={i === 0 ? ref : null}
      >
        <SearchResultSkeleton />
      </Grid>
    ))
  }

  // Return an ARRAY of skeleton items
  return skeletonItems
}
