/**
 * @fileoverview Implements a view for initiating onboarding a Pebble partner
 * from an invitation.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Done as DoneIcon } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material'

import { handleSuccessUrl } from '../../../lib/handle-success-url'
import { useUser } from '../../../lib/user-context'
import AccountSection from '../account-section'
import AccountSectionSkeleton from '../account-section-skeleton'
import LinkButton from '../../link-button'
import NotFound from '../../not-found'
import PebbleApi from '../../../lib/pebble-api'
import CommunityBannerCore from '../../community/community-banner-core'

export default function OnboardInvitation () {
  const { id } = useParams()
  const mounted = useRef()
  const location = useLocation()
  const navigate = useNavigate()
  const { loadingUser, user } = useUser()

  const [invitation, setInvitation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [shop, setShop] = useState('')
  const [shops, setShops] = useState([])

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    setLoading(true)
    setShop('')

    // Load invitation by ID
    PebbleApi
      .getInvitation(id)
      .then(newInvitation => {
        if (mounted.current) {
          setInvitation(newInvitation)

          if (newInvitation.shop) {
            setLoading(false)
          } else {
            PebbleApi
              .getShopsToManage()
              .then(newShops => {
                if (mounted.current) {
                  setShops(newShops)
                  setLoading(false)
                }
              })
          }
        }
      })
  }, [id])

  /**
   * @function handleClick
   * Handles clicking the button to accept and "use" the invitation.
   */
  const handleClick = useCallback(() => {
    setSaving(true)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    PebbleApi
      .useInvitation(id)
      .then(status => {
        if (mounted.current) {
          setSaving(false)

          if (status.success) {
            handleSuccessUrl(status.successUrl, navigate)
          }
        }
      })
  }, [id, navigate])

  /**
   * @function handleSelectShop
   * Handles clicking the button to accept and "use" the invitation with the
   * selected shop.
   */
  const handleSelectShop = useCallback(() => {
    setSaving(true)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    PebbleApi
      .useInvitation(id, shop)
      .then(status => {
        if (mounted.current) {
          setSaving(false)

          if (status.success) {
            handleSuccessUrl(status.successUrl, navigate)
          }
        }
      })
  }, [id, navigate, shop])

  // Handle changing the selected shop
  const handleShopChange = event => setShop(event.target.value)

  if (loadingUser || loading || saving) {
    return <AccountSectionSkeleton />
  }

  // Make sure the invitation was found successfully
  if (!invitation) {
    return <NotFound entity="invitation" />
  }

  const redirectUrl = location.pathname + location.search + location.hash
  const params = new URLSearchParams({ 'r': redirectUrl })

  return (
    <AccountSection
      heading={`Invitation to join ${invitation.community?.name}`}
    >
      <Box mb={2}>
        <Link to={`/community/${invitation.community?.slug}`}>
          <CommunityBannerCore community={invitation.community} />
        </Link>
      </Box>
      <Box mb={2}>
        <Typography gutterBottom variant="body1">
          Your business
          {invitation.shop ? `, ${invitation.shop.name}, ` : ' '}
          has been invited to join Pebble as part of the
          {' '}
          <Link to={`/community/${invitation.community?.slug}`}>
            {invitation.community?.name}
          </Link>
          {' '}
          community.
        </Typography>
        <LinkButton
          color="secondary"
          mode="router"
          to={`/community/${invitation.community?.slug}`}
          variant="outlined"
        >
          View community
        </LinkButton>
      </Box>
      <Box mb={2}>
        <Alert
          severity="info"
        >
          <AlertTitle>
            What is Pebble?
          </AlertTitle>
          Pebble is an online marketplace for customers to find and shop small businesses and local communities.
          <Box mt={1}>
            <LinkButton
              color="inherit"
              mode="router"
              size="small"
              to="/what-is-pebble"
              variant="outlined"
            >
              Learn more
            </LinkButton>
          </Box>
        </Alert>
      </Box>
      <Box mb={2}>
        <Alert
          severity="info"
        >
          <AlertTitle>
            Why join this community?
          </AlertTitle>
          By joining this community on Pebble, you can leverage the community to meet new customers and expand your market reach.
          <Box mt={1}>
            <LinkButton
              color="inherit"
              mode="router"
              size="small"
              to="/partner"
              variant="outlined"
            >
              Learn more
            </LinkButton>
          </Box>
        </Alert>
      </Box>
      <Box mb={2}>
        <Typography gutterBottom variant="h3">
          What's next?
        </Typography>
        {!loadingUser && !user.loggedIn &&
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  1
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Log in or sign up for a Pebble account to accept the invitation" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  2
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Select an existing shop or create a new shop on Pebble" />
            </ListItem>
          </List>
        }
        {!loadingUser && user.loggedIn &&
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: 'success.main' }}>
                  <DoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Log in or sign up for a Pebble account to accept the invitation" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  2
                </Avatar>
              </ListItemAvatar>
              {invitation.shop &&
                <ListItemText primary={`Accept the invitation for ${invitation.shop.name}`} />
              }
              {!invitation.shop && shops.length === 0 &&
                <ListItemText primary="Create a new shop on Pebble" />
              }
              {!invitation.shop && shops.length > 0 &&
                <ListItemText primary="Select an existing shop or create a new shop on Pebble" />
              }
            </ListItem>
          </List>
        }
      </Box>
      {!loadingUser && !user.loggedIn &&
        <LinkButton
          color="secondary"
          mode="router"
          to={`/login?${params}`}
        >
          Log in to accept invitation
        </LinkButton>
      }
      {!loadingUser && user.loggedIn &&
        <>
          {(invitation.shop || shops.length === 0) &&
            <Button
              color="secondary"
              disabled={saving}
              onClick={handleClick}
            >
              Accept invitation
            </Button>
          }
          {!invitation.shop && shops.length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card sx={{ height: '100%' }} variant="outlined">
                  <CardContent sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    height: '100%',
                    textAlign: 'center'
                  }}>
                    <Typography gutterBottom variant="h5">
                      Select existing shop
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      Select an existing Pebble shop to join this community.
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="onboard-invitation-shop">
                        Shop
                      </InputLabel>
                      <Select
                        labelId="onboard-invitation-shop"
                        value={shop}
                        label="Shop"
                        onChange={handleShopChange}
                      >
                        {shops.map(shop =>
                          <MenuItem value={shop.slug}>
                            {shop.name}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                      color="secondary"
                      disabled={loading || saving || !Boolean(shop)}
                      onClick={handleSelectShop}
                    >
                      Accept invitation
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card sx={{ height: '100%' }} variant="outlined">
                  <CardContent sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    height: '100%',
                    textAlign: 'center'
                  }}>
                    <Typography gutterBottom variant="h5">
                      Add new shop
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      Create a new Pebble shop to join this community.
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                      color="secondary"
                      disabled={loading || saving}
                      onClick={handleClick}
                    >
                      Add shop
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          }
        </>
      }
    </AccountSection>
  )
}
