/**
 * @fileoverview Implements an error page.
 */

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import Section from './section'

export default function ErrorPage(props) {
  const { action, message } = props
  const defaultMessage = 'An unknown error occurred. Please try again later.'
  const location = useLocation()

  useEffect(() => {
    window.rg4js && window.rg4js('send', {
      error: new Error(`${location.pathname}: ${message}`)
    })
  }, [location.pathname, message])

  return (
    <Section maxWidth="sm">
      <Box sx={{
        textAlign: 'center',
        '& img': {
          mb: 4,
          maxWidth: '256px',
          width: '100%'
        }
      }}>
        <img
          alt="error"
          src="/images/noun/error.png"
        />
        <Typography variant="h1" gutterBottom>
          Uh oh!
        </Typography>
        <Typography variant="body1">
          {message || defaultMessage}
        </Typography>
        {action &&
          <Button
            color="secondary"
            onClick={action.onClick}
            sx={{ mt: 4 }}
          >
            {action.label}
          </Button>
        }
      </Box>
    </Section>
  )
}
