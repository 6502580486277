/**
 * @fileoverview Implements a context for managing the current search.
 */

import { createContext, useContext, useReducer } from 'react';
import Cache from './cache';

// Default search radius in miles
const DEFAULT_RADIUS = 40;

/**
 * A search model containing search parameters. A few rules:
 * 1. If `location` is not null, then `radius` must also be not null and
 *    greater than zero, and `shops` must be an empty array.
 * 2. If `shops` is not an empty array, then `location` and `radius` must both
 *    be null.
 * 
 * @typedef {object} setSearchModel
 * @property {string} [category] - Optional category to search.
 * @property {string} [community] - Optional Pebble Community to search.
 * @property {object} [location] - Search location object. If not null, then
 * `radius` must also be not null.
 * @property {number} [maxPrice] - Optional maximum price.
 * @property {number} [minPrice] - Optional minimum price.
 * @property {boolean} [offersFreeShipping] - Optional flag to filter for shops
 * that offer a free shipping option
 * @property {boolean} [offersLocalPickup] - Optional flag to filter for shops
 * that offer a local pickup option
 * @property {string} query - User-entered search query
 * @property {number} [radius] - Search radius in miles. If not null, then must
 * be greater than zero and `location` must also be not null.
 * @property {boolean} [recommendedSearch] - Whether this search originated
 * from a recommended search. Used for search analytics.
 * @property {Array<object>} shops - List of shops to search within. To not
 * search by shop, this should be an empty array.
 * @property {boolean} shouldSearch - Whether to execute a search with the
 * current parameters. If this gets set to true, the search should be executed
 * and this should get immediately set back to false.
 * @property {string} [subcategory] - Optional subcategory to search.
 * @property {string} [types] - Optional product types to search.
 * @property {boolean} useCurrentLocation - Whether to use the user's current
 * location when searching by location. If true, will populate `location` with
 * the user's current location. If false, `location` and `radius` are
 * unaffected.
 * @property {object} [userLocation] - Optional user location object. Provide
 * whenever possible for search analytics.
 * @property {boolean} userLocationFailed - Whether user location lookup failed
 * @property {Array<string>} values - List of shop values to search. To not
 * search by values, this should be an empty array.
 */

/**
 * Creates and returns a default search model object.
 * @param {object} [model] - Optional initial search model to use instead of
 * the default
 * @returns {SearchModel} Default search model
 */
const initSearch = model => {
  return {
    category: null,
    community: null,
    location: null,
    query: '',
    radius: DEFAULT_RADIUS,
    shops: [],
    shouldSearch: false,
    subcategory: null,
    types: [],
    useCurrentLocation: false, // Default to searching everywhere
    userLocation: null,
    userLocationFailed: false,
    values: [],
    ...model,
  }
};

/**
 * Enforces search model validation rules.
 * @param {SearchModel} state - Search model state to validate.
 * @returns Validated state object, potentially with changes.
 */
const enforceRules = state => {
  // If radius is not null, then it must be greater than zero
  if (state.radius != null && state.radius <= 0) {
    throw new Error('Radius must be greater than zero when it is non-null');
  }

  return state;
};

/**
 * Compares two search models to determine if they are equivalent (not
 * necessarily equal).
 * Note: `recommendedSearch` property does not impact equivalency.
 * @param {SearchModel} a - First search model to compare
 * @param {SearchModel} b - Second search model to compare
 * @returns {boolean} True if models are equivalent, otherwise false
 */
const searchModelsAreEquivalent = (a, b) => {
  const aLat = a.location != null ? a.location.latitude : null;
  const aLon = a.location != null ? a.location.longitude : null;
  const aShops = a.shops.join(',');
  const aTypes = a.types.join(',');
  const aValues = a.values?.join(',') ?? '';

  const bLat = b.location != null ? b.location.latitude : null;
  const bLon = b.location != null ? b.location.longitude : null;
  const bShops = b.shops.join(',');
  const bTypes = b.types.join(',');
  const bValues = b.values?.join(',') ?? '';

  return a.category === b.category &&
    a.community?.slug === b.community?.slug && 
    a.query === b.query &&
    a.useCurrentLocation === b.useCurrentLocation &&
    aLat === bLat &&
    aLon === bLon &&
    a.maxPrice === b.maxPrice &&
    a.minPrice === b.minPrice &&
    a.offersFreeShipping === b.offersFreeShipping &&
    a.offersLocalPickup === b.offersLocalPickup &&
    a.radius === b.radius &&
    aShops === bShops &&
    aTypes === bTypes &&
    a.subcategory === b.subcategory &&
    aValues === bValues;
};

/**
 * Compares the given search model to the default search model to determine if
 * they are equivalent.
 * @param {SearchMode} model - Search model to compare agains the default
 * @returns {boolean} True if the mdoel is equivalent to the default, otherwise
 * false
 */
const isDefaultSearchModel = model => {
  const defaultModel = initSearch();
  return searchModelsAreEquivalent(model, defaultModel);
};

/**
 * @function getSearchModelFromUrlQuery
 * Builds a search model from the given URL query. URL search params map to a
 * `SearchModel` like so:
 * 
 * - 'ca'  -> `category`
 * - 'co'  -> `community`
 * - 'q'   -> `query`
 * - 'c'   -> `useCurrentLocation` (`1` -> `true`, `0` -> `false`)
 * - 's'   -> `shop`
 * - 'lat' -> `location.latitude`
 * - 'lon' -> `location.longitude`
 * - 'max' -> `maxPrice`
 * - 'min' -> `minPrice`
 * - 'fs'  -> `offersFreeShipping`
 * - 'lp'  -> `offersLocalPickup`
 * - 'r'   -> `radius`
 * - 'rs'  -> `recommendedSearch`
 * - 'sc'  -> `subcategory`
 * - 't'   -> `types`
 * - 'v'   -> `values`
 * 
 * @param {object} urlQuery - URL search params object
 * @param {boolean} [useDefault] - Optional flag to use the default search
 * model as a base when building the search model from the URL query.
 * @returns {SearchModel} Search model
 */
const getSearchModelFromUrlQuery = (urlQuery, useDefault) => {
  const category = urlQuery.get('ca') ?? null;
  const communitySlug = urlQuery.get('co') ?? null;
  const query = urlQuery.get('q');
  const useCurrentLocation = (parseInt(urlQuery.get('c')) || 0) === 1;
  const lat = parseFloat(urlQuery.get('lat')) || null;
  const lon = parseFloat(urlQuery.get('lon')) || null;
  const maxPrice = parseFloat(urlQuery.get('max')) || null;
  const minPrice = parseFloat(urlQuery.get('min')) || null;
  const offersFreeShipping = (parseInt(urlQuery.get('fs')) || 0) === 1;
  const offersLocalPickup = (parseInt(urlQuery.get('lp')) || 0) === 1;
  const radius = parseFloat(urlQuery.get('r')) || null;
  const ulat = parseFloat(urlQuery.get('ulat')) || null;
  const ulon = parseFloat(urlQuery.get('ulon')) || null;
  const userLocationFailed = (parseInt(urlQuery.get('f')) || 0) === 1;
  const recommendedSearch = (parseInt(urlQuery.get('rs')) || 0) === 1;
  const shopSlugs = urlQuery.getAll('s');
  const subcategory = urlQuery.get('sc') ?? null;
  const typeSlugs = urlQuery.getAll('t');
  const values = urlQuery.getAll('v');
  let location = null;
  let shops = [];
  let userLocation = null;
  let types = [];
  let community = null;

  if (lat != null && lon != null) {
    location = {
      latitude: lat,
      longitude: lon
    };
  }

  if (useCurrentLocation) {
    userLocation = location;
  } else if (ulat != null && ulon != null) {
    userLocation = {
      latitude: ulat,
      longitude: ulon
    };
  }

  if (shopSlugs && shopSlugs.length) {
    // Search filters want the slug and the name, so look it up from cache
    shops = shopSlugs.map(slug => ({
      name: Cache.shops.getName(slug),
      slug,
    }));
  }

  if (typeSlugs && typeSlugs.length) {
    types = Cache.getTypes().filter(type => typeSlugs.includes(type.slug));
  }

  if (communitySlug) {
    community = Cache.communities.get(communitySlug)
  }

  const newSearchModel = {
    category,
    community,
    query,
    useCurrentLocation,
    location,
    maxPrice,
    minPrice,
    offersFreeShipping,
    offersLocalPickup,
    radius,
    recommendedSearch,
    shops,
    subcategory,
    types,
    userLocation,
    userLocationFailed,
    values
  };

  // If using the default as a base, remove any null/undefined properties so
  // they don't overwrite the defaults.
  Object.keys(newSearchModel)
    .forEach(key => newSearchModel[key] == null && delete newSearchModel[key]);

  return {
    ...useDefault ? initSearch() : {},
    ...newSearchModel
  };
};

/**
 * @function getUrlQueryFromSearchModel
 * Builds a URL query object from a search model. A `SearchModel` maps to URL
 * search params like so:
 * 
 * - `category`           -> 'ca'
 * - `community`          -> 'co'
 * - `query`              -> 'q'
 * - `useCurrentLocation` -> 'c' (`true` -> `1`, `false` -> `0`)
 * - `shops`              -> 's'
 * - `location.latitude`  -> 'lat'
 * - `location.longitude` -> 'lon'
 * - `maxPrice`           -> 'max'
 * - `minPrice`           -> 'min'
 * - `offersFreeShipping` -> 'fs'
 * - `offersLocalPickup`  -> 'lp'
 * - `radius`             -> 'r'
 * - `recommendedSearch`  -> 'rs'
 * - `subcategory`        -> 'sc'
 * - `types`              -> 't'
 * - `values`             -> 'v'
 * 
 * @param {SearchModel} model - Search model from which to build URL query
 * @returns {object} URLSearchParams object
 */
const getUrlQueryFromSearchModel = model => {
  const params = new URLSearchParams();

  if (model != null) {
    params.append('q', model.query ?? '');
    params.append('c', model.useCurrentLocation ? 1 : 0);

    if (model.shops?.length) {
      model.shops.forEach(shop => params.append('s', shop.slug));
    }

    // Add flag when user location lookup failed to trigger effect on search
    // page, even if none of the other properties changed
    if (model.userLocationFailed) {
      params.append('f', 1);
    }

    // Add flag when this is a recommended search so we can track it
    if (model.recommendedSearch) {
      params.append('rs', 1);
    }

    // Add narrower search filters
    if (model.maxPrice) {
      params.append('max', model.maxPrice);
    }

    if (model.minPrice) {
      params.append('min', model.minPrice);
    }

    if (model.offersFreeShipping) {
      params.append('fs', 1);
    }

    if (model.offersLocalPickup) {
      params.append('lp', 1);
    }

    if (model.values && model.values.length) {
      model.values.forEach(value => params.append('v', value));
    }

    if (model.category) {
      params.append('ca', model.category);
    }

    if (model.subcategory) {
      params.append('sc', model.subcategory);
    }

    if (model.types?.length) {
      model.types.forEach(type => params.append('t', type.slug));
    }

    if (model.community) {
      params.append('co', model.community.slug);
    }
    
    if (model.location != null && model.radius != null) {
      params.append('r', model.radius);
      params.append('lat', model.location.latitude);
      params.append('lon', model.location.longitude);
    }

    // Include the user's location explicitly if it's not using the current
    // location
    if (!model.useCurrentLocation && model.userLocation != null) {
      params.append('ulat', model.userLocation.latitude);
      params.append('ulon', model.userLocation.longitude);
    }
  }

  return params;
};

/**
 * @function getSearchModelInput
 * Builds GraphQL `SearchModelInput` object from the given search model.
 * @param {object} model - Search model
 * @returns {object} `SearchModelInput` object
 */
const getSearchModelInput = model => ({
  category: model.category,
  community: model.community?.slug,
  exclude: model.exclude,
  latitude: model.location?.latitude,
  longitude: model.location?.longitude,
  maxPrice: model.maxPrice,
  minPrice: model.minPrice,
  offersFreeShipping: model.offersFreeShipping,
  offersLocalPickup: model.offersLocalPickup,
  query: model.query,
  radius: model.radius,
  recommendedSearch: model.recommendedSearch,
  slugs: model.shops?.map(shop => shop.slug) ?? [],
  subcategory: model.subcategory,
  types: model.types?.map(type => type.slug) ?? [],
  userLatitude: model.userLocation?.latitude,
  userLongitude: model.userLocation?.longitude,
  values: model.values ?? []
});

/**
 * Reducer function to update the search context state. Valid action types:
 * - 'set'
 * - 'set and search'
 * - 'search'
 * - 'stop search'
 * - 'reset'
 * 
 * @param {object} state - Current state of the search context
 * @param {object} action - Action object to apply to the search context
 * @returns Updated search context state.
 */
const searchReducer = (state, action) => {
  switch (action.type) {
    /**
     * Set search model properties.
     */
    case 'set':
      return enforceRules({
        ...state,
        ...action.payload,
      });
    /**
     * Set search model properties and set flag to execute search.
     */
    case 'set and search':
      return enforceRules({
        ...state,
        ...action.payload,
        shouldSearch: true,
      });
    /**
     * Set flag to execute search.
     */
    case 'search':
      return {
        ...state,
        shouldSearch: true,
      };
    /**
     * Set flag to not execute search.
     */
    case 'stop search':
      return {
        ...state,
        shouldSearch: false,
      };
    /**
     * Reset the search model to an initial state.
     */
    case 'reset':
      return initSearch(action.payload);
    /**
     * Unknown action type!
     */
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

/**
 * The context for the current search state.
 */
const SearchContext = createContext();

/**
 * The context for the search state updater.
 */
const SearchDispatchContext = createContext();

/**
 * The Context Provider for the SearchContext.
 * @param {children} Object - Children components to place inside the provider
 * @returns Context Provider component
 */
const SearchProvider = ({ children, model }) => {
  const [state, dispatch] = useReducer(searchReducer, model, initSearch);

  return (
    <SearchContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
};

/**
 * Hook to use the search state context.
 * @returns {object} SearchContext
 */
const useSearch = () => {
  const searchContext = useContext(SearchContext);

  if (typeof searchContext === 'undefined') {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return searchContext;
};

/**
 * Hook to use the search dispatch context.
 * @returns {object} SearchDispatchContext
 */
const useSearchDispatch = () => {
  const searchDispatchContext = useContext(SearchDispatchContext);

  if (typeof searchDispatchContext === 'undefined') {
    throw new Error('useSearchDispatch must be used within a SearchProvider');
  }

  return searchDispatchContext;
};

/**
 * Debugger component to spit out search model
 */
const SearchDebugger = () => {
  const search = useSearch();

  return (
    <div style={{ backgroundColor: '#f99' }}>
      {JSON.stringify(search)}
    </div>
  );
};

export {
  SearchProvider,
  SearchDebugger,
  useSearch,
  useSearchDispatch,
  searchModelsAreEquivalent,
  isDefaultSearchModel,
  getSearchModelFromUrlQuery,
  getUrlQueryFromSearchModel,
  getSearchModelInput,
};
