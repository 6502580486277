/**
 * @fileover Implements a generic Section component used as a wrapper.
 */

import { Box, Container } from '@mui/material'

const sectionStyles = {
  white: {
    backgroundColor: 'common.white',
    color: 'darkGrey.main'
  },
  grey: {
    backgroundColor: 'grey.main',
    color: 'darkGrey.main'
  },
  greyLight: {
    backgroundColor: 'grey.light',
    color: 'darkGrey.main'
  },
  primary: {
    backgroundColor: 'primary.main',
    color: 'grey.light',
    '& .MuiTypography-root': {
      color: 'grey.light'
    }
  },
  secondary: {
    backgroundColor: 'secondary.main',
    color: 'grey.light',
    '& .MuiTypography-root': {
      color: 'grey.light'
    }
  },
  secondaryLight: {
    backgroundColor: 'secondary.light',
    color: 'darkGrey.main'
  },
  gradient1: {
    background: theme => `linear-gradient(300deg, ${theme.palette.grey.main} 50%, ${theme.palette.common.white} 100%)`,
    color: 'darkGrey.main'
  }
}

export default function Section(props) {
  const { boxProps, children, color, id, maxWidth } = props
  const sectionStyle = sectionStyles.hasOwnProperty(color) ?
    sectionStyles[color] : null
  

  return (
    <Box id={id} sx={{
      overflow: 'hidden',
      ...sectionStyle
    }}>
      <Container maxWidth={maxWidth || 'lg'}>
        <Box py={8} {...boxProps}>
          {children}
        </Box>
      </Container>
    </Box>
  )
}
