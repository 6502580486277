/**
 * @fileoverview Implements a filter for narrowing which shops are included in
 * the search results based on the delivery methods they offer.
 */

import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

export default function FilterDelivery(props) {
  const { offersFreeShipping, offersLocalPickup, onChange } = props

  const handleFreeShippingChecked = event => {
    const payload = { offersFreeShipping: event.target.checked }
    onChange && onChange(payload)
  }

  const handleLocalPickupChecked = event => {
    const payload = { offersLocalPickup: event.target.checked }
    onChange && onChange(payload)
  }

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend">
        Delivery
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={offersFreeShipping ?? false}
              onChange={handleFreeShippingChecked}
            />
          }
          label="Offers free shipping"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={offersLocalPickup ?? false}
              onChange={handleLocalPickupChecked}
            />
          }
          label="Offers local pickup"
          sx={{ mt: -1.5 }}
        />
      </FormGroup>
    </FormControl>
  )
}
