/**
 * @fileoverview Implements content for the Checkout -> Address step.
 */

import { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Select,
  Step,
  StepButton,
  StepContent,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography
} from '@mui/material'

import AddressFormatter from '../../lib/address-formatter'
import CheckoutStepContentSkeleton from './checkout-step-content-skeleton'
import PebbleApi from '../../lib/pebble-api'

import SnackbarAlert from '../snackbar-alert'

const states = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' }
]

const countries = [
  { name: 'United States', code: 'US' },
  { name: 'Canada', code: 'CA' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antigua & Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Ascension Island', code: 'AC' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia & Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'British Virgin Islands', code: 'VG' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Caribbean Netherlands', code: 'BQ' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo - Brazzaville', code: 'CG' },
  { name: 'Congo - Kinshasa', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Côte d’Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czechia', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Eswatini', code: 'SZ' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard & McDonald Islands', code: 'HM' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong SAR', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Kosovo', code: 'XK' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Laos', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao SAR', code: 'MO' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Moldova', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar (Burma)', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'North Korea', code: 'KP' },
  { name: 'North Macedonia', code: 'MK' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palestinian Territories', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn Islands', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russia', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'São Tomé & Príncipe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten', code: 'SX' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia & South Sandwich Islands', code: 'GS' },
  { name: 'South Korea', code: 'KR' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'St. Barthélemy', code: 'BL' },
  { name: 'St. Helena', code: 'SH' },
  { name: 'St. Kitts & Nevis', code: 'KN' },
  { name: 'St. Lucia', code: 'LC' },
  { name: 'St. Martin', code: 'MF' },
  { name: 'St. Pierre & Miquelon', code: 'PM' },
  { name: 'St. Vincent & Grenadines', code: 'VC' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard & Jan Mayen', code: 'SJ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syria', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad & Tobago', code: 'TT' },
  { name: 'Tristan da Cunha', code: 'TA' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks & Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'U.S. Outlying Islands', code: 'UM' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vatican City', code: 'VA' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Wallis & Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
  // { name: 'Unknown Region', code: 'ZZ' }
]

export default function CheckoutStepAddress(props) {
  const {
    address: propsAddress,
    completed,
    errors,
    isCompleting,
    isGuest,
    onChange,
    onClick,
    onComplete,
    ...otherProps
  } = props
  const [isValid, setIsValid] = useState(false)
  const [address, setAddress] = useState(propsAddress)
  const [formattedAddress, setFormattedAddress] = useState('')
  const [addressErrors, setAddressErrors] = useState(errors ?? {})
  const [saving, setSaving] = useState(false)
  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  const requiredFields = useMemo(() => {
    const fields = [
      'first_name',
      'last_name',
      'address1',
      'city',
      'country_code',
      'province_code',
      'zip',
      'phone'
    ]

    if (isGuest) {
      // Email field is required for guest checkouts
      fields.push('email')
    }

    return fields
  }, [isGuest])

  /**
   * @function validateAddress
   * Validates the entire address for overall validity.
   */
  const validateAddress = useCallback(() => {
    let newIsValid = true;

    if (address != null) {
      requiredFields.forEach(field => {
        if (address[field] == null || address[field].trim().length === 0) {
          newIsValid = false
        }
      })
    }

    setIsValid(newIsValid)
  }, [address, requiredFields])

  /**
   * @function validateAddressProp
   * Validates a specific address property for errors.
   * @param {string} prop - Address property name
   * @returns {function} Validation function for the specific given property
   */
  const validateAddressProp = prop => event => {
    const value = event.target.value
    const newAddressErrors = { ...addressErrors }

    if (requiredFields.includes(prop) &&
        (value == null || value.trim().length === 0)) {
      newAddressErrors[prop] = true
    } else {
      delete newAddressErrors[prop]
    }

    setAddressErrors(newAddressErrors)
  }

  useEffect(() => {
    validateAddress()
    onChange && onChange(address)
    setFormattedAddress(AddressFormatter.toOneLine(address))
  }, [address, onChange, validateAddress])

  useEffect(() => {
    setAddressErrors(errors)
  }, [errors])

  const saveAccount = () => {
    // Build user object to push to the server
    const newUser = {
      address: address
    }

    setSaving(true)

    PebbleApi
      .saveUser(newUser)
      .then(success => {
        if (mounted.current) {
          setSaving(false)
          if (success) {
            setShowSavedSnackbar(true)
          }
        }
    })
  }

  const handleSavedSnackbarClose = () => {
    setShowSavedSnackbar(false)
  }

  const handleAddressPropChanged = prop => event => {
    const newAddress = { ...address }
    newAddress[prop] = event.target.value
    setAddress(newAddress)
  }

  const handleSignUpChanged = event => {
    const newAddress = { ...address }
    newAddress.shouldSignUp = event.target.checked
    setAddress(newAddress)
  }

  const handleCompleted = event => {
    event.stopPropagation()
    onComplete && onComplete()
  }

  if (propsAddress == null) {
    return (
      <Step {...otherProps}>
        <StepButton>
          Delivery address
        </StepButton>
        <StepContent>
          <CheckoutStepContentSkeleton />
        </StepContent>
      </Step>
    )
  }

  return (
    <Step {...otherProps}>
      <StepButton
        onClick={onClick}
        completed={completed}
      >
        Delivery address
        {completed === true && (
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            {' '}
            &mdash;
            {' '}
            {formattedAddress}
          </Typography>
        )}
      </StepButton>
      <StepContent>
        <Box mt={1}>
          <Alert severity="info">
            We need your address to find out what shipping options are available from our merchants.
          </Alert>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                label="First Name"
                name="given-name"
                required
                value={address.first_name}
                onBlur={validateAddressProp('first_name')}
                onChange={handleAddressPropChanged('first_name')}
                variant="outlined"
                error={addressErrors['first_name']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="family-name"
                label="Last Name"
                name="family-name"
                required
                value={address.last_name}
                onBlur={validateAddressProp('last_name')}
                onChange={handleAddressPropChanged('last_name')}
                variant="outlined"
                error={addressErrors['last_name']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="address-line1"
                label="Address Line 1"
                name="address-line1"
                required
                value={address.address1}
                onBlur={validateAddressProp('address1')}
                onChange={handleAddressPropChanged('address1')}
                variant="outlined"
                error={addressErrors['address1']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="address-line2"
                label="Address Line 2"
                name="address-line2"
                value={address.address2}
                onBlur={validateAddressProp('address2')}
                onChange={handleAddressPropChanged('address2')}
                variant="outlined"
                error={addressErrors['address2']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                autoComplete="address-level2"
                label="City"
                name="address-level2"
                required
                value={address.city}
                onBlur={validateAddressProp('city')}
                onChange={handleAddressPropChanged('city')}
                variant="outlined"
                error={addressErrors['city']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl
                required
                variant="outlined"
                disabled={isCompleting}
                sx={{ width: '100%' }}
              >
                <InputLabel id="checkout-step-address-state">State</InputLabel>
                <Select
                  labelId="checkout-step-address-state"
                  label="State"
                  native
                  value={address.province_code}
                  onBlur={validateAddressProp('province_code')}
                  onChange={handleAddressPropChanged('province_code')}
                  error={addressErrors['province_code']}
                  inputProps={{
                    autoComplete: 'address-level1',
                    name: 'address-level1',
                  }}
                >
                  <option aria-label="None" value="" />
                  {states.map(state =>
                    <option
                      key={state.code}
                      value={state.code}
                    >
                      {state.name}
                    </option>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                autoComplete="postal-code"
                label="ZIP Code"
                name="postal-code"
                required
                value={address.zip}
                onBlur={validateAddressProp('zip')}
                onChange={handleAddressPropChanged('zip')}
                variant="outlined"
                error={addressErrors['zip']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                disabled={isCompleting}
                required
                sx={{ width: '100%' }}
                variant="outlined"
              >
                <InputLabel id="checkout-step-address-country">Country</InputLabel>
                <Select
                  inputProps={{
                    autoComplete: 'country',
                    name: 'country',
                  }}
                  labelId="checkout-step-address-country"
                  label="Country"
                  native
                  onChange={handleAddressPropChanged('country_code')}
                  value={address.country_code}
                >
                  <option aria-label="None" value="" />
                  {countries.map(countries =>
                    <option
                      key={countries.code}
                      value={countries.code}
                    >
                      {countries.name}
                    </option>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="tel"
                label="Phone"
                name="tel"
                required
                value={address.phone}
                onBlur={validateAddressProp('phone')}
                onChange={handleAddressPropChanged('phone')}
                variant="outlined"
                error={addressErrors['phone']}
                disabled={isCompleting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                disabled={isCompleting}
                error={Boolean(addressErrors['email'])}
                helperText={addressErrors['email']}
                label="Email"
                name="email"
                onBlur={validateAddressProp('email')}
                onChange={handleAddressPropChanged('email')}
                required={Boolean(isGuest)}
                sx={{ width: '100%' }}
                value={address.email}
                variant="outlined"
              />
            </Grid>
            {isGuest &&
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(address.shouldSignUp)}
                      onChange={handleSignUpChanged}
                    />
                  }
                  label="Sign up for a Pebble account"
                />
              </Grid>
            }
          </Grid>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleCompleted}
            endIcon={isCompleting
              ? <CircularProgress size={20} sx={{ color: 'common.white' }} />
              : null}
            disabled={!isValid || isCompleting}
          >
            Use this address
          </Button>
          {!isGuest &&
            <Button
              color="primary"
              disabled={!isValid || isCompleting || saving}
              endIcon={saving ? <CircularProgress size={20} /> : null}
              onClick={saveAccount}
              sx={{ ml: 1 }}
              variant="contained"
            >
              Save to my account
            </Button>
          }
        </Box>
        <SnackbarAlert
          onClose={handleSavedSnackbarClose}
          open={showSavedSnackbar}
          severity="success"
        >
          Address saved to your account
        </SnackbarAlert>
      </StepContent>
    </Step>
  )
}
