/**
 * @fileoverview Implements a view for displaying recommended products.
 */

import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Box, Grid, Skeleton, Typography } from '@mui/material'

import { useSearch } from '../lib/search-context'
import analyticsEvent, { analyticsEvents } from '../lib/analytics'
import SearchResult from './search/search-result'
import SearchResultSkeleton from './search/search-result-skeleton'
import Section from './section'
import PebbleApi from '../lib/pebble-api'

/**
 * Number of similar products per page
 */
const PAGE_SIZE = 4

/**
 * A component for searching and displaying search results.
 */
export default function SimilarProducts(props) {
  const { color, heading, productId } = props

  if (productId == null || productId.length === 0) {
    throw new Error('Missing prop "productId"')
  }

  const { ref, inView } = useInView()
  const search = useSearch()
  const mounted = useRef(false)
  const [loadedFirstPage, setLoadedFirstPage] = useState(false)
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])
  let products = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    if (!loadedFirstPage && inView) {
      setLoading(true)

      // Grab the first page of recommended products
      /** @todo Support loading additional pages */
      const pagination = { first: PAGE_SIZE }

      PebbleApi
        .getSimilarProductsConnection(productId, pagination)
        .then(result => {
          if (mounted.current) {
            const edges = result.edges
            const nodes = edges.map(edge => edge.node)

            analyticsEvent(analyticsEvents.view_item_list, {
              item_list_id: 'similar',
              item_list_name: 'Similar products',
              items: nodes.map(item => ({
                affiliation: item.shop.slug,
                currency: 'USD',
                item_id: item.product_id,
                item_brand: item.shop.name,
                item_name: item.title,
                price: item.price,
                quantity: item.quantity
              }))
            })

            window.fbq('track', 'ViewContent')

            setResults(nodes)
            setLoading(false)
            setLoadedFirstPage(true)
          }
        })
    }
  }, [inView, loadedFirstPage, productId, search])

  if (loading) {
    // Render skeleton search results while loading the featured products
    products = [...Array(PAGE_SIZE)].map((_, i) => (
      <Grid item key={i} xs={6} md={3}>
        <SearchResultSkeleton />
      </Grid>
    ))
  } else if (results && results.length > 0) {
    products = results.map(product => (
      <Grid item key={product._id} xs={6} md={3}>
        <SearchResult product={product} />
      </Grid>
    ))
  } else if (!results || results.length === 0) {
    // If there are no products, don't render anything at all
    return null
  }

  return (
    <Box ref={ref}>
      <Section color={color}>
        <Typography variant="h2" gutterBottom>
          {loading ?
            <Skeleton width="40%" /> :
            (heading ?? 'Similar products')
          }
        </Typography>
        <Grid container spacing={2}>
          {products}
        </Grid>
      </Section>
    </Box>
  )
}
