/**
 * @fileoverview User account view.
 */

import { lazy } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch
} from 'react-router-dom'

import Grid from '@mui/material/Grid'

import { useUser } from '../../lib/user-context'
import AccountOrders from './account-orders'
import AccountSummary from './account-summary'
import AccountWishlist from './account-wishlist'
import HelmetWrapper from '../helmet-wrapper'
import OnboardInvitation from './onboard/onboard-invitation'
import Section from '../section'
import AccountHome from './account-home'
import SkeletonPage from '../skeleton-page'

// Lazy-load the Community Manager and Shop Manager sections
const CommunityManager = lazy(() => import('./community-manager/community-manager'))
const ShopManager = lazy(() => import('./shop-manager/shop-manager'))

export default function Account () {
  const { loadingUser, user } = useUser()
  const location = useLocation()
  const isInvitation = useMatch('/account/invitation/:id')

  // Always ensure the Account page is not embedded
  if (window.frameElement) {
    // Open current URL in a new tab
    window.open(window.location.href, '_blank')

    // Navigate the frame to the previous URL
    return <Navigate to={-1} />
  }

  if (loadingUser) {
    return <SkeletonPage />
  }

  if (!loadingUser && !user.loggedIn) {
    if (!isInvitation) {
      // If the user is not logged in and this is not an invitation to join
      // Pebble, then redirect to the login page
      const redirectUrl = location.pathname + location.search + location.hash
      const params = new URLSearchParams({ 'r': redirectUrl })
      const newUrl = '/login?' + params.toString()
      return <Navigate to={newUrl} />
    } else {
      // If this is an invitation, show page without account info
      return (
        <>
          <HelmetWrapper title="Invitation" />
          <Section maxWidth="md" boxProps={{ py: 2 }}>
            <Routes>
              <Route path="/invitation/:id" element={<OnboardInvitation />} />
            </Routes>
          </Section>
        </>
      )
    }
  }

  return (
    <>
      <HelmetWrapper title="My Account" />
      <Section maxWidth="xl" boxProps={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <AccountSummary />
          </Grid>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <Routes>
              <Route index element={<AccountHome />} />
              <Route path="/communities/*" element={<CommunityManager />} />
              <Route path="/invitation/:id" element={<OnboardInvitation />} />
              <Route path="/orders" element={<AccountOrders />} />
              <Route path="/shops/*" element={<ShopManager />} />
              <Route path="/wishlist" element={<AccountWishlist />} />
            </Routes>
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
