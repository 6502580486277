/**
 * @fileoverview Implements a single recommended shop.
 */

import { useNavigate } from 'react-router-dom'

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material'

const METERS_PER_MILE = 1609.34

export default function RecommendedShop(props) {
  const { shop } = props
  const navigate = useNavigate()

  if (shop == null) {
    return null
  }

  const handleClick = () => {
    navigate(`/shop/${shop.slug}`)
  }

  // Convert shop distance from meters to miles
  const miles = (shop.distance / METERS_PER_MILE)
    // Show up to 1 decimal place
    .toFixed(1)
    // Remove trailing zero: "1.0" -> "1"
    .replace(/\.0$/, '')

  let cardMediaChildren

  if (!shop.logo_url) {
    let shopNameWords = shop.name.split(' ')
    let maxLineLength = 14
    let shopNameLines = shopNameWords.reduce((lines, word, words) => {
      if (lines.length > 0 &&
          lines[lines.length - 1].length + word.length + 1 < maxLineLength) {
        lines[lines.length - 1] = lines[lines.length - 1] + ' ' + word
      } else {
        lines.push(word)
      }

      return lines
    }, [])

    const longestLineLength = shopNameLines
      .map(i => i.length)
      .sort((a, b) => a - b)
      .pop()

    const width = longestLineLength * 16 * 0.75

    cardMediaChildren = (
      <Box sx={{
        alignItems: 'center',
        backgroundColor: 'secondary.light',
        display: 'flex',
        height: { xs: '180px', sm: '290px' },
        justifyContent: 'center',
        overflowWrap: 'break-word',
        p: 2,
        textAlign: 'center',
        width: '100%',
        '& svg': {
          width: '100%',
          '& text': {
            fill: 'primary.main',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 600,
            fontSize: '16px',
            textTransform: 'uppercase'
          }
        }
      }}>
        <svg viewBox={`0 0 ${width} ${shopNameLines.length * 16}`}>
          <text x={width / 2} y="0" textAnchor="middle">
            {shopNameLines.map((line, index) =>
              <tspan x={width / 2} y={(index + 1) * 16} key={`tspan-${index}`}>{line}</tspan>
            )}
          </text>
        </svg>
      </Box>
    )
  }

  // Render the recommended shop
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'common.white',
        height: '100%',
        overflowWrap: 'anywhere'
      }}
      variant="outlined"
    >
      <CardActionArea
        onClick={handleClick}
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <CardMedia
          children={cardMediaChildren}
          image={shop.logo_url}
          sx={[
            ...(shop.logo_url
              ? [{
                backgroundOrigin: 'content-box',
                backgroundSize: 'contain',
                height: { xs: '180px', sm: '290px' },
                p: 2,
                width: '100%'
              }]
              : [])
          ]}
          title={shop.name}
        />
        <CardContent>
          <Typography variant="h6" component="p">
            {shop.name}
          </Typography>
          <Typography variant="body2">
            {shop.custom_location ?
              `${shop.custom_location.city}, ${shop.custom_location.state}` :
              `${shop.city}, ${shop.province_code}`
            }
            <br />
            <b>{miles} miles</b>
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
      </CardActionArea>
    </Card>
  )
}
