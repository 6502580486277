/**
 * @fileoverview Implements a view showing all categories.
 */

import { useNavigate } from 'react-router-dom'

import { Box, Grid, Paper, Typography } from '@mui/material'

import Section from './section'

const categories = [
  {
    name: 'Gifts',
    slug: 'Gifts',
    imageUrl: '/images/categories/gifts.jpg'
  },
  {
    name: 'Home',
    slug: 'Home',
    imageUrl: '/images/categories/home.jpg',
    backgroundPosition: 'bottom center'
  },
  {
    name: 'Self-Care',
    slug: 'Self-care',
    imageUrl: '/images/categories/self-care.jpg'
  },
  {
    name: 'Books',
    slug: 'Books',
    imageUrl: '/images/categories/books.jpg'
  },
  {
    name: 'Women',
    slug: 'Women',
    imageUrl: '/images/categories/women.jpg',
    backgroundPosition: 'top center'
  },
  {
    name: 'Men',
    slug: 'Men',
    imageUrl: '/images/categories/men.jpg',
    backgroundPosition: 'top center'
  },
  {
    name: 'Kids',
    slug: 'Kids',
    imageUrl: '/images/categories/kids.jpg'
  },
  {
    name: 'Pets',
    slug: 'Pets',
    imageUrl: '/images/categories/pets.jpg'
  },
  {
    name: 'Outdoor',
    slug: 'Outdoor',
    imageUrl: '/images/categories/outdoor.jpg'
  },
  {
    name: 'Art',
    slug: 'Art',
    imageUrl: '/images/categories/art.jpg'
  }
]

export default function CategoriesGrid(props) {
  const { id } = props
  const navigate = useNavigate()

  const handleCategoryClick = category => () => {
    navigate(`/search?q=&ca=${category.slug}`)
  }

  return (
    <Section color="white" id={id}>
      <Typography
        color="secondary"
        variant="h2"
        gutterBottom
      >
        More to explore
      </Typography>
      <Grid container spacing={2}>
        {categories.map(category =>
          <Grid item key={category.name} xs={6} sm={3}>
            <Paper
              elevation={0}
              onClick={handleCategoryClick(category)}
              sx={{
                alignItems: 'center',
                // aspectRatio: 1 / 1,
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${category.imageUrl})`,
                backgroundPosition: category.backgroundPosition ?? 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                pt: '75%'
              }}
            >
              <Box sx={{
                color: 'white',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                pb: { xs: 3, md: 4 },
                textAlign: 'center',
                textTransform: 'uppercase'
              }}>
                {category.name}
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}
