/**
 * @fileoverview Implements a filter for geolocation search radius, to be used
 * in conjunction with searching from a specific location.
 */

import { useEffect, useState } from 'react'

import { Box, Button, FormLabel, Menu, MenuItem } from '@mui/material'

const options = [
  { value: 1, label: '1 mile' },
  { value: 2, label: '2 miles' },
  { value: 5, label: '5 miles' },
  { value: 9, label: '9 miles' },
  { value: 10, label: '10 miles' },
  { value: 20, label: '20 miles' },
  { value: 40, label: '40 miles' },
  { value: 60, label: '60 miles' },
  { value: 80, label: '80 miles' },
  { value: 100, label: '100 miles' },
  { value: 250, label: '250 miles' },
  { value: 500, label: '500 miles' }
]

export default function FilterRadius(props) {
  const { value, onChange } = props

  const [filterRadiusAnchorEl, setFilterRadiusAnchorEl] = useState(null)
  const [radius, setRadius] = useState(value)
  const [radiusIndex, setRadiusIndex] =
    useState(options.findIndex(option => option.value === radius))

  // Handle radius changing from top down
  // E.g. if radius is coming from search page URL
  useEffect(() => {
    setRadius(value)
  }, [value])

  const handleFilterRadiusClick = (event) => {
    setFilterRadiusAnchorEl(event.currentTarget)
  }

  const handleFilterRadiusClose = () => {
    setFilterRadiusAnchorEl(null)
  }

  const handleOptionClick = (_event, index) => {
    const newRadius = options[index].value
    setRadius(newRadius)
    onChange && onChange(newRadius)
    setRadiusIndex(index)
    handleFilterRadiusClose()
  }

  const isFilterRadiusOpen = Boolean(filterRadiusAnchorEl)
  const filterRadiusId = isFilterRadiusOpen
    ? 'filter-radius-popover'
    : undefined

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <FormLabel sx={{ pb: 1 }}>
          Radius
        </FormLabel>
        <Button
          aria-describedby={filterRadiusId}
          fullWidth
          variant="outlined"
          onClick={handleFilterRadiusClick}
        >
          {radius} miles
        </Button>
      </Box>
      <Menu
        id={filterRadiusId}
        open={isFilterRadiusOpen}
        anchorEl={filterRadiusAnchorEl}
        keepMounted
        onClose={handleFilterRadiusClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === radiusIndex}
            onClick={event => handleOptionClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
