/**
 * @fileoverview Defines a Material UI theme for the whole client application.
 */

import { createTheme } from '@mui/material/styles'

const colors = {
  pebbleGreen: '#817755',
  pebbleRustDark: '#b76e35',
  pebbleRust: '#e3c6b0',
  pebbleRustLight: '#efe2ce',
  pebbleGreyDark: '#d0d0d1',
  pebbleGrey: '#eeeeea',
  pebbleGreyLight: '#f7f6f5',
  pebbleDarkGrey: '#4c4a47',
  pebbleDarkGreyLight: '#7e7b71'
}

const theme = createTheme({
  typography: {
    color: colors.pebbleDarkGreyLight,
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      color: colors.pebbleGreen,
      fontSize: '3rem',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    h2: {
      color: colors.pebbleGreen,
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    h3: {
      color: colors.pebbleGreen,
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    h4: {
      color: colors.pebbleGreen,
      fontSize: '1.25rem',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    h5: {
      color: colors.pebbleGreen,
      fontSize: '1.1rem',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    h6: {
      color: colors.pebbleGreen,
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.3',
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: '1.1rem',
      lineHeight: '1.5'
    },
    body2: {
      fontSize: '0.9rem',
      lineHeight: '1.5'
    }
  },
  palette: {
    primary: {
      main: colors.pebbleGreen,
      contrastText: colors.pebbleGreyLight
    },
    secondary: {
      light: colors.pebbleRustLight,
      main: colors.pebbleRustDark,
      dark: colors.pebbleRust
    },
    grey: {
      light: colors.pebbleGreyLight,
      main: colors.pebbleGrey,
      dark: colors.pebbleGreyDark
    },
    darkGrey: {
      main: colors.pebbleDarkGrey,
      light: colors.pebbleDarkGreyLight
    },
    text: {
      primary: colors.pebbleDarkGrey
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: colors.pebbleDarkGrey
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontWeight: 600
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.pebbleDarkGrey
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        },
        underline: {
          borderBottom: 'none',
          '&.Mui-disabled:before': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          color: colors.pebbleRustDark
        }
      }
    }
  }
})

export default theme
