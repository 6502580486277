/**
 * @fileoverview Cache for Pebble product types.
 */

import Cache from './cache';

/**
 * Cache item key
 */
const KEY = 'types';

/**
 * Time to expiration in minutes
 */
const EXPIRY = 1440; // 24 hours

export default class TypesCache extends Cache {
  /**
  * @function get
  * Retrieves types from the cache
  * @returns {Array<object>} List of types
  */
  static get() {
    return Cache.get(KEY);
  }

  /**
  * @function set
  * Stores types in the cache
  * @param {Array<object>} types
  */
  static set(types) {
    Cache.set(KEY, types, EXPIRY);
  }
}
