/**
 * @fileoverview Implementation of Google Maps as a React component to show
 * search results as a map of shops close to the user.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

import { Box } from '@mui/material'

import { useSearch, useSearchDispatch } from '../../lib/search-context'
import PebbleApi from '../../lib/pebble-api'

/**
 * Search must be done in meters, but the radius is more naturally presented
 * in miles.
 */
const METERS_PER_MILE = 1609.34

/**
 * Default coordinates in the middle of the US.
 */
const MIDDLE_OF_THE_USA = { lat: 38.5362515, lng: -105.5705632 }

export default function FilterMap(props) {
  const { shops } = props
  const search = useSearch()
  const searchDispatch = useSearchDispatch()
  const mounted = useRef(false)
  const mapRef = useRef(null)
  const isGoogleMapsLoaded = useRef(false)

  const [coords, setCoords] = useState(MIDDLE_OF_THE_USA)
  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState([])
  const [infoWindow, setInfoWindow] = useState(null)
  const [radius, setRadius] = useState(null)
  const [radiusCircle, setRadiusCircle] = useState(null)
  const [userMarker, setUserMarker] = useState(null)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  /**
   * @function updateLocation
   * Updates the search location based on the new given map center position.
   * Makes an API call to get more details about the location (city, state,
   * etc.).
   */
  const updateLocation = useCallback(center => {
    // Make a call to the API to get location from new center
    PebbleApi
      .getGeolocation(center.lat(), center.lng())
      .then(geoloc => {
        if (mounted.current) {
          searchDispatch({ type: 'set', payload: {
            location: geoloc,
            useCurrentLocation: false
          }})
        }
      })
  }, [searchDispatch])

  /**
   * @function showInfoWindow
   * Displays the info window with info about the given shop when the given
   * marker is clicked.
   * 
   * NOTE: This is a double arrow function!
   */
  const showInfoWindow = useCallback((marker, shop) => () => {
    if (map && infoWindow) {
      let address = ''

      if (shop.custom_location) {
        address = `
          ${shop.custom_location.city}, ${shop.custom_location.state}
          ${shop.custom_location.postalCode}
        `
      } else {
        address = `
          ${shop.address1}<br>
          ${shop.address2 != null && shop.address2.length > 0 ? shop.address2 + '<br>' : ''}
          ${shop.city}, ${shop.province} ${shop.zip}
        `
      }

      infoWindow.setContent(`
        <b><a href="/shop/${shop.slug}">${shop.name}</a></b>
        <br>
        ${address}
      `)

      infoWindow.open(map, marker)
    }
  }, [map, infoWindow])

  useEffect(() => {
    if (!isGoogleMapsLoaded.current) {
      // Initialize the Google Maps API loader
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly'
      })

      // Load the Google Maps API
      loader.load()
        .then(() => {
          isGoogleMapsLoaded.current = true

          // Then create the map
          const mapNode = mapRef.current
          const newMap = new window.google.maps.Map(mapNode, {
            center: MIDDLE_OF_THE_USA,
            // Disable map type controls, e.g. "Map" and "Satellite"
            mapTypeControl: false,
            // Disable street view control
            streetViewControl: false,
            // Set a default zoom level
            zoom: 8
          })
      
          setMap(newMap)

          // Create a marker for the user's location
          const newUserMarker = new window.google.maps.Marker({
            position: MIDDLE_OF_THE_USA,
            map: newMap,
            title: 'You are here!',
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: '#505c60',
              fillOpacity: 0.5,
              strokeColor: '#253439',
              strokeWeight: 2,
              strokeOpacity: 0.5,
              scale: 5
            }
          })

          setUserMarker(newUserMarker)

          // Create a radius circle around the user's location
          const newRadiusCircle = new window.google.maps.Circle({
            map: newMap,
            radius: 0,
            center: MIDDLE_OF_THE_USA,
            strokeColor: "#B29E84",
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: "#E4D7CA",
            fillOpacity: 0.5
          })

          setRadiusCircle(newRadiusCircle)

          // Create a single info window used by all markers
          const newInfoWindow = new window.google.maps.InfoWindow({
            content: ''
          })

          setInfoWindow(newInfoWindow)

          newMap.addListener('center_changed', () => {
            newUserMarker.setPosition(newMap.getCenter())
            newRadiusCircle.setCenter(newMap.getCenter())
          })

          newMap.addListener('idle', () => {
            // Unset max zoom after fitting to bounds so the user can zoom in
            // as far as they want
            newMap.setOptions({ maxZoom: null })
          })

          newMap.addListener('dragend', () => {
            const center = newMap.getCenter()
            updateLocation(center)
          })
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [updateLocation])

  // When the search changes, update the radius and coordinates on the map
  // accordingly
  useEffect(() => {
    if (search) {
      // Update search center location
      if (search.location) {
        // Update radius in miles
        if (search.radius > 0) {
          const newRadius = search.radius * METERS_PER_MILE
          setRadius(newRadius)
        }

        const newCoords = {
          lat: search.location.latitude,
          lng: search.location.longitude
        }

        // Only update the coordinates if they changed
        setCoords(oldCoords => {
          if (oldCoords == null ||
              newCoords.lat !== oldCoords.lat ||
              newCoords.lng !== oldCoords.lng) {
            return newCoords
          }

          return oldCoords
        })
      } else {
        // If there's no search location, remove center and radius
        setRadius(null)
        setCoords(null)
      }
    }
  }, [search])

  // When the coordinates are updated, recenter the map as well as the radius
  // circle and user marker on the map
  useEffect(() => {
    if (map) {
      if (coords) {
        // Center the map at the user's location
        map.setCenter(coords)
        window.google.maps.event.trigger(map, 'recenter')

        // Center the radius circle at the user's location
        if (radiusCircle) {
          radiusCircle.setCenter(coords)
          radiusCircle.setMap(map)
        }

        // Move the user marker to the user's location
        if (userMarker) {
          userMarker.setPosition(coords)
          userMarker.setMap(map)
        }
      } else {
        // If there's no coords, remove the radius circle
        if (radiusCircle) {
          radiusCircle.setMap(null)
        }

        if (userMarker) {
          userMarker.setMap(null)
        }
      }
    }
  }, [coords, map, radiusCircle, userMarker])

  // When the list of shops changes, update the shop markers on the map
  useEffect(() => {
    if (map && shops) {
      const newMarkers = []
      
      for (const shop of shops) {
        // Create a marker for this shop's location
        const marker = new window.google.maps.Marker({
          position: {
            // The shop coordinates are in a GeoJSON object in an array ordered
            // longitude then latitude.
            lat: shop.location.coordinates[1],
            lng: shop.location.coordinates[0]
          },
          map: map,
          title: shop.name
        })

        marker.addListener('click', showInfoWindow(marker, shop))
        newMarkers.push(marker)
      }

      setMarkers(oldMarkers => {
        // Remove old markers from the map first
        oldMarkers.forEach(marker => marker.setMap(null))
        return newMarkers
      })
    }
  }, [map, shops, showInfoWindow])

  // When the search radius changes, update the radius circle on the map
  useEffect(() => {
    if (map && radiusCircle) {
      if (radius > 0) {
        radiusCircle.setRadius(radius)
        radiusCircle.setMap(map)
      } else {
        // If there's no radius, remove the radius circle
        radiusCircle.setMap(null)
      }
    }
  }, [map, radius, radiusCircle])

  // When the shop markers and search radius change, update the map bounds to
  // fit everything within view
  useEffect(() => {
    if (map) {
      var bounds = new window.google.maps.LatLngBounds()
  
      // Extend bounds to include each shop marker
      for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition())
      }
  
      // If radius circle is shown, extend bounds to show entire radius circle
      if (radiusCircle && radiusCircle.getMap() === map) {
        bounds.union(radiusCircle.getBounds())
      }
  
      // Set max zoom before fitting to bounds so it doesn't zoom in too far
      map.setOptions({ maxZoom: 15 })
      map.fitBounds(bounds)
    }
  }, [map, markers, radius, radiusCircle])

  return (
    <Box
      ref={mapRef}
      sx={{
        borderRadius: '4px',
        width: '100%',
        height: '180px',
        mt: 1
      }}
    />
  )
}
