import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ItemVariantList(props) {
  const { variant, variants, onChange } = props;
  const [currentVariant, setCurrentVariant] = useState(null);
  const [currentVariantIndex, setCurrentVariantIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (variant != null) {
      const variantIndex = variants.findIndex(v => v.id === variant.id);
      setCurrentVariant(variant);
      setCurrentVariantIndex(variantIndex);
    }
  }, [variant, variants]);

  useEffect(() => {
    onChange && onChange(currentVariant);
  }, [currentVariant, onChange]);

  const handleVariantChange = index => {
    if (index === currentVariantIndex) {
      // This variant is already selected, so now unselect it
      setCurrentVariant(null);
      setCurrentVariantIndex(-1);
    } else {
      setCurrentVariant(variants[index]);
      setCurrentVariantIndex(index);
    }

    setIsMenuOpen(false);
  };

  const handleToggle = () => {
    setIsMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsMenuOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleToggle}
        endIcon={<ArrowDropDownIcon />}
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-controls={isMenuOpen ? 'item-variant-list-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-label="select product variant"
        aria-haspopup="menu"
      >
        {currentVariant && currentVariant.title}
      </Button>
      <Menu
        id="item-variant-list-menu"
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {variants.map((productVariant, index) =>
          <MenuItem
            key={productVariant.id}
            selected={index === currentVariantIndex}
            onClick={() => handleVariantChange(index)}
            variant={index === currentVariantIndex ? 'contained' : 'outlined'}
            disabled={productVariant.available === false ||
              (productVariant.inventory_quantity === 0 &&
                productVariant.inventory_policy === 'deny' &&
                productVariant.inventory_management != null)
            }
          >
            {productVariant.title}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
