/**
 * @fileoverview Implements a video section for the Partner page.
 */

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Section from '../section'

export default function PartnerVideo() {
  return (
    <Section color="white" id="why-customers-love-pebble" maxWidth="md">
      <Typography
        align="center"
        component="h2"
        gutterBottom
        variant="h1"
      >
        Why customers love Pebble
      </Typography>
      <Box pt={2} align="center">
        <iframe
          allow="
            accelerometer; 
            autoplay;
            clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture" 
          allowfullscreen
          frameborder="0"
          title="YouTube video player"
          src="https://www.youtube.com/embed/tlpg-pgS2d0"
          style={{
            marginTip: '30px',
            width: '100%',
            height: '570px'
          }}
        >
        </iframe>
      </Box>
    </Section>
  )
}
