/**
 * @fileoverview Implements helper functions for interacting with Hygraph.
 */

import { request, gql } from 'graphql-request'

/**
 * @function getPage
 * Retrieves a page from Hygraph by its slug.
 * @returns {Promise<object>} Object with page data
 */
const getPage = async slug => {
  const query = gql`
    query Assets($slug: String!) {
      page(where: { slug: $slug }) {
        content {
          html
        }
        id
        slug
        title
        updatedAt
      }
    }
  `
  const variables = { slug }
  const response = await request(
    process.env.REACT_APP_HYGRAPH_ENDPOINT,
    query,
    variables
  )

  if (!response.page) {
    return null
  }

  return response.page
}

export { getPage }
