/**
 * @fileoverview Implements the primary app bar.
 */

import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'

import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'
import { AppBar, Box, Button, Toolbar, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { useUser } from '../../lib/user-context.js'
import CartDrawer from '../cart/cart-drawer.js'
import MainMenu from '../main-menu'
import SearchAutocomplete from './search-autocomplete'
import SearchField from './search-field'
import useQuery from '../../lib/use-query'
import {
  getUrlQueryFromSearchModel,
  useSearch,
  useSearchDispatch
} from '../../lib/search-context'

export default function PrimaryAppBar(props) {
  const { excludeSearchRoutes } = props
  const { user } = useUser()
  const search = useSearch()
  const searchDispatch = useSearchDispatch()
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  const query = useQuery()
  const location = useLocation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const isScrolledDown = useScrollTrigger({ disableHysteresis: true })
  const [searchFocused, setSearchFocused] = useState(false)

  let shouldExcludeSearch = excludeSearchRoutes
    .reduce((acc, route) => {
      const match = matchPath(currentPath, route)

      if (match == null) {
        return acc
      }

      return acc || match.isExact
    }, false)

  // Always exclude search bar for the Log In and Sign Up popup windows
  // originating from Shopify admin for account connection, identified
  // by the presence of the `shop` query parameter
  if ((currentPath === '/login' || currentPath === '/signup') &&
      query.has('shop')) {
    shouldExcludeSearch = true
  }

  const goToLogIn = () => {
    if (location.pathname !== '/logout') {
      const redirectUrl = location.pathname + location.search + location.hash
      const params = new URLSearchParams({ 'r': redirectUrl })
      const loginUrl = '/login?' + params.toString()
      navigate(loginUrl)
    } else {
      navigate('/login')
    }
  }

  const goToHome = () => navigate('/')
  const goToSignUp = () => navigate('/signup')
  const goToAccount = () => navigate('/account')

  const handleSearchBlur = () => setSearchFocused(false)
  const handleSearchFocus = () => setSearchFocused(true)

  const handleSearchChange = query => {
    searchDispatch({
      type: 'set',
      payload: {
        query: query,
        // Reset recommended search flag when the query string changes
        recommendedSearch: false,
        category: null,
        subcategory: null
      }
    })
  }

  const handleAutocompleteSearch = query => {
    searchDispatch({ type: 'set and search', payload: { query }})
  }

  const doSearch = useCallback(() => {
    const params = getUrlQueryFromSearchModel(search)
    const pathname = '/search?' + params.toString()

    // When executing a search, always push onto the history stack
    navigate(pathname)
  }, [navigate, search])

  const handleSearchSubmit = () => {
    searchDispatch({ type: 'search' })
  }

  useEffect(() => {
    if (search.shouldSearch) {
      doSearch()
    }
  }, [doSearch, search])

  const searchExpanded = (searchFocused || (mobile && !isScrolledDown))

  return (
    <>
      <AppBar
        color="primary"
        elevation={1}
        position="fixed"
      >
        <Toolbar sx={{
          justifyContent: 'space-between',
          // When user first loads page on mobile, app bar is "fat" with
          // additional actions for the user.
          // When the user scrolls on mobile, app bar collapses to a narrower
          // bar.
          // On desktop, this class should not impact the UI.
          pb: theme => ({
            xs: isScrolledDown
              ? 0
              : 'calc(33px + ' + theme.spacing(1.5) + ')',
            md: 0
          }),
          transitionProperty: 'padding-bottom',
          transitionDuration: '0.2s'
        }}>
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
            opacity: {
              xs: isScrolledDown && searchExpanded ? 0 : 1,
              md: searchExpanded ? 0 : 1
            },
            transitionProperty: 'opacity',
            transitionDuration: '0.2s'
          }}>
            <MainMenu />
            <Box sx={{
              display: {
                xs: isScrolledDown ? 'none' : 'block',
                md: 'block'
              },
              '& img.logo-desktop': {
                cursor: 'pointer',
                display: { xs: 'none', md: 'block' },
                maxHeight: theme => 'calc(64px - ' + theme.spacing(4) + ')'
              },
              '& img.logo-mobile': {
                cursor: 'pointer',
                display: { xs: 'block', md: 'none' },
                my: 2,
                maxHeight: theme => 'calc(56px - ' + theme.spacing(4) + ')'
              }
            }}>
              <img
                alt="Pebble"
                className="logo-desktop"
                onClick={goToHome}
                src="/images/logo-desktop-white_2x.png"
              />
              <img
                alt="Pebble"
                className="logo-mobile"
                onClick={goToHome}
                src="/images/avatar-mobile_2x.png"
              />
            </Box>
          </Box>
          <Box sx={{
            display: { xs: 'block', md: 'none' },
            flexGrow: 1
          }} />
          {!shouldExcludeSearch &&
            <>
              <Box sx={[
                {
                  borderRadius: '17px',
                  backgroundColor: 'common.white',
                  left: theme => ({
                    xs: searchExpanded ? theme.spacing(1) : '52px',
                    md: searchExpanded ? theme.spacing(2) : '25vw'
                  }),
                  position: 'absolute',
                  top: theme => ({
                    xs: theme.spacing(isScrolledDown ? 1.5 : 7),
                    md: searchExpanded ? '15px' : 'unset'
                  }),
                  transitionProperty: 'left, top, width',
                  transitionDuration: '0.2s',
                  width: theme => ({
                    xs: 'calc(100% - ' +
                      (searchExpanded ? theme.spacing(2) : '104px') + ')',
                    md: searchExpanded
                      ? 'calc(100% - ' + theme.spacing(4) + ')'
                      : '50vw'
                  }),
                  '& ::-webkit-search-cancel-button': {
                    '-webkit-appearance': 'none'
                  },
                  zIndex: searchExpanded && 1000
                }
              ]}>
                <SearchField
                  onBlur={handleSearchBlur}
                  onChange={handleSearchChange}
                  onFocus={handleSearchFocus}
                  onSubmit={handleSearchSubmit}
                  value={search.query}
                />
              </Box>
              <SearchAutocomplete
                onSearch={handleAutocompleteSearch}
                open={searchFocused}
                query={search.query}
                sx={{
                  top: { xs: '95px', md: 'unset' }
                }}
              />
            </>
          }
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
            opacity: {
              xs: isScrolledDown && searchExpanded ? 0 : 1,
              md: searchExpanded ? 0 : 1
            },
            transitionProperty: 'opacity',
            transitionDuration: '0.2s'
          }}>
            <Box sx={{
              display: {
                xs: isScrolledDown ? 'none' : 'block',
                md: 'block'
              }
            }}>
              {!user.loggedIn &&
                <>
                  <Button
                    color="inherit"
                    onClick={goToLogIn}
                    size="small"
                    sx={{ borderRadius: theme => theme.spacing(2) }}
                    variant="outlined"
                  >
                    Log in
                  </Button>
                  <Button
                    color="secondary"
                    onClick={goToSignUp}
                    size="small"
                    sx={{
                      borderRadius: theme => theme.spacing(2),
                      ml: 1
                    }}
                  >
                    Sign up
                  </Button>
                </>
              }
              {user.loggedIn &&
                <IconButton
                  onClick={goToAccount}
                  sx={{ color: 'common.white' }}
                >
                  <AccountCircleIcon />
                </IconButton>
              }
            </Box>
            <CartDrawer />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ minHeight: { xs: '101px', md: '64px' } }} />
    </>
  )
}
