/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API for checkout operations.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

/**
 * Mutation to initiate checkout.
 * @returns {object} Pebble order
 */
const MUTATION_INITIATE_CHECKOUT = `
mutation InitiateCheckout($guestCartItems: [CartItemInput]) {
  initiateCheckout(guestCartItems: $guestCartItems) {
    __typename
    ... on Order {
      id
      created
      updated
      shippingAddress {
        address1
        address2
        city
        country_code
        email
        first_name
        last_name
        phone
        province_code
        zip
      }
      shopifyCheckouts {
        discount_code
        line_items {
          compare_at_price
          fulfillment_service
          grams
          id
          line_price
          price
          product_id
          quantity
          requires_shipping
          sku
          taxable
          title
          variant_id
          variant_title
          vendor
        }
        payment_due
        requires_shipping
        shipping_line {
          handle
          price
          title
        }
        shipping_rate {
          id
          price
          title
        }
        shipping_rates {
          id
          price
          title
        }
        shop {
          address1
          address2
          city
          free_shipping_threshold
          local_pickup_timeframe
          name
          offers_free_shipping
          offers_local_pickup
          offersLocalDelivery
          province
          slug
          zip
        }
        shopify_payments_account_id
        subtotal_price
        token
        total_price
        total_tax
      }
    }
    ... on Error {
      code
    }
  }
}
`;

/**
 * Mutation to save the shipping address during checkout.
 * @param {string} id - Pebble order ID
 * @param {object} address - Address object
 * @returns {object} Pebble order
 */
const MUTATION_SAVE_CHECKOUT_SHIPPING_ADDRESS = `
mutation SaveCheckoutShippingAddress($id: String!, $address: AddressInput) {
  saveCheckoutShippingAddress(id: $id, address: $address) {
    __typename
    ... on Order {
      id
      created
      updated
      paymentMethod {
        brand
        expirationMonth
        expirationYear
        lastFour
      }
      shopifyCheckouts {
        discount_code
        line_items {
          compare_at_price
          fulfillment_service
          grams
          id
          line_price
          price
          product_id
          quantity
          requires_shipping
          sku
          taxable
          title
          variant_id
          variant_title
          vendor
        }
        payment_due
        requires_shipping
        shipping_line {
          handle
          price
          title
        }
        shipping_rate {
          id
          price
          title
        }
        shipping_rates {
          id
          price
          title
        }
        shop {
          address1
          address2
          city
          free_shipping_threshold
          local_pickup_timeframe
          name
          offers_free_shipping
          offers_local_pickup
          offersLocalDelivery
          province
          slug
          zip
        }
        shopify_payments_account_id
        subtotal_price
        token
        total_price
        total_tax
      }
    }
    ... on Error {
      code
    }
  }
}
`;

/**
 * Mutation to save the delivery methods during checkout.
 * @param {string} id - Pebble order ID
 * @param {Array<object>} deliveryMethods - Array of selected delivery methods
 * @returns {object} Pebble order
 */
const MUTATION_SAVE_CHECKOUT_DELIVERY_METHODS = `
mutation SaveCheckoutDeliveryMethods($id: String!, $deliveryMethods: [DeliveryMethodInput!]!) {
  saveCheckoutDeliveryMethods(id: $id, deliveryMethods: $deliveryMethods) {
    __typename
    ... on Order {
      id
      created
      updated
      paymentMethod {
        brand
        expirationMonth
        expirationYear
        lastFour
      }
      shopifyCheckouts {
        discount_code
        line_items {
          compare_at_price
          fulfillment_service
          grams
          id
          line_price
          price
          product_id
          quantity
          requires_shipping
          sku
          taxable
          title
          variant_id
          variant_title
          vendor
        }
        payment_due
        requires_shipping
        shipping_line {
          handle
          price
          title
        }
        shipping_rate {
          id
          price
          title
        }
        shipping_rates {
          id
          price
          title
        }
        shop {
          address1
          address2
          city
          free_shipping_threshold
          local_pickup_timeframe
          name
          offers_free_shipping
          offers_local_pickup
          offersLocalDelivery
          province
          slug
          zip
        }
        shopify_payments_account_id
        subtotal_price
        token
        total_price
        total_tax
      }
    }
    ... on Error {
      code
    }
  }
}
`;

/**
 * Mutation to save the payment method during checkout.
 * @param {string} id - Pebble order ID
 * @param {object} paymentMethod - Payment method object
 * @returns {object} Pebble order
 */
const MUTATION_SAVE_CHECKOUT_PAYMENT_METHOD = `
mutation SaveCheckoutPaymentMethod($id: String!, $paymentMethod: PaymentMethodInput!) {
  saveCheckoutPaymentMethod(id: $id, paymentMethod: $paymentMethod) {
    __typename
    ... on Order {
      id
      created
      updated
      paymentMethod {
        brand
        expirationMonth
        expirationYear
        lastFour
      }
      shopifyCheckouts {
        discount_code
        line_items {
          compare_at_price
          fulfillment_service
          grams
          id
          line_price
          price
          product_id
          quantity
          requires_shipping
          sku
          taxable
          title
          variant_id
          variant_title
          vendor
        }
        payment_due
        requires_shipping
        shipping_line {
          handle
          price
          title
        }
        shipping_rate {
          id
          price
          title
        }
        shipping_rates {
          id
          price
          title
        }
        shop {
          address1
          address2
          city
          free_shipping_threshold
          local_pickup_timeframe
          name
          offers_free_shipping
          offers_local_pickup
          offersLocalDelivery
          province
          slug
          zip
        }
        shopify_payments_account_id
        subtotal_price
        token
        total_price
        total_tax
      }
    }
    ... on Error {
      code
    }
  }
}
`;

/**
 * Mutation to complete checkout.
 * @param {string} id - Pebble order ID
 * @returns {boolean} Whether the operation succeeded.
 */
const MUTATION_COMPLETE_CHECKOUT = `
mutation CompleteCheckout($id: String!) {
  completeCheckout(id: $id) {
    __typename
    ... on Status {
      success
    }
    ... on Error {
      code
    }
  }
}
`;

const queries = {
  MUTATION_INITIATE_CHECKOUT,
  MUTATION_SAVE_CHECKOUT_SHIPPING_ADDRESS,
  MUTATION_SAVE_CHECKOUT_DELIVERY_METHODS,
  MUTATION_SAVE_CHECKOUT_PAYMENT_METHOD,
  MUTATION_COMPLETE_CHECKOUT,
};

export default queries;
