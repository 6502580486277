/**
 * @fileoverview Implements a CTA for becoming a Pebble partner on the Account
 * page.
 */

import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { handleSuccessUrl } from '../../lib/handle-success-url'
import PebbleApi from '../../lib/pebble-api'

export default function AccountBecomePartnerCta() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(() => {
    setLoading(true)

    PebbleApi
      .addShop()
      .then(status => {
        setLoading(false)

        if (status.success) {
          handleSuccessUrl(status.successUrl, navigate)
        }
      })
  }, [navigate])

  return (
    <Box mt={2}>
      <Button
        color="secondary"
        disabled={loading}
        endIcon={loading ? <CircularProgress size={20} /> : null}
        onClick={handleClick}
        variant="outlined"
      >
        Become a partner
      </Button>
    </Box>
  );
}
