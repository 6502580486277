/**
 * @fileoverview Implements address formatting functions.
 */

/**
 * @classdesc A class providing static functions for formatting an address
 * object into human-readable strings.
 * 
 * Expects address objects with this shape:
 * 
 * {
 *   first_name,
 *   last_name,
 *   address1,
 *   address2,
 *   city,
 *   province_code,
 *   zip
 * }
 * 
 */
export default class AddressFormatter {
  /**
   * Formats the given address into a single line.
   * @param {object} address - Address object to format
   * @returns {string} Formatted single line string
   */
  static toOneLine(address) {
    if (address == null) {
      return '';
    }

    let str = '';

    str = AddressFormatter.appendPart(str, address.first_name);
    str = AddressFormatter.appendPart(str, address.last_name);
    str = AddressFormatter.appendPart(str, address.address1, ', ');
    str = AddressFormatter.appendPart(str, address.address2);
    str = AddressFormatter.appendPart(str, address.city, ', ');
    str = AddressFormatter.appendPart(str, address.province_code, ', ');
    str = AddressFormatter.appendPart(str, address.zip, ' ');

    return str;
  }

  /**
   * Formats the given address into multiple lines.
   * @param {string} address - Address object to format
   * @returns {object} JSX expression containing multiple lines
   */
  static toMultiLine(address) {
    if (address == null) {
      return null;
    }

    let str = '';

    str = AddressFormatter.appendPart(str, address.first_name);
    str = AddressFormatter.appendPart(str, address.last_name);
    str = AddressFormatter.appendPart(str, address.address1, '<br />');
    str = AddressFormatter.appendPart(str, address.address2);
    str = AddressFormatter.appendPart(str, address.city, '<br />');
    str = AddressFormatter.appendPart(str, address.province_code, ', ');
    str = AddressFormatter.appendPart(str, address.zip, ' ');

    const jsx = str.split('<br />')
      .map((line, index) => <span key={`mla-${index}`}>{line}</span>)
      // Put a divider between each item in the list
      .reduce((acc, cur, index) => [acc, <br key={`br-${index}`} />, cur]);

    return jsx;
  }

  /**
   * Helper function to append a part to a string with a separator.
   * @param {string} str - String to which to append the part
   * @param {string} part - Part to append to the string
   * @param {string} separator - Separator to place before the part. Defaults
   * to a single space.
   * @returns New string with part appended.
   */
  static appendPart(str, part, separator = ' ') {
    let newStr = '';

    if (str != null) {
      newStr = str;

      if (part != null && part.length > 0) {
        if (str.length > 0) {
          newStr += separator;
        }
        
        newStr += part;
      }
    }

    return newStr;
  }
}
