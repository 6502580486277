/**
 * @fileoverview Implements a banner for a community
 */

import { useMemo } from 'react'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export default function CommunityBannerCore(props) {
  // Assumes `community` is non-null when rendering this component
  const { community } = props
  const theme = useTheme()

  const backgroundStyle = useMemo(() => {
    const gradient =
      'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)) center'
    let logo = ''
    let bg = ''

    if (community.logoUrl) {
      logo = `
url(${community.logoUrl})
left ${theme.spacing(2)} bottom ${theme.spacing(2)}
/ auto 50%
no-repeat, `
    }

    if (community.imageUrl) {
      bg = `, url(${community.imageUrl}) center / cover`
    }

    return { background: `${logo}${gradient}${bg}` }
  }, [community, theme])

  return (
    <Paper sx={{
      borderRadius: theme => theme.spacing(2),
      height: { xs: '125px', sm: '250px' },
      ...backgroundStyle
    }}>
      {/* Only show community name if logo is not given */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-end',
        p: 1,
        visibility: community.logoUrl ? 'hidden' : null
      }}>
        <Typography
          component="h2"
          sx={{ color: 'common.white' }}
          variant="h4"
        >
          {community.name}
        </Typography>
      </Box>
    </Paper>
  )
}
