/**
 * @fileoverview Implements search view for loading and displaying search
 * results based on the search parameters from the URL.
 * 
 * If there's less than a full page of primary search results, a banner will be
 * shown and supplemental search results will be loaded below the banner. These
 * supplemental search results will use the same search model, but with location
 * and shop filters removed to search everywhere.
 * 
 * If there's another page of primary or supplemental search results that can be
 * loaded, then loading skeletons will be shown at the bottom of the search
 * results, and once those are scrolled into view, more results will be fetched.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import HelmetWrapper from '../helmet-wrapper'
import MoreResultsSkeleton from './more-results-skeleton'
import RecommendedSearches from '../recommended-searches/recommended-searches'
import SearchFewResults from './search-few-results'
import SearchPinpointingOverlay from './search-pinpointing-overlay'
import SearchResult from './search-result'
import DesktopFilters from '../filters/desktop-filters'
import MobileFilters from '../filters/mobile-filters'
import Section from '../section'

import { useSnackbar } from '../../lib/snackbar-context'
import { useUser } from '../../lib/user-context'
import {
  getSearchModelFromUrlQuery,
  getUrlQueryFromSearchModel,
  useSearch,
  useSearchDispatch
} from '../../lib/search-context'
import { getGeolocation } from '../../lib/geolocation'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import PebbleApi from '../../lib/pebble-api'

const PAGE_SIZE = 20

export default function Search() {
  const { user } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const urlSearch = location.search
  const search = useSearch()
  const searchDispatch = useSearchDispatch()
  const { showInfo, showWarning } = useSnackbar()
  const mounted = useRef(false)

  const [count, setCount] = useState(0)
  const [firstPageLoading, setFirstPageLoading] = useState(true)
  const [pinpointing, setPinpointing] = useState(false)
  const [nextCursor, setNextCursor] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [primarySearchModel, setPrimarySearchModel] = useState(null)
  const [supplementalCount, setSupplementalCount] = useState(0)
  const [supplementalSearchModel, setSupplementalSearchModel] = useState(null)
  const [supplementalSearchResults, setSupplementalSearchResults] =
    useState([])
  const [fromShop, setFromShop] = useState(null)
    
  let products = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  // Whenever the URL path changes, scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [urlSearch])

  /**
   * @function loadResults
   * Loads search results using the given search model. Updates the next cursor
   * from the results. Returns an object with two properties:
   * 
   * {
   *   results: {}, // Original search results object with metadata
   *   nodes: []    // List of search result nodes
   * }
   * 
   * @param {object} searchModel - Search model
   * @returns {Promise<object>} Search results
   */
  const loadResults = useCallback(async (searchModel, nextCursor) => {
    const pagination = {
      first: PAGE_SIZE,
      after: nextCursor,
    };

    const results = await PebbleApi
      .getSearchResultsConnection(searchModel, pagination)

    const edges = results.edges
    const nodes = edges.map(edge => edge.node)
    const newNextCursor = results.pageInfo.hasNextPage
      ? edges[edges.length - 1].cursor
      : null

    if (mounted.current) {
      setNextCursor(newNextCursor)
    }
    
    return { results, nodes }
  }, [])

  /**
   * @function loadFirstPageSupplemental
   * Loads the first page of supplemental search results for the given primary
   * search model.
   * @param {object} searchModel - Primary search model
   */
  const loadFirstPageSupplemental = useCallback(async primarySearchModel => {
    // Build a modified search model for getting supplemental search
    // results by removing the location and shop filters so it searches
    // everywhere
    const supplementalSearchModel = {
      ...primarySearchModel,
      location: null,
      shops: [],
      useCurrentLocation: false
    }

    // Perform supplemental search
    const { results, nodes } = await loadResults(supplementalSearchModel)

    if (!mounted.current) {
      return
    }

    setSupplementalSearchModel(supplementalSearchModel)
    setSupplementalSearchResults(prev => [...prev, ...nodes])
    setSupplementalCount(results.totalCount)

    // Update list of available shops by including shops from the
    // supplemental search results
    const newLocationShops = [
      ...(primarySearchModel.locationShops ?? []),
      ...results.locationShops
    ]
      // Only get distinct values
      .filter((shop, index, shops) =>
        shops.findIndex(s => s.slug === shop.slug) === index
      )
      // Sort by shop name alphabetically, ignoring case
      /** @todo Put this in a string utility */
      .sort((a, b) => {
        var aName = a.name.toUpperCase()
        var bName = b.name.toUpperCase()

        if (aName < bName) {
          return -1
        }

        if (aName > bName) {
          return 1
        }

        return 0
      })

    // Update available shops for the filters
    searchDispatch({ type: 'set', payload: {
      locationShops: newLocationShops
    }})
  }, [loadResults, searchDispatch])

  /**
   * @function loadFirstPage
   * Loads the first page of search results for the given search model.
   * @param {object} searchModel - Search model
   */
  const loadFirstPage = useCallback(async searchModel => {
    setPinpointing(false)
    const { results, nodes } = await loadResults(searchModel)

    if (!mounted.current) {
      return
    }

    // Log GA event for this search
    analyticsEvent(analyticsEvents.search, {
      latitude: searchModel.latitude,
      longitude: searchModel.longitude,
      radius: searchModel.radius,
      search_term: searchModel.query,
      slug: searchModel.slug,
      user_email: user.email
    })

    // Log FB event for this search
    window.fbq('track', 'Search')

    setPrimarySearchModel(searchModel)
    setSupplementalSearchModel(null)
    setSearchResults(nodes)
    setSupplementalSearchResults([])
    setCount(results.totalCount)

    // Update available shops for the filters
    searchDispatch({ type: 'set', payload: {
      locationShops: results.locationShops,
      filteredShops: results.filteredShops
    }});

    // If results are from a single shop, include that in the heading
    if (results.filteredShops?.length === 1) {
      setFromShop(results.filteredShops[0].name)
    } else {
      setFromShop(null)
    }

    setFirstPageLoading(false)

    // If there are fewer than a single page's worth of results, request
    // supplemental results
    if (nodes.length < PAGE_SIZE && !results.pageInfo.hasNextPage) {
      await loadFirstPageSupplemental(searchModel)
    }
  }, [loadFirstPageSupplemental, loadResults, searchDispatch, user.email])

  /**
   * @function loadMore
   * Loads more primary or supplemental search results as necessary.
   */
  const loadMore = useCallback(() => {
    loadResults(primarySearchModel, nextCursor).then(({ nodes }) => {
      if (!mounted.current) {
        return
      }

      if (supplementalSearchModel) {
        setSupplementalSearchResults(oldResults => [...oldResults, ...nodes])
      } else {
        setSearchResults(oldResults => [...oldResults, ...nodes])
      }
    })
  }, [loadResults, nextCursor, primarySearchModel, supplementalSearchModel])

  /**
   * @function loadGeolocation
   * Loads the user's current location.
   */
  const loadGeolocation = useCallback(async searchModel => {
    setPinpointing(true)
    const geoloc = await getGeolocation({ mode: 'cache-api-ip' })
    
    if (!mounted.current) {
      return
    }

    // Always set the user's location on the search model, even if it's
    // null
    searchModel.userLocation = geoloc;

    if (geoloc != null) {
      // Clear the geolocation failed flag
      searchModel.userLocationFailed = false;

      // If the search model says to use the user's current location, and
      // the location isn't set yet, update the search model with the
      // location
      if (searchModel.useCurrentLocation &&
        searchModel.location == null) {
        // Set the search location and make sure it doesn't search by shop
        searchModel.location = geoloc;
        searchModel.shops = [];

        if (geoloc.mode === 'ip') {
          // Current location was found by IP, so update the search model
          // with the location and wider radius and do the search
          searchModel.radius = searchModel.radius * 2;

          // Show snackbar notifying user location couldn't be found
          // by API, so using approximation by IP instead
          showInfo("We couldn't find your exact location, so we're getting as close as we can based on your IP address and increasing the search radius. Try enabling Location Services and then searching by your current location again for more accurate results.")
        }
      }
    } else {
      // Flag the search model as having failed to get geolocation so it
      // doesn't get stuck in a loop
      searchModel.userLocationFailed = true;

      // If the search model says to use the user's current location, and
      // the location isn't set yet, update the search model with the
      // location
      if (searchModel.useCurrentLocation &&
        searchModel.location == null) {
        // Current location couldn't be found, so fall back to searching
        // everywhere instead
        searchModel.useCurrentLocation = false;
        searchModel.location = null;
        searchModel.shops = [];

        // Show snackbar notifying user location couldn't be found
        showWarning("Your location couldn't be found, so we're searching everywhere for you instead. Try enabling Location Services and then searching by your current location again.")
      }
    }

    // Replace the current history stack entry with an updated URL with
    // full location data
    const newParams = getUrlQueryFromSearchModel(searchModel)
    const pathname = '/search?' + newParams.toString()
    navigate(pathname, { replace: true })
  }, [navigate, showInfo, showWarning])

  // When the search model is updated by filters, navigate to update
  // the search URL and trigger a search
  useEffect(() => {
    // Remove community reference if search community is not set
    if (!search.community) {
      sessionStorage.removeItem('community')
    }

    if (search.shouldSearch) {
      const params = getUrlQueryFromSearchModel(search)
      const pathname = '/search?' + params.toString()
  
      // When executing a search, always push onto the history stack
      navigate(pathname)
    }
  }, [navigate, search])

  // When the URL parameters change, reload the search results
  useEffect(() => {
    setFirstPageLoading(true)
    const urlSearchParams = new URLSearchParams(urlSearch)
    const newSearchModel = getSearchModelFromUrlQuery(urlSearchParams, true)

    // Update the search context based on the search model from the URL
    searchDispatch({ type: 'set', payload: newSearchModel })

    // If the search model doesn't have the user's location and didn't already
    // fail to find the user's location, then look it up, even if it's not a
    // location search
    if (newSearchModel.userLocation == null &&
      !newSearchModel.userLocationFailed) {
      loadGeolocation(newSearchModel)
        .catch(console.error)
    } else {
      // Perform the search with the current search model
      loadFirstPage(newSearchModel)
        .catch(console.error)
    }
  }, [loadFirstPage, loadGeolocation, searchDispatch, urlSearch])

  if (firstPageLoading) {
    products = (
      <MoreResultsSkeleton currentCount={0} />
    )
  } else {
    // If there are no primary or supplemental search results, notify the user
    if (searchResults.length === 0 &&
        supplementalSearchResults.length === 0) {
      products = [
        <Grid item key="zero-results" xs={12}>
          <SearchFewResults />
        </Grid>
      ]
    }

    // If there are any primary search results, display them
    if (searchResults.length > 0) {
      products = searchResults.map(product => (
        <Grid item key={product._id} xs={6} lg={4}>
          <SearchResult product={product} />
        </Grid>
      ))
    }

    // If there are any supplemental search results, insert a heading and
    // append the supplemental search results
    if (supplementalSearchResults.length > 0) {
      const plural = supplementalCount !== 1

      products.push(
        <Grid item key="supplemental-results" xs={12}>
          <SearchFewResults count={searchResults.length} />
          <Typography variant="body1">
            Here {plural ? 'are' : 'is'} {supplementalCount} more result{plural ? 's' : ''} from outside your search radius.
          </Typography>
        </Grid>
      )

      products = products.concat(supplementalSearchResults.map(product => (
        <Grid item key={`supplemental-${product._id}`} xs={6} lg={4}>
          <SearchResult product={product} />
        </Grid>
      )))
    }

    // If there's another page of results that can be loaded (either primary or
    // supplemental search results), show skeletons to trigger loading another
    // page
    if (nextCursor) {
      const currentCount = searchResults.length +
        supplementalSearchResults.length

      products = products.concat(
        <MoreResultsSkeleton
          currentCount={currentCount}
          key="MoreResultsSkeleton"
          onLoad={loadMore}
        />
      )
    }
  }

  // Build search results heading
  let resultsFor = ''

  if (primarySearchModel?.query?.length) {
    resultsFor = `“${primarySearchModel.query}”`
  } else if (primarySearchModel?.subcategory?.length) {
    resultsFor = primarySearchModel.subcategory
  } else if (primarySearchModel?.category?.length) {
    resultsFor = primarySearchModel.category
  }

  /** @todo Use `plural` string utility */
  const heading = (
    <Typography gutterBottom variant="body2">
      {count} result{count !== 1 ? 's' : ''}
      {resultsFor && <span>{' '}for <b>{resultsFor}</b></span>}
      {fromShop && <span>{' '}from <b>{fromShop}</b></span>}
    </Typography>
  )

  return (
    <>
      <HelmetWrapper title={primarySearchModel?.query ?? 'Search'} />
      <Section boxProps={{ py: 2 }}>
        {heading}
        <Grid container spacing={2}>
          <Grid
            item md={4} lg={3}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <DesktopFilters />
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={2}>
              {products}
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <RecommendedSearches color="greyLight" />
      <MobileFilters />
      <SearchPinpointingOverlay show={pinpointing} />
    </>
  )
}
