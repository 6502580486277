/**
 * @fileoverview Implements the landing page.
 */

import { Navigate } from 'react-router-dom'

export default function Landing() {
  // For now, just redirect to the What is Pebble? page
  return <Navigate to="/what-is-pebble" />
}
