/**
 * @fileoverview Implements a view of featured collections.
 */

import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'

import Section from './section'

const collections = [
  {
    heading: 'For Her',
    category: 'Women',
    cardimg: '/images/collections/for-her.jpg'
  },
  {
    heading: 'For Him',
    category: 'Men',
    cardimg: '/images/collections/for-men.jpg'
  },
  {
    heading: 'For Littles',
    category: 'Kids',
    cardimg: '/images/collections/for-the-littles.jpg'
  },
  {
    heading: 'For Home',
    category: 'Home',
    cardimg: '/images/collections/for-home.jpg'
  }
]

export default function Collections() {
  const navigate = useNavigate()

  const handleCollectionClick = collection => () => {
    navigate(`/search?q=&ca=${collection.category}`)
  }

  return (
    <Section color="greyLight">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card
                elevation={0}
                onClick={handleCollectionClick(collections[0])}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${collections[0].cardimg})`,
                  backgroundPosition: 'bottom center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'flex-end',
                  pt: '50%'
                }}
              >
                <Typography sx={{
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  pb: { xs: 3, md: 4 },
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }}>
                  {collections[0].heading}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                elevation={0}
                onClick={handleCollectionClick(collections[1])}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${collections[1].cardimg})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'flex-end',
                  pt: '50%'
                }}
              >
                <Typography sx={{
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  pb: { xs: 3, md: 4 },
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }}>
                  {collections[1].heading}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={0}
                onClick={handleCollectionClick(collections[2])}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${collections[2].cardimg})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'flex-end',
                  pt: { xs: '50%', sm: '25%' }
                }}
              >
                <Typography sx={{
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  pb: { xs: 3, md: 4 },
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }}>
                  {collections[2].heading}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            elevation={0}
            onClick={handleCollectionClick(collections[3])}
            sx={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${collections[3].cardimg})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end',
              pt: { xs: '50%', sm: '25%' }
            }}
          >
            <Typography sx={{
              color: 'white',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              pb: { xs: 3, md: 4 },
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
              {collections[3].heading}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Section>
  )
}
