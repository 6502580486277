/**
 * @fileoverview Implements a single list item for the Partner page "how it
 * works" section.
 */

import { Box, Typography } from '@mui/material'

export default function PartnerHowListItem(props) {
  const { children, number, title } = props

  return (
    <Box sx={{
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      pb: 4,
      position: 'relative'
    }}>
      <Box sx={{
        backgroundColor: 'common.white',
        border: theme => '1px solid ' + theme.palette.secondary.main,
        borderRadius: theme => theme.spacing(4),
        color: 'secondary.main',
        flexShrink: 0,
        height: theme => theme.spacing(4),
        lineHeight: '30px',
        mr: 4,
        textAlign: 'center',
        width: theme => theme.spacing(4),
        zIndex: 101
      }}>
        {number}
      </Box>
      <Box sx={{
        borderLeft: theme => '1px solid ' + theme.palette.secondary.main,
        display: 'block',
        height: '100%',
        left: theme => theme.spacing(2),
        position: 'absolute',
        top: 0,
        zIndex: 100,
        '*:last-child > &': {
          display: 'none'
        }
      }}/>
      <Box>
        <Typography component="body1" gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body2">
          {children}
        </Typography>
      </Box>
    </Box>
  )
}
