/**
 * @fileoverview Implements a view for a community.
 */

import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useSearchDispatch } from '../../lib/search-context'
import { useUser } from '../../lib/user-context'
import Cache from '../../lib/cache.js'
import CommunityBanner from './community-banner'
import CommunityBrands from './community-brands'
import CommunityCategories from './community-categories'
import CommunityFeaturedProducts from './community-featured-products'
import CommunityPartnerCta from './community-partner-cta'
import CommunitySkeleton from './community-skeleton'
import HelmetWrapper from '../helmet-wrapper'
import NotFound from '../not-found'
import PebbleApi from '../../lib/pebble-api'
import RecommendedSearches from '../recommended-searches/recommended-searches'
import WhatIsPebblePartner from '../what-is-pebble/what-is-pebble-partner'

export default function Community() {
  const { slug } = useParams()
  const mounted = useRef(false)
  const searchDispatch = useSearchDispatch()
  const { user } = useUser()

  const [blocks, setBlocks] = useState([])
  const [community, setCommunity] = useState(null)
  const [loading, setLoading] = useState(true)
  const [shops, setShops] = useState([])

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    setLoading(true)

    PebbleApi
      .getCommunity(slug)
      .then(data => {
        if (mounted.current) {
          setCommunity(data.community)
          sessionStorage.setItem('community', data.community.slug)
          setShops(data.communityBrands)
          setLoading(false)
        }
      })
  }, [slug])

  useEffect(() => {
    let newBlocks = null

    if (community?.blocks?.length > 0) {
      newBlocks = community.blocks.map((block, index) => {
        switch (block.type) {
          case 'banner':
            return (
              <CommunityBanner
                key={`block-${index}`}
                community={community}
              />
            )
          case 'categories':
            return (
              <CommunityCategories
                key={`block-${index}`}
                community={community}
                exclude={block.exclude}
              />
            )
          case 'featured-products':
            // Make sure the community is part of the search model
            block.searchModel = {
              ...block.searchModel,
              community: {
                name: community.name,
                slug: community.slug
              }
            }

            return (
              <CommunityFeaturedProducts
                key={`block-${index}`}
                community={community}
                searchModel={block.searchModel}
              />
            )
          case 'recommended-searches':
            return (
              <RecommendedSearches
                key={`block-${index}`}
                community={community.slug}
              />
            )
          case 'brands':
            return (
              <CommunityBrands
                key={`block-${index}`}
                community={community}
                shops={shops}
              />
            )
          case 'partner-cta':
            return user.partner
              ? <CommunityPartnerCta key={`block-${index}`} />
              : <WhatIsPebblePartner key={`block-${index}`} />
          default:
            return null
        }
      })
    }

    setBlocks(newBlocks)
  }, [community, shops, user.partner])

  useEffect(() => {
    if (community && searchDispatch) {
      // Cache the community
      Cache.communities.set(community)

      // Scope the search context to this community
      searchDispatch({ type: 'set', payload: { community }})
    }
  }, [community, searchDispatch])

  if (loading) {
    return <CommunitySkeleton />
  }

  if (!community) {
    return <NotFound entity="community" />
  }

  // If no blocks are defined, use the default layout
  if (!blocks) {
    return (
      <>
        <HelmetWrapper title={community.name} />
        <CommunityBanner community={community} />
        <CommunityCategories community={community} />
        <CommunityFeaturedProducts
          community={community}
          searchModel={{ community: community.slug }}
        />
        {/* <CommunityNewArrivals community={community} /> */}
        <RecommendedSearches community={community.slug} />
        <CommunityBrands community={community} shops={shops} />
        {user.partner
          ? <CommunityPartnerCta />
          : <WhatIsPebblePartner />
        }
      </>
    )
  }

  // If blocks are defined, use those for the layout
  return (
    <>
      <HelmetWrapper title={community.name} />
      {blocks}
    </>
  )
}
