/**
 * @fileoverview Implements a filter for narrowing search results by price
 * range.
 */

import { useCallback, useEffect, useState } from 'react'

import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

/**
 * @param {function} props.onChange - Function to call when price range
 * changes. Called with 1 argument, an array with 2 elements, the minimum and
 * maximum prices: `[min, max]`
 */
export default function FilterPrice(props) {
  const { max, min, onChange } = props
  const [minPrice, setMinPrice] = useState(min ?? '')
  const [maxPrice, setMaxPrice] = useState(max ?? '')

  useEffect(() => {
    setMaxPrice(max ?? '')
  }, [max])

  useEffect(() => {
    setMinPrice(min ?? '')
  }, [min])

  const handleBlur = useCallback(() => {
    onChange && onChange([minPrice, maxPrice])
  }, [onChange, minPrice, maxPrice])

  const handleMinPriceChanged = event => {
    const newMinPrice = event.target.value !== '' ?
      Number(event.target.value) || 0:
      null
    setMinPrice(newMinPrice)
  }

  const handleMaxPriceChanged = event => {
    const newMaxPrice = event.target.value !== '' ?
      Number(event.target.value) || 0:
      null
    setMaxPrice(newMaxPrice)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Price
      </FormLabel>
      <FormGroup sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Min"
              margin="dense"
              onBlur={handleBlur}
              onChange={handleMinPriceChanged}
              value={minPrice}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Max"
              margin="dense"
              onBlur={handleBlur}
              onChange={handleMaxPriceChanged}
              value={maxPrice}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormGroup>
    </FormControl>
  )
}
