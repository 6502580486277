/**
 * @fileoverview Implements a checkout view and process for users to purchase
 * the items in their cart.
 */

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export default function CheckoutSummary(props) {
  const { checkouts } = props

  let itemCount = 0
  let subtotal = 0
  let tax = 0
  let shipping = 0
  let discounts = 0
  let total = 0

  checkouts.forEach(checkout => {
    checkout.line_items.forEach(line_item => {
      itemCount += Number(line_item.quantity)

      if (line_item.applied_discounts) {
        line_item.applied_discounts.forEach(discount => {
          discounts += Number(discount.amount)
        })
      }
    })

    subtotal += Number(checkout.subtotal_price)
    tax += Number(checkout.total_tax)

    if (checkout.shipping_line) {
      shipping += Number(checkout.shipping_line.price)
    }

    if (checkout.applied_discount) {
      discounts += Number(checkout.applied_discount.amount)
    }

    total += Number(checkout.total_price)
  })

  return (
    <Paper elevation={0} sx={{ backgroundColor: 'grey.main' }}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {itemCount === 1 && '1 item'}
              {itemCount > 1 && `${itemCount} items`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              Subtotal:
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="body1" component="p">
              ${subtotal.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              Tax:
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="body1" component="p">
              ${tax.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p" gutterBottom={discounts <= 0}>
              Shipping:
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="body1" component="p">
              ${shipping.toFixed(2)}
            </Typography>
          </Grid>
          {discounts > 0 &&
            <>
              <Grid item xs={6}>
                <Typography variant="body1" component="p" gutterBottom>
                  Discounts:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Typography variant="body1" component="p">
                  &ndash;${discounts.toFixed(2)}
                </Typography>
              </Grid>
            </>
          }
          <Grid item xs={6}>
            <Typography variant="h5">
              Order total:
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="h5">
              <b>${total.toFixed(2)}</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
