/**
 * @fileoverview Implements a view of a single order to show within the Account
 * view.
 */

import { Box, Skeleton, Typography } from '@mui/material'

export default function AccountOrderSkeleton() {
  return (
    <Box sx={{
      backgroundColor: 'grey.light',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: theme => theme.spacing(2),
      boxShadow: theme =>
        `0 ${theme.spacing(1)}px ${theme.spacing(2)}px 0 rgba(0, 0, 0, 0.05)`,
      color: 'darkGrey.main',
      overflow: 'hidden',
      '& + &': {
        mt: 2
      }
    }}>
      <Box sx={{
        alignItems: 'center',
        backgroundColor: 'grey.main',
        '& .MuiTypography-root': {
          color: 'darkGrey.main',
        },
        '& .MuiTypography-h3': {
          /** @todo Temporary until global styles are updated */
          fontSize: '1.1rem',
          letterSpacing: 'normal',
          textTransform: 'none',
        },
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        justifyContent: 'flex-start',
        p: 2,
      }}>
        <Box>
          <Typography gutterBottom variant="h3">
            <Skeleton width="60px" />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
        </Box>
        <Box>
          <Typography gutterBottom variant="h3">
            <Skeleton width="60px" />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Typography gutterBottom variant="h3">
            <Skeleton width="60px" />
          </Typography>
          <Typography gutterBottom variant="body2">
            <Skeleton />
          </Typography>
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Skeleton />
        <Skeleton />
        <Skeleton width="40%" />
      </Box>
    </Box>
  )
}
