/**
 * @fileoverview Implements a shopping cart.
 */

import { useEffect } from 'react'

import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'

import CartItem from './cart-item'
import CartItemSkeleton from './cart-item-skeleton'
import HelmetWrapper from '../helmet-wrapper'
import LinkButton from '../link-button'
import Section from '../section'

import { useUser } from '../../lib/user-context'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'

export default function Cart() {
  const { loadFullCart, loadingUser, user } = useUser()

  useEffect(() => {
    if (!loadingUser && !user.cart.loadedFull) {
      loadFullCart()
    }
  }, [loadFullCart, loadingUser, user])

  useEffect(() => {
    analyticsEvent(analyticsEvents.view_cart, {
      currency: 'USD',
      items: user.cart.items.map(item => ({
        affiliation: item.shop.slug,
        currency: 'USD',
        item_id: item.product_id,
        item_brand: item.shop.name,
        item_name: item.title,
        item_variant: item.variants[0].id,
        price: item.price,
        quantity: item.quantity
      })),
      user_email: user.email,
      value: user.cart.subtotal
    });
  }, [user.cart.items, user.cart.subtotal, user.email])

  // While loading, show skeleton cart items
  let results = [...Array(user.cart.count)].map((_, i) =>
    <CartItemSkeleton key={i} />
  );

  if (!loadingUser) {
    if (user.cart.items.length === 0) {
      results = (
        <Typography variant="h5" component="h3">
          No items in your cart!
        </Typography>
      )
    } else {
      // Render each cart item
      results = user.cart.items.map(item =>
        <CartItem
          boxProps={{ py: 2 }}
          item={item}
          key={item.cartItemId}
        />
      )
    }
  }

  return (
    <>
      <HelmetWrapper
        title="Cart"
      />
      <Section>
        <Typography variant="h1" gutterBottom>
          Cart
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper elevation={0} sx={{ width: '100%' }}>
              {results}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={0} sx={{ backgroundColor: 'grey.main' }}>
                  <Box p={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h2" gutterBottom>
                          {loadingUser && (
                            <Skeleton />
                          )}
                          {!loadingUser && user.cart.quantity === 0 && (
                            <>No items</>
                          )}
                          {!loadingUser && user.cart.quantity === 1 && (
                            <>1 item</>
                          )}
                          {!loadingUser && user.cart.quantity > 1 && (
                            <>{user.cart.quantity} items</>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h5" component="h2">
                          Subtotal:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: 'right' }}>
                        <Typography variant="h5" component="h2">
                          {loadingUser && (
                            <Skeleton />
                          )}
                          {!loadingUser && (
                            <b>${user.cart.subtotal.toFixed(2)}</b>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <LinkButton
                  color="secondary"
                  disabled={user.cart.quantity === 0 || loadingUser}
                  fullWidth
                  mode="router"
                  to={user.loggedIn ? '/checkout' : '/login?r=%2Fcart'}
                  variant="contained"
                >
                  {user.loggedIn ? 'Check out now' : 'Log in to check out'}
                </LinkButton>
                {!user.loggedIn &&
                  <Box mt={1}>
                    <LinkButton
                      color="secondary"
                      disabled={user.cart.quantity === 0 || loadingUser}
                      fullWidth
                      mode="router"
                      to="/checkout"
                      variant="outlined"
                    >
                      Check out as a guest
                    </LinkButton>
                  </Box>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
