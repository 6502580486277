/**
 * @fileoverview Implements content for the Checkout -> Review step.
 */

import { useEffect, useState } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepContent from '@mui/material/StepContent'
import Typography from '@mui/material/Typography'
import AddressFormatter from '../../lib/address-formatter'

export default function CheckoutStepReview(props) {
  const {
    address,
    checkouts,
    total,
    completed,
    isCompleting,
    onClick,
    onComplete,
    ...otherProps
  } = props
  const [formattedAddress, setFormattedAddress] = useState('')

  useEffect(() => {
    setFormattedAddress(AddressFormatter.toMultiLine(address))
  }, [address])

  const handleClicked = () => {
    onClick && onClick()
  }

  const handleCompleted = event => {
    event.stopPropagation()
    onComplete && onComplete()
  }

  return (
    <Step {...otherProps}>
      <StepButton
        onClick={handleClicked}
        completed={completed}
      >
        Review
      </StepButton>
      <StepContent>
        <Box mt={1}>
          <Alert severity="info">
            We'll send your order to the merchants and keep you up to date on your order status!
          </Alert>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            Delivering:
          </Typography>
          <Typography component="ul" variant="body2" gutterBottom>
            {checkouts.map(checkout => {
              let shippingTitle = '(Unknown)';

              if (checkout.discount_code?.startsWith('PEBBLELOCALPICKUP_')) {
                shippingTitle = 'Local In-store Pickup';
              } else if (checkout.shipping_line != null) {
                shippingTitle = checkout.shipping_line.title;
              }

              const itemCount = checkout.line_items
                .reduce((acc, cur) => acc + Number(cur.quantity), 0);
              const plural = itemCount > 1;
              
              return (
                <li key={checkout.token}>
                  {itemCount} item{plural ? 's' : ''} from {checkout.shop.name} via {shippingTitle}
                </li>
              );
            })}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            To:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {formattedAddress}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">
            Order total: <b>${total.toFixed(2)}</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleCompleted}
            endIcon={isCompleting ? <CircularProgress size={20} sx={{ color: 'common.white' }} /> : null}
            disabled={isCompleting}
          >
            Place order
          </Button>
        </Box>
      </StepContent>
    </Step>
  )
}
 