/**
 * @fileoverview Implements a summary for the Account view.
 */

import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemLink from '../list-item-link'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import AccountBecomePartnerCta from './account-become-partner-cta'
import { useUser } from '../../lib/user-context'

export default function AccountSummary() {
  const { user } = useUser()

  return (
    <div>
      <Typography
        gutterBottom
        sx={{
          /** @todo Temporary until global styles are updated */
          fontSize: '2rem',
          letterSpacing: 'normal',
          textTransform: 'none'
        }}
        variant="h1"
      >
        Hi, {user.firstName}
      </Typography>
      <List dense>
        <ListItemLink to="/account">
          <ListItemText primary="Account" />
        </ListItemLink>
        <ListItemLink to="/account/orders">
          <ListItemText primary="Purchase history" />
        </ListItemLink>
        <ListItemLink to="/account/wishlist">
          <ListItemText primary="Wishlist" />
        </ListItemLink>
      </List>
      {(user.manager || user.partner || user.admin) &&
        <>
          <Divider />
          <List dense>
            {(user.manager || user.admin) &&
              <ListItemLink end={false} to="/account/communities">
                <ListItemText primary="Manage communities" />
              </ListItemLink>
            }
            {(user.partner || user.admin) &&
              <ListItemLink end={false} to="/account/shops">
                <ListItemText primary="Manage shops" />
              </ListItemLink>
            }
          </List>
        </>
      }
      <Divider />
      <List dense>
        {user.admin &&
          <ListItemLink to="/admin">
            <ListItemText primary="Admin" />
          </ListItemLink>
        }
        <ListItemLink to="/logout">
          <ListItemText primary="Log out" />
        </ListItemLink>
      </List>
      {!user.partner && <AccountBecomePartnerCta />}
    </div>
  )
}
