/**
 * @fileoverview Implements a single recommended search.
 */

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material'

export default function RecommendedSearch(props) {
  const { onClick, search } = props

  if (search == null) {
    return null
  }

  // Render the recommended search
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'common.white',
        height: '100%'
      }}
      variant="outlined"
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <CardMedia
          image={search.imageUrl}
          sx={{
            height: { xs: '180px', sm: '290px' },
            width: '100%'
          }}
          title={search.query}
        />
        <CardContent>
          <Typography variant="h6" component="p">
            &ldquo;{search.query}&rdquo;
          </Typography>
          <Typography variant="body2">
            From <b>{search.shop.name}</b> and more
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
      </CardActionArea>
    </Card>
  )
}
