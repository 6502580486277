/**
 * @fileoverview Implements a view of a single order to show within the Account
 * view.
 */

import { Launch as LaunchIcon } from '@mui/icons-material'
import { Box, Grid, Link, Typography } from '@mui/material'

import AccountOrderCheckoutItem from './account-order-checkout-item'

export default function AccountOrderCheckout(props) {
  const { checkout } = props

  if (!checkout) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      '& + &': {
        borderTop: theme => '1px solid ' + theme.palette.grey.dark,
        mt: 2,
        pt: 2,
      }
    }}>
      <Box>
        <Box sx={{
          alignItems: 'flex-start',
          '& .MuiTypography-root:not(a)': {
            color: 'darkGrey.main',
          },
          '& .MuiTypography-h4': {
            /** @todo Temporary until global styles are updated */
            fontSize: '1rem',
            letterSpacing: 'normal',
            textTransform: 'none',
          },
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'space-between'
        }}>
          <Box>
            <Typography gutterBottom variant="h4">
              {checkout.shop.name}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography gutterBottom variant="body2">
              ${checkout.total_price}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          alignItems: 'flex-start',
          '& .MuiTypography-root:not(a)': {
            color: 'darkGrey.main',
          },
          '& .MuiTypography-h4': {
            /** @todo Temporary until global styles are updated */
            fontSize: '1rem',
            letterSpacing: 'normal',
            textTransform: 'none',
          },
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'space-between'
        }}>
          <Box>
            <Typography variant="body2">
              Via {checkout.shipping_line.title}
            </Typography>
          </Box>
          {checkout.order_status_url && (
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2">
                <Link
                  href={checkout.order_status_url}
                  rel="noopener"
                  sx={{
                    whiteSpace: 'nowrap',
                    '& .MuiSvgIcon-root': {
                      fontSize: '0.8rem',
                      mb: '-1px',
                      verticalAlign: 'baseline'
                    }
                  }}
                  target="_blank"
                >
                  View order status
                  {' '}
                  <LaunchIcon />
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        {checkout.line_items.map(item => (
          <Grid item xs={12} md={6}>
            <AccountOrderCheckoutItem item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
