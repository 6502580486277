import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'

import {
  Add as AddIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  Launch as LaunchIcon,
  Remove as RemoveIcon
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'

import { useUser } from '../../lib/user-context'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import HelmetWrapper from '../helmet-wrapper'
import ItemSkeleton from './item-skeleton'
import ItemVariantButtons from './item-variant-buttons'
import ItemVariantList from './item-variant-list'
import ImagePreloader from '../../lib/image-preloader'
import NotFound from '../not-found'
import PebbleApi from '../../lib/pebble-api'
import RecommendedSearches from '../recommended-searches/recommended-searches'
import RecommendedProducts from '../recommended-products'
import Section from '../section'
import ShareButton from '../share-button'
import ShopAbout from '../shop/shop-about'
import SimilarProducts from '../similar-products'

import 'react-medium-image-zoom/dist/styles.css'

export default function Item() {
  const { id } = useParams()
  const mounted = useRef(false)
  const {
    addCartItem,
    addWishlistItem,
    removeWishlistItem,
    user,
    wishlistContains,
  } = useUser()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const [product, setProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [variant, setVariant] = useState(null)
  const [priceRange, setPriceRange] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [currentImage, setCurrentImage] = useState(null)
  const [carouselImages, setCarouselImages] = useState([])
  const [priceRangeCompareAt, setPriceRangeCompareAt] = useState(null)
  const [onWishlist, setOnWishlist] = useState(false)
  const [updatingWishlist, setUpdatingWishlist] = useState(false)
  const [addingToCart, setAddingToCart] = useState(false)

  useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false
  })

  useEffect(() => {
    // Dispose preloaded images when component unmounts
    return () => ImagePreloader.dispose()
  }, [])

  useEffect(() => {
    // Load product by ID
    if (id != null && id.length > 0) {
      setIsLoading(true)

      // Make a call to the API to get the item
      PebbleApi
        .getProductById(id)
        .then(product => {
          if (mounted.current) {
            setProduct(product)

            if (product) {
              // Default to the first variant with quantity available when first
              // loading to item
              const defaultVariant = product.variants
                ?.filter(productVariant =>
                  productVariant.available === true &&
                  (productVariant.inventory_quantity > 0 ||
                    productVariant.inventory_policy === 'continue' ||
                    productVariant.inventory_management == null)
                )

              if (defaultVariant?.length > 0) {
                setVariant(defaultVariant[0])
              }

              if (product.images?.length > 0) {
                setCurrentImage(product.images[0])
              } else if (product.imageUrl) {
                setCurrentImage({ src: product.imageUrl })
              }

              if (product != null) {
                analyticsEvent(analyticsEvents.view_item, {
                  currency: 'USD',
                  items: [{
                    affiliation: product.shop.slug,
                    currency: 'USD',
                    item_id: product._id,
                    item_name: product.title,
                    item_brand: product.shop.name,
                    price: product.minPrice,
                    quantity: 1,
                    item_variant: defaultVariant?.id
                  }],
                  user_email: user.email,
                  value: product.minPrice
                })

                window.fbq('track', 'ViewContent')
              }

              // Generate formatted price range
              const minPrice = product.minPrice
              const maxPrice = product.maxPrice

              if (minPrice != null && minPrice === maxPrice) {
                setPriceRange(`$${minPrice.toFixed(2)}`)
              } else if (maxPrice != null) {
                setPriceRange(
                  `$${minPrice.toFixed(2)}–$${maxPrice.toFixed(2)}`
                )
              }

              let maxCompareAt = 0

              if (product.variants) {
                for (let i = 0; i < product.variants.length; i++) {
                  if (product.variants[i].compare_at_price != null) {
                    const compareAtPrice =
                      Number(product.variants[i].compare_at_price)
                    if (compareAtPrice > maxCompareAt) {
                      maxCompareAt = compareAtPrice
                    }
                  }
                }
              }

              if (maxCompareAt > 0) {
                setPriceRangeCompareAt(maxCompareAt)
              } else {
                setPriceRangeCompareAt(null)
              }
            } else {
              analyticsEvent(analyticsEvents.not_found, { id })
            }

            setIsLoading(false)
          }
        });
    } else {
      setProduct(null)
      setIsLoading(false)
    }
  }, [id, user.email])

  useEffect(() => {
    let newCarouselImages = []

    if (product) {
      // Only show images for the current variant if possible
      newCarouselImages = variant != null && variant.image_id !== null ?
        product.images?.filter(i => i.id === variant.image_id) :
        product.images ?? [{
          id: 'product.imageUrl',
          src: product.imageUrl,
          srcSet: null
        }]

      if (variant && variant.image_id && currentImage &&
          currentImage.id !== variant.image_id) {
        setCurrentImage(newCarouselImages[0])
      }
    }

    setCarouselImages(newCarouselImages)
  }, [currentImage, product, variant])

  useEffect(() => {
    if (product) {
      const wishlistItem = {
        productId: product._id,
        variantId: variant?.id
      }
      setOnWishlist(wishlistContains(wishlistItem))
    }
  }, [product, user, variant, wishlistContains])

  useEffect(() => {
    // Preload carousel images
    if (carouselImages) {
      carouselImages.forEach(image => {
        ImagePreloader.preload(image)
      })
    }
  }, [carouselImages])

  /**
   * @function handleAddToCartClick
   * Handle adding the item to the cart.
   */
  const handleAddToCartClick = useCallback(() => {
    const cartItem = {
      ...product,
      variants: [variant],
      quantity
    }

    setAddingToCart(true)
    addCartItem(cartItem)
      .then(() => setAddingToCart(false))
  }, [product, quantity, variant])

  const handleExternalLinkClick = useCallback(() => {
    if (product?.externalUrl) {
      analyticsEvent(analyticsEvents.select_content, {
        content_type: 'product_external_url',
        item_id: product._id
      })

      // Look up current community reference, if any
      const community = sessionStorage.getItem('community')

      // Track the external URL click for this shop, product, and community
      PebbleApi
        .createClick(product.shop.slug, product._id, community)
        .then(() => {})
      
      window.open(product.externalUrl, '_blank').focus()
    }
  }, [product])

  const handleVariantChange = variant => {
    setVariant(variant)
  }

  const handleImageChange = image => () => {
    setCurrentImage(image)
  }

  const handleIncrementQuantity = () => {
    if (variant && quantity < variant.inventory_quantity) {
      const newQty = quantity + 1
      setQuantity(newQty)
    }
  }

  const handleDecrementQuantity = () => {
    if (quantity > 0) {
      const newQty = quantity - 1
      setQuantity(newQty)
    }
  }

  const updateWishlist = () => {
    const newSaved = !onWishlist
    const wishlistItem = {
      productId: product._id,
      variantId: variant?.id
    }
    const apiPromise = newSaved 
      ? addWishlistItem
      : removeWishlistItem

    setUpdatingWishlist(true)
    apiPromise(wishlistItem)
      .then(() => setUpdatingWishlist(false))
  }

  if (isLoading) {
    return <ItemSkeleton />
  }

  // Product must exist, belong to an active shop, and have an image to display
  if (product == null ||
    product.shop == null ||
    product.shop.state !== 'active' ||
    (product.imageUrl == null &&
      (product.images == null || product.images.length === 0))) {
    return <NotFound entity="product" />
  }

  const freeShippingThreshold =
    product.shop.free_shipping_threshold != null
      ? Number(product.shop.free_shipping_threshold).toFixed(2)
      : null

  let localPickupTimeframe = null

  if (product.shop.local_pickup_timeframe != null) {
    const days = Number(product.shop.local_pickup_timeframe)

    if (days === 0) {
      localPickupTimeframe = (
        <span>free <b>same day</b> local pickup</span>
      )
    } else if (days === 1) {
      localPickupTimeframe = (
        <span>free local pickup within <b>1 business day</b></span>
      )
    } else if (days > 0) {
      localPickupTimeframe = (
        <span>free local pickup within <b>{days} business days</b></span>
      )
    }
  } else {
    // Fallback with no timeframe
    localPickupTimeframe = (
      <span>free local pickup</span>
    )
  }

  return (
    <>
      <HelmetWrapper title={product.title} />
      <Section maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              gap: 1,
              justifyContent: 'flex-start',
              minHeight: { xs: '320px', md: '480px' }
            }}>
              <Box sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                flexShrink: 0,
                flexWrap: { xs: 'wrap', md: 'nowrap' },
                gap: 1,
                justifyContent: 'flex-start',
                '& img': {
                  border: '2px solid rgba(0, 0, 0, 0)',
                  borderRadius: theme => theme.spacing(0.5),
                  cursor: 'pointer',
                  height: 'auto',
                  width: 8,
                  '&.current': { 
                    borderColor: 'secondary.main'
                  }
                }
              }}>
                {carouselImages && carouselImages.map(image =>
                  <img
                    className={image.id === currentImage.id ? 'current' : null}
                    key={image.id}
                    sizes="50vw"
                    src={image.src}
                    srcSet={image.srcSet}
                    alt={product.title}
                    onClick={handleImageChange(image)}
                  />
                )}
              </Box>
              <Box sx={{
                flexGrow: 1,
                maxHeight: { xs: 'auto', md: '480px' },
                width: { xs: 'auto', md: 'calc(100% - 72px)' },
                '& img': {
                  borderRadius: theme => theme.spacing(0.5),
                  maxHeight: { xs: '100%', md: '480px' },
                  maxWidth: '100%',
                  objectFit: 'contain',
                  objectPosition: 'top left'
                }
              }}>
                <Zoom>
                  <img
                    alt={product.title}
                    src={currentImage.src}
                    srcSet={currentImage.srcSet}
                  />
                </Zoom>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Box>
                <Typography
                  component="h1"
                  sx={{ color: 'darkGrey.dark' }}
                  variant="h4"
                >
                  {product.title}
                </Typography>
                <Typography variant="body1">
                  {product.type === 'service' ? 'Provided by' : 'Sold by'}
                  {' '}
                  <b>
                    <MuiLink
                      component={Link}
                      sx={{ color: 'secondary.main' }}
                      to={`/shop/${product.shop.slug}`}
                    >
                      {product.shop.name}
                    </MuiLink>
                  </b>
                </Typography>
              </Box>
              {(variant || priceRange || priceRangeCompareAt ||
                (product.type === 'service' && product.minPrice != null) ||
                product.shop.offers_free_shipping ||
                product.shop.offers_local_pickup ||
                product.variants?.length > 1) &&
                <>
                  <Divider sx={{
                    height: '2px',
                    backgroundColor: 'grey.main'
                  }} />
                  <Box>
                    <Typography
                      component="p"
                      gutterBottom
                      sx={{
                        '& .compare-at': {
                          color: theme => alpha(theme.palette.darkGrey.main, 0.5),
                          fontSize: '80%',
                          pl: 1,
                          textDecoration: 'line-through'
                        }
                      }}
                      variant="h4"
                    >
                      {variant && `$${Number(variant.price).toFixed(2)}`}
                      {variant && variant.compare_at_price &&
                        <span className="compare-at">
                          ${variant.compare_at_price}
                        </span>
                      }
                      {variant == null && priceRange}
                      {variant == null && priceRangeCompareAt != null &&
                        <span className="compare-at">
                          ${priceRangeCompareAt}
                        </span>
                      }
                      {product.type === 'service' && product.minPrice != null && `$${Number(product.minPrice).toFixed(2)}`}
                    </Typography>
                    {product.type === 'service' &&
                      <Chip color="secondary" label="SERVICE" />
                    }
                    {(product.shop.offers_free_shipping ||
                      product.shop.offers_local_pickup) && (
                      <Box mt={2}>
                        {product.shop.offers_free_shipping && (
                          <Alert
                            severity="info"
                            sx={{
                              color: 'darkGrey.main',
                              pt: 0,
                              pb: 0,
                              background: 'none',
                              '& .MuiAlert-icon': {
                                color: 'darkGrey.main'
                              }
                            }}
                          >
                            {product.shop.name} offers <b>free shipping</b> on orders over ${freeShippingThreshold}.
                          </Alert>
                        )}
                        {product.shop.offers_local_pickup && (
                          <Alert
                            severity="info"
                            sx={{
                              color: 'darkGrey.main',
                              pt: 0,
                              pb: 0,
                              background: 'none',
                              '& .MuiAlert-icon': {
                                color: 'darkGrey.main'
                              }
                            }}
                          >
                            {product.shop.name} offers {localPickupTimeframe}.
                          </Alert>
                        )}
                      </Box>
                    )}
                    {product.variants?.length > 1 &&
                      <Box mt={2}>
                        {product.variants?.length > 1 && product.variants?.length < 9 &&
                          <ItemVariantButtons
                            variants={product.variants}
                            variant={variant}
                            onChange={handleVariantChange}
                          />
                        }
                        {product.variants?.length > 8 &&
                          <ItemVariantList
                            variants={product.variants}
                            variant={variant}
                            onChange={handleVariantChange}
                          />
                        }
                      </Box>
                    }
                  </Box>
                </>
              }
              <Divider sx={{
                height: '2px',
                backgroundColor: 'grey.main'
              }} />
              <Stack alignItems="center" direction="row" spacing={1}>
                {product.type !== 'service' &&
                product.shop.platform === 'shopify' &&
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          aria-label="remove quantity from cart"
                          color="secondary"
                          onClick={handleDecrementQuantity}
                          disabled={quantity === 1}
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          aria-label="add quantity to cart"
                          color="secondary"
                          onClick={handleIncrementQuantity}
                          disabled={
                            variant && quantity === variant.inventory_quantity
                          }
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      )
                    }}
                    sx={{
                      backgroundColor: 'grey.main',
                      borderRadius: theme => theme.spacing(0.5),
                      width: '132px',
                      '& .MuiInputBase-root': {
                        p: 0
                      },
                      '& .MuiInputBase-input': {
                        height: '1.1876em',
                        p: '6px 0 7px',
                        textAlign: 'center',
                      },
                      '& .MuiInput-underline:before, & .MuiInput-underline:hover:before': {
                        border: 'none',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '& .MuiButtonBase-root': {
                        p: '12px'
                      }
                    }}
                    value={quantity}
                  />
                }
                <ShareButton title={product.title} text={product.title} />
                {/* For now only support wishlist for logged-in users */}
                {user.loggedIn &&
                  <IconButton
                    aria-label="favorite"
                    color="secondary"
                    disabled={updatingWishlist}
                    onClick={updateWishlist}
                  >
                    {updatingWishlist
                      ? <CircularProgress size={24} />
                      : onWishlist
                        ? <FavoriteIcon />
                        : <FavoriteBorderIcon />
                    }
                  </IconButton>
                }
              </Stack>
              {product.type !== 'service' &&
               product.shop.platform === 'shopify' &&
                <Button
                  aria-label="add item to shopping cart"
                  variant="outlined"
                  color="secondary"
                  // Prevent adding to cart until user selects a variant
                  disabled={variant === null || addingToCart}
                  disableElevation
                  endIcon={addingToCart
                    ? <CircularProgress
                        size={20}
                        sx={{ color: 'common.white' }}
                      />
                    : null
                  }
                  fullWidth
                  onClick={handleAddToCartClick}
                >
                  Add to Cart
                </Button>
              }
              {product.type === 'service' && product.externalUrl &&
                <Button
                  aria-label="learn more about this service"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  endIcon={<LaunchIcon />}
                  fullWidth
                  onClick={handleExternalLinkClick}
                >
                  Learn more
                </Button>
              }
              {product.type !== 'service' && product.externalUrl &&
                <Button
                  aria-label="buy product"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  endIcon={<LaunchIcon />}
                  fullWidth
                  onClick={handleExternalLinkClick}
                >
                  Buy from {product.shop.name}
                </Button>
              }
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ backgroundColor: 'grey.light' }}>
              <Box p={2}>
                <Typography
                  component="h1"
                  gutterBottom
                  sx={{
                    color: 'darkGrey.dark',
                    mb: '30px'
                  }}
                  variant="h4"
                >
                  Product Information
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Section>
      <SimilarProducts
        heading="People also purchased"
        productId={product._id}
      />
      <RecommendedProducts
        color="greyLight"
        mode="from"
        heading={`Also from ${product.shop.name}`}
        slug={product.shop.slug}
        exclude={[product._id]}
      />
      <RecommendedSearches color="white" />
      <ShopAbout color="secondaryLight" slug={product.shop.slug} />
    </>
  )
}
