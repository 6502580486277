import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { request, gql } from 'graphql-request'
import dayjs from 'dayjs'

import {
  Avatar,
  Box,
  Skeleton,
  Typography
} from '@mui/material'

import HelmetWrapper from '../helmet-wrapper'
import Section from '../section'

export default function BlogPost() {
  const { slug } = useParams()
  const [blog, setBlog] = useState(null)

  useEffect(() => {
    const fetchBlog = async () => {
  
      const query = gql`
        query getPost($postSlug: String!) {
          post(where: {slug: $postSlug}) {
            id
            date
            title
            slug
            tags
            content {
              html
            }
            coverImage {
              url
            }
            author {
              name
              picture {
                url
              }
              title
              biography
            }
            seo {
              title
              description
              keywords
            }
          }
        }
      `

      const variables = {
        postSlug: slug
      }

      const blog = await request(
        'https://api-us-east-1.graphcms.com/v2/ckpyeeq9apzas01z62pcnb2wk/master',
        query,
        variables
      )

      setBlog(blog)
    }

    fetchBlog()
  }, [slug])

  if (!blog) {
    return (
      <>
        <HelmetWrapper title="Blog" />
        <Section maxWidth="md">
          <Typography
            sx={{
              textAlign: 'center',
              mb: 8
            }}
            variant="h1"
          >
            The Ripple
          </Typography>
          <Typography variant="h2" gutterBottom>
            <Skeleton width="60%" />
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </Typography>
          <Typography variant="body1">
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </Typography>
        </Section>
      </>
    )
  }

  let seoTitle = blog.post.title
  let seoDescription = blog.post.excerpt
  let seoKeywords = blog.post.tags

  if (blog.post.seo != null) {
    if (blog.post.seo.title != null && blog.post.seo.title.length) {
      // Use SEO title instead of blog post title
      seoTitle = blog.post.seo.title
    }

    if (blog.post.seo.description != null &&
        blog.post.seo.description.length) {
      // Use SEO description instead of blog post excerpt
      seoDescription = blog.post.seo.description
    }

    if (blog.post.seo.keywords != null && blog.post.seo.keywords.length) {
      // Add SEO keywords on top of blog post tags
      seoKeywords = [...blog.post.seo.keywords, ...seoKeywords]
    }
  }

  return (
    <>
      <HelmetWrapper
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords}
      />
      <Section maxWidth="md">
        <Typography
          sx={{
            textAlign: 'center',
            mb: 8
          }}
          variant="h1"
        >
          The Ripple
        </Typography>
        {blog.post.coverImage &&
          <Box
            sx={{
              backgroundImage: `url(${blog.post.coverImage.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: 0,
              mb: 4,
              pt: '56.25%', // 16:9 aspect ratio
              width: '100%'
            }}
          />
        }
        <Typography variant="h2">
          {blog.post.title}
        </Typography>
        <Typography
          sx={{
            color: 'darkGrey.light',
            mb: 4
          }}
          variant="subtitle2"
        >
          Published on {dayjs(blog.post.date).format('MMMM D, YYYY')} by {blog.post.author.name}
        </Typography>
        <Box
          dangerouslySetInnerHTML={{__html: blog.post.content.html}}
          sx={{
            '& p': {
              fontSize: '1.1rem'
            },
            '& img': {
              width: '100%',
              height: 'auto'
            }
          }}
        />
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          mt: 8
        }}>
          <Avatar
            src={blog.post.author.picture.url}
            sx={{
              height: theme => theme.spacing(16),
              width: theme => theme.spacing(16),
            }}
            alt={blog.post.author.name}
          />
          <Box sx={{
            pl: 2,
            '& .MuiTypography-root': {
              color: 'darkGrey.main'
            }
          }}>
            <Typography variant="h6" component="p">
              {blog.post.author.name}
            </Typography>
            <Typography variant="body2">
              {blog.post.author.title}
            </Typography>
          </Box>
        </Box>
      </Section>
    </>
  )
}
