import { Helmet } from 'react-helmet';

/**
 * Wrapper for react-helmet that allows consistent control of the title
 * branding in one location. Expects props:
 * - title - Page title without branding
 * - description - Page meta description
 * @param {object} props Properties passed to this component
 */
export default function HelmetWrapper(props) {
  const { title, description, keywords, imagesToPreload } = props;

  // Application title brand. Used by itself if no page title is provided,
  // otherwise it's appended at the end of the title.
  let fullTitle = 'Pebble';

  // Symbol to use as a separator in the title between the page-specific title
  // and the brand
  const titleSeparator = '·';

  // Default meta description to use
  let fullDescription = 'Search for your favorite products across local business and buy them from the comfort of your home.';

  // Default keywords to null for now. We can add default keywords later.
  let fullKeywords = null;

  if (title != null && title.length > 0) {
    fullTitle = `${title} ${titleSeparator} ${fullTitle}`;
  }

  if (description != null && description.length > 0) {
    fullDescription = description;
  }

  if (keywords != null && keywords.length > 0) {
    // If `keywords` is an array, join it into a comma-separated string
    if (Array.isArray(keywords)) {
      fullKeywords = keywords.join(',');
    } else {
      // Otherwise use keywords as-is, assuming it's already a comma-separated
      // string
      fullKeywords = keywords;
    }
  }

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={fullDescription} />
      {keywords && <meta name="keywords" content={fullKeywords} />}
      {imagesToPreload?.map((image, i) =>
        <link
          as="image"
          href={image.src}
          imagesizes="50vw"
          imagesrcset={image.srcSet}
          rel="preload"
        />
      )}
    </Helmet>
  );
}
