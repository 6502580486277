/**
 * @fileoverview Implements a section for displayed recommended searches.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import HorizontalScroller from '../horizontal-scroller'
import PebbleApi from '../../lib/pebble-api'
import RecommendedSearch from './recommended-search'
import RecommendedSearchSkeleton from './recommended-search-skeleton'
import Section from '../section'
import { getUrlQueryFromSearchModel } from '../../lib/search-context'

const RECOMMENDATION_COUNT = 8

/**
 * A component for searching and displaying search results.
 */
export default function RecommendedSearches(props) {
  const { community } = props
  const { ref, inView } = useInView()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()

  const mounted = useRef(false)

  // Initialize in a loading state until the section is scrolled into view
  const [loading, setLoading] = useState(true)

  const [loaded, setLoaded] = useState(false)
  const [searches, setSearches] = useState([])

  const heading = 'Popular searches'
  let renderedSearches = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })
  
  // Don't load recommended searches until they are scrolled into view
  useEffect(() => {
    if (inView && !loaded) {
      setLoading(true)
  
      PebbleApi
        .getRecommendedSearches(community)
        .then(searches => {
          if (mounted.current) {
            analyticsEvent(analyticsEvents.view_item_list, {
              item_list_name: 'Recommended searches'
            })
  
            window.fbq('track', 'ViewContent')
  
            setSearches(searches)
            setLoading(false)
            setLoaded(true)
          }
        })
    }
  }, [community, inView, loaded])

  const handleClick = useCallback(search => () => {
    const searchModel = {
      query: search.query,
      community: community,
      useCurrentLocation: !Boolean(community),
      recommendedSearch: true
    }
    const params = getUrlQueryFromSearchModel(searchModel)
    const url = '/search?' + params.toString()
    navigate(url)
  }, [community, navigate])

  if (loading) {
    // Render skeleton results while loading
    renderedSearches = [...Array(RECOMMENDATION_COUNT)].map((_, i) => (
      <Grid
        item
        key={i}
        xs={6}
        md={3}
      >
        <RecommendedSearchSkeleton />
      </Grid>
    ))
  } else if (searches && searches.length > 0) {
    renderedSearches = searches.map((search, index) => (
      <Grid
        item
        xs={6}
        md={3}
        key={search.query + index}
      >
        <RecommendedSearch onClick={handleClick(search)} search={search} />
      </Grid>
    ))
  } else if (!searches || searches.length === 0) {
    // If there are no recommended searches, don't render anything at all
    return <Box ref={ref} />
  }

  // For mobile devices, use horizontal scroller
  if (mobile) {
    return (
      <Box ref={ref}>
        <Section boxProps={{ py: 2 }} color={props.color}>
          <Typography gutterBottom variant="h4" component="h2">
            {loading ? <Skeleton width="40%" /> : heading}
          </Typography>
          <Box sx={{ mx: { xs: -2, sm: -3 } }}>
            <HorizontalScroller>
              {loading
                ? [...Array(RECOMMENDATION_COUNT)].map((_, i) =>
                    <RecommendedSearchSkeleton key={i} />
                  )
                : searches.map((search, index) =>
                    <RecommendedSearch
                      key={search.query + index}
                      onClick={handleClick(search)}
                      search={search}
                    />
                  )
              }
            </HorizontalScroller>
          </Box>
        </Section>
      </Box>
    )
  }

  return (
    <Box ref={ref}>
      <Section color={props.color}>
        <Typography variant="h2" gutterBottom>
          {loading ? <Skeleton width="40%" /> : heading}
        </Typography>
        <Grid container spacing={2}>
          {renderedSearches}
        </Grid>
      </Section>
    </Box>
  )
}
