/**
 * @fileoverview Implements a list item that behaves like a link.
 */

import { NavLink } from 'react-router-dom'

import { ListItemButton } from '@mui/material'

export default function ListItemLink(props) {
  const { to, ...otherProps } = props

  return (
    <ListItemButton
      component={NavLink}
      end
      sx={{
        '&.active': {
          color: 'secondary.main',
          '& .MuiListItemText-primary': {
            fontWeight: 'bold'
          },
          '& .MuiListItemIcon-root': {
            color: 'secondary.main'
          }
        }
      }}
      to={to}
      {...otherProps}
    />
  )
}
