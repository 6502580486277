/**
 * @fileoverview Implements a Plans section for the Partner page.
 */

import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'

import Section from '../section'

export default function PartnerPlans() {
  return (
    <Section color="white" id="plans" maxWidth="sm">
    <Typography
      align="center"
      component="h2"
      gutterBottom
      variant="h1"
    >
      Partner plans
    </Typography>
    <Box pt={2} align="center">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%' }} variant="outlined">
            <CardContent sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              height: '100%',
              textAlign: 'center'
            }}>
              <Typography gutterBottom variant="h5">
                Basic
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>Free</b>
              </Typography>
              <Typography gutterBottom variant="body1">
                Do it yourself
              </Typography>
              <Typography
                component="ul"
                gutterBottom
                sx={{
                  listStyle: 'none',
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  '& li': {
                    alignItems: 'flex-start',
                    display: 'flex',
                    fontSize: '0.75rem',
                    '& + li': {
                      mt: 0.5,
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'success.main',
                      height: '0.75rem',
                      width: '0.75rem',
                      mr: 0.5,
                      mt: '2px'
                    },
                    '&.con .MuiSvgIcon-root': {
                      color: 'error.main'
                    }
                  }
                }}
                variant="body2"
              >
                <li>
                  <CheckCircleIcon />
                  Unique Pebble shop page
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited products and services
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited communities
                </li>
                <li>
                  <CheckCircleIcon />
                  Manually add products and services
                </li>
                <li className="con">
                  <CancelIcon />
                  No automatic syncing of products and services from your website
                </li>
                <li className="con">
                  <CancelIcon />
                  No external links to your website
                </li>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%' }} elevation={6}>
            <CardContent sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              height: '100%',
              textAlign: 'center'
            }}>
              <Typography gutterBottom variant="h5">
                Integrated
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>$7 per month</b>
              </Typography>
              <Typography gutterBottom variant="body1">
                Let us handle it
              </Typography>
              <Typography
                component="ul"
                gutterBottom
                sx={{
                  listStyle: 'none',
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  '& li': {
                    alignItems: 'flex-start',
                    display: 'flex',
                    fontSize: '0.75rem',
                    '& + li': {
                      mt: 0.5,
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'success.main',
                      height: '0.75rem',
                      width: '0.75rem',
                      mr: 0.5,
                      mt: '2px'
                    },
                    '&.con .MuiSvgIcon-root': {
                      color: 'error.main'
                    }
                  }
                }}
                variant="body2"
              >
                <li>
                  <CheckCircleIcon />
                  Unique Pebble shop page
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited products and services
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited communities
                </li>
                <li>
                  <CheckCircleIcon />
                  Direct link to your website from each listing
                </li>
                <li>
                  <CheckCircleIcon />
                  Pebble Analytics
                </li>
                <li>
                  <CheckCircleIcon />
                  Automatically sync products and services from your website
                </li>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%' }} elevation={6}>
            <CardContent sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              height: '100%',
              textAlign: 'center'
            }}>
              <Typography gutterBottom variant="h5">
                Shopify
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>$5 per month</b>
              </Typography>
              <Typography gutterBottom variant="body1">
                Integrate with Shopify
              </Typography>
              <Typography
                component="ul"
                gutterBottom
                sx={{
                  listStyle: 'none',
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  '& li': {
                    alignItems: 'flex-start',
                    display: 'flex',
                    fontSize: '0.75rem',
                    '& + li': {
                      mt: 0.5,
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'success.main',
                      height: '0.75rem',
                      width: '0.75rem',
                      mr: 0.5,
                      mt: '2px'
                    },
                    '&.con .MuiSvgIcon-root': {
                      color: 'error.main'
                    }
                  }
                }}
                variant="body2"
              >
                <li>
                  <CheckCircleIcon />
                  Unique Pebble shop page
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited products and services
                </li>
                <li>
                  <CheckCircleIcon />
                  Unlimited communities
                </li>
                <li>
                  <CheckCircleIcon />
                  Automatically sync products and services from Shopify
                </li>
                <li>
                  <CheckCircleIcon />
                  Handle Pebble orders directly in Shopify
                </li>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    </Section>
  )
}
