/**
 * @fileoverview Implements a wrapper for the banner on the community page.
 */

import Section from '../section';
import CommunityBannerCore from './community-banner-core';

export default function CommunityBanner(props) {
  // Assumes `community` is non-null when rendering this component
  const { community } = props

  return (
    <Section boxProps={{ py: 2 }}>
      <CommunityBannerCore community={community} />
    </Section>
  )
}
