/**
 * @fileoverview Implements a view showing the user's most recent order.
 */

import { useEffect, useState } from 'react'

import { Stack } from '@mui/material'

import { useUser } from '../../lib/user-context'
import AccountOrder from './account-order'
import AccountOrderSkeleton from './account-order-skeleton'
import AccountSection from './account-section'
import AccountSectionSkeleton from './account-section-skeleton'
import PebbleApi from '../../lib/pebble-api'

export default function AccountOrders() {
  const { loadingUser, user } = useUser()
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!loadingUser && user.loggedIn) {
      setLoading(true)

      // Only show placed orders
      const statuses = ['placed']

      PebbleApi
        .getOrders(statuses)
        .then(connection => {
          const newOrders = connection.edges.map(edge => edge.node)
          setOrders(newOrders)
          setLoading(false)
        })
    }
  }, [loadingUser, user])

  if (loading) {
    return <AccountSectionSkeleton />
  }

  return (
    <AccountSection heading="Purchase history">
      <Stack spacing={2}>
        {orders.map(order =>
          <AccountOrder key={order.id} order={order} />
        )}
      </Stack>
    </AccountSection>
  )
}
