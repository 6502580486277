/**
 * @fileoverview Implements a loading skeleton for cart items.
 */

import { Box, Skeleton, Typography } from '@mui/material'

export default function CartItemSkeleton() {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }}>
      <Skeleton
        sx={{
          flex: '0 0 auto',
          width: { xs: '120px', md: '160px' },
          height: { xs: '120px', md: '160px' }
        }}
        variant="rect"
      />
      <Box sx={{
        flexGrow: 1,
        p: { xs: 2, sm: 2 }
      }}>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </Box>
    </Box>
  )
}
