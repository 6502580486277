/**
 * @fileoverview Logout view.
 */

import { useEffect } from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import LinkButton  from '../link-button'
import Typography from '@mui/material/Typography'

import { useUser } from '../../lib/user-context'

export default function Logout() {
  const { logout, user } = useUser()

  // Do the actual logout
  useEffect(() => {
    if (user.loggedIn) {
      logout()
    }
  }, [logout, user])

  return (
    <Container maxWidth="sm">
      <Box py={4}>
        <Typography variant="h2" gutterBottom>
          You're logged out!
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are now shopping as a guest.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Didn't mean to log out?
        </Typography>
        <LinkButton
          color="primary"
          size="large"
          sx={{ mb: 2, width: '100%' }}
          to="/login"
        >
          Log back in
        </LinkButton>
      </Box>
    </Container>
  )
}
