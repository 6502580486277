/**
 * @fileoverview Implements a context for showing snackbar messages anywhere in
 * the application.
 */

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import SnackbarAlert from '../components/snackbar-alert'

/**
 * The context for the snackbar alerts.
 */
const SnackbarContext = createContext()

/**
 * Default auto-hide duration for the snackbar.
 */
const DEFAULT_AUTO_HIDE_DURATION = 5000

/**
 * @function SnackbarProvider
 * The provider for the snackbar context.
 * @param {objects} props - Props passed to the provider
 * @returns {object} Context provider component
 */
const SnackbarProvider = props => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const [severity, setSeverity] = useState(null)
  const [autoHideDuration, setAutoHideDuration] = useState(null)

  const handleClose = useCallback(() => setOpen(false), [])

  const showMessage = useCallback((message, severity, autoHideDuration) => {
    setMessage(message)
    setSeverity(severity)
    setAutoHideDuration(autoHideDuration)
    setOpen(true)
  }, [])

  // Helper functions to simplify severity messages
  const showError = useCallback((message, autoHideDuration) =>
    showMessage(message, 'error', autoHideDuration), [showMessage])
  const showInfo = useCallback((message, autoHideDuration) =>
    showMessage(message, 'info', autoHideDuration), [showMessage])
  const showSuccess = useCallback((message, autoHideDuration) =>
    showMessage(message, 'success', autoHideDuration), [showMessage])
  const showWarning = useCallback((message, autoHideDuration) =>
    showMessage(message, 'warning', autoHideDuration), [showMessage])

  const value = useMemo(() => ({
    showError,
    showInfo,
    showMessage,
    showSuccess,
    showWarning
  }), [showError, showInfo, showMessage, showSuccess, showWarning])

  return (
    <>
      <SnackbarContext.Provider value={value} {...props} />
      <SnackbarAlert
        autoHideDuration={autoHideDuration ?? DEFAULT_AUTO_HIDE_DURATION}
        onClose={handleClose}
        open={open}
        severity={severity}
      >
        {message}
      </SnackbarAlert>
    </>
  )
}

/**
 * @function useSnackbar
 * Hook to use the snackbar context.
 * @returns {object} SnackbarContext
 */
const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  
  return context
};

export {
  SnackbarProvider,
  useSnackbar
}
