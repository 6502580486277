/**
 * @fileoverview Implements an email list signup form for customers.
 */

import { useState } from 'react'

import {
  Link as MuiLink
} from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Section from './section'
import PebbleApi from '../lib/pebble-api'
import analyticsEvent, { analyticsEvents } from '../lib/analytics'

export default function EmailSignupCustomer() {
  const [formError, setFormError] = useState(null)
  const [formSuccess, setFormSuccess] = useState(null)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleFirstNameChange = event => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = event => {
    setLastName(event.target.value)
  }

  const handleSubmit = () => {
    // First clear out all errors
    setFormError(null)
    setEmailError(null)

    let hasError = false

    if (email == null || email.trim().length === 0) {
      setEmailError('Email address is required')
      hasError = true
    }

    // If there's a validation error, bail before talking to the server
    if (hasError) {
      return
    }

    setLoading(true)

    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute('6Ldn0G4bAAAAAOHwhde8AAVfhYpiazRho5Qygg8a', { action: 'submit' })
        .then(token => {
          // First, verify the reCAPTCHA response token through our server
          PebbleApi
            .verifyRecaptcha(token)
            .then(success => {
              if (!success) {
                setLoading(false)
                setFormError('Oops! Something went wrong while subscribing you to the list. Please try again later.')
                return
              }
          
              const customerInfo = {
                email,
                firstName,
                lastName
              }
          
              PebbleApi
                .addCustomerToEmailList(customerInfo)
                .then(success => {
                  setLoading(false)
          
                  if (success) {
                    analyticsEvent(analyticsEvents.sign_up, {
                      method: 'generate_lead',
                      user_email: email
                    })
          
                    window.fbq('track', 'Lead')
                    
                    setFormSuccess("Thanks for subscribing! Check your inbox for a confirmation email.")
                  } else {
                    setFormError('Oops! Something went wrong while subscribing you to the list. Please try again later.')
                  }
                })
            })
        })
    })
  }

  return (
    <Section maxWidth="sm" color="grey">
      <Box pb={4}>
        <Typography gutterBottom variant="h2" color="secondary">
          Sign up for our newsletter
        </Typography>
        <Typography variant="body1">
          Join our mailing list to get regular updates on what's new with Pebble and how you can support small businesses!
        </Typography>
      </Box>
      {formSuccess &&
        <Alert severity="success" sx={{
          mb: 2,
          width: '100%'
        }}>
          {formSuccess}
        </Alert>
      }
      {formError &&
        <Alert severity="error" sx={{
          mb: 2,
          width: '100%'
        }}>
          {formError}
        </Alert>
      }

      <TextField
        autoComplete="given-name"
        disabled={loading}
        label="First Name"
        name="given-name"
        onChange={handleFirstNameChange}
        sx={{
          mb: 2,
          width: '100%'
        }}
        value={firstName}
        variant="outlined"
      />
      <TextField
        autoComplete="family-name"
        disabled={loading}
        label="Last Name"
        name="family-name"
        onChange={handleLastNameChange}
        sx={{
          mb: 2,
          width: '100%'
        }}
        value={lastName}
        variant="outlined"
      />
        <TextField
        autoComplete="email"
        disabled={loading}
        error={Boolean(emailError)}
        helperText={emailError}
        label="Email"
        name="email"
        onChange={handleEmailChange}
        required
        sx={{
          mb: 2,
          width: '100%'
        }}
        variant="outlined"
        value={email}
      />
      <Button 
        color="secondary"
        disabled={loading}
        endIcon={loading && <CircularProgress size={24} />}
        onClick={handleSubmit}
        size="large"
        sx={{
          mb: 2,
          width: '100%'
        }}
      >
        Subscribe
      </Button>
      <Typography sx={{ color: 'darkGrey.light' }} variant="body2">
        This site is protected by reCAPTCHA and the Google <MuiLink href="https://policies.google.com/privacy">Privacy Policy</MuiLink> and <MuiLink href="https://policies.google.com/terms">Terms of Service</MuiLink> apply.
      </Typography>
    </Section>
  )
}
