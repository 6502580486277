import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const DEFAULT_AUTO_HIDE_DURATION = 5000;

export default function SnackbarAlert(props) {
  const { autoHideDuration, children, onClose, open, severity } = props;

  return (
    <Snackbar
      autoHideDuration={autoHideDuration ?? DEFAULT_AUTO_HIDE_DURATION}
      onClose={onClose}
      open={open}
    >
      <Alert
        elevation={6}
        onClose={onClose}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
