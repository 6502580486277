/**
 * @fileoverview Implements a skeleton section for the Account view.
 */

import { Skeleton } from '@mui/material'

import AccountSection from './account-section'

export default function AccountSectionSkeleton() {
  return (
    <AccountSection heading={<Skeleton width="20%" />}>
      <Skeleton />
      <Skeleton />
      <Skeleton width="40%" />
    </AccountSection>
  )
}
