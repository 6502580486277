/**
 * @fileoverview Implements a shopping cart.
 */

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Close as CloseIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  IconButton,
  Skeleton,
  SwipeableDrawer,
  Toolbar,
  Typography
} from '@mui/material'

import CartItem from './cart-item'
import CartItemSkeleton from './cart-item-skeleton'
import LinkButton from '../link-button'

import { useUser } from '../../lib/user-context'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import { useCallback } from 'react'

export default function CartDrawer() {
  const { loadFullCart, loadingUser, user } = useUser()
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    if (!loadingUser && !user.cart.loadedFull) {
      loadFullCart()
    }
  }, [loadFullCart, loadingUser, user])

  useEffect(() => {
    analyticsEvent(analyticsEvents.view_cart, {
      currency: 'USD',
      items: user.cart.items.map(item => ({
        affiliation: item.shop.slug,
        currency: 'USD',
        item_id: item.product_id,
        item_brand: item.shop.name,
        item_name: item.title,
        item_variant: item.variants[0].id,
        price: item.price,
        quantity: item.quantity
      })),
      user_email: user.email,
      value: user.cart.subtotal
    })
  }, [user.cart.items, user.cart.subtotal, user.email])

  // Handle drawer opening and closing
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), [])
  const openDrawer = useCallback(() => setIsDrawerOpen(true), [])

  /**
   * @function handleLogInToCheckOut
   * Handles initiating checkout. If the user is not logged in, redirects to the
   * login view instead.
   */
  const handleLogInToCheckOut = useCallback(() => {
    closeDrawer()

    if (user.loggedIn) {
      navigate('/checkout')
    } else {
      navigate('/login?r=%2Fcart')
    }
  }, [closeDrawer, navigate])

  /**
   * @function handleCheckOutAsAGuest
   * Handles initiating checkout as a guest.
   */
  const handleCheckOutAsAGuest = useCallback(() => {
    closeDrawer()
    navigate('/checkout')
  }, [closeDrawer, navigate])

  // While loading, show skeleton cart items
  let results = [...Array(user.cart.count)].map((_, i) =>
    <CartItemSkeleton key={i} />
  );

  if (!loadingUser) {
    if (user.cart.items.length === 0) {
      results = (
        <Box p={2}>
          <Typography variant="h5" component="h3">
            No items in your cart!
          </Typography>
        </Box>
      )
    } else {
      // Render each cart item
      results = user.cart.items.map(item =>
        <CartItem
          boxProps={{ p: 2 }}
          item={item}
          key={item.cartItemId}
        />
      )
    }
  }

  return (
    <div>
      {/* Cart Icon Button */}
      <IconButton
        aria-label={`show ${user.cart.quantity} items in cart`}
        edge="end"
        onClick={openDrawer}
        sx={{ color: 'common.white' }}
      >
        <Badge badgeContent={user.cart.quantity} color="secondary">
          <ShoppingCartOutlinedIcon />
        </Badge>
      </IconButton>
      {/* / Cart Icon Button */}

      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onOpen={openDrawer}
        onClose={closeDrawer}
        sx={{
          '.MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: { xs: '100vw', sm: '50vw', md: '40vw', lg: '30vw' }
          }
        }}
      >
        <Toolbar sx={{
          backgroundColor: 'primary.main',
          display: 'flex',
          flexDirection: 'row',
          '& *': {
            color: 'common.white'
          }
        }}>
          <Badge
            badgeContent={user.cart.quantity}
            color="secondary"
            sx={{ mr: 2 }}
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
          <Typography variant="h4">
            Cart
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            aria-label="menu"
            edge="end"
            onClick={closeDrawer}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Box sx={{
          flexGrow: 1,
          maxHeight: 'calc(100vh - 56px)',
          overflowY: 'auto'
        }}>
          {results}
        </Box>
        <Box
          boxShadow={5}
          sx={{
            bottom: 0,
            backgroundColor: 'white',
            p: 2,
            width: '100%'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pb: 2
          }}>
            <Typography variant="body1">
              <b>Subtotal</b>
            </Typography>
            <Typography variant="body1">
              {loadingUser && <Skeleton />}
              {!loadingUser && <b>${user.cart.subtotal.toFixed(2)}</b>}
            </Typography>
          </Box>
          <Typography
            align="center"
            gutterBottom
            variant="body2"
          >
            Shipping, taxes and discount codes calculated at checkout.
          </Typography>
          <LinkButton
            color="secondary"
            disabled={user.cart.quantity === 0 || loadingUser}
            fullWidth
            onClick={handleLogInToCheckOut}
            sx={{ borderRadius: theme => theme.spacing(4) }}
            variant="contained"
          >
            {user.loggedIn ? 'Check out now' : 'Log in to check out'}
          </LinkButton>
          {!user.loggedIn &&
            <Box mt={1}>
              <Button
                color="secondary"
                disabled={user.cart.quantity === 0 || loadingUser}
                fullWidth
                onClick={handleCheckOutAsAGuest}
                sx={{ borderRadius: theme => theme.spacing(4) }}
                variant="outlined"
              >
                Check out as a guest
              </Button>
            </Box>
          }
        </Box>
      </SwipeableDrawer>
    </div>
  )
}
