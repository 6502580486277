/**
 * @fileoverview Implements a wrapper for scrolling components horizontally on
 * mobile devices.
 * 
 * Direct descendents are styled so that a certain number are fully visible and
 * one is partially visible on the right side, to indicate to the user there
 * are more to be seen by scrolling.
 * 
 * This will fill the height of its parent, so parent component should define
 * the height explicitly.
 * 
 * Assumes full width display in the viewport, so the parent component should
 * not have any margins or padding.
 */

import { useRef } from 'react'

import { useTheme } from '@mui/material/styles'
import {
  Box,
  Fab,
  useMediaQuery
} from '@mui/material'
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon
} from '@mui/icons-material'

export default function HorizontalScroller(props) {
  const { children } = props
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const childWrapper = useRef(null)

  /**
   * @function handleLeftButtonClick
   * Handles clicking the left button to scroll one child's width to the left.
   */
  const handleLeftButtonClick = () => {
    if (!childWrapper.current || childWrapper.current.children.length === 0) {
      return
    }

    const spacing = Number(theme.spacing(1).slice(0, -2))
    const width = childWrapper.current.children[0].offsetWidth + spacing
    childWrapper.current.scrollLeft = childWrapper.current.scrollLeft - width
  }

  /**
   * @function handleRightButtonClick
   * Handles clicking the right button to scroll one child's width to the right.
   */
  const handleRightButtonClick = () => {
    if (!childWrapper.current || childWrapper.current.children.length === 0) {
      return
    }

    const spacing = Number(theme.spacing(1).slice(0, -2))
    const width = childWrapper.current.children[0].offsetWidth + spacing
    childWrapper.current.scrollLeft = childWrapper.current.scrollLeft + width
  }

  return (
    <Box sx={{
      height: '100%',
      position: 'relative'
    }}>
      <Box
        ref={childWrapper}
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          height: '100%',
          justifyContent: 'flex-start',
          overflowX: 'scroll',
          scrollBehavior: 'smooth',
          width: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {children.map((child, index) => (
          <Box
            key={index}
            sx={{
              flexShrink: 0,
              ml: 1,
              width: theme => ({
                // Start with full width, removing spacing, and divide by 2-1/3,
                // the number of children that are visible initially
                xs: `calc((100vw - ${theme.spacing(4)}) / (7 / 3))`,
                // On tablets and larger, show 4-1/3 children
                sm: `calc((100vw - ${theme.spacing(4)}) / (13 / 3))`
              }),
              '&:first-child': {
                ml: { xs: 2, sm: 3 }
              },
              '&:last-child': {
                mr: { xs: 2, sm: 3 }
              }
            }}
          >
            {child}
          </Box>
        ))}
      </Box>
      {!mobile &&
        <>
          <Box sx={{
            left: 5,
            position: 'absolute',
            top: 'calc(50% - 20px)',
            '& .MuiButtonBase-root': {
              backgroundColor: 'grey.light',
              '&:hover': {
                backgroundColor: 'common.white'
              }
            }
          }}>
            <Fab onClick={handleLeftButtonClick} size="small">
              <ArrowBackIcon />
            </Fab>
          </Box>
          <Box sx={{
            position: 'absolute',
            right: 5,
            top: 'calc(50% - 20px)',
            '& .MuiButtonBase-root': {
              backgroundColor: 'grey.light',
              '&:hover': {
                backgroundColor: 'common.white'
              }
            }
          }}>
            <Fab onClick={handleRightButtonClick} size="small">
              <ArrowForwardIcon />
            </Fab>
          </Box>
          <Box sx={{
            background: theme => 'linear-gradient(to right, ' + theme.palette.common.white + ', rgb(255, 255, 255, 0))',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: theme => theme.spacing(3)
          }} />
          <Box sx={{
            background: theme => 'linear-gradient(to left, ' + theme.palette.common.white + ', rgb(255, 255, 255, 0))',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            width: theme => theme.spacing(3)
          }} />
        </>
      }
    </Box>
  )
}
