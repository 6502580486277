/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API for account onboarding operations.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

/***********
 * Queries *
 ***********/

/**
 * Query for an invitation.
 */
const QUERY_INVITATION = `
query InvitationQuery($id: String) {
  invitation(id: $id) {
    community {
      imageUrl
      logoUrl
      name
      slug
    }
    shop {
      name
      shop_owner
      slug
    }
  }
}`

/*************
 * Mutations *
 *************/

const MUTATION_ADD_SHOP = `
mutation AddShopMutation {
  addShop {
    success
    successUrl
  }
}
`

const MUTATION_SET_SHOP_PLAN = `
mutation SetShopPlanMutation($slug: String, $plan: String) {
  setShopPlan(slug: $slug, plan: $plan) {
    success
    successUrl
  }
}
`

const MUTATION_SET_SHOP_PLATFORM = `
mutation SetShopPlatformMutation($slug: String, $platform: String) {
  setShopPlatform(slug: $slug, platform: $platform) {
    success
    successUrl
  }
}
`

const MUTATION_USE_INVITATION = `
mutation UseInvitation($id: String, $slug: String) {
  useInvitation(id: $id, slug: $slug) {
    success
    successUrl
  }
}
`

const queries = {
  QUERY_INVITATION,
  MUTATION_ADD_SHOP,
  MUTATION_SET_SHOP_PLAN,
  MUTATION_SET_SHOP_PLATFORM,
  MUTATION_USE_INVITATION
}

export default queries
