/**
 * @fileoverview Implements a view showing a featured community.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Typography } from '@mui/material'

import PebbleApi from '../lib/pebble-api'
import Section from './section'
import CommunitySkeleton from './community/community-skeleton'
import CommunityBannerCore from './community/community-banner-core'

export default function FeaturedCommunity(props) {
  const { slug } = props
  const mounted = useRef(false)
  const navigate = useNavigate()

  const [community, setCommunity] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    setLoading(true)

    PebbleApi
      .getCommunity(slug)
      .then(data => {
        if (mounted.current) {
          setCommunity(data.community)
          setLoading(false)
        }
      })
  }, [slug])

  const handleClick = useCallback(() => {
    navigate(`/community/${slug}`)
  }, [slug])

  return (
    <Section color="white">
      <Stack spacing={2}>
        <Typography
          color="secondary"
          variant="h2"
          gutterBottom
        >
          Visit our newest community
        </Typography>
        {loading && <CommunitySkeleton />}
        {!loading &&
          <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
            <CommunityBannerCore community={community} />
          </Box>
        }
      </Stack>
    </Section>
  )
}
