/**
 * @fileoverview Implements a section for displaying recommended shops.
 */

import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Box, Grid, Skeleton, Typography } from '@mui/material'

import RecommendedShop from './recommended-shop'
import RecommendedSearchSkeleton from '../recommended-searches/recommended-search-skeleton'
import Section from '../section'

import PebbleApi from '../../lib/pebble-api'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'

const RECOMMENDATION_COUNT = 8

export default function RecommendedShops(props) {
  const { ref, inView } = useInView()

  const mounted = useRef(false)

  // Initialize in a loading state until the section is scrolled into view
  const [loading, setLoading] = useState(true)

  const [loaded, setLoaded] = useState(false)
  const [shops, setShops] = useState([])

  const heading = 'Popular Stores Near You'
  let renderedShops = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })
  
  // Don't load recommended searches until they are scrolled into view
  useEffect(() => {
    if (inView && !loaded) {
      setLoading(true)
  
      PebbleApi
        .getRecommendedShops()
        .then(shops => {
          if (mounted.current) {
            analyticsEvent(analyticsEvents.view_item_list, {
              item_list_name: 'Recommended shops'
            })
  
            window.fbq('track', 'ViewContent')
  
            setShops(shops)
            setLoading(false)
            setLoaded(true)
          }
        })
    }
  }, [inView, loaded])

  if (loading) {
    // Render skeleton results while loading
    renderedShops = [...Array(RECOMMENDATION_COUNT)].map((_, i) => (
      <Grid
        item
        key={i}
        xs={6}
        md={3}
      >
        <RecommendedSearchSkeleton />
      </Grid>
    ))
  } else if (shops && shops.length > 0) {
    renderedShops = shops.map((shop, index) => (
      <Grid
        item
        xs={6}
        md={3}
        key={shop.slug}
      >
        <RecommendedShop shop={shop} />
      </Grid>
    ))
  } else if (!shops || shops.length === 0) {
    // If there are no recommended shops, don't render anything at all
    return null
  }

  return (
    <Box ref={ref}>
      <Section color={props.color}>
        <Typography variant="h2" gutterBottom>
          {loading ? <Skeleton width="40%" /> : heading}
        </Typography>
        <Grid container spacing={2}>
          {renderedShops}
        </Grid>
      </Section>
    </Box>
  )
}
