/**
 * @fileoverview Implements a search field for the primary app bar.
 */

import { useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import InputBase from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { useSearchDispatch } from '../../lib/search-context'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useSearch } from '../../lib/search-context'
import recentSearchesCache from '../../lib/caches/recent-searches'

export default function SearchField(props) {
  const { onBlur, onChange, onFocus, onSubmit, value } = props
  const inputRef = useRef(null)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const search = useSearch()
  const searchDispatch = useSearchDispatch()

  const handleSearchBlur = () => onBlur && onBlur()
  const handleSearchFocus = () => onFocus && onFocus()
  const clearSearchQuery = () => onChange && onChange('')

  // Variables tracking search query 
  const isShopPage = useMatch('/shop/*')
  const isItemPage = useMatch('/item/*')
  const isSearchPage = useMatch('/search')
  const isCommunityPage = useMatch('/community/*')

  const submitSearchQuery = () => {
    const trimmedSearchQuery = (value ?? '').trim()

    // If the search string is not empty, then submit
    if (trimmedSearchQuery.length > 0) {
      inputRef.current && inputRef.current.blur()
      recentSearchesCache.add(trimmedSearchQuery)
      onChange && onChange(trimmedSearchQuery)
      onSubmit && onSubmit()
    }
  }

  const handleSearchChange = event => onChange && onChange(event.target.value)

  const handleSearchKeyDown = event => {
    // If the user hits Enter, then submit
    if (event.key === 'Enter') {
      submitSearchQuery()
    }
  }

  // Clear search query when leaving search page or search result
  useEffect(() => {
    if (!isItemPage && !isSearchPage && !isShopPage) {
      searchDispatch({
        type: 'set', payload: { query: '' }
      })
    }

    if (!isItemPage && !isSearchPage && !isShopPage && !isCommunityPage) {
      searchDispatch({
        type: 'set', payload: { community: null }
      })
    }
  }, [isItemPage, isSearchPage, isShopPage, isCommunityPage, searchDispatch])

  return (
    <InputBase
      endAdornment={
        <>
          {value?.length > 0 &&
            <InputAdornment position="end">
              <CloseIcon color="primary" onClick={clearSearchQuery} />
            </InputAdornment>
          }
          <InputAdornment position="end">
            <SearchIcon color="primary" onClick={submitSearchQuery} />
          </InputAdornment>
        </>
      }
      inputProps={{ 
        'aria-label': 'search',
        'autoCapitalize': 'off'
      }}
      inputRef={inputRef}
      onBlur={handleSearchBlur}
      onChange={handleSearchChange}
      onKeyDown={handleSearchKeyDown}
      onFocus={handleSearchFocus}
      placeholder={
        mobile
          ? 'Search'
          : (search.community
            ? 'Search within ' + search.community.name
            : 'Search for a product')
      }
      sx={{
        color: 'darkGrey.main',
        pl: 2,
        pr: 1,
        py: 0,
        width: '100%',
        '& input': {
          input: { width: '100%' }
        },
        '& .MuiSvgIcon-root': {
          cursor: 'pointer'
        }
      }}
      type="search"
      value={value}
    />
  )
}
