/**
 * @fileoverview Implements a checkout view and process for users to purchase
 * the items in their cart.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'

import HelmetWrapper from '../helmet-wrapper'
import CheckoutError from './checkout-error'
import CheckoutSkeleton from './checkout-skeleton'
import CheckoutStepAddress from './checkout-step-address'
import CheckoutStepDelivery from './checkout-step-delivery'
import CheckoutStepPayment from './checkout-step-payment'
import CheckoutStepReview from './checkout-step-review'
import CheckoutSummary from './checkout-summary'
import CheckoutSummarySkeleton from './checkout-summary-skeleton'
import Section from '../section'

import { useUser } from '../../lib/user-context'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import GuestCart from '../../lib/guest-cart'
import PebbleApi from '../../lib/pebble-api'

const steps = [
  'Delivery address',
  'Delivery method',
  'Payment method',
  'Review'
]

export default function Checkout() {
  const { loadFullCart, user } = useUser()
  const navigate = useNavigate()
  const mounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCompletingStep, setIsCompletingStep] = useState(false)
  const [address, setAddress] = useState(null)
  const [addressErrors, setAddressErrors] = useState({})
  const [orderId, setOrderId] = useState(null)
  const [shopifyCheckouts, setShopifyCheckouts] = useState([])
  const [deliveryMethods, setDeliveryMethods] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [total, setTotal] = useState(0)
  const [checkoutError, setCheckoutError] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    // Redirect to cart if the user has no items in their cart
    if (user.cart.quantity === 0) {
      navigate('/cart')
    }
  }, [navigate, user.cart.quantity])

  // Load checkout on entry or when cart status or user changes
  useEffect(() => {
    let guestCartItems = []

    // If the user is not logged in, use guest cart items to check out
    if (!user.loggedIn) {
      const guestCart = new GuestCart();
      guestCartItems = guestCart.items.map(i => ({
        productId: i._id,
        variantId: i.variants[0].id,
        quantity: i.quantity
      }))
    }
    
    setIsLoading(true)

    PebbleApi
      .initiateCheckout(guestCartItems)
      .then(order => {
        if (mounted.current) {
          if (order == null || order.__typename === 'Error') {
            setCheckoutError(order?.code ?? true)
            setIsLoading(false)
            return
          }

          analyticsEvent(analyticsEvents.begin_checkout, {
            coupon: '',
            currency: 'USD',
            /** @todo populate from checkouts */
            items: [],
            user_email: user.email,
            /** @todo populate from checkouts */
            value: 0
          })

          window.fbq('track', 'InitiateCheckout')

          setOrderId(order.id)
          setAddress(order.shippingAddress)
          setShopifyCheckouts(order.shopifyCheckouts)
          setIsLoading(false)
        }
      })
  }, [user.email, user.loggedIn])

  // Recalculate order total when shopify checkouts change
  useEffect(() => {
    const newTotal = shopifyCheckouts
      .reduce((acc, cur) => acc + Number(cur.total_price), 0)
    setTotal(newTotal)
  }, [shopifyCheckouts])

  /**********************************
   * Delivery Address step handlers *
   **********************************/

  /**
   * Handles a new address object coming from the Delivery Address step.
   * @param {object} newAddress - New address object
   */
  const handleAddressChanged = useCallback(newAddress => {
    setAddress(newAddress)
  }, [])

  /**
   * Handles the Delivery Address step being completed.
   */
  const handleAddressCompleted = async () => {
    const success = await saveCheckoutShippingAddress()

    if (success) {
      handleCompleted()
    }
  }

  /**
   * Saves the shipping address to Pebble and Shopify and load the latest
   * updated Shopify checkouts.
   */
  const saveCheckoutShippingAddress = useCallback(async () => {
    setIsCompletingStep(true)

    try {
      const newOrder = await PebbleApi
        .saveCheckoutShippingAddress(orderId, address)

      if (mounted.current) {
        if (newOrder == null || newOrder.__typename === 'Error') {
          if (newOrder?.code === 'EMAIL_ADDRESS_IS_ALREADY_IN_USE') {
            setAddressErrors({ 'email': 'Email address is already in use' })
          } else {
            setCheckoutError(newOrder?.code ?? true)
          }

          setIsCompletingStep(false)
          return false
        }
        
        setShopifyCheckouts(newOrder.shopifyCheckouts)
      }
    } catch (e) {
      console.error(e)
      setCheckoutError(true)
      setIsCompletingStep(false)
      return false
    }

    setIsCompletingStep(false)
    return true
  }, [orderId, address])

  /*********************************
   * Delivery Method step handlers *
   *********************************/
  
  /**
   * Handles a new list of delivery methods coming from the Delivery Method
   * step.
   * @param {Array<object>} newDeliveryMethods - New list of delivery methods
   */
  const handleDeliveryMethodsChanged = useCallback(newDeliveryMethods => {
    setDeliveryMethods(newDeliveryMethods)
  }, [])

  /**
   * Handles the Delivery Method step being completed.
   */
  const handleDeliveryCompleted = async () => {
    analyticsEvent(analyticsEvents.add_shipping_info, {
      coupon: '',
      currency: 'USD',
      /** @todo Populate items array */
      items: [],
      /** @todo Aggregate from selections */
      shipping_tier: '',
      user_email: user.email,
      value: total
    })

    await saveCheckoutDeliveryMethods()
    handleCompleted()
  };

  /**
   * Saves the delivery methods to Pebble and Shopify and load the latest
   * updated Shopify checkouts.
   */
  const saveCheckoutDeliveryMethods = useCallback(async () => {
    setIsCompletingStep(true)

    try {
      const newOrder = await PebbleApi
        .saveCheckoutDeliveryMethods(orderId, deliveryMethods)
      
      if (mounted.current) {
        if (newOrder == null || newOrder.__typename === 'Error') {
          setCheckoutError(newOrder?.code ?? true)
          return
        }
        
        setShopifyCheckouts(newOrder.shopifyCheckouts)
      }
    } catch (e) {
      console.error(e)
      setCheckoutError(true)
    }

    setIsCompletingStep(false)
  }, [orderId, deliveryMethods])

  /********************************
   * Payment Method step handlers *
   ********************************/

  /**
   * Handles the Payment Method step being completed.
   */
  const handlePaymentCompleted = async paymentMethod => {
    if (paymentMethod) {
      analyticsEvent(analyticsEvents.add_payment_info, {
        coupon: '',
        currency: 'USD',
        /** @todo Populate items array */
        items: [],
        payment_type: 'Credit Card via Stripe',
        user_email: user.email,
        value: total
      })
      
      window.fbq('track', 'AddPaymentInfo')

      await saveCheckoutPaymentMethod(paymentMethod)
    }

    // If no payment method is given, assume that the user went back to the
    // payment method step but did not edit the card info, so there's no new
    // payment method object to pass here. Thus, just move on to the next step
    // and use the previous payment method info that's already stored.
    handleCompleted()
  }

  /**
   * Saves the delivery methods to Pebble and Shopify and load the latest
   * updated Shopify checkouts.
   */
  const saveCheckoutPaymentMethod = useCallback(async paymentMethod => {
    setIsCompletingStep(true)

    try {
      const newOrder = await PebbleApi
        .saveCheckoutPaymentMethod(orderId, paymentMethod)
      
      if (mounted.current) {
        if (newOrder == null || newOrder.__typename === 'Error') {
          setCheckoutError(newOrder?.code ?? true)
          return
        }
        
        setShopifyCheckouts(newOrder.shopifyCheckouts)
        setPaymentMethod(newOrder.paymentMethod)
      }
    } catch (e) {
      console.error(e)
      setCheckoutError(true)
    }

    setIsCompletingStep(false)
  }, [orderId])

  /************************
   * Review step handlers *
   ************************/

  /**
   * Handles the final Review step being completed and places the order.
   */
  const handleReviewCompleted = async () => {
    await completeCheckout()
    handleCompleted()
  }

  /**
   * Generates Stripe card tokens for each checkout and completes the order
   * using those tokens for payment.
   */
  const completeCheckout = useCallback(async () => {
    setIsCompletingStep(true)

    let success = false

    try {
      success = await PebbleApi.completeCheckout(orderId)
    } catch (e) {
      console.error(e)
      /** @todo Display error message to user */
    }

    if (!mounted.current) {
      return
    }

    setIsCompletingStep(false)

    if (success === false || success.__typename === 'Error') {
      setCheckoutError(success?.code ?? true)
      return
    }

    // If the user is not logged in, clear out the guest cart
    if (!user.loggedIn) {
      const guestCart = new GuestCart()
      guestCart.clear()
    }

    analyticsEvent(analyticsEvents.purchase, {
      /** @todo populate with shop name */
      affiliation: '',
      coupon: '',
      currency: 'USD',
      /** @todo Populate items array */
      items: [],
      transaction_id: orderId,
      /** @todo Store this on Pebble order */
      shipping: 0,
      /** @todo Store this on Pebble order */
      tax: 0,
      user_email: user.email,
      value: total
    })

    window.fbq('track', 'Purchase', { value: total, currency: 'USD' })

    // Reload user cart
    loadFullCart()

    // Redirect to completion page
    navigate('/checkout/complete/' + orderId)
  }, [navigate, loadFullCart, orderId, total, user])

  /********************
   * Stepper handlers *
   ********************/

  /**
   * Navigates to the next incomplete step in the stepper.
   */
  const handleNextIncomplete = () => {
    // Find the next step that hasn't been completed yet
    let nextIncompleteStep = steps
      .findIndex((_, i) => i !== activeStep && !(i in completed))

    if (nextIncompleteStep === -1) {
      // All steps are complete, so show the final Review step
      nextIncompleteStep = steps.length - 1
    }

    setActiveStep(nextIncompleteStep)
  }

  /**
   * Handles activating a specific step by clicking on it.
   * @param {number} step - Step to activate
   */
  const handleStep = step => () => {
    setActiveStep(step)
  }

  /**
   * Handles marking the active step as completed.
   */
  const handleCompleted = () => {
    const newCompleted = { ...completed }
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNextIncomplete()
  }

  /**
   * Determines whether the given step is currently being completed and waiting
   * on a server response.
   * @param {number} step - Step to check for completing status
   * @returns {boolean} Whether given step is completing
   */
  const getIsCompletingStep = step => {
    return isCompletingStep && activeStep === step
  }

  // While loading, show a skeleton view
  if (isLoading) {
    return <CheckoutSkeleton />
  }

  // If there was an error during checkout, show the error view
  if (checkoutError) {
    return <CheckoutError code={checkoutError} />
  }

  return (
    <>
      <HelmetWrapper
        title="Checkout"
      />
      <Section maxWidth="lg">
        <Typography variant="h1" gutterBottom>
          Checkout
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              square={false}
              sx={{
                '& .MuiStepIcon-root.MuiStepIcon-active': {
                  color: 'secondary.main'
                },
                '& .MuiStepLabel-labelContainer': {
                  textAlign: 'left'
                }
              }}
              variant="outlined"
              nonLinear
            >
              <CheckoutStepAddress
                address={address}
                completed={completed[0]}
                errors={addressErrors}
                isCompleting={getIsCompletingStep(0)}
                isGuest={!user.loggedIn}
                onChange={handleAddressChanged}
                onClick={handleStep(0)}
                onComplete={handleAddressCompleted}
                disabled={isCompletingStep}
              />
              <CheckoutStepDelivery
                checkouts={shopifyCheckouts}
                completed={completed[1]}
                isCompleting={getIsCompletingStep(1)}
                onChange={handleDeliveryMethodsChanged}
                onClick={handleStep(1)}
                onComplete={handleDeliveryCompleted}
                disabled={isCompletingStep || (!completed[1] && activeStep !== 1)}
              />
              <CheckoutStepPayment
                checkouts={shopifyCheckouts}
                postalCode={address != null ? address.zip : null}
                completed={completed[2]}
                isCompleting={getIsCompletingStep(2)}
                onClick={handleStep(2)}
                onComplete={handlePaymentCompleted}
                disabled={isCompletingStep || (!completed[2] && activeStep !== 2)}
                paymentMethod={paymentMethod}
              />
              <CheckoutStepReview
                address={address}
                checkouts={shopifyCheckouts}
                total={total}
                completed={completed[3]}
                isCompleting={getIsCompletingStep(3)}
                onClick={handleStep(3)}
                onComplete={handleReviewCompleted}
                disabled={isCompletingStep || (!completed[3] && activeStep !== 3)}
              />
            </Stepper>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            {isCompletingStep ?
              <CheckoutSummarySkeleton /> :
              <CheckoutSummary checkouts={shopifyCheckouts} />
            }
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
