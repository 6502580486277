/**
 * @fileoverview Implements a filter for narrowing which shops are included in
 * the search results. Incoming list of shops is provided by the search results
 * initially, and then the filter outputs a list of which shops to include to
 * further refine the results.
 */

import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import Chip from '@mui/material/Chip'

export default function FilterCommunity(props) {
  const { community, onRemove } = props

  const handleDelete = () => {
    onRemove && onRemove()
  }

  // If there is no community, hide the filter
  if (!community) {
    return null
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Community
      </FormLabel>
      <FormGroup sx={{ mt: 1 }}>
        <Chip
          label={community.name}
          onDelete={handleDelete}
        />
      </FormGroup>
    </FormControl>
  )
}
