/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API for account shop manager operations.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

/***********
 * Queries *
 ***********/

/**
 * Query for a product belonging to a shop.
 */
const QUERY_PRODUCT_TO_MANAGE = `
query ProductToManageQuery($slug: String, $id: String) {
  products(slug: $slug, id: $id) {
    _id
    description
    externalUrl
    handle
    imageUrl
    minPrice
    title
    type
  }
}
`

/**
 * Query for all products belonging to a shop.
 * @todo Paginate
 */
const QUERY_PRODUCTS_TO_MANAGE = `
query ProductsToManageQuery($slug: String) {
  products(slug: $slug) {
    _id
    description
    externalUrl
    handle
    imageUrl
    minPrice
    title
    type
  }
}
`

/**
 * Query for a community and all the active shops that the current user manages.
 */
const QUERY_REQUEST_TO_JOIN_COMMUNITY = `
query RequestToJoinCommunityQuery($slug: String) {
  community(slug: $slug) {
    name
    price
  }
  shops(managed: true, active: true) {
    name
    slug
    communities {
      slug
    }
  }
}
`

/**
 * Query for a single shop to manage.
 */
const QUERY_SHOP_TO_MANAGE = `
query ShopQuery($slug: String) {
  shop(slug: $slug, managed: true) {
    _id
    about
    address1
    address2
    city
    communities {
      availableTags
      name
      slug
      tags
    }
    completedSteps
    country
    custom_location {
      postalCode
      city
      state
      stateName
    }
    email
    externalUrl
    facebookUrl
    free_shipping_threshold
    instagramUrl
    local_pickup_timeframe
    location {
      coordinates
    }
    logo_url
    myshopify_domain
    name
    offers_free_shipping
    offers_local_pickup
    offersLocalDelivery
    onboardingSteps {
      name
      slug
    }
    phone
    plan
    platform
    primaryContact {
      email
      name
      phone
    }
    productsSynced
    province
    requests {
      community {
        name
        price
      }
      created
      id
      state
    }
    serviceArea
    shop_owner
    slug
    state
    stats {
      totalViewsLast30Days {
        counts
        total
      }
      topItemsLast30Days {
        _id
        handle
        name
        total
      }
      updated
    }
    syncRequested
    tikTokUrl
    twitterUrl
    zip
  }
}
`

/**
 * Query for onboarding info for a single shop to manage.
 */
const QUERY_SHOP_TO_MANAGE_ONBOARDING = `
query ShopQuery($slug: String) {
  shop(slug: $slug, managed: true) {
    completedSteps
    onboardingSteps {
      name
      slug
    }
    state
  }
}
`

/**
 * Query for all the shops that the current user manages.
 */
const QUERY_SHOPS_TO_MANAGE = `
query ShopsToManageQuery {
  shops(managed: true) {
    name
    plan
    platform
    slug
    state
  }
}`

/*************
 * Mutations *
 *************/

/**
 * Confirms the shop's link was shared to complete that onboarding step.
 */
const MUTATION_CONFIRM_SHOP_LINK_SHARED = `
mutation ConfirmShopLinkSharedMutation($slug: String) {
  confirmShopLinkShared(slug: $slug) {
    success
  }
}
`

/**
 * Upgrades the given shop using the given plan.
 */
const MUTATION_UPGRADE_SHOP = `
mutation UpgradeShopMutation($slug: String, $plan: String) {
  upgradeShop(slug: $slug, plan: $plan) {
    success
    successUrl
  }
}
`

/**
 * Saves the given product.
 */
const MUTATION_SAVE_PRODUCT = `
mutation SaveProductMutation($slug: String, $product: ProductInput) {
  saveProduct(slug: $slug, product: $product) {
    success
  }
}
`

/**
 * Saves the given shop.
 */
const MUTATION_SAVE_SHOP = `
mutation SaveShopMutation($shop: ShopInput) {
  saveShop(shop: $shop) {
    success
    successUrl
  }
}`

/**
 * Requests the given shop to join the given community.
 */
const MUTATION_REQUEST_TO_JOIN_COMMUNITY = `
mutation RequestToJoinCommunityMutation($slug: String, $community: String) {
  requestToJoinCommunity(slug: $slug, community: $community) {
    success
    successUrl
  }
}
`

/**
 * Requests to have products synced for the given shop.
 */
const MUTATION_REQUEST_SYNC = `
mutation RequestSyncMutation($slug: String) {
  requestSync(slug: $slug) {
    success
  }
}
`

/**
 * Subscribes to community membership for a request that was approved without
 * a subscription.
 */
const MUTATION_SUBSCRIBE_FOR_REQUEST = `
mutation SubscribeForRequestMutation($id: String) {
  subscribeForRequest(id: $id) {
    success
    successUrl
  }
}
`

const queries = {
  MUTATION_CONFIRM_SHOP_LINK_SHARED,
  MUTATION_REQUEST_TO_JOIN_COMMUNITY,
  MUTATION_REQUEST_SYNC,
  MUTATION_SAVE_PRODUCT,
  MUTATION_SAVE_SHOP,
  MUTATION_SUBSCRIBE_FOR_REQUEST,
  MUTATION_UPGRADE_SHOP,
  QUERY_PRODUCT_TO_MANAGE,
  QUERY_PRODUCTS_TO_MANAGE,
  QUERY_REQUEST_TO_JOIN_COMMUNITY,
  QUERY_SHOP_TO_MANAGE,
  QUERY_SHOP_TO_MANAGE_ONBOARDING,
  QUERY_SHOPS_TO_MANAGE
}

export default queries
