/**
 * @fileoverview Implements a view showing the user's most recent order.
 */

import { useEffect, useState } from 'react';

import { useUser } from '../../lib/user-context';
import AccountOrder from './account-order';
import AccountOrderSkeleton from './account-order-skeleton';
import AccountSection from './account-section';
import PebbleApi from '../../lib/pebble-api';

export default function AccountRecentOrder() {
  const { loadingUser, user } = useUser();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loadingUser && user.loggedIn) {
      setLoading(true);
 
      // Only show placed orders
      const statuses = ['placed'];

      // Only get the single most recent order
      const first = 1;
      
      PebbleApi
        .getOrders(statuses, first)
        .then(connection => {
          const newOrder = connection.edges.map(edge => edge.node)[0] ?? null;

          setOrder(newOrder);
          setLoading(false);
        });
    }
  }, [loadingUser, user]);

  return (
    <AccountSection
      action={{
        label: 'View all orders',
        to: '/account/orders'
      }}
      heading="Recent order"
    >
      {loading
        ? <AccountOrderSkeleton />
        : <AccountOrder order={order} />
      }
    </AccountSection>
  );
}
