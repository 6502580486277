/**
 * @fileoverview Implements a component that scrolls the window to the top.
 * See {@link https://reactrouter.com/web/guides/scroll-restoration}.
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // Whenever the URL path changes, scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
