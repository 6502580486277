/**
 * @fileoverview User account home view.
 */

import { Stack } from '@mui/material'

import AccountDefaultShippingAddress from './account-default-shipping-address'
import AccountInfo from './account-info'
import AccountRecentOrder from './account-recent-order'
import AccountWishlistPreview from './account-wishlist-preview'

export default function AccountHome () {
  return (
    <Stack spacing={2}>
      <AccountRecentOrder />
      <AccountWishlistPreview />
      <AccountInfo />
      <AccountDefaultShippingAddress />
    </Stack>
  )
}
