/**
 * @fileoverview Implements a shared core view of search filters used for both
 * desktop and mobile views.
 */

import { useCallback, useRef, useState } from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'

import FilterDelivery from './filter-delivery'
import FilterLocation from './filter-location'
import FilterMap from './filter-map'
import FilterPrice from './filter-price'
import FilterRadius from './filter-radius'
import FilterShops from './filter-shops'
import FilterTypes from './filter-types'
// import FilterValues from './filter-values'
import FilterCommunity from './filter-community'

import {
  useSearch,
  useSearchDispatch
} from '../../lib/search-context'

export default function Filters (props) {
  const { onClose } = props
  const search = useSearch()
  const searchDispatch = useSearchDispatch()
  const scrollableRef = useRef()

  const [scrollShadowDepth, setScrollShadowDepth] = useState(0)

  // Updates scroll shadow depth based on scroll position of the scrollable
  // section of the mobile filters. If there are more filters below the fold,
  // add a shadow as a hint to the user, otherwise hide the shadow.
  const updateScrollShadowDepth = () => {
    if (scrollableRef.current) {
      const el = scrollableRef.current

      if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
        setScrollShadowDepth(0)
      } else {
        setScrollShadowDepth(4)
      }
    }
  }

  // Update scroll shadow depth whenever the scrollable DOM element reference
  // changes
  const handleScrollableRefChange = useCallback(node => {
    if (node) {
      scrollableRef.current = node
      updateScrollShadowDepth()
    } else {
      scrollableRef.current = null
    }
  }, [])

  // Handle filter scrolling
  const handleScroll = () => updateScrollShadowDepth()

  const handleRadiusChange = radius => {
    if (radius > 0 && radius !== search.radius) {
      searchDispatch({ type: 'set', payload: { radius: radius } })
    }
  }

  const handleLocationChange = (location, useCurrentLocation) => {
    searchDispatch({
      type: 'set', payload: {
        location: location,
        useCurrentLocation: useCurrentLocation
      }
    })
  }

  const handleCommunityRemove = () => {
    searchDispatch({ type: 'set and search', payload: { community: null } })
    sessionStorage.removeItem('community')
  }

  const handlePriceChange = priceRange => {
    searchDispatch({
      type: 'set', payload: {
        minPrice: priceRange[0],
        maxPrice: priceRange[1]
      }
    })
  }

  const handleDeliveryChange = deliveryOptions => {
    searchDispatch({ type: 'set', payload: deliveryOptions })
  }

  /** @todo Support values filter */
  // const handleValuesChange = values => {
  //   searchDispatch({ type: 'set', payload: { values }})
  // }

  const handleShopsChange = shops => {
    searchDispatch({ type: 'set', payload: { shops } })
  }

  const handleTypesChange = types => {
    searchDispatch({ type: 'set', payload: { types } })
  }

  const handleFilterClick = () => {
    searchDispatch({ type: 'search' })
    onClose && onClose()
  }

  // Only show radius filter if searching a specific location
  const shouldShowRadius = search.shop == null &&
    (search.location != null || search.useCurrentLocation)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-start'
    }}>
      <Box
        onScroll={handleScroll}
        ref={handleScrollableRefChange}
        sx={{
          flexGrow: { xs: 1, md: false },
          maxHeight: { xs: 'calc(100vh - 56px)', md: false },
          overflowY: { xs: 'auto', md: false }
        }}
      >
        <Stack pt={2} px={2} spacing={2}>
          <Typography variant="h6">
            Filters
          </Typography>
          <FilterLocation
            onChange={handleLocationChange}
            location={search.location}
            useCurrentLocation={search.useCurrentLocation}
          />
          {shouldShowRadius &&
            <FilterRadius
              value={search.radius}
              onChange={handleRadiusChange}
            />
          }
          <FilterMap shops={search.filteredShops} />
          <Button
            color="secondary"
            fullWidth
            onClick={handleFilterClick}
          >
            Update Location
          </Button>
          <FilterTypes
            onChange={handleTypesChange}
            selectedTypes={search.types}
          />
          <FilterCommunity
            community={search.community}
            onRemove={handleCommunityRemove}
          />
          <FilterPrice
            max={search.maxPrice}
            min={search.minPrice}
            onChange={handlePriceChange}
          />
          <FilterDelivery
            offersFreeShipping={search.offersFreeShipping}
            offersLocalPickup={search.offersLocalPickup}
            onChange={handleDeliveryChange}
          />
          {/* <FilterValues
            onChange={handleValuesChange}
            selectedValues={search.values}
          /> */}
          <FilterShops
            onChange={handleShopsChange}
            selectedShops={search.shops}
            shops={search.locationShops}
          />
        </Stack>
      </Box>
      <Box boxShadow={scrollShadowDepth} p={2}>
        <Button
          color="secondary"
          fullWidth
          onClick={handleFilterClick}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  )
}
