/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API for account onboarding operations.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

/*************
 * Mutations *
 *************/

const MUTATION_SET_COMMUNITY_PLAN = `
mutation SetCommunityPlanMutation($slug: String, $plan: String) {
  setCommunityPlan(slug: $slug, plan: $plan) {
    success
    successUrl
  }
}
`

const queries = {
  MUTATION_SET_COMMUNITY_PLAN
}

export default queries
