/**
 * @fileoverview Implements a hero promoting a shop's featured collection.
 */

import { useEffect, useState } from 'react'
import * as Vibrant from 'node-vibrant'

import { Box, Container, Skeleton, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getContrast } from '../../lib/color-utilities'
import LinkButton from '../link-button'
import Section from '../section'

export default function FeaturedCollectionHero(props) {
  const { loading, shop } = props
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [image, setImage] = useState(null)
  const [bgColor, setBgColor] = useState(theme.palette.secondary.main)
  const [textColor, setTextColor] = useState(theme.palette.grey.light)

  useEffect(() => {
    if (shop?.featuredCollection?.imageUrl) {
      setImage(shop.featuredCollection.imageUrl)

      Vibrant
        .from(shop.featuredCollection.imageUrl)
        .getPalette()
        .then(palette => {
          const newBgColor = palette.Muted.getHex()
          const newTextColor = getContrast(newBgColor,
            theme.palette.grey.light, theme.palette.darkGrey.main)

          setBgColor(newBgColor)
          setTextColor(newTextColor)
        });
    }
  }, [shop, theme])

  if (loading) {
    return (
      <Section color="grey">
        <Typography variant="h2" component="p">
          <Skeleton width="40%" />
        </Typography>
        <Typography variant="h1" component="h2">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      </Section>
    )
  }

  if (!shop ||
      !shop.featuredCollection ||
      !shop.featuredCollection.title ||
      shop.featuredCollection.title.trim().length === 0) {
    return null
  }

  const gradientStyle = mobile
    ? `linear-gradient(to bottom, ${bgColor} 50%, ${alpha(bgColor, 0.5)} 100%)`
    : `linear-gradient(to right, ${bgColor} 50%, ${alpha(bgColor, 0)} 100%)`

  const imageStyle = image
    ? (mobile
      ? `center / cover no-repeat url(${image})`
      : `right center / 50% no-repeat url(${image})`)
    : ''

  const rootStyle = {
    background: `${gradientStyle}, ${imageStyle}`
  }

  const buttonStyle = {
    borderColor: alpha(textColor, 0.75),
    color: textColor
  }

  return (
    <Box style={rootStyle}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', py: { xs: 6, sm: 16 } }}>
          <Box sx={{ maxWidth: { xs: '100%', sm: '50%' } }}>
            <Typography style={{ color: textColor }} variant="h2" component="p">
              {shop.name}
            </Typography>
            <Typography style={{ color: textColor }} variant="h1" component="h2">
              {shop.featuredCollection.title}
            </Typography>
            {shop.featuredCollection.description &&
             shop.featuredCollection.description.length > 0 &&
              <Typography style={{ color: textColor }} variant="body1">
                {shop.featuredCollection.description}
              </Typography>
            }
            <Box sx={{ mt: 4 }}>
              <LinkButton
                sx={{
                  borderWidth: '2px',
                  padding: '4px 14px',
                  ...buttonStyle
                }}
                to="#featured-products"
                variant="outlined"
              >
                Shop now
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}