/**
 * @fileoverview Implements a mission section for the Partner page.
 */

import { Check as CheckIcon } from '@mui/icons-material'
import { Alert, Box, Divider, Grid, Typography } from '@mui/material'

import Section from '../section'

export default function PartnerMission() {
  return (
    <Section color="white">
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            Our mission is to help your small business reach more customers.
          </Typography>
          <Typography variant="body1">
            Pebble is a powerful community marketplace and directory that helps small businesses and independent brands sell and promote their products. We take a community-centric approach&mdash;connecting you with the communities that you belong too and leveraging their social reach.
          </Typography>
          <Divider sx={{
            height: '2px',
            backgroundColor: 'grey.main',
            mx: 0,
            my: 2
          }} />
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Get set up in as little as 15 minutes
          </Alert>
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Connect with more customers
          </Alert>
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Engage with your community
          </Alert>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Customer checking out on their phone"
              src="/images/partner/mission.png"
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
