/**
 * @fileoverview Implements a skeleton of a recommended search.
 */

import { Box, Paper, Skeleton } from '@mui/material'

export default function RecommendedSearchSkeleton() {
  return (
    <Paper elevation={0} sx={{ height: '100%' }}>
      <Box sx={{ height: '290px' }}>
        <Skeleton
          sx={{
            height: '100%',
            width: '100%'
          }}
          variant="rect"
        />
      </Box>
    </Paper>
  )
}
