/**
 * @fileoverview Implements a landing page for potential Pebble Partners.
 */

import HelmetWrapper from '../helmet-wrapper'
import PartnerHero from './partner-hero'
import PartnerCta from './partner-cta'
import PartnerHow from './partner-how'
import PartnerMission from './partner-mission'
import PartnerPlans from './partner-plans'
import PartnerVideo from './partner-video'

export default function Partner() {
  return (
    <>
      <HelmetWrapper
        title="Partner with Pebble"
        description="Sell on Pebble to reach a wider audience."
      />
      <PartnerHero />
      <PartnerMission />
      <PartnerHow />
      <PartnerPlans />
      <PartnerVideo />
      <PartnerCta />
    </>
  )
}
