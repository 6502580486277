/**
 * @fileoverview Implements a checkout view and process for users to purchase
 * the items in their cart.
 */

import { useNavigate } from 'react-router-dom';

import HelmetWrapper from '../helmet-wrapper';
import Error from '../error';

const INTERNAL_ERROR = "Something went wrong during checkout. We're looking into it. Please try again later.";

export default function CheckoutError(props) {
  const { code, message } = props;
  const navigate = useNavigate();
  let errorMessage = INTERNAL_ERROR;

  if (code &&
      CheckoutErrors[code] &&
      CheckoutErrors[code] !== INTERNAL_ERROR) {
    errorMessage = CheckoutErrors[code];
  } else if (message) {
    errorMessage = message;
  }

  /**
   * @function handleReturnToCart
   * Returns the user to the Cart page.
   */
  const handleReturnToCart = () => {
    navigate('/cart');
  };

  return (
    <>
      <HelmetWrapper title="Checkout" />
      <Error
        action={{
          label: 'Return to cart',
          onClick: handleReturnToCart
        }}
        message={errorMessage}
      />
    </>
  );
}

/**
 * Error messages to show for each error code
 */
const CheckoutErrors = Object.freeze({
  NO_ITEMS_IN_CART: 'There are no items in your cart available to check out. Shop around a bit first!',
  FAILED_TO_ADD_TAGS_TO_ORDER: INTERNAL_ERROR,
  FAILED_TO_CALCULATE_PICKUP_DATE: INTERNAL_ERROR,
  FAILED_TO_CREATE_CHECKOUT: INTERNAL_ERROR,
  FAILED_TO_CREATE_PAYMENT: INTERNAL_ERROR,
  FAILED_TO_EMPTY_CART: INTERNAL_ERROR,
  FAILED_TO_GET_SHIPPING_RATES: INTERNAL_ERROR,
  FAILED_TO_SEND_ORDER_CONFIRMATION: INTERNAL_ERROR,
  FAILED_TO_UPDATE_CHECKOUT: INTERNAL_ERROR,
  FAILED_TO_UPDATE_ORDER: INTERNAL_ERROR,
  ORDER_NOT_FOUND: "That order doesn't exist.",
  ORDER_IS_NOT_OPEN: "That order doesn't exist.", // It does exist but we don't need the user to know that
  SHIPPING_ADDRESS_IS_NULL: 'The shipping address is missing. Please return to your cart and try checking out again.',
  SHIPPING_ADDRESS_EMAIL_IS_MISSING: INTERNAL_ERROR,
  SHOP_NOT_FOUND: INTERNAL_ERROR,
  STRIPE_TOKEN_NOT_FOUND: INTERNAL_ERROR,
  DELIVERY_METHODS_IS_NULL: INTERNAL_ERROR,
});
