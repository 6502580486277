/**
 * @fileoverview Implements a context for the current user, their cart, and
 * their wishlist, whether they are logged in or not.
 */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useSnackbar } from './snackbar-context';
import analyticsEvent, { analyticsEvents } from '../lib/analytics';
import GuestCart from '../lib/guest-cart';
import PebbleApi from '../lib/pebble-api';

/**
 * The context for the current user.
 */
const UserContext = createContext();

/**
 * @function getDefaultUser
 * Gets a default user model.
 * @returns {object} User model
 */
const getDefaultUser = () => ({
  address: null,
  admin: false,
  cart: {
    count: 0,
    loadedFull: false,
    quantity: 0,
    subtotal: 0,
    items: [],
  },
  email: null,
  firstName: null,
  lastName: null,
  loggedIn: false,
  wishlist: {
    loadedFull: false,
    items: [],
  },
});

/**
 * @function getUserModel
 * Builds a new user model for the context.
 * @param {object} [dbUser] - Optional DB user record from the server. If
 * omitted, assumes user is not logged in.
 * @returns {object} User model
 */
const getUserModel = dbUser => {
  let newUser = getDefaultUser();

  // `dbUser` may be missing properties from nested properties like `cart` and
  // `wishlist`, so those need to be pulled out and extended properly
  let newCart = newUser.cart;
  let newWishlist = newUser.wishlist;

  newUser = {
    ...newUser,
    ...dbUser,
    loggedIn: dbUser != null,
  };

  newUser.cart = {
    ...newCart,
    ...newUser.cart,
  };

  newUser.wishlist = {
    ...newWishlist,
    ...newUser.wishlist,
  };
  
  return newUser;
};

/**
 * @function updateUserCart
 * Builds a new user model with an updated cart.
 * @param {object} user - User model to update
 * @param {object} cart - Updated cart
 * @param {object} [itemToAdd] - Optional item to add to the cart
 * @param {object} [itemIdToRemove] - Optional ID of cart item to remove
 * @returns {object} Updated user model
 */
const updateUserCart = (user, cart, itemToAdd, itemIdToRemove) => {
  const newUser = { ...user };

  newUser.cart = {
    ...newUser.cart,
    ...cart
  };

  if (itemToAdd) {
    // See if this item is already in the cart
    const existingItem = newUser.cart.items.find(i =>
      i._id === itemToAdd._id && i.variants[0].id === itemToAdd.variants[0].id
    );

    if (existingItem) {
      // This product and variant is already in the cart, so increase quantity
      existingItem.quantity += itemToAdd.quantity;
    } else {
      // Not already in cart, so add it
      newUser.cart.items.push(itemToAdd);
    }
  }

  if (itemIdToRemove) {
    const deleteIndex = newUser.cart.items
      .findIndex(i => i.cartItemId === itemIdToRemove);
    newUser.cart.items.splice(deleteIndex, 1);
  }

  return newUser;
};

/**
 * @function updateUserWishlist
 * Builds a new user model with an updated wishlist.
 * @param {object} user - User model to update
 * @param {object} wishlist - Updated wishlist
 * @param {object} [itemToAdd] - Optional item to add to the wishlist
 * @param {object} [itemToRemove] - Optional wishlist item to remove
 * @returns {object} Updated user model
 */
const updateUserWishlist = (user, wishlist, itemToAdd, itemToRemove) => {
  const newUser = { ...user };

  newUser.wishlist = {
    ...newUser.wishlist,
    ...wishlist
  };

  if (itemToAdd) {
    newUser.wishlist.items.push(itemToAdd);
  }

  if (itemToRemove) {
    const deleteIndex = newUser.wishlist.items.findIndex(i =>
      i.productId === itemToRemove.productId &&
      i.variantId === itemToRemove.variantId
    );
    newUser.wishlist.items.splice(deleteIndex, 1);
  }

  return newUser;
};

/**
 * @function UserProvider
 * The provider for the user context.
 * 
 * The `user` model provided by this context is abstracted a level above the
 * concept of a `user` in the database. Here, a user can be an authenticated
 * user (logged in) or an unauthenticated user (guest). In both cases, cart and
 * wishlist information is available. If the user is logged in, additional user
 * information is available as well.
 * 
 * @param {object} props - Props passed to the provider
 * @returns {object} Context provider component
 */
const UserProvider = props => {
  const { showError, showInfo, showSuccess } = useSnackbar();
  const [user, setUser] = useState(getDefaultUser());
  
  // Initialize this to true so the user doesn't get redirected to the login
  // page before it finishes loading the user initially
  const [loadingUser, setLoadingUser] = useState(true);

  /**
   * @function loadUser
   * Loads the user model for the current user, whether logged in or not.
   */
  const loadUser = useCallback(() => {
    setLoadingUser(true)

    PebbleApi
      .getUser()
      .then(dbUser => {
        const newUser = getUserModel(dbUser)
        newUser.cart.loadedFull = true
        newUser.wishlist.loadedFull = true
        setUser(newUser)
        initializeCart(newUser)
        setLoadingUser(false)
      })
  }, [])

  /**
   * @function loadFullCart
   * Loads the user's full cart, as opposed to just the cart status.
   */
  const loadFullCart = useCallback(() => {
    setLoadingUser(true);

    if (user?.loggedIn) {
      PebbleApi
        .getCart()
        .then(newCart => {
          // Update user's cart
          const newUser = updateUserCart(user, newCart);
          newUser.cart.loadedFull = true;
          setUser(newUser);
          setLoadingUser(false);
        });
    } else {
      // User is not logged in, so load cart from the guest cart
      const guestCart = new GuestCart(); 
      const newUser = updateUserCart(user, guestCart.getCart());
      newUser.cart.loadedFull = true;
      setUser(newUser);
      setLoadingUser(false);
    }
  }, [user]);

  /**
   * @function loadFullWishlist
   * Loads the user's full wishlist, as opposed to just minimal details
   */
  const loadFullWishlist = useCallback(() => {
    setLoadingUser(true);
    if (user?.loggedIn) {
      PebbleApi
        .getWishlist()
        .then(newWishlistUser => {
          // Update user's wishlist
          const newUser = updateUserWishlist(user, newWishlistUser.wishlist);
          newUser.wishlist.loadedFull = true;
          setUser(newUser);
          setLoadingUser(false);
        });
    } else {
      /** @todo Support guest wishlists */
      setLoadingUser(false);
    }
  }, [user]);

  /**
   * @function logout
   * Logs out the currently logged in user.
   */
  const logout = () => {
    setLoadingUser(true);

    PebbleApi
      .logout()
      .then(() => {
        const newUser = getUserModel();
        setUser(newUser);
        initializeCart(newUser);
        setLoadingUser(false);
      });
  };

  /**
   * @function initializeCart
   * Initializes the cart for the current user. If the user is logged in, saves
   * items in the guest cart to the server. If the user is a guest, initializes
   * the guest cart.
   */
  const initializeCart = user => {
    const guestCart = new GuestCart(); 

    // If the user is logged in and there are items in the local guest
    // cart, need to push them up to the server.
    if (user?.loggedIn) {
      if (guestCart.items.length > 0) {
        // Generate cart item objects from the guest cart
        const cartItems = guestCart.items.map(i => ({
          productId: i._id,
          variantId: i.variants[0].id,
          quantity: i.quantity
        }));

        // Push cart items to the server
        PebbleApi
          .addCartItems(cartItems)
          .then(newCartStatus => {
            // Update user's cart status, including all items in the cart
            const newUser = updateUserCart(user, newCartStatus);
            setUser(newUser);

            // Clear out the local cart now that the data has been pushed to
            // the server
            guestCart.clear();
          });
      }
    } else {
      // User is not logged in, so update cart from the guest cart
      // Note: for guest users, full cart is loaded by default, not just status
      const newUser = updateUserCart(user, guestCart.getCart());
      newUser.cart.loadedFull = true;
      setUser(newUser);
    }
  };

  /**
   * @function addCartItem
   * Adds an item to the current user's cart.
   * @param {object} cartItem - Item to add to the cart
   * @returns {Promise<boolean>} Promise for result
   */
  const addCartItem = useCallback(cartItem => {
    if (user?.loggedIn) {
      // User is logged in, so add to cart on the server
      return PebbleApi
        .addCartItem(cartItem._id, cartItem.variants[0].id, cartItem.quantity)
        .then(newCartStatus => {
          analyticsEvent(analyticsEvents.add_to_cart, {
            currency: 'USD',
            items: [{
              item_id: cartItem._id,
              item_variant: cartItem.variants[0].id,
              quantity: cartItem.quantity,
            }],
            user_email: user.email,
          });
          
          window.fbq('track', 'AddToCart');

          // Set ID on the cart item
          cartItem.cartItemId = newCartStatus.items[0].cartItemId

          // Prevent overwriting the items in the cart
          delete newCartStatus.items

          // Update user's cart status
          const newUser = updateUserCart(user, newCartStatus, cartItem);
          setUser(newUser);

          // Notify user
          showSuccess('Added to cart');
          return true;
        });
    } else {
      // User is not logged in, so use local guest cart
      const guestCart = new GuestCart();
      guestCart.addItem(cartItem);

      // Update user's cart status
      const newUser = updateUserCart(user, guestCart.getCart());
      setUser(newUser);

      // Notify user
      showSuccess('Added to cart');
      return Promise.resolve(true);
    }
  }, [showSuccess, user]);

  /**
   * @function updateCartItemQuantity
   * Updates the quantity of the given cart item.
   * @param {string} cardItemId - ID of the cart item to update
   * @param {number} quantity - New quantity for the cart item
   * @returns {Promise<boolean>} Promise for result
   */
  const updateCartItemQuantity = useCallback((cartItemId, quantity) => {
    if (user?.loggedIn) {
      return PebbleApi
        .updateCartItemQuantity(cartItemId, quantity)
        .then(newCartStatus => {
          // Update user's cart status
          const newUser = updateUserCart(user, newCartStatus);
          const cartItem = newUser.cart.items
            .find(i => i.cartItemId === cartItemId);
          cartItem.quantity = quantity;
          setUser(newUser);
          return true;
        });
    } else {
      // User is not logged in, so use the local guest cart
      const guestCart = new GuestCart();
      guestCart.updateQuantity(cartItemId, quantity);

      // Update user's cart status
      const newUser = updateUserCart(user, guestCart.getCart());
      setUser(newUser);
      return Promise.resolve(true);
    }
  }, [user]);

  /**
   * @function removeCartItem
   * Removes the cart item with the given ID from the current user's cart.
   * @param {string} cartItemId - ID of the cart item to remove
   * @returns {Promise<boolean>} Promise for result
   */
  const removeCartItem = useCallback(cartItemId => {
    if (user?.loggedIn) {
      return PebbleApi
        .removeCartItem(cartItemId)
        .then(newCartStatus => {
          analyticsEvent(analyticsEvents.remove_from_cart, {
            currency: 'USD',
            items: [
              {
                item_id: cartItemId,
                /** @todo grab quantity for the cart line being removed */
                quantity: 1,
              },
            ],
            user_email: user.email,
          });

          // Update user's cart status
          const newUser = updateUserCart(user, newCartStatus, null, cartItemId);
          setUser(newUser);
          return true;
        });
    } else {
      // User is not logged in, so use the local guest cart
      const guestCart = new GuestCart();
      guestCart.removeItem(cartItemId);

      // Update user's cart status
      const newUser = updateUserCart(user, guestCart.getCart());
      setUser(newUser);
      return Promise.resolve(true);
    }
  }, [user]);

  /**
   * @function addWishlistItem
   * Adds the given item to the current user's wishlist.
   * @param {object} item - Item to add to the wishlist
   * @param {string} item.productId - Product ID
   * @param {string} [item.variantId] - Optional product variant ID
   * @returns {Promise<boolean>} Promise for result
   */
  const addWishlistItem = useCallback(item => {
    if (user?.loggedIn) {
      // Normalize into a `WishlistItemInput` object to send to the API
      // This removes any unnecessary properties
      const wishlistItem = {
        productId: item.productId,
        variantId: item.variantId,
      };

      return PebbleApi
        .addWishlistItem(wishlistItem)
        .then(newWishlistItem => {
          if (newWishlistItem) {
            // Item was added successfully, so add it to the local list
            const newUser = updateUserWishlist(user, null, wishlistItem);
            setUser(newUser);

            // Notify user
            showSuccess('Added to wishlist');
            return true;
          } else {
            // Notify user
            showError('Failed to add to wishlist');
            return false;
          }
        });
    } else {
      /** @todo Support guest wishlists */
      return Promise.resolve(false);
    }
  }, [showError, showSuccess, user]);

  /**
   * @function removeWishlistItem
   * Removes the given item from the current user's wishlist.
   * @param {object} item - Item to remove from the wishlist
   * @returns {Promise<boolean>} Promise for result
   */
  const removeWishlistItem = useCallback(item => {
    if (user?.loggedIn) {
      // Normalize into a `WishlistItemInput` object to send to the API
      const wishlistItem = {
        productId: item.productId,
        variantId: item.variantId,
      };

      return PebbleApi
        .removeWishlistItem(wishlistItem)
        .then(success => {
          if (success) {
            // Remove the item from the items array
            const newUser = updateUserWishlist(user, null, null, wishlistItem);
            setUser(newUser);

            // Notify user
            showInfo('Removed from wishlist');
            return true;
          } else {
            // Notify user
            showError('Failed to remove from wishlist');
            return false;
          }
        });
    } else {
      /** @todo Support guest wishlists */
      return Promise.resolve(false);
    }
  }, [showError, showInfo, user]);

  /**
   * @function wishlistContains
   * Determines if the given wishlist item is currently in the user's wishlist.
   * @param {object} item - Wishlist item to look for
   * @param {string} item.productId - Product ID
   * @param {string} [item.variantId] - Optional product variant ID
   * @returns {boolean} Whether the item is in the wishlist
   */
  const wishlistContains = useCallback(item => {
    if (user?.wishlist?.items) {
      let matchingWishlistItems = user.wishlist.items.filter(i =>
        i.productId === item.productId && i.variantId === item.variantId
      );

      return matchingWishlistItems.length > 0;
    }

    return false;
  }, [user]);

  // Load the user when the app loads
  useEffect(() => loadUser(), [loadUser])

  const value = {
    addCartItem,
    addWishlistItem,
    loadingUser,
    loadFullCart,
    loadFullWishlist,
    loadUser,
    logout,
    removeCartItem,
    removeWishlistItem,
    updateCartItemQuantity,
    user,
    wishlistContains,
  };

  return <UserContext.Provider value={value} {...props} />;
};

/**
 * @function useUser
 * Hook to use the user context.
 * @returns {object} UserContext
 */
const useUser = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useUser must be used within a UserProvider');
  }
  
  return userContext;
};

export {
  UserProvider,
  useUser,
};
