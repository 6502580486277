/**
 * @fileoverview Implements a preloader to assist in preloading images.
 */

// Array of preloaded images to keep them in memory
let preloadedImages = [];

export default class ImagePreloader {
  /**
   * @function preload
   * Preloads an image
   * @param {object} image - Image object. Should have `src` and optionally
   * `srcSet` properties.
   */
  static preload(image) {
    if (image && image.src) {
      const preloadedImage = new Image();
      preloadedImage.src = image.src;
      preloadedImage.srcset = image.srcSet;
      preloadedImages.push(preloadedImage);
    }
  }

  /**
   * @function dispose
   * Disposes all references to preloaded images. Relies on automatic garbage
   * collection.
   */
  static dispose() {
    preloadedImages = [];
  }
};
