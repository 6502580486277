/**
 * @fileoverview Implements an inline search field to use in places such as
 * shop products.
 */

import { useCallback, useEffect, useRef, useState } from 'react'

import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { Box, InputAdornment, InputBase } from '@mui/material'

/**
 * A component for searching and displaying search results.
 */
export default function InlineSearchField(props) {
  const { blurOnSubmit, onChange, onSubmit } = props
  const inputRef = useRef(null)
  const [value, setValue] = useState('')

  useEffect(() => {
    onChange && onChange(value)
  }, [onChange, value])

  const clearSearchQuery = () => {
    setValue('')
    onChange && onChange('')
    onSubmit && onSubmit('')
  };

  const submitSearchQuery = useCallback(() => {
    const trimmedSearchQuery = (value ?? '').trim()

    if (blurOnSubmit) {
      inputRef.current && inputRef.current.blur()
    }

    onChange && onChange(trimmedSearchQuery)
    onSubmit && onSubmit(trimmedSearchQuery)
  }, [blurOnSubmit, onChange, onSubmit, value])

  const handleSearchChange = event => setValue(event.target.value)

  const handleSearchKeyDown = event => {
    // If the user hits Enter, then submit
    if (event.key === 'Enter') {
      submitSearchQuery()
    }
  }

  return (
    <Box sx={{
      borderColor: 'secondary.main',
      borderRadius: theme => theme.spacing(2),
      backgroundColor: 'grey.main',
      flexShrink: 0,
      maxWidth: '100%',
      height: '34px',
      width: '240px',
      '& ::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none'
      }
    }}>
      <InputBase
        endAdornment={
          <>
            {value?.length > 0 &&
              <InputAdornment position="end">
                <CloseIcon color="secondary" onClick={clearSearchQuery} />
              </InputAdornment>
            }
            <InputAdornment position="end">
              <SearchIcon color="secondary" onClick={submitSearchQuery} />
            </InputAdornment>
          </>
        }
        inputProps={{ 
          'aria-label': 'search',
          'autoCapitalize': 'off'
        }}
        inputRef={inputRef}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
        placeholder="Search"
        sx={{
          color: 'darkGrey.main',
          pl: 2,
          pr: 1,
          py: 0,
          width: '100%',
          '& input': {
            width: '100%'
          },
          '& .MuiSvgIcon-root': {
            cursor: 'pointer'
          }
        }}
        type="search"
        value={value}
      />
    </Box>
  )
}
