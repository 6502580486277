/**
 * @fileoverview Implements a cache for Pebble Communities.
 */

import Cache from './cache';

/**
 * Cache item key prefix
 */
const KEY_PREFIX = 'communities';

/**
 * Time to expiration in minutes
 */
const EXPIRY = 1440; // 24 hours

export default class CommunitiesCache extends Cache {
  /**
   * @function get
   * Retrieves types from the cache
   * @returns {Array<object>} List of types
   */
  static get (slug) {
    const key = `${KEY_PREFIX}.${slug}`;
    return Cache.get(key);
  }

  /**
   * @function set
   * Stores community in the cache
   * @param {object} community - Community to cache
   */
  static set (community) {
    const key = `${KEY_PREFIX}.${community.slug}`;
    Cache.set(key, community, EXPIRY);
  }
}
