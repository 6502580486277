/**
 * @fileoverview Implements a generic skeleton page.
 */

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import Section from './section';

export default function SkeletonPage() {
  return (
    <Section>
      <Typography variant="h1" gutterBottom>
        <Skeleton width="60%" />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Skeleton />
        <Skeleton />
        <Skeleton width="75%" />
      </Typography>
    </Section>
  );
}
