/**
 * @fileoverview Implements utility functions for handling success URLs.
 */

/**
 * @function handleSuccessUrl
 * Handles the given success URL based on whether it's absolute or relative.
 * @param {string} successUrl - Success URL to handle
 * @param {object} navigate - React Router `navigate` function
 */
export const handleSuccessUrl = (successUrl, navigate) => {
  if (successUrl && successUrl.length > 0) {
    if (successUrl.substring(0, 4) === 'http') {
      // Assume absolute
      window.location.href = successUrl
    } else {
      // Assume relative
      navigate && navigate(successUrl)
    }
  }
}
