/**
 * @fileoverview Implements a guest cart that stores data locally on the client
 * instead of sending it to the server.
 */

import { v4 as uuidv4 } from 'uuid';

/**
 * @constant {string}
 * Local storage key
 */
const STORAGE_KEY = 'pebbleGuestCart';

export default class GuestCart {
  /**
   * @constructor
   * Initializes the guest cart with any data already stored.
   */
  constructor() {
    this.items = [];
    this.quantity = 0;
    this.subtotal = 0;
    this._load();
  }

  /**
   * @function _load
   * @private
   * Loads the guest cart from local storage.
   */
  _load() {
    const json = localStorage.getItem(STORAGE_KEY);

    if (json != null) {
      const items = JSON.parse(json);

      if (items != null && items.length > 0) {
        this.items = items;
      }

      this._update();
    }
  }

  /**
   * @function _store
   * @private
   * Stores the current state in local storage.
   */
  _store() {
    // The cart items have all the information needed for storage
    const json = JSON.stringify(this.items);
    localStorage.setItem(STORAGE_KEY, json);
  }

  /**
   * @function _update
   * @private
   * Updates totals based on the current items.
   */
  _update() {
    this.quantity = this.items
      .reduce((total, item) => total + item.quantity, 0);

    this.subtotal = this.items
      .reduce((total, item) =>
        total + (item.variants[0].price * item.quantity), 0);
  }

  /**
   * @function addItem
   * Adds an item to the guest cart.
   * @param {object} item - Item to add to the cart
   */
  addItem(item) {
    if (item) {
      // Check if item is already in the cart
      const existingItem = this.items.find(i =>
        i._id === item._id &&
        i.variants[0].id === item.variants[0].id
      )

      if (existingItem) {
        // Item is already in the cart, so increase its quantity
        const newQuantity = existingItem.quantity + item.quantity
        this.updateQuantity(existingItem.cartItemId, newQuantity)
      } else {
        // Item isn't in cart, so add it

        // Generate a temporary ID to use locally
        item.cartItemId = uuidv4()

        this.items.push(item)
        this._update()
        this._store()
      }
    }
  }

  /**
   * @function updateQuantity
   * Updates the quantity for an item in the cart.
   * @param {string} cartItemId - ID of the cart item to update
   * @param {number} qty - New quantity for the cart item
   */
  updateQuantity(cartItemId, qty) {
    if (cartItemId) {
      if (qty === 0) {
        // Quantity is zero, so remove the item from the cart instead
        this.removeItem(cartItemId);
      } else if (qty > 0) {
        // Positive quantity, so find the item object and update the quantity
        const item = this.items.find(i => i.cartItemId === cartItemId);

        if (item) {
          item.quantity = qty;
          this._update();
          this._store();
        }
      }
    }
  }

  /**
   * @function removeItem
   * Removes an item from the cart.
   * @param {string} cartItemId - ID of the cart item to remove
   */
  removeItem(cartItemId) {
    if (cartItemId) {
      const index = this.items.findIndex(i => i.cartItemId === cartItemId);

      if (index >= 0 && index < this.items.length) {
        this.items.splice(index, 1);
        this._update();
        this._store();
      }
    }
  }

  /**
   * @function getCart
   * Gets the current guest cart, including status and items.
   * @returns {object} Cart object
   */
  getCart() {
    return {
      quantity: this.quantity,
      subtotal: this.subtotal,
      items: this.items,
    };
  }

  /**
   * @function getStatus
   * Gets the current status of the guest cart.
   * @returns {object} Cart status object
   */
  getStatus() {
    return {
      quantity: this.quantity,
      subtotal: this.subtotal
    };
  }

  /**
   * @function clear
   * Clears out the entire guest cart.
   */
  clear() {
    this.items = [];
    this._update();
    this._store();
  }
};
