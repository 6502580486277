import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

/**
 * A component for displaying a single search result loading skeleton.
 */
export default function SearchResultSkeleton(props) {
  return (
    <Card elevation={0} sx={{ height: '100%' }}>
      <CardMedia sx={{ height: '290px' }}>
        <Skeleton
          sx={{
            height: '100%',
            width: '100%'
          }}
          variant="rect"
        />
      </CardMedia>
      <CardContent>
        <Typography variant="h6" component="p">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </CardContent>
      <CardActions>
        <Typography sx={{ pl: 1 }} variant="body1">
          <Skeleton width={75} />
        </Typography>
      </CardActions>
    </Card>
  )
}
