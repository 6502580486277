/**
 * @fileoverview Implements a generic section for the Account view.
 */

import { Link } from 'react-router-dom'

import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { styled } from '@mui/system'

const HeadingLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  'a&:hover, a&:active': {
    color: theme.palette.common.white
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.8rem',
    marginBottom: '-1px',
    verticalAlign: 'baseline'
  }
}))

const levels = [
  {
    backgroundColor: 'common.white',
    headingBackgroundColor: 'primary.light',
    headingColor: 'common.white',
    headingVariant: 'h2'
  }, {
    backgroundColor: 'grey.light',
    headingBackgroundColor: 'grey.main',
    headingColor: 'darkGrey.main',
    headingVariant: 'h3'
  }
]

export default function AccountSectionBase(props) {
  const {
    action,
    children,
    heading,
    id,
    level,
    padding
  } = props

  return (
    <Box
      id={id}
      sx={{
        backgroundColor: levels[level].backgroundColor,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: theme => theme.spacing(2),
        boxShadow: theme =>
          `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0, 0, 0, 0.05)`,
        color: 'darkGrey.main',
        scrollMargin: 10
      }}
    >
      <Box sx={{
        borderTopLeftRadius: theme => theme.spacing(2),
        borderTopRightRadius: theme => theme.spacing(2),
        overflow: 'hidden'
      }}>
        <Box sx={{
          alignItems: 'center',
          backgroundColor: levels[level].headingBackgroundColor,
          '& .MuiTypography-root': {
            color: levels[level].headingColor
          },
          '& .MuiTypography-h2': {
            /** @todo Temporary until global styles are updated */
            fontSize: '1.25rem',
            letterSpacing: 'normal',
            textTransform: 'none'
          },
          '& .MuiTypography-h3': {
            /** @todo Temporary until global styles are updated */
            fontSize: '1.1rem',
            letterSpacing: 'normal',
            textTransform: 'none'
          },
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'flex-start',
          p: 2
        }}>
          {typeof heading === 'string'
            ? <Typography variant={levels[level].headingVariant}>
                {heading}
              </Typography>
            : heading
          }
          <Box sx={{ flexGrow: 1 }} />
          {action &&
            <MuiLink component={HeadingLink} to={action.to}>
              {action.label}
              {' '}
              <ArrowForwardIosIcon />
            </MuiLink>
          }
        </Box>
      </Box>
      <Box p={padding ?? 2}>
        {children}
      </Box>
    </Box>
  )
}
