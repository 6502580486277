/**
 * @fileoverview Cache for Pebble values.
 */

const VALUES_KEY = 'values';
const UPDATED_KEY = `${VALUES_KEY}.updated`;
const DAYS_UNTIL_STALE = 1;

const values = {
  /**
  * @function get
  * Retrieves values from the cache
  * @returns {Array<object>} List of values
  */
  get: () => {
    let updated = sessionStorage.getItem(UPDATED_KEY);
    let isStale = false;

    if (updated) {
      let updatedDate = new Date(updated);
      updatedDate.setDate(updatedDate.getDate() + DAYS_UNTIL_STALE);

      if (updatedDate <= new Date()) {
        isStale = true;
      }
    } else {
      isStale = true;
    }

    if (isStale) {
      sessionStorage.removeItem(UPDATED_KEY);
      sessionStorage.removeItem(VALUES_KEY);
      return null;
    }

    let values = sessionStorage.getItem(VALUES_KEY);

    if (!values) {
      return null;
    }

    return JSON.parse(values);
  },

  /**
  * @function set
  * Stores values in the cache
  * @param {Array<object>} values
  */
  set: values => {
    sessionStorage.setItem(UPDATED_KEY, (new Date()).valueOf);
    sessionStorage.setItem(VALUES_KEY, JSON.stringify(values));
  }
};

export default values;
