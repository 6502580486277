/**
 * @fileoverview Implements a blog home view, listing recent blog posts.
 */

import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { request, gql } from 'graphql-request'
import dayjs from 'dayjs'

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Typography
} from '@mui/material'

import { useUser } from '../../lib/user-context'
import HelmetWrapper from '../helmet-wrapper'
import Section from '../section'

// List of available blog stages
const blogStages = [
  'DRAFT',
  'PUBLISHED'
]

export default function BlogHome() {
  const { user } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [blog, setBlog] = useState(null)
  const [blogStage, setBlogStage] =
    useState(searchParams.get('stage') ||  'PUBLISHED')
  const [blogStageMenuAnchor, setBlogStageMenuAnchor] = useState(null)

  const query = gql`
    query getPosts {
      posts(stage: ${blogStage}, orderBy: date_DESC) {
        id
        title
        slug
        excerpt
        date
        author {
          name
          picture {
            url
          }
          title
        }
        coverImage {
          url
        }
      }
    }
  `

  useEffect(() => {
    const fetchBlogs = async () => {
      const blog = await request(
        'https://api-us-east-1.graphcms.com/v2/ckpyeeq9apzas01z62pcnb2wk/master',
        query
      )
      setBlog(blog)
    }

    fetchBlogs()
  }, [query])

  // Handler for clicking on the blog stage button
  const handleBlogStageClick = event => {
    setBlogStageMenuAnchor(event.currentTarget)
  }

  // Handler for closing the blog stage menu
  const handleBlogStageMenuClose = () => {
    setBlogStageMenuAnchor(null)
  }

  // Handler for selecting a new blog stage
  const handleBlogStageSelect = stage => {
    setBlogStage(stage)
    handleBlogStageMenuClose()
  }

  /**
   * @function handleBlogPostClick
   * Handles clicking on a blog post and loading that post by its slug.
   * @param {string} slug - Post slug
   */
  const handleBlogPostClick = slug => () => {
    navigate(`/blog/${slug}`)
  }

  if (!blog) {
    return (
      <>
        <HelmetWrapper title="Blog" />
        <Section maxWidth="md">
          <Typography
            sx={{
              textAlign: 'center',
              mb: 8
            }}
            variant="h1"
          >
            The Ripple
          </Typography>
          {[...Array(3)].map((_, i) => (
            <Card
              elevation={0}
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                cursor: 'pointer',
                '& + &': {
                  mt: 4
                }
              }}
            >
              <CardMedia sx={{
                height: 0,
                paddingTop: '56.25%', // 16:9 aspect ratio
                width: '100%',
                flexShrink: '0',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
                <Skeleton variant="rect" width="100%" height="100%" />
              </CardMedia>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}>
                <Typography variant="h4" component="p" gutterBottom>
                  <Skeleton width="60%" />
                </Typography>
                <Typography variant="body1">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width="40%" />
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Section>
      </>
    )
  }

  // Grab the first post out of the array, leaving the rest of the posts in
  // `blog.posts`. If there are zero posts, `firstPost` will be `undefined`.
  const firstPost = blog.posts.shift()

  return (
    <>
      <HelmetWrapper title="Blog" />
      <Section maxWidth="md">
        <Typography
          sx={{
            textAlign: 'center',
            mb: 8
          }}
          variant="h1"
        >
          The Ripple
        </Typography>
        {user.loggedIn && user.admin && (
          <Box mb={4}>
            <Button
              aria-controls="blog-stage-menu"
              aria-haspopup="true"
              onClick={handleBlogStageClick}
            >
              {blogStage}
            </Button>
            <Menu
              anchorEl={blogStageMenuAnchor}
              id="blog-stage-menu"
              keepMounted
              open={Boolean(blogStageMenuAnchor)}
              onClose={handleBlogStageMenuClose}
            >
              {blogStages.map(stage =>
                <MenuItem
                  key={stage}
                  onClick={() => handleBlogStageSelect(stage)}
                >
                  {stage}
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
        <Grid container spacing={2}>
          {firstPost &&
            <Grid item xs={12}>
              <Card
                elevation={0}
                onClick={handleBlogPostClick(firstPost.slug)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  cursor: 'pointer',
                  '& + &': {
                    mt: 4
                  }
                }}
                variant="outlined"
              >
                {firstPost.coverImage &&
                  <CardMedia
                    image={firstPost.coverImage.url}
                    sx={{
                      height: 0,
                      paddingTop: '56.25%', // 16:9 aspect ratio
                      width: '100%',
                      flexShrink: '0',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                    title={firstPost.title}
                  />
                }
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}>
                  <Typography variant="h4" component="p" gutterBottom>
                    {firstPost.title}
                  </Typography>
                  <Typography variant="body1">
                    {firstPost.excerpt}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    mt: 4
                  }}>
                    <Avatar
                      src={firstPost.author.picture.url}
                      sx={{
                        height: theme => theme.spacing(8),
                        width: theme => theme.spacing(8),
                      }}
                      alt={firstPost.author.name}
                    />
                    <Box sx={{
                      pl: 2,
                      '& .MuiTypography-root': {
                        color: 'darkGrey.main'
                      }
                    }}>
                      <Typography variant="h6" component="p">
                        {firstPost.author.name}
                      </Typography>
                      <Typography variant="body2">
                        {dayjs(firstPost.date).format('MMMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          }
          {blog.posts.map(post => (
            <Grid item key={post.id} xs={12} md={6}>
              <Card
                elevation={0}
                onClick={handleBlogPostClick(post.slug)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  cursor: 'pointer',
                  '& + &': {
                    mt: 4
                  }
                }}
                variant="outlined"
              >
                {post.coverImage &&
                  <CardMedia
                    image={post.coverImage.url}
                    sx={{
                      height: 0,
                      paddingTop: '56.25%', // 16:9 aspect ratio
                      width: '100%',
                      flexShrink: '0',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                    title={post.title}
                  />
                }
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}>
                  <Typography variant="h5" component="p" gutterBottom>
                    {post.title}
                  </Typography>
                  <Typography variant="body2">
                    {post.excerpt}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    mt: 4
                  }}>
                    <Avatar
                      src={post.author.picture.url}
                      sx={{
                        height: theme => theme.spacing(6),
                        width: theme => theme.spacing(6)
                      }}
                      alt={post.author.name}
                    />
                    <Box sx={{
                      pl: 2,
                      '& .MuiTypography-root': {
                        color: 'darkGrey.main'
                      }
                    }}>
                      <Typography variant="h6" component="p">
                        {post.author.name}
                      </Typography>
                      <Typography variant="body2">
                        {dayjs(post.date).format('MMMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Section>
    </>
  )
}
