/**
 * @fileoverview Implements a "what if" section for the What is Pebble? page.
 */

import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function WhatIsPebbleMoney() {
  return (
    <Section color="white">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            gutterBottom
            variant="h1"
          >
            Where does your money go?
          </Typography>
          <Typography gutterBottom variant="body1">
            When you shop small businesses, your money goes directly back into your community, through wages and local taxes. <b>We all win.</b>
          </Typography>
          <Typography gutterBottom variant="body2">
            Create an account and start supporting your communities in a powerful way.
          </Typography>
          <Box mt={2}>
            <LinkButton
              color="secondary"
              mode="router"
              to="/signup"
            >
              Get started
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Percentage of money going back to local communities"
              src="/images/what-is-pebble/money.png"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
