/**
 * @fileoverview Implements a skeleton view for a single wishlist item.
 */

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
  Typography
} from '@mui/material'

/**
 * A component for displaying a single wishlist item.
 */
export default function AccountWishlistItemSkeleton() {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'common.white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root': {
          pb: 0
        }
      }}
    >
      <CardMedia sx={{ height: { xs: '180px', md: '290px' } }}>
        <Skeleton variant="rect" height="100%" />
      </CardMedia>
      <CardContent>
        <Typography sx={{ fontSize: '90%' }} variant="body1">
          <Skeleton />
        </Typography>
        <Typography sx={{ fontSize: '90%' }} variant="body2">
          <Skeleton />
        </Typography>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <CardActions>
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: 1,
            pb: 1,
            pl: 1,
          }}
          variant="body1"
        >
          <Skeleton width={75} />
        </Typography>
      </CardActions>
    </Card>
  )
}
