/**
 * @fileoverview Implements a list of brands for a Pebble Community.
 */

import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import CommunityBrand from './community-brand'
import HorizontalScroller from '../horizontal-scroller'
import Section from '../section'

export default function CommunityBrands(props) {
  const { shops } = props
  const navigate = useNavigate()

  const handleShopClicked = shop => () => {
    navigate(`/shop/${shop.slug}`)
  }

  return (
    <Section boxProps={{ py: 2 }}>
      <Typography gutterBottom variant="h4">
        Local brands you'll love
      </Typography>
      <Box sx={{
        height: '165px',
        my: 0,
        mx: { xs: -2, sm: -3 }
      }}>
        <HorizontalScroller>
          {shops.map(shop => (
            <CommunityBrand
              key={shop.slug}
              onClick={handleShopClicked(shop)}
              shop={shop}
            />
          ))}
        </HorizontalScroller>
      </Box>
    </Section>
  )
}
