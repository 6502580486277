/**
 * @fileoverview Implements a wrapper for combining a link and a button.
 * Handles both React Router links and regular href links using the `mode`
 * prop.
 */

import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'

export default function LinkButton(props) {
  if (props.mode?.toLowerCase() === 'router') {
    return (
      <Button
        component={Link}
        sx={{
          textDecoration: 'none',
          '&:hover, &:active': {
            textDecoration: 'none'
          },
          whiteSpace: 'nowrap'
        }}
        to={props.to}
        {...props}
      >
        {props.children}
      </Button>
    )
  }

  if (props.mode?.toLowerCase() === 'hash') {
    const handleHashClick = () => {
      const el = document.getElementById(props.to?.substring(1))
      el?.scrollIntoView && el.scrollIntoView({ behavior: 'smooth' })
    }

    return (
      <Button
        onClick={handleHashClick}
        sx={{
          textDecoration: 'none',
          '&:hover, &:active': {
            textDecoration: 'none'
          },
          whiteSpace: 'nowrap'
        }}
        {...props}
      >
        {props.children}
      </Button>
    )
  }

  return (
    <Button
      href={props.to}
      sx={{
        textDecoration: 'none',
        '&:hover, &:active': {
          textDecoration: 'none'
        },
        whiteSpace: 'nowrap'
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}
