/**
 * @fileoverview Implements a filter for narrowing which types of products are
 * included in the search results.
 */

import { useEffect, useRef, useState } from 'react'

import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import PebbleApi from '../../lib/pebble-api'

export default function FilterTypes(props) {
  const { onChange, selectedTypes } = props
  const mounted = useRef(false)
  const [types, setTypes] = useState([])

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    PebbleApi
      .getTypes()
      .then(types => {
        if (mounted.current) {
          setTypes(types)
        }
      })
  }, [])

  const handleSelected = type => event => {
    // Start by copying existing list of selected types
    let newSelectedTypes = [...selectedTypes]

    // See if this type is already in the list
    const index = newSelectedTypes.findIndex(t => t.slug === type.slug)
    
    // If the checkbox for this type was checked
    if (event.target.checked) {
      // Only add it to the array if it's not already there
      if (index === -1) {
        newSelectedTypes.push(type)
      }
    } else {
      // Checkbox was unchecked, so remove it from the array
      if (index !== -1) {
        newSelectedTypes.splice(index, 1)
      }
    }

    // Parent is responsible for managing state so just trigger the event
    onChange && onChange(newSelectedTypes)
  }

  // If there are no types to filter, hide the filter
  if (!types || types.length === 0) {
    return null
  }

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend">
        Types
      </FormLabel>
      <FormGroup>
        {types.map(type =>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedTypes.some(t => t.slug === type.slug)}
                onChange={handleSelected(type)}
                value={type.slug}
              />
            }
            key={type.slug}
            label={type.name}
            sx={{
              '& + &': {
                mt: -1.5
              }
            }}
          />
        )}
      </FormGroup>
    </FormControl>
  )
}
