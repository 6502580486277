/**
 * @fileoverview Implements a landing page for potential Pebble customers.
 */

import HelmetWrapper from '../helmet-wrapper'
import PartnerVideo from '../partner/partner-video'
import WhatIsPebbleCommunities from './what-is-pebble-communities'
import WhatIsPebbleHero from './what-is-pebble-hero'
import WhatIsPebblePartner from './what-is-pebble-partner'
import WhatIsPebbleMoney from './what-is-pebble-money'

export default function WhatIsPebble() {
  return (
    <>
      <HelmetWrapper
        title="What is Pebble?"
        description="What if shopping small was as simple as Amazon?"
      />
      <WhatIsPebbleHero />
      <WhatIsPebbleMoney />
      <WhatIsPebbleCommunities />
      <PartnerVideo />
      <WhatIsPebblePartner />
    </>
  )
}
