/**
 * @fileoverview Generic wrapper for analytics event logging. Currently built
 * on Google Analytics 4.
 * 
 * https://developers.google.com/analytics/devguides/collection/ga4/events
 * https://developers.google.com/gtagjs/reference/ga4-events
 */

/**
 * Object defining valid event names that can be used to log events.
 */
export const analyticsEvents = {
  /*
   * Trigger: when a user submits their payment information
   * Parameters: coupon, currency, items, payment_type, value
   */
  add_payment_info: 'add_payment_info',

  /*
   * Trigger: when a user submits their shipping information
   * Parameters: coupon, currency, items, shipping_tier, value
   */
  add_shipping_info: 'add_shipping_info',

  /*
   * Trigger: when a user adds items to cart
   * Parameters: currency, items, value
   */
  add_to_cart: 'add_to_cart',

  /*
   * Trigger: when a user adds items to a wishlist
   * Parameters: currency, items, value
   */
  add_to_wishlist: 'add_to_wishlist',

  /*
   * Trigger: when a user begins checkout
   * Parameters: coupon, currency, items, value
   */
  begin_checkout: 'begin_checkout',

  /*
   * Trigger: when a user logs in
   * Parameters: method
   */
  login: 'login',

  /*
   * Trigger: when a resource is not found
   * Parameters: id
   */
  not_found: 'not_found',

  /*
   * Trigger: when a user completes a purchase
   * Parameters: affiliation, coupon, currency, items, transaction_id,
   * shipping, tax, value (required parameter)
   */
  purchase: 'purchase',

  /*
   * Trigger: when a refund is issued
   * Parameters: affiliation, coupon, currency, items, transaction_id,
   * shipping, tax, value
   */
  refund: 'refund',

  /*
   * Trigger: when a user removes items from a cart
   * Parameters: currency, items, value
   */
  remove_from_cart: 'remove_from_cart',

  /*
   * Trigger: when a user searches your content	
   * Parameters: search_term
   */
  search: 'search',

  /*
   * Trigger: when a user has selected content
   * Parameters: content_type, item_id
   */
  select_content: 'select_content',

  /*
   * Trigger: when an item is selected from a list
   * Parameters: items, item_list_name, item_list_id
   */
  select_item: 'select_item',

  /*
   * Trigger: when a user has signed up
   * Parameters: method
   */
  sign_up: 'sign_up',

  /*
   * Trigger: when a user views their cart
   * Parameters: currency, items, value
   */
  view_cart: 'view_cart',

  /*
   * Trigger: when a user views an item
   * Parameters: currency, items, value
   */
  view_item: 'view_item',

  /*
   * Trigger: when a user sees a list of items/offerings
   * Parameters: items, item_list_name, item_list_id
   */
  view_item_list: 'view_item_list',
};

/**
 * Logs an event with the given name and the given payload.
 * @param {string} eventName - Name of the event. Must be in `analyticsEvents`.
 * @param {object} eventObj - Event payload object. Properties depend on event.
 */
export default function analyticsEvent(eventName, eventObj) {
  if (window.gtag == null) {
    console.error('[analytics] gtag not initialized');
    return;
  }

  if (analyticsEvents[eventName] == null) {
    console.error('[analytics] Invalid event name: ', eventName);
    return;
  }

  if (eventObj == null) {
    console.error('[analytics] Missing event object');
    return;
  }

  window.gtag('event', eventName, eventObj);
};
