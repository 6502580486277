/**
 * @fileoverview Implements a list of brands for a Pebble Community.
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import HorizontalScroller from '../horizontal-scroller'
import PebbleApi from '../../lib/pebble-api'
import Section from '../section'
import SearchResult from '../search/search-result'
import { getUrlQueryFromSearchModel } from '../../lib/search-context'

const PAGE_SIZE = 12

export default function CommunityFeaturedProducts(props) {
  // Assumes `community` is non-null when rendering this component
  const { community, searchModel } = props
  const navigate = useNavigate()
  const mounted = useRef(false)
  const [products, setProducts] = useState([])

  const handleShowAll = useCallback(() => {
    const params = getUrlQueryFromSearchModel(searchModel)
    const url = '/search?' + params.toString()
    navigate(url)
  }, [navigate, searchModel])

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    // Perform the search with the current search model
    PebbleApi
      .getSearchResultsConnection(searchModel, { first: PAGE_SIZE })
      .then(results => {
        if (!mounted.current) {
          return
        }

        const nodes = results.edges.map(edge => edge.node)

        setProducts(nodes)
      })
  }, [community, searchModel])

  return (
    <Section boxProps={{ py: 2 }}>
      <Typography
        gutterBottom
        sx={{
          '&::after': {
            content: "''",
            clear: 'both',
            display: 'block'
          }
        }}
        variant="h4"
      >
        Featured products
        <Button
          onClick={handleShowAll}
          sx={{
            color: 'secondary.main',
            float: 'right',
            mr: { xs: -2, sm: -3 }
          }}
          variant={null}
        >
          Show all
        </Button>
      </Typography>
      <Box sx={{
        my: 0,
        mx: { xs: -2, sm: -3 },
        minHeight: '64px',
      }}>
        <HorizontalScroller>
          {products.map(product => (
            <SearchResult
              key={product._id}
              hideOffers
              product={product}
              small
            />
          ))}
        </HorizontalScroller>
      </Box>
    </Section>
  )
}
