/**
 * @fileoverview Implements a "Not Found" page.
 */

import { Box, Typography } from '@mui/material'
import Section from './section'

export default function NotFound(props) {
  const { entity } = props

  return (
    <Section maxWidth="sm">
      <Box
        sx={{
          textAlign: 'center',
          '& img': {
            mb: 4
          }
        }}
      >
        <img
          alt="Empty"
          src="/images/noun/empty.png"
          style={{
            maxWidth: '256px',
            width: '100%'
          }}
        />
        <Typography variant="h1" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="body1">
          We couldn't find that{entity ? ' ' + entity : ''}!
        </Typography>
      </Box>
    </Section>
  )
}
