/**
 * @fileoverview Implements a view for a single wishlist item.
 */

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Info as InfoIcon
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography
} from '@mui/material'

import { useUser } from '../../lib/user-context'
import AccountWishlistItemSkeleton from './account-wishlist-item-skeleton'


/**
 * A component for displaying a single wishlist item.
 */
export default function AccountWishlistItem(props) {
  const { item } = props
  const { removeWishlistItem } = useUser()
  const [priceAlert, setPriceAlert] = useState(null)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    if (item) {
      const priceDiff = item.priceWhenAdded - item.price

      if (priceDiff > 0) {
        // Price has dropped since it was added to the wishlist
        setPriceAlert(
          <ul sx={{
            listStyle: 'none',
            p: 0,
            mt: 0.5
          }}>
            <li sx={{
              alignItems: 'center',
              color: 'success.main',
              display: 'flex',
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
              '& .MuiSvgIcon-root': {
                height: '0.75rem',
                width: '0.75rem',
                mr: 0.5
              }
            }}>
              <CheckCircleIcon />
              Price has dropped ${priceDiff.toFixed(2)} since it was added to your wishlist
            </li>
          </ul>
        )
      } else if (priceDiff < 0) {
        // Price has dropped since it was added to the wishlist
        setPriceAlert(
          <ul sx={{
            listStyle: 'none',
            p: 0,
            mt: 0.5
          }}>
            <li sx={{
              alignItems: 'center',
              color: 'warning.main',
              display: 'flex',
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
              '& .MuiSvgIcon-root': {
                height: '0.75rem',
                width: '0.75rem',
                mr: 0.5
              }
            }}>
              <InfoIcon />
              Price has gone up ${priceDiff.toFixed(2)} since it was added to your wishlist
            </li>
          </ul>
        )
      } else {
        // No price change
        setPriceAlert(null)
      }
    }
  }, [item])

  const handleDeleteClick = () => {
    removeWishlistItem(item)
    setDeleting(true)
  }

  if (item == null) {
    return null
  }

  if (deleting) {
    return <AccountWishlistItemSkeleton />
  }

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'common.white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root': {
          pb: 0
        }
      }}
      variant="outlined"
    >
      <Link to={`/item/${item.productId}/${item.handle}`}>
        {item.imageUrl ? (
          <CardMedia
            image={item.imageUrl}
            title={item.title}
          />
        ) : (
          <Box
            sx={{ height: { xs: '180px', md: '290px' } }}
            title={item.title}
          />
        )}
      </Link>
      <CardContent>
        <Typography
          sx={{
            fontSize: '90%',
            lineHeight: 1.25,
            '& a': {
              color: 'darkGrey.main'
            },
            '& a:hover, & a:active': {
              color: 'secondary.main'
            }
          }}
          variant="body1"
        >
          <Link to={`/item/${item.productId}/${item.handle}`}>
            <b>{item.title}</b>
          </Link>
        </Typography>
        <Link to={`/shop/${item.shop.slug}`}>
          <Typography sx={{ fontSize: '90%' }} variant="body2">
            {item.shop.name}
          </Typography>
        </Link>
        {priceAlert}
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <CardActions>
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: 1,
            pb: 1,
            pl: 1
          }}
          variant="body1"
        >
          ${item.price.toFixed(2)}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}
