/**
 * @fileoverview Implements a main menu drawer to be opened by a hamburger icon
 * in the primary app bar.
 */

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import MenuIcon from '@mui/icons-material/Menu'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useUser } from '../lib/user-context'
import PebbleApi from '../lib/pebble-api'
import { ListItemButton } from '@mui/material'

export default function MainMenu() {
  const { user } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showSubcategories, setShowSubcategories] = useState(false)

  useEffect(() => {
    setLoading(true)

    PebbleApi
      .getCategories()
      .then(categories => {
        setCategories(categories)
        setLoading(false)
      })
  }, [])

  const handleCategoryClicked = category => () => {
    if (currentCategory != null &&
      currentCategory.category === category.category &&
      showSubcategories === true) {
      setShowSubcategories(false)
      setTimeout(() => setCurrentCategory(null), 300)
    } else {
      setCurrentCategory(category)
      setShowSubcategories(true)
    }
  }

  const handleSubcategoryClicked = subcategory => () => {
    const params = new URLSearchParams({
      // Intentionally blank query string
      'q': '',
      // Category name
      'ca': currentCategory.category
    })

    if (subcategory !== 'All') {
      params.append('sc', subcategory)
    }

    const url = '/search?' + params.toString()
    navigate(url)
    handleDrawerClose()
  };

  const openDrawer = () => setDrawerOpen(true)
  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setCurrentCategory(null)
  }

  const goToHome = () => {
    handleDrawerClose()
    navigate('/')
  };

  const goToLogIn = () => {
    handleDrawerClose()
    
    if (location.pathname !== '/logout') {
      const redirectUrl = location.pathname + location.search + location.hash
      const params = new URLSearchParams({ 'r': redirectUrl })
      const loginUrl = '/login?' + params.toString()
      navigate(loginUrl)
    } else {
      navigate('/login')
    }
  };

  const goToSignUp = () => {
    handleDrawerClose()
    navigate('/signup')
  };

  const goToAccount = () => {
    handleDrawerClose()
    navigate('/account')
  }

  const goToAdmin = () => {
    handleDrawerClose()
    navigate('/admin')
  }

  const goToLogOut = () => {
    handleDrawerClose()
    navigate('/logout')
  };

  // Return an empty component while loading
  if (loading) {
    return null
  }

  return (
    <>
      <IconButton
        aria-label="menu"
        edge="start"
        onClick={openDrawer}
        sx={{ color: 'common.white' }}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          '.MuiDrawer-paper': {
            width: { xs: '80vw', sm: '40vw', md: '30vw', lg: '20vw' }
          }
        }}
      >
        <Toolbar sx={{
          '& img': {
            cursor: 'pointer',
            display: 'block',
            height: { xs: '24px', sm: '32px' },
            mx: 0,
            my: 1,
          }
        }}>
          <IconButton
            aria-label="menu"
            edge="start"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
          <img
            alt="mobile logo"
            onClick={goToHome}
            src={mobile ? '/images/logo-mobile_2x.png' : '/images/logo-desktop_2x.png'}
          />
        </Toolbar>
        <List dense>
          {categories && categories.map(category => (
            <React.Fragment key={category.category}>
              <ListItemButton onClick={handleCategoryClicked(category)}>
                <ListItemText primary={category.category} />
                {currentCategory?.category === category.category && <ExpandLessIcon />}
              </ListItemButton>
              <Collapse in={currentCategory?.category === category.category} timeout={300}>
                <ListItemButton
                  onClick={handleSubcategoryClicked('All')}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="All" />
                </ListItemButton>
                {category.subcategories.map(subcategory => (
                  <ListItemButton
                    key={category.category + '-' + subcategory}
                    onClick={handleSubcategoryClicked(subcategory)}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={subcategory} />
                  </ListItemButton>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
          <Divider />
          <ListSubheader disableSticky>Account</ListSubheader>
          {!user.loggedIn &&
            <>
              <ListItemButton onClick={goToLogIn}>
                <ListItemText primary="Log in" />
              </ListItemButton>
              <ListItemButton onClick={goToSignUp}>
                <ListItemText primary="Sign up" />
              </ListItemButton>
            </>
          }
          {user.loggedIn &&
            <>
              <ListItemButton onClick={goToAccount}>
                <ListItemText primary="My account" />
              </ListItemButton>
              {user.admin &&
                <ListItemButton onClick={goToAdmin}>
                  <ListItemText primary="Admin" />
                </ListItemButton>
              }
              <ListItemButton onClick={goToLogOut}>
                <ListItemText primary="Log out" />
              </ListItemButton>
            </>
          }
        </List>
      </SwipeableDrawer>
    </>
  )
}
