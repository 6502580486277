import { useCallback } from 'react'

import { Launch as LaunchIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Typography,
  Skeleton
} from '@mui/material'

import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import LinkButton from '../link-button'
import Section from '../section'

export default function ShopAbout(props) {
  const { color, loading, shop } = props

  const handleExternalLinkClick = useCallback(() => {
    if (shop.externalUrl) {
      analyticsEvent(analyticsEvents.select_content, {
        content_type: 'shop_external_url',
        item_id: shop.slug
      })

      window.open(shop.externalUrl, '_blank').focus()
    }
  }, [shop])

  if (loading) {
    return (
      <Section color={color}>
        <Grid
          container
          spacing={2}
          sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h1" gutterBottom>
              <Skeleton />
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton
              sx={{
                borderRadius: '4px',
                display: 'block',
                height: { xs: 'auto', sm: 'unset' },
                m: '0 auto',
                maxHeight: { xs: 'none', sm: theme => theme.spacing(32) },
                maxWidth: '100%',
                width: { xs: '100%', sm: 'auto' },
                '&.MuiSkeleton-root': {
                  height: theme => theme.spacing(32)
                }
              }}
              variant="rect"
            />
          </Grid>
        </Grid>
      </Section>
    )
  }

  if (!shop) {
    return null
  }

  return (
    <Section color={color}>
      <Grid
        container
        spacing={2}
        sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
      >
        <Grid item xs={12}>
          {shop.logo_url
            ? <Box sx={{
                mb: 2,
                '& img': {
                  borderRadius: '4px',
                  display: 'block',
                  height: { xs: 'auto', sm: 'unset' },
                  m: '0 auto',
                  maxHeight: { xs: 'none', sm: theme => theme.spacing(32) },
                  maxWidth: '100%',
                  width: { xs: '100%', sm: 'auto' },
                  '&.MuiSkeleton-root': {
                    height: theme => theme.spacing(32)
                  }
                }
              }}>
                <img
                  alt={shop.name}
                  src={shop.logo_url}
                />
              </Box>
            : <Typography variant="h1" gutterBottom>
                {loading ? <Skeleton /> : shop.name }
              </Typography>
          }
          <Typography variant="body1" gutterBottom>
            {loading ? <Skeleton /> : shop.about}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 2
          }}>
            <LinkButton
              color="secondary"
              mode="hash"
              to="#products"
            >
              Shop now
            </LinkButton>
            {shop.externalUrl &&
              <Button
                color="secondary"
                endIcon={<LaunchIcon />}
                onClick={handleExternalLinkClick}
                variant="outlined"
              >
                Visit {shop.name}
              </Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
