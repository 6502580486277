/**
 * @fileoverview Implements a view showing the user's wishlist.
 */

import { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { useUser } from '../../lib/user-context'
import AccountSection from './account-section'
import AccountWishlistItem from './account-wishlist-item'
import AccountWishlistItemSkeleton from './account-wishlist-item-skeleton'
import HorizontalScroller from '../horizontal-scroller'

const PAGE_SIZE = 8

export default function AccountWishlistPreview() {
  const { loadingUser, user } = useUser()
  const [wishlistItems, setWishlistItems] = useState([])

  useEffect(() => {
    setWishlistItems(user.wishlist.items.slice(0, PAGE_SIZE))
  }, [user])

  return (
    <AccountSection
      action={{
        label: 'View wishlist',
        to: '/account/wishlist'
      }}
      heading="Wishlist"
    >
      <Box sx={{ mx: -2, my: 0 }}>
        {!loadingUser && wishlistItems.length === 0
          ? <Box sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              px: 2,
              py: 8
            }}>
              <Typography variant="body1">
                No items in your wishlist!
              </Typography>
            </Box>
          : <HorizontalScroller>
              {loadingUser
                ? <>
                    <AccountWishlistItemSkeleton />
                    <AccountWishlistItemSkeleton />
                    <AccountWishlistItemSkeleton />
                  </>
                : wishlistItems.map(item => (
                    <AccountWishlistItem item={item} />
                  ))
              }
            </HorizontalScroller>
        }
      </Box>
    </AccountSection>
  )
}
