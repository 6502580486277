/**
 * @fileoverview Implements a banner prompting the user to install the PWA.
 */

import { useEffect, useRef, useState } from 'react'
import { isMobileSafari } from 'react-device-detect'

import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import GetAppIcon from '@mui/icons-material/GetApp'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'

export default function PwaInstallBanner() {
  const deferredEvent = useRef(null)
  const [visible, setVisible] = useState(false)

  const handleBeforeInstallPrompt = event => {
    // Stash the event so it can be triggered later.
    deferredEvent.current = event
    
    // Show the install banner
    setVisible(true)
  };

  const handleAppInstalled = () => {
    // Clear the reference so it can be garbage collected
    deferredEvent.current = null
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    window.addEventListener('appinstalled', handleAppInstalled)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
      window.removeEventListener('appinstalled', handleAppInstalled)
    }
  }, [])
  
  // For Mobile Safari, just set a brief timeout before displaying the snackbar
  useEffect(() => {
    // Avoid showing the snackbar if it's already running in standalone mode
    const isStandalone = 'standalone' in window.navigator &&
      window.navigator.standalone

    if (isMobileSafari && !isStandalone) {
      setTimeout(() => {
        setVisible(true)
      }, 1000)
    }
  }, [])

  const handleClose = () => setVisible(false)

  const handleInstall = async () => {
    const promptEvent = deferredEvent.current

    if (!promptEvent) {
      // The deferred prompt isn't available
      return
    }

    // Show the install prompt
    promptEvent.prompt()

    // Wait for the result
    await promptEvent.userChoice

    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    deferredEvent.current = null

    // Hide the install banner
    setVisible(false)
  };

  // Mobile Safari doesn't currently support the `beforeinstallprompt` event or
  // direct install, so we need to prompt the user to install the app using
  // manual steps
  if (isMobileSafari) {
    const message = (
      <>
        <Typography variant="body1">
          Get the Pebble app!
        </Typography>
        <Typography
          component="ol"
          sx={{
            listStylePosition: 'inside',
            m: 0,
            p: 0,
          }}
          variant="body2"
        >
          <li>Tap the <b>Share</b> <img src="/images/square-and-arrow-up-light.png" alt="share" /> button below</li>
          <li>Tap <b>Add to Home Screen</b> <img src="/images/plus-square-light.png" alt="add" /></li>
          <li>Tap <b>Add</b> to finish installing!</li>
        </Typography>
      </>
    )

    return (
      <Snackbar
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={message}
        onClose={handleClose}
        open={visible}
        sx={{
          '& img': {
            borderRadius: '3px',
            height: '1em',
            mb: 0.5,
            mt: 0,
            mx: 0.5,
            verticalAlign: 'middle',
            '&:first-child': {
              ml: 0,
            },
          },
          '& .MuiSnackbarContent-action': {
            position: 'absolute',
            top: 1,
            right: 2,
          },
        }}
        TransitionComponent={Slide}
      />
    )
  }

  return (
    <Snackbar
      action={
        <>
          <Button
            color="secondary"
            size="small"
            startIcon={<GetAppIcon />}
            sx={{ mr: 1 }}
            onClick={handleInstall}
          >
            Install
          </Button>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      message="Get the Pebble app!"
      onClose={handleClose}
      open={visible}
      TransitionComponent={Slide}
    />
  )
}
