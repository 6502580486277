/**
 * @fileoverview Implements a payment input control group for customers to
 * enter their credit card information.
 */

import { useEffect, useState } from 'react'
import {
  PaymentInputsWrapper,
  usePaymentInputs
} from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { css } from 'styled-components'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { useTheme } from '@mui/material/styles'

dayjs.extend(customParseFormat)

export default function CheckoutStepPaymentInput(props) {
  const { disabled, onChange, orderPaymentMethod } = props
  const theme = useTheme()

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs()

  const [cardNumber, setCardNumber] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [cvc, setCvc] = useState('')
  const [wrapperStyles, setWrapperStyles] = useState({})

  // When any of the fields change, build a new payment method object
  useEffect(() => {
    let paymentMethod = null

    const hasErrors = meta.erroredInputs.cardNumber != null ||
      meta.erroredInputs.expiryDate != null ||
      meta.erroredInputs.cvc != null

    // Check for errors on any of the inputs
    if (!hasErrors &&
        cardNumber.length === 19 &&
        expiryDate.length === 7 &&
        cvc.length === 3 &&
        meta.cardType != null) {
      // Remove spaces from card number
      const normalizedCardNumber = cardNumber.replaceAll(' ', '')

      // Transform expiry date into separate month and year values
      const expiryDateObj = dayjs(expiryDate, 'MM / YY')
      const expirationMonth = Number(expiryDateObj.format('M'))
      const expirationYear = Number(expiryDateObj.format('YYYY'))

      // Build payment method object
      paymentMethod = {
        cardNumber: normalizedCardNumber,
        cardType: meta.cardType.displayName,
        expirationMonth,
        expirationYear,
        securityCode: cvc
      }
    }

    onChange && onChange(paymentMethod)
  }, [
    cardNumber,
    expiryDate,
    cvc,
    meta.cardType,
    meta.erroredInputs,
    onChange
  ])

  useEffect(() => {
    if (orderPaymentMethod) {
      const expMonth =
        orderPaymentMethod.expirationMonth.toString().padStart(2, '0')
      const expYear =
        orderPaymentMethod.expirationYear.toString().substring(2)

      setCardNumber(`•••• •••• •••• ${orderPaymentMethod.lastFour}`)
      setExpiryDate(`${expMonth} / ${expYear}`)
      setCvc('•••')
    } else {
      // Reset input fields
      setCardNumber('')
      setExpiryDate('')
      setCvc('')

      // Focus on the card number field when starting to edit
      getCardNumberProps().ref.current.focus()
    }
  }, [getCardNumberProps, orderPaymentMethod])

  useEffect(() => {
    if (disabled) {
      setWrapperStyles({
        inputWrapper: {
          base: css`
            background: ${theme.palette.grey.main};
            border-radius: ${theme.spacing(0.5)}px;
          `
        },
        input: {
          base: css`
            background: inherit
          `
        }
      })
    } else {
      setWrapperStyles({
        inputWrapper: {
          base: css`
            border-radius: ${theme.spacing(0.5)}px;
          `
        }
      })
    }
  }, [disabled, theme])

  const handleCardNumberChange = event => setCardNumber(event.target.value)
  const handleExpiryDateChange = event => setExpiryDate(event.target.value)
  const handleCvcChange = event => setCvc(event.target.value)

  return (
    <PaymentInputsWrapper {...wrapperProps} styles={wrapperStyles}>
      <svg {...getCardImageProps({ images })} />
      <input
        {...getCardNumberProps({
          disabled,
          onChange: handleCardNumberChange
        })}
        ref={getCardNumberProps().ref}
        value={cardNumber}
      />
      <input
        {...getExpiryDateProps({
          disabled,
          onChange: handleExpiryDateChange
        })}
        value={expiryDate}
      />
      <input
        {...getCVCProps({
          disabled,
          onChange: handleCvcChange
        })}
        value={cvc}
      />
    </PaymentInputsWrapper>
  )
}
