import { useEffect, useState } from 'react'

import { Box, Button } from '@mui/material'

export default function ItemVariantButtons(props) {
  const { variant, variants, onChange } = props
  const [currentVariant, setCurrentVariant] = useState(null)
  const [currentVariantIndex, setCurrentVariantIndex] = useState(-1)

  useEffect(() => {
    if (variant != null) {
      const variantIndex = variants.findIndex(v => v.id === variant.id)
      setCurrentVariant(variant)
      setCurrentVariantIndex(variantIndex)
    }
  }, [variant, variants])

  useEffect(() => {
    onChange && onChange(currentVariant)
  }, [currentVariant, onChange])

  const handleVariantChange = index => {
    if (index === currentVariantIndex) {
      // This variant is already selected, so now unselect it
      setCurrentVariant(null)
      setCurrentVariantIndex(-1)
    } else {
      setCurrentVariant(variants[index])
      setCurrentVariantIndex(index)
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 1,
      justifyContent: 'flex-start'
    }}>
      {variants.map((variant, index) =>
          <Button
            color="secondary"
            key={variant.id}
            onClick={() => handleVariantChange(index)}
            variant={index === currentVariantIndex ? 'contained' : 'outlined'}
            disabled={variant.available === false ||
              (variant.inventory_quantity === 0 &&
                variant.inventory_policy === 'deny' &&
                variant.inventory_management != null)
            }
          >
            {variant.title}
          </Button>
      )}
    </Box>
  )
}
