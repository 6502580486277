/**
 * @fileoverview Login view.
 */

import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import {
  Link as MuiLink
} from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { useUser } from '../../lib/user-context'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'
import LinkButton  from '../link-button'
import PebbleApi from '../../lib/pebble-api'

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const { loadUser } = useUser()
  const mounted = useRef(false)
  const searchParams = new URLSearchParams(location.search)
  const redirectUrl = searchParams.get('r') ?? ''

  // Prepopulate the Email field from optional query parameter `email`
  const [email, setEmail] = useState(searchParams.get('email') || '')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  // Grab the optional query parameter "shop"
  // If populated, this login view is for connecting a Pebble account to the
  // specified Shopify shop domain.
  const shopifyDomain = searchParams.get('shop')

  const handlEmailChange = event => setEmail(event.target.value)
  const handlePasswordChange = event => setPassword(event.target.value)

  const handleSubmit = event => {
    event.preventDefault()

    // First clear out all errors
    setFormError(null)
    setEmailError(null)
    setPasswordError(null)

    let hasError = false

    if (email == null || email.trim().length === 0) {
      setEmailError('Email address is required')
      hasError = true
    }

    if (password == null || password.trim().length === 0) {
      setPasswordError('Password is required')
      hasError = true
    }

    // If there's a validation error, bail before talking to the server
    if (hasError) {
      return
    }

    setLoading(true)

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(
          '6Ldn0G4bAAAAAOHwhde8AAVfhYpiazRho5Qygg8a',
          { action: 'submit' }
        )
        // First, verify the reCAPTCHA response token through our server
        .then(token => PebbleApi.verifyRecaptcha(token))
        .then(success => {
          if (mounted.current) {
            if (success) {
              return PebbleApi
                .login(email, password, shopifyDomain)
            }

            setLoading(false)
            setFormError("Oops! Pebble thinks you're a robot. Please try again.")
          }

          return Promise.resolve(null)
        })
        .then(response => {
          if (mounted.current && response) {
            setLoading(false)

            if (response.status === 200) {
              // 200 OK

              // Log analytics events for Google and Facebook
              analyticsEvent(analyticsEvents.login, {
                method: 'email',
                user_email: email
              })

              // Load the newly loggin in user
              loadUser()

              if (shopifyDomain) {
                // Login originated from Shopify admin, so this must be in a popup
                // and should be closed to continue connecting the account
                window.close()
              } else {
                // Success, so redirect to the home page
                if (redirectUrl !== '') {
                  navigate(redirectUrl)
                } else {
                  navigate('/')
                }
              }
            } else if (response.status === 401) {
              // 401 Unauthorized

              setFormError('Invalid email or password')
            } else if (response.status === 500) {
              // 500 Internal Server Error

              setFormError('Error logging in. Please try again later.')
            }
          }
        })
    })
  }

  const handlePasswordRevealMouseDown = () => {
    setShowPassword(true)
  }

  const handlePasswordRevealMouseUp = () => {
    setShowPassword(false)
  }

  let resetPasswordLink = '/resetpassword'
  
  if (email && email.length) {
    resetPasswordLink += `?email=${email}`
  }

  return (
    <Container maxWidth="sm">
      <Box py={8}>
        <Box pb={4}>
          <Typography variant="h1">
            Log in
          </Typography>
          {shopifyDomain && (
            <Typography variant="body1">
              Log in to your Pebble account to connect it to the Shopify sales channel.
            </Typography>
          )}
        </Box>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {formError &&
            <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
              {formError}
            </Alert>
          }
          <TextField
            autoComplete="email"
            disabled={loading}
            error={Boolean(emailError) || Boolean(formError)}
            helperText={emailError}
            inputProps={{
              autoCapitalize: 'off',
            }}
            label="Email"
            name="email"
            onChange={handlEmailChange}
            required
            sx={{ mb: 2, width: '100%' }}
            value={email}
            variant="outlined"
          />
          <FormControl
            disabled={loading}
            required
            sx={{ mb: 2, width: '100%' }}
            variant="outlined"
          >
            <InputLabel
              error={Boolean(passwordError) || Boolean(formError)}
              htmlFor="login-current-password"
            >
              Password
            </InputLabel>
            <OutlinedInput
              aria-describedby="login-current-password-error"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    disabled={loading}
                    disableRipple
                    onMouseDown={handlePasswordRevealMouseDown}
                    onMouseUp={handlePasswordRevealMouseUp}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              }
              error={Boolean(passwordError) || Boolean(formError)}
              id="login-current-password"
              inputProps={{
                autoCapitalize: 'off',
              }}
              label="Password *"
              name="current-password"
              onChange={handlePasswordChange}
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
            <FormHelperText id="login-current-password-error" error={true}>
              {passwordError}
            </FormHelperText>
          </FormControl>
          <Button
            color="primary"
            disabled={loading}
            endIcon={loading && <CircularProgress size={24} />}
            size="large"
            sx={{ mb: 2, width: '100%' }}
            type="submit"
          >
            Log in
          </Button>
        </form>
        <Typography variant="body2" gutterBottom>
          Forgot your password?
          {' '}
          <MuiLink component={Link} to={resetPasswordLink}>
            Reset it
          </MuiLink>
        </Typography>
        <Box pt={2}>
          <Typography variant="body1" gutterBottom>
            Don't have a Pebble account yet?
          </Typography>
          <LinkButton
            color="primary"
            size="large"
            sx={{ mb: 2, width: '100%' }}
            to={`/signup${location.search}`}
            variant="outlined"
          >
            Sign up
          </LinkButton>
        </Box>
      </Box>
    </Container>
  )
}
