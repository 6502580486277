/**
 * @fileoverview Lightweight session storage cache to persist data for lookup.
 */

import values from './caches/values.js'
import categories from './caches/categories.js'
import CommunitiesCache from './caches/communities.js'
import ShopsCache from './caches/shops.js'
import TypesCache from './caches/types.js'

const GEOLOCATION_PREFIX = 'geolocation.'

/**
 * @function hashLatLon
 * Helper function to generate a hash based on the given latitude and longitude
 * that can be used as a storage key.
 * @param {number} lat - Latitude
 * @param {number} lon - Longitude
 * @returns {string} Generated hash
 */
const hashLatLon = (lat, lon) => {
  // Format: "geolocation.<latitude>,<longitude>"
  return GEOLOCATION_PREFIX + Number(lat).toString() + ',' +
    Number(lon).toString();
};

const Cache = {
  /*
   * Geolocation
   */

  /**
   * @function getGeolocation
   * Looks up geolocation data by latitude and longitude.
   * @param {number} lat - Latitude
   * @param {longitude} lon - Longitude
   * @returns {object} Geolocation object if found, or null
   */
  getGeolocation: (lat, lon) => {
    const key = hashLatLon(lat, lon);
    let geoloc = sessionStorage.getItem(key);

    if (geoloc) {
      geoloc = JSON.parse(geoloc);
    }

    return geoloc;
  },

  /**
   * @function setGeolocation
   * Stores geolocation object by latitude and longitude
   * @param {object} geoloc - Geolocation object with properties `latitude`
   * and `longitude`
   */
  setGeolocation: geoloc => {
    const key = hashLatLon(geoloc.latitude, geoloc.longitude);
    sessionStorage.setItem(key, JSON.stringify(geoloc));
  },

  // Cache for Pebble values
  values,
  categories,

  /**
   * @function getTypes
   * Retrieves types from the cache.
   * @returns {Array<object>} List of product types
   */
  getTypes: () => TypesCache.get(),

  /**
   * @function setTypes
   * Stores types in the cache.
   * @param {Array<object>} types - List of product types
   */
  setTypes: types => TypesCache.set(types),

  // Cache for Pebble Communities
  communities: CommunitiesCache,
  
  // Cache for Pebble Shops
  shops: ShopsCache
}

export default Cache
