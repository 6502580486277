/**
 * @fileoverview Implements a testing component illustrating the Pebble brand
 * colors and typography.
 */

import { Box, Button, Stack, Typography } from '@mui/material'

import Section from './section'

const sections = [
  'greyLight',
  'secondaryLight',
  'primary',
  'white',
  'secondary',
  'grey'
]

const headings = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6'
]

export default function Brand() {
  const buttonGroup = (
    <Stack direction="row" spacing={1}>
      <Button color="secondary">
        Call to action
      </Button>
      <Button color="primary">
        Primary action
      </Button>
      <Button color="primary" variant="outlined">
        Secondary action
      </Button>
      <Button color="primary" variant="none">
        Tertiary action
      </Button>
    </Stack>
  )

  return (
    <>
      {sections.map((section, index) => (
        <Section
          color={section}
          maxWidth="md"
          key={index}
        >
          {headings.map((heading, index) => (
            <Box mb={4} key={index}>
              <Typography gutterBottom variant={heading}>
                The quick brown fox jumps over the lazy dog.
              </Typography>
              <Typography gutterBottom variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida nibh mi, eu vestibulum nibh varius a. Curabitur pellentesque viverra orci sed viverra. Morbi nulla metus, tincidunt et mattis sit amet, rhoncus sed quam.
              </Typography>
              <Typography gutterBottom variant="body2">
                Donec bibendum, lorem id gravida tristique, diam odio luctus enim, ut semper est enim aliquet quam. Nullam aliquam maximus lacus, at pharetra felis auctor nec. Morbi aliquam nisl ac lectus mollis, quis varius ante consectetur.
              </Typography>
            </Box>
          ))}
          {buttonGroup}
        </Section>
      ))}
    </>
  )
}
