/**
 * @fileoverview Implements a simple page with content loaded from Hygraph CMS.
 */

import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Box, Typography } from '@mui/material'

import { getPage } from '../lib/hygraph.js'
import SkeletonPage from './skeleton-page.js'
import Section from './section.js'

export default function CmsPage(props) {
  const { slug } = props
  const [page, setPage] = useState(null)
  const [loading, setLoading] = useState(true)

  // Load page contents from Hygraph CMS
  useEffect(() => {
    setLoading(true)
    getPage(slug)
      .then(newPage => {
        setPage(newPage)
        setLoading(false)
      })
  }, [slug])

  if (loading) {
    return (
      <SkeletonPage />
    )
  }

  return (
    <Section maxWidth="md">
      <Typography gutterBottom variant="h1">
        {page.title}
      </Typography>
      <Typography gutterBottom variant="body2">
        <i>
          Last updated
          {' '}
          {dayjs(page.updatedAt).format('MMMM D, YYYY')}
        </i>
      </Typography>
      <Box dangerouslySetInnerHTML={{__html: page.content.html}} />
    </Section>
  )
}
