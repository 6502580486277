/**
 * @fileoverview Implements a view showing the user's account info.
 */

import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { useUser } from '../../lib/user-context'
import AccountSection from './account-section'
import PebbleApi from '../../lib/pebble-api'
import SnackbarAlert from '../snackbar-alert'

export default function AccountInfo() {
  const { loadingUser, loadUser, user } = useUser()
  const [saving, setSaving] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false)

  useEffect(() => {
    if (!loadingUser && user.loggedIn) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
    }
  }, [loadingUser, user])

  const save = () => {
    // Build user object to push to the server
    const newUser = { firstName, lastName }

    setSaving(true)

    PebbleApi
      .saveUser(newUser)
      .then(success => {
        setSaving(false)

        if (success) {
          setShowSavedSnackbar(true)
          loadUser()
        }
    })
  }
  
  const handleFirstNameChanged = event => {
    setFirstName(event.target.value)
  }
  
  const handleLastNameChanged = event => {
    setLastName(event.target.value)
  }

  const handleSavedSnackbarClose = () => {
    setShowSavedSnackbar(false)
  }

  return (
    <AccountSection heading="Account info">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            label="Email"
            value={user ? user.email : '(Unavailable)'}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            disabled={saving}
            fullWidth
            label="First Name"
            name="given-name"
            onChange={handleFirstNameChanged}
            value={firstName}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="family-name"
            disabled={saving}
            fullWidth
            label="Last Name"
            name="family-name"
            onChange={handleLastNameChanged}
            value={lastName}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            color="secondary"
            disabled={saving}
            endIcon={saving ? <CircularProgress size={20} /> : null}
            onClick={save}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <SnackbarAlert
        onClose={handleSavedSnackbarClose}
        open={showSavedSnackbar}
        severity="success"
      >
        Account info saved
      </SnackbarAlert>
    </AccountSection>
  )
}
