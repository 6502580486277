/**
 * @fileoverview Implements a view showing the user's wishlist.
 */

import { useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useUser } from '../../lib/user-context'
import AccountSection from './account-section'
import AccountWishlistItem from './account-wishlist-item'
import AccountWishlistItemSkeleton from './account-wishlist-item-skeleton'


export default function AccountWishlist() {
  const { loadingUser, user } = useUser()
  const [wishlistItems, setWishlistItems] = useState([])

  useEffect(() => {
    setWishlistItems(user.wishlist.items)
  }, [user])

  return (
    <AccountSection heading="Wishlist">
      <Grid container spacing={2}>
        {loadingUser
          ? <>
              <Grid item xs={6} md={3}>
                <AccountWishlistItemSkeleton />
              </Grid>
              <Grid item xs={6} md={3}>
                <AccountWishlistItemSkeleton />
              </Grid>
              <Grid item xs={6} md={3}>
                <AccountWishlistItemSkeleton />
              </Grid>
            </>
          : wishlistItems.length === 0
            ? <Grid item xs={12}>
                <Box sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  px: 2,
                  py: 8
                }}>
                  <Typography variant="body1">
                    No items in your wishlist!
                  </Typography>
                </Box>
              </Grid>
            : wishlistItems.map(item => (
                <Grid item xs={6} md={3}>
                  <AccountWishlistItem item={item} />
                </Grid>
              ))
        }
      </Grid>
    </AccountSection>
  )
}
