/**
 * @fileoverview Implements a view for displaying a single search result, which
 * may be either a product (default) or a service.
 */

import React from 'react'
import { Link } from 'react-router-dom'

import { alpha } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link as MuiLink,
  Typography
} from '@mui/material'

/**
 * A component for displaying a single search result product.
 */
export default function SearchResultProduct(props) {
  const { product, small, hideOffers } = props

  if (product == null) {
    return null
  }

  const hasPriceRange = product.minPrice > 0 && product.maxPrice > 0 &&
    product.minPrice !== product.maxPrice

  const type = product.type === 'service' ? 'service' : 'item'
  const url = `/${type}/${product._id}/${product.handle}`

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'common.white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root': {
          pb: 0
        }
      }}
      variant="outlined"
      title={product.score > 0 ? `Score: ${product.score}` : null}
    >
      <Link to={url}>
        {product.imageUrl ? (
          <CardMedia
            image={product.imageUrl}
            sx={{ height: { xs: '180px', sm: '290px' } }}
            title={product.title}
          />
        ) : (
          <Box
            sx={{ height: { xs: '180px', sm: '290px' } }}
            title={product.title}
          />
        )}
      </Link>
      <CardContent sx={{
        fontSize: small ? '90%' : '100%',
        pb: 0
      }}>
        <Typography
          sx={{
            lineHeight: 1.25,
            '& a': {
              color: 'darkGrey.main'
            },
            '& a:hover, & a:active': {
              color: 'secondary.main'
            }
          }}
          variant="body1"
        >
          <MuiLink component={Link} to={url}>
            <b>{product.title}</b>
          </MuiLink>
        </Typography>
        <Typography variant="body2">
          <MuiLink component={Link} to={`/shop/${product.shop.slug}`}>
            {product.shop.name}
          </MuiLink>
        </Typography>
        {product.type === 'product' && !hideOffers &&
          <Typography
            component="ul"
            sx={{
              listStyle: 'none',
              p: 0,
              mt: 0.5,
              '& li': {
                alignItems: 'center',
                display: 'flex',
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                '& .MuiSvgIcon-root': {
                  height: '0.75rem',
                  width: '0.75rem',
                  mr: 0.5
                }
              }
            }}
          >
            {product.shop.offers_free_shipping && (
              <li>
                <CheckCircleIcon />
                Free shipping
              </li>
            )}
            {product.shop.offers_local_pickup && (
              <li>
                <CheckCircleIcon />
                Local pickup
              </li>
            )}
          </Typography>
        }
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <CardActions>
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: 1,
            pb: 1,
            pl: 1,
            '& .starting-at': {
              color: theme => alpha(theme.palette.darkGrey.main, 0.5),
              display: 'block',
              fontSize: '0.875rem',
              mb: 0.5
            },
            '& .compare-at': {
              color: theme => alpha(theme.palette.darkGrey.main, 0.5),
              fontSize: '80%',
              pl: 1,
              textDecoration: 'line-through'
            }
          }}
          variant="body1"
        >
          {product.minPrice != null &&
            <>
              {hasPriceRange &&
                <span className="starting-at">
                  starting at
                </span>
              }
              ${product.minPrice.toFixed(2)}
            </>
          }
          {product.maxCompareAtPrice &&
            <span className="compare-at">
              ${product.maxCompareAtPrice.toFixed(2)}
            </span>
          }
        </Typography>
      </CardActions>
    </Card>
  )
}
