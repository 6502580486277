/**
 * @fileoverview Implements a view for a specific shop.
 */

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from '../../lib/user-context';
import HelmetWrapper from '../helmet-wrapper';
import NotFound from '../not-found';
import FeaturedCollectionHero from './featured-collection-hero'
import FeaturedProducts from './featured-products';
import ShopAbout from './shop-about';
import ShopMap from './shop-map';
import ShopProducts from './shop-products';
import Cache from '../../lib/cache';
import PebbleApi from '../../lib/pebble-api';
import analyticsEvent, { analyticsEvents } from '../../lib/analytics';

export default function Shop() {
  const { user } = useUser();
  const { slug } = useParams();
  const [shop, setShop] = useState(null);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false;
  });

  useEffect(() => {
    // Load shop by slug
    if (slug != null && slug.length > 0) {
      setLoading(true);

      // Make a call to the API to get shop data and products
      PebbleApi
        .getShopBySlug(slug)
        .then(shop => {
          setShop(shop);
          Cache.shops.setName(shop.slug, shop.name);

          if (shop != null) {
            analyticsEvent(analyticsEvents.select_content, {
              content_type: 'shop',
              item_id: shop.slug,
              user_email: user.email,
            });

            window.fbq('track', 'ViewContent');

            /** @todo Removing this for now. No special handling on shop pages. */
            // When viewing a shop page, update the search model to search
            // within this specific shop
            // searchDispatch({ type: 'set', payload: {
            //   // Don't use location
            //   useCurrentLocation: false,
            //   location: null,
            //   // Empty out query string initially
            //   query: '',
            //   // Set the shops list
            //   shops: [shop],
            // }});
          }

          setLoading(false);
        });
    } else {
      setShop(null);
      setLoading(false);
    }
  }, [slug, user.email]);

  if (!loading && shop == null) {
    return <NotFound entity="shop" />;
  }

  return (
    <>
      <HelmetWrapper
        title={loading ? 'Shop' : shop.name}
        description="Search for your favorite products across small business and buy them from the comfort of your home."
      />
      <ShopAbout loading={loading} shop={shop} />
      <FeaturedCollectionHero loading={loading} shop={shop} />
      <FeaturedProducts
        color="greyLight"
        id="featured-products"
        loading={loading}
        shop={slug}
      />
      <ShopProducts
        color="white"
        id="products"
        loading={loading}
        shop={slug}
      />
      <ShopMap loading={loading} shop={shop} />
    </>
  );
}
