/**
 * @fileoverview Implements a view for when there are zero search results.
 */

import { useState } from 'react'

import { Alert, Box, Button, Typography } from '@mui/material'

import RecommendShopDialog from '../recommend-shop-dialog'

export default function SearchFewResults (props) {
  const { count } = props
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => setShowDialog(true)
  const handleDialogClose = () => setShowDialog(false)

  return (
    <>
      <Typography variant="h3">
        {count > 0 ? 'Few' : 'No'} results found near you
      </Typography>
      <Box py={2}>
        <Alert severity="info">
          Looking for a specific shop? Recommend it to the Pebble team!
          <Box pt={1}>
            <Button
              color="inherit"
              onClick={handleClick}
              variant="outlined"
            >
              Recommend a shop
            </Button>
          </Box>
        </Alert>
      </Box>
      <RecommendShopDialog
        open={showDialog}
        onClose={handleDialogClose}
      />
    </>
  )
}
