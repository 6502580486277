/**
 * @fileoverview Implements a partner section for the What is Pebble? page.
 */

import { useParams } from 'react-router-dom'

import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function CommunityPartnerCta() {
  const { slug } = useParams()

  return (
    <Section color="white">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h1"
          >
            Part of this community?
          </Typography>
          <Typography
            color="secondary"
            component="h2"
            gutterBottom
            variant="h1"
          >
            Join today.
          </Typography>
          <Typography gutterBottom variant="body1">
            Request to join this community so more customers can find you!
          </Typography>
          <Box mt={2}>
            <LinkButton
              color="secondary"
              mode="router"
              to={`/account/shops/join/${slug}`}
            >
              Request to join
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Customer checking out on their phone"
              src="/images/partner/mission.png"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
