/**
 * @fileoverview Implements a dialog that allows a user to recommend a shop to
 * be added to Pebble.
 */

import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PebbleApi from '../lib/pebble-api';

export default function RecommendShopDialog(props) {
  const { onClose, open } = props;
  const mounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [shopLocation, setShopLocation] = useState('');
  const [shopName, setShopName] = useState('');
  const [shopWebsite, setShopWebsite] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false;
  });

  useEffect(() => {
    if (shopName.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [shopName, shopWebsite]);

  const submit = useCallback(() => {
    setLoading(true);
    
    const recommendedShop = {
      location: shopLocation,
      name: shopName,
      website: shopWebsite
    };

    PebbleApi
      .recommendShop(recommendedShop)
      .then(() => {
        if (mounted.current) {
          setShopLocation('');
          setShopName('');
          setShopWebsite('');
          setLoading(false);
          onClose && onClose();
        }
      });
  }, [onClose, shopLocation, shopName, shopWebsite]);

  const handleShopLocationChange = event => {
    setShopLocation(event.target.value);
  };

  const handleShopNameChange = event => {
    setShopName(event.target.value);
  };

  const handleShopWebsiteChange = event => {
    setShopWebsite(event.target.value);
  };

  return (
    <Dialog
      aria-labelledby="recommend-shop-dialog-title"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="recommend-shop-dialog-title">
        Recommend a shop
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          To recommend a shop to the Pebble team, please provide the shop's name, website, and location if possible.
        </DialogContentText>
        <TextField
          autoFocus
          disabled={loading}
          fullWidth
          label="Shop name"
          margin="dense"
          onChange={handleShopNameChange}
          required
          value={shopName}
        />
        <TextField
          disabled={loading}
          fullWidth
          label="Shop location"
          margin="dense"
          onChange={handleShopLocationChange}
          value={shopLocation}
        />
        <TextField
          disabled={loading}
          fullWidth
          label="Shop website"
          margin="dense"
          onChange={handleShopWebsiteChange}
          value={shopWebsite}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant={null}
        >
          Cancel
        </Button>
        <Button
          disabled={!valid || loading}
          onClick={submit}
          variant={null}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
