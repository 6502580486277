/**
 * @fileoverview Implements a cache for Pebble Shops.
 */

import Cache from './cache'

/**
 * Cache item key prefix.
 */
const KEY_PREFIX = 'shops'

/**
 * Cache item key suffix for shop names.
 */
const NAME_SUFFIX = 'name'

/**
 * Cache item key suffix for the "hide onboarding" flag.
 */
const HIDE_ONBOARDING_SUFFIX = 'hideOnboarding'

export default class ShopsCache extends Cache {
  /**
   * @function getName
   * Gets the human-readable name of the shop with the given slug.
   * @param {string} slug - Shop slug
   * @returns {string} The shop name
   */
  static getName (slug) {
    const key = `${KEY_PREFIX}.${slug}.${NAME_SUFFIX}`
    return Cache.get(key)
  }

  /**
   * @function setName
   * Sets the human-readable name of the shop with the given slug.
   * @param {string} slug - Shop slug
   * @param {string} name - Shop name
   */
  static setName (slug, name) {
    const key = `${KEY_PREFIX}.${slug}.${NAME_SUFFIX}`
    Cache.set(key, name)
  }

  /**
   * @function getHideOnboarding
   * Gets the "hide onboarding" flag for the shop with the given slug. This flag
   * indicates whether to hide the onboarding section when managing a shop that
   * has completed all onboarding steps.
   * @param {string} slug - Shop slug
   * @returns {Boolean} The "hide onboarding" flag
   */
  static getHideOnboarding (slug) {
    const key = `${KEY_PREFIX}.${slug}.${HIDE_ONBOARDING_SUFFIX}`
    return Cache.get(key)
  }

  /**
   * @function getHideOnboarding
   * Sets the "hide onboarding" flag for the shop with the given slug. This flag
   * indicates whether to hide the onboarding section when managing a shop that
   * has completed all onboarding steps. This setting does not expire.
   * @param {string} slug - Shop slug
   * @param {Boolean} hideOnboarding - Whether to hide the onboarding section
   */
  static setHideOnboarding (slug, hideOnboarding) {
    const key = `${KEY_PREFIX}.${slug}.${HIDE_ONBOARDING_SUFFIX}`
    Cache.set(key, hideOnboarding)
  }
}
