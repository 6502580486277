/**
 * @fileoverview Implements an About page.
 */

import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography';
import HelmetWrapper from './helmet-wrapper';
import Section from './section';
import PebbleApi from '../lib/pebble-api';

export default function About() {
  const [loading, setLoading] = useState(true);
  const [version, setVersion] = useState(null);

  // Load version data
  useEffect(() => {
    setLoading(true);

    PebbleApi
      .getVersion()
      .then(version => {
        setLoading(false);
        setVersion(version);
      })
  }, []);

  return (
    <>
      <HelmetWrapper
        title="About Pebble"
      />
      <Section maxWidth="md">
        <Typography variant="h1" gutterBottom>
          About Pebble
        </Typography>
        {loading &&
          <>
            <Typography variant="h2">
              <Skeleton width="70%" />
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Skeleton width="40%" />
            </Typography>
            <Typography variant="h3">
              <Skeleton width="70%" />
            </Typography>
            <Typography variant="body1">
              <Skeleton />
              <Skeleton />
              <Skeleton width="40%" />
            </Typography>
          </>
        }
        {version &&
          <>
            <Typography variant="h2">
              {version.release} Release
            </Typography>
            <Typography variant="body1" gutterBottom>
              Version {version.version}
              <br />
              {process.env.SOURCE_VERSION}
            </Typography>
            {version.changes && version.changes.length > 0 &&
              <>
                <Typography variant="h3">
                  Changes
                </Typography>
                <Typography variant="body1">
                  <ul>
                    {version.changes.map((change, index) =>
                      <li key={index}>
                        {change}
                      </li>
                    )}
                  </ul>
                </Typography>
              </>
            }
          </>
        }
      </Section>
    </>
  );
}
