/**
 * @fileoverview Implements a loading skeleton for the Item view.
 */

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

import Section from '../section'

export default function ItemSkeleton() {
  return (
    <>
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rect" width="100%" height={480} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ backgroundColor: 'grey.light' }}>
              <Box p={2}>
                <Typography
                  component="h1"
                  gutterBottom
                  variant="h4"
                >
                  <Skeleton />
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Skeleton width="40%" />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '1.33rem' }}
                  gutterBottom
                >
                  <Skeleton width="75" />
                </Typography>
                <Typography variant="body1">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width="75%" />
                </Typography>
              </Box>
            </Paper>
            <Box mt={2}>
              <Skeleton variant="rect" width={150} height={36} />
            </Box>
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
