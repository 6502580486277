import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Add as AddIcon,
  Remove as RemoveIcon
} from '@mui/icons-material'
import {
  Box,
  CardMedia,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography
} from '@mui/material'

import CartItemSkeleton from './cart-item-skeleton'

import { useUser } from '../../lib/user-context'
import debounce from '../../lib/debounce'

/**
 * A component for displaying the results of a search.
 */
export default function CartItem(props) {
  const { boxProps, item } = props
  const { removeCartItem, updateCartItemQuantity } = useUser()
  const [deleting, setDeleting] = useState(false)
  const [quantity, setQuantity] =
    useState(props.item != null ? props.item.quantity : 0)

  /**
   * @function doUpdateQuantity
   * Debounced function to update cart quantity. Debouncing it prevents
   * multiple calls being made in quick succession; it only sends the last one
   * after it hasn't been called for 500 milliseconds.
   * 
   * Wrapping this in a callback means it won't be recreated each render, so
   * the debounce will work properly.
   */
  const doUpdateQuantity = useMemo(() => debounce((cartItemId, quantity) => {
    updateCartItemQuantity(cartItemId, quantity)
  }, 500), [updateCartItemQuantity])

  // Update cart item quantity when the cart item changes
  // The cart item passed in is the source of truth for the quantity
  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  /**
   * @function handleIncrementQuantity
   * Handles increasing the quantity of the cart item by 1.
   */
  const handleIncrementQuantity = useCallback(() => {
    const newQuantity = quantity + 1
    setQuantity(newQuantity)
    doUpdateQuantity(item.cartItemId, newQuantity)
  }, [doUpdateQuantity, item.cartItemId, quantity, removeCartItem])

  /**
   * @function handleIncrementQuantity
   * Handles decreasing the quantity of the cart item by 1. If this results in a
   * quantity of zero, the cart item will be removed from the cart.
   */
  const handleDecrementQuantity = useCallback(() => {
    if (quantity > 0) {
      const newQuantity = quantity - 1
      setQuantity(newQuantity)

      if (newQuantity === 0) {
        removeCartItem(item.cartItemId)
        setDeleting(true)
      } else {
        doUpdateQuantity(item.cartItemId, newQuantity)
      }
    }
  }, [doUpdateQuantity, item.cartItemId, quantity, removeCartItem])

  if (item == null) {
    // Missing item, so bail and don't render anything
    return null
  }

  if (deleting) {
    return <CartItemSkeleton />
  }

  // Determine which image URL to use
  const imageUrl = item.images != null && item.images.length > 0 ?
    (item.variants[0].image_id != null ?
      item.images.filter(i => i.id === item.variants[0].image_id)[0].src :
      item.images[0].src) :
    item.imageUrl ?? null

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        '& + &': {
          borderTopColor: 'grey.main',
          borderTopWidth: '1px'
        }
      }}
      {...boxProps}
    >
      <CardMedia
        image={imageUrl}
        sx={{
          objectFit: 'cover',
          width: '130px',
          height: '130px',
          maxWidth: '30%',
          borderRadius: theme => theme.spacing(2),
          flexShrink: 0
        }}
        title={item.title}
      />
      <Box sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
        px: 2
      }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: 1.25,
            '& a': {
              color: 'darkGrey.main'
            },
            '& a:hover, & a:active': {
              color: 'secondary.main'
            }
          }}
          variant="body1"
        >
          <MuiLink component={Link} to={`/item/${item._id}/${item.handle}`}>
            {item.title}
          </MuiLink>
        </Typography>
        <Typography gutterBottom variant="body2">
          {item.variants[0].title}
        </Typography>
        <Typography variant="body2">
          <MuiLink component={Link} to={`/shop/${item.shop.slug}`}>
            {item.shop.name}
          </MuiLink>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <TextField
          InputProps={{
            readOnly: true,
            startAdornment: (
              <IconButton
                aria-label="remove quantity from cart"
                disabled={quantity < 0}
                onClick={handleDecrementQuantity}
                size="small"
              >
                <RemoveIcon />
              </IconButton >
            ),
            endAdornment: (
              <IconButton
                aria-label="add quantity to cart"
                onClick={handleIncrementQuantity}
                disabled={quantity === item.variants[0].inventory_quantity}
                size="small"
              >
                <AddIcon />
              </IconButton>
            )
          }}
          sx={{
            border: theme => `solid 2px ${theme.palette.secondary.main}`,
            borderRadius: theme => theme.spacing(4),
            '& .MuiInputBase-root': {
              padding: 0
            },
            '& .MuiInputBase-input': {
              color: 'secondary.main',
              fontWeight: 'bold',
              height: '1.1876em',
              padding: '6px 0 7px 0',
              textAlign: 'center',
              width: theme => theme.spacing(4)
            },
            '& .MuiIconButton-root': {
              color: 'secondary.main',
              '&.Mui-disabled': {
                color: 'grey.dark'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }}
          value={quantity}
        />
      </Box>
      <Box sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            lineHeight: 1.25,
            pb: 1,
            pl: 1
          }}
          variant="body1"
        >
          ${Number(item.variants[0].price).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  )
}
