/**
 * @fileoverview Implements a search autocomplete list.
 */

import { useEffect, useState, useRef } from 'react'

import Divider from '@mui/material/Divider'
import Grow from '@mui/material/Grow'
import HistoryIcon from '@mui/icons-material/History'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'

import debounce from '../../lib/debounce'
import PebbleApi from '../../lib/pebble-api'
import recentSearchesCache from '../../lib/caches/recent-searches'
import { ListItemButton } from '@mui/material'

const MAX_OPTIONS = 10

const getAutocompleteOptions = debounce((query, count, callback) => {
  PebbleApi
    .getAutocompleteOptions(query, count)
    .then(options => callback(options))
}, 500)

export default function SearchAutocomplete(props) {
  const { onSearch, open, sx, query } = props

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [recentSearches, setRecentSearches] = useState([])
  const mounted = useRef(false)

  const autocomplete = queryString => {
    setLoading(true)
    const newRecentSearches = recentSearchesCache.get()
    setRecentSearches(newRecentSearches)

    const count = MAX_OPTIONS - newRecentSearches.length

    getAutocompleteOptions(queryString, count, newOptions => {
      if (mounted.current) {
        setOptions(newOptions)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    if (open) {
      autocomplete(query)
    }
  }, [open, query])

  const handleSearch = query => () => {
    recentSearchesCache.add(query)
    onSearch && onSearch(query)
  }

  const showAutocomplete = open &&
    (recentSearches.length > 0 || options.length > 0)

  const showDivider = recentSearches.length > 0 &&
    (options.length > 0 || loading)

  const skeletonArray = [...Array(MAX_OPTIONS - recentSearches.length)]

  return (
    <Grow
      in={showAutocomplete}
      sx={[
        {
          left: { xs: 1, md: 2 },
          position: 'absolute',
          top: { xs: '51px', md: '56px' },
          transitionProperty: 'top',
          transitionDuration: '0.2s',
          width: {
            xs: theme => 'calc(100% - ' + theme.spacing(2) + ')',
            md: theme => 'calc(100% - ' + theme.spacing(4) + ')'
          },
          '& .MuiList-root': {
            color: 'darkGrey.light',
            p: 0
          },
          '& .MuiListItemText-root .MuiTypography-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
          '& .MuiListItemSecondaryAction-root': {
            right: 1
          },
          ...sx
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Paper elevation={2}>
        <List dense>
          {recentSearches.map((search, i) =>
            <ListItemButton
              key={`recent-search-${i}`}
              onClick={handleSearch(search)}
            >
              <ListItemText primary={search} />
              <ListItemSecondaryAction>
                <HistoryIcon />
              </ListItemSecondaryAction>
            </ListItemButton>
          )}
          {showDivider && <Divider />}
          {!loading && options.map((option, i) =>
            <ListItemButton
              key={`option-${i}`}
              onClick={handleSearch(option)}
            >
              <ListItemText primary={option} />
            </ListItemButton>
          )}
          {loading && skeletonArray.map((_, i) =>
            <ListItem key={`skeleton-${i}`}>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
          )}
        </List>
      </Paper>
    </Grow>
  )
}
