/**
 * @fileoverview Cache for recent searches.
 */

import cache from './cache';

const KEY = 'recent-searches';
const MAX_COUNT = 5;

/**
 * @function add
 * Adds a single recent search to the cache
 * @param {string} recentSearch - New recent search query to add to the cache
 */
const add = recentSearch => {
  const recentSearches = get() ?? [];

  // If the new recent search already exists in the list, remove it so it gets
  // moved to the top
  if (recentSearches.includes(recentSearch)) {
    recentSearches.splice(recentSearches.indexOf(recentSearch), 1);
  }

  // Constrain recent searches list to the maximum size
  if (recentSearches.length >= MAX_COUNT) {
    // Remove oldest recent search
    recentSearches.pop();
  }

  // Add new recent search to the front of the array
  recentSearches.unshift(recentSearch);

  set(recentSearches);
};

/**
 * @function get
 * Retrieves recent searches from the cache
 * @returns {Array<object>} List of valeus
 */
const get = () => {
  // Recent searches don't get stale, so only pass the key
  // Fallback to an empty array so caller doesn't need to handle null
  return cache.get(KEY) ?? [];
}

/**
 * @function set
 * Stores recent searches in the cache
 * @param {Array<object>} values
 */
const set = values => {
  cache.set(KEY, values);
};

const recentSearches = {
  add,
  get,
  set
};

export default recentSearches;
