/**
 * @fileoverview Implements content for the Checkout -> Payment Method step.
 */

import { useCallback, useEffect, useState } from 'react'

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  StepContent,
  Typography
} from '@mui/material'

import CheckoutStepPaymentInput from './checkout-step-payment-input'

export default function CheckoutStepPayment(props) {
  const {
    checkouts,
    completed,
    isCompleting,
    onClick,
    onComplete,
    paymentMethod: orderPaymentMethod,
    ...otherProps
  } = props
  const [isValid, setIsValid] = useState(false)
  const [formattedPaymentMethod, setFormattedPaymentMethod] = useState('')
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (orderPaymentMethod != null) {
      const message =
        `Paying with ${orderPaymentMethod.brand}
        •••• ${orderPaymentMethod.lastFour} via Stripe`
      setFormattedPaymentMethod(message)
      setIsValid(true)
    } else if (paymentMethod != null) {
      const message = `Paying with ${paymentMethod.cardType} via Stripe`
      setFormattedPaymentMethod(message)
      setIsValid(true)
    } else {
      setFormattedPaymentMethod('')
      setIsValid(false)
    }
  }, [orderPaymentMethod, paymentMethod])

  const handleClicked = () => onClick && onClick()
  const handleEditClick = () => setEditing(true)
  const handleCancelClick = () => setEditing(false)

  const handlePaymentMethodChange = useCallback(
    newPaymentMethod => setPaymentMethod(newPaymentMethod)
  , [])

  const handleCompleted = async event => {
    event.stopPropagation()
    setEditing(false)
    onComplete && onComplete(paymentMethod)
  }

  return (
    <Step {...otherProps}>
      <StepButton
        onClick={handleClicked}
        completed={completed}
      >
        Payment method
        {completed === true && (
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            {' '}
            &mdash;
            {' '}
            {formattedPaymentMethod}
          </Typography>
        )}
      </StepButton>
      <StepContent>
        <Box mt={1}>
          <Alert severity="info">
            Pay securely with Stripe.
          </Alert>
        </Box>
        <Box sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2
        }}>
          <CheckoutStepPaymentInput
            disabled={isCompleting || (!editing && orderPaymentMethod)}
            onChange={handlePaymentMethodChange}
            orderPaymentMethod={editing || isCompleting ? null : orderPaymentMethod}
          />
          {!editing && orderPaymentMethod && !isCompleting &&
            <Button onClick={handleEditClick}>Change</Button>
          }
          {editing &&
            <Button onClick={handleCancelClick}>Cancel</Button>
          }
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleCompleted}
            endIcon={
              isCompleting
                ? <CircularProgress size={20} sx={{ color: 'common.white' }} />
                : null
            }
            disabled={!isValid || isCompleting}
          >
            Use this payment method
          </Button>
        </Box>
      </StepContent>
    </Step>
  )
}
 