/**
 * @fileoverview Implements a "Featured Products" view that lists products
 * featured by a specific shop.
 */

import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SearchResult from '../search/search-result';
import SearchResultSkeleton from '../search/search-result-skeleton';
import Section from '../section';
import PebbleApi from '../../lib/pebble-api';

/**
 * A component for searching and displaying search results.
 */
export default function FeaturedProducts(props) {
  const { color, heading, id, loading: shopLoading, shop } = props;
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(false);
  let products = [];

  useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false;
  });

  useEffect(() => {
    setIsLoading(true);

    PebbleApi
      .getFeaturedProducts(shop)
      .then(products => {
        if (mounted.current) {
          setResults(products);
          setIsLoading(false);
        }
      });
  }, [shop]);

  if (!shop) {
    return null;
  }

  if (isLoading || shopLoading) {
    // Render skeleton search results while loading the featured products
    const skeletonItemCount = 8;
    products = [...Array(skeletonItemCount)].map((_, i) => (
      <Grid
        item
        key={i}
        xs={6}
        md={3}
      >
        <SearchResultSkeleton />
      </Grid>
    ));
  } else if (!results || results.length === 0) {
    // If there are no featured products, don't render anything at all
    return null;
  } else if (results && results.length > 0) {
    products = results.map(product => (
      <Grid
        item
        key={product._id}
        xs={6}
        md={3}
      >
        <SearchResult product={product} />
      </Grid>
    ));
  }

  return (
    <Section color={color} id={id}>
      <Typography variant="h2" gutterBottom>
        {isLoading || shopLoading ?
          <Skeleton width="40%" /> :
          (heading || 'Featured products')
        }
      </Typography>
      <Grid container spacing={2}>
        {products}
      </Grid>
    </Section>
  );
}
