/**
 * @fileoverview Implements a view for displaying recommended products.
 */

import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Box, Grid, Skeleton, Typography } from '@mui/material'

import SearchResult from './search/search-result'
import SearchResultSkeleton from './search/search-result-skeleton'
import Section from './section'
import { useSearch } from '../lib/search-context'
import PebbleApi from '../lib/pebble-api'
import analyticsEvent, { analyticsEvents } from '../lib/analytics'

/**
 * Number of recommended products per page
 */
const PAGE_SIZE = 4

/**
 * The `similar` mode displays recommended products that are similar to the
 * given query. For example, a product title might be passed to `props.query`
 * to provide recommended products similar to that product based on its
 * title.
 * 
 * When this mode is used, `query` MUST be provided on `props`.
 */
const MODE_SIMILAR = 'similar'

/**
 * The `from` mode displays recommended products from the given shop. For
 * example, a shop's slug could be passed to `props.slug` to provide
 * recommended products from that shop.
 * 
 * When this mode is used, `slug` MUST be provided on `props`. You can
 * optionally also provide `exclude` on `props` as an array of product IDs
 * to exclude from the results.
 */
const MODE_FROM = 'from'

/**
 * A component for searching and displaying search results.
 */
export default function RecommendedProducts(props) {
  const { color, exclude, heading, mode, query, slug } = props

  // Validate the props first
  if (mode !== MODE_FROM && mode !== MODE_SIMILAR) {
    throw new Error(`Invalid mode: "${mode}". Expected one of "from", "similar"`)
  }

  if (mode === 'similar' && (query == null || query.length === 0)) {
    throw new Error('Missing prop "query" for mode "similar"')
  }

  if (mode === 'from' && (slug == null || slug.length === 0)) {
    throw new Error('Missing prop "slug" for mode "from"')
  }

  const { ref, inView } = useInView()
  const search = useSearch()

  const mounted = useRef(false)

  const [loadedFirstPage, setLoadedFirstPage] = useState(false)
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])

  let products = []

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    if (!loadedFirstPage && inView) {
      setLoading(true)
      
      // Copy the current search model with some modifications
      // Note that the query will have been cleared out
      const searchModel = {
        ...search,
        maxPrice: null,
        minPrice: null,
        offersFreeShipping: null,
        offersLocalPickup: null
      }

      // Grab the first page of recommended products
      /** @todo Support loading additional pages */
      const pagination = { first: PAGE_SIZE }

      // Build search model based on the mode
      switch (mode) {
        case MODE_FROM:
          searchModel.shops = [{ slug }]
          break
        case MODE_SIMILAR:
        default:
          searchModel.query = query
          break
      }

      if (exclude && exclude.length > 0) {
        searchModel.exclude = exclude
      }

      PebbleApi
        .getSearchResultsConnection(searchModel, pagination)
        .then(result => {
          if (mounted.current) {
            const edges = result.edges
            const nodes = edges.map(edge => edge.node)

            analyticsEvent(analyticsEvents.view_item_list, {
              item_list_id: props.type,
              item_list_name: 'Recommended products',
              items: nodes.map(item => ({
                affiliation: item.shop.slug,
                currency: 'USD',
                item_id: item.product_id,
                item_brand: item.shop.name,
                item_name: item.title,
                // item_variant: item.variants[0].id,
                price: item.price,
                quantity: item.quantity
              }))
            })

            window.fbq('track', 'ViewContent')

            setResults(nodes)
            setLoading(false)
            setLoadedFirstPage(true)
          }
        })
    }
  }, [inView, loadedFirstPage, search, exclude, mode, props.type, query, slug])

  if (loading) {
    // Render skeleton search results while loading the featured products
    products = [...Array(PAGE_SIZE)].map((_, i) => (
      <Grid item key={i} xs={6} md={3}>
        <SearchResultSkeleton />
      </Grid>
    ))
  } else if (results && results.length > 0) {
    products = results.map(product => (
      <Grid item key={product._id} xs={6} md={3}>
        <SearchResult product={product} />
      </Grid>
    ))
  } else if (!results || results.length === 0) {
    // If there are no featured products, don't render anything at all
    return null
  }

  return (
    <Box ref={ref}>
      <Section color={color}>
        <Typography variant="h2" gutterBottom>
          {loading ?
            <Skeleton width="40%" /> :
            (heading ?? 'Recommended products')
          }
        </Typography>
        <Grid container spacing={2}>
          {products}
        </Grid>
      </Section>
    </Box>
  )
}
