/**
 * @fileoverview Implements a hero section for the Partner page.
 */

import { PlayCircleOutline as PlayCircleOutlineIcon } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function PartnerHero() {
  return (
    <Section color="gradient1">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} style={{ zIndex: 100 }}>
          <Typography variant="h1" gutterBottom>
            Become a Pebble Partner today
          </Typography>
          <Box sx={{
            alignItems: { xs: 'center', sm: 'flex-start' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            mt: 4
          }}>
            <LinkButton
              color="secondary"
              to="/signup?r=%2Faccount"
            >
              Become a partner
            </LinkButton>
            <LinkButton
              endIcon={<PlayCircleOutlineIcon />}
              mode="hash"
              to="#why-customers-love-pebble"
              variant="outlined"
            >
              Learn more
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{
            minHeight: '364px',
            position: 'relative'
          }}>
            <img
              alt="More revenue for your business"
              src="/images/partner/hero-1.png"
              style={{
                left: '50%',
                marginLeft: '-481px',
                position: 'absolute',
                top: '-174px'
              }}
            />
            <img
              alt="Attract more customers"
              src="/images/partner/hero-2.png"
              style={{
                left: '50%',
                marginLeft: '-230px',
                position: 'absolute',
                top: '-101px'
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
