/**
 * @fileoverview Implements a list of categories for a Pebble Community.
 */

import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import CommunityCategory from './community-category'
import PebbleApi from '../../lib/pebble-api'
import Section from '../section'

export default function CommunityCategories(props) {
  // Assumes `community` is non-null when rendering this component
  const { community, exclude } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const tablet = useMediaQuery(theme.breakpoints.down('md'))
  const mounted = useRef(false)

  const [categories, setCategories] = useState([])
  const [firstCategories, setFirstCategories] = useState([])
  const [remainingCategories, setRemainingCategories] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    setLoading(true)

    PebbleApi
      .getCategories()
      .then(categories => {
        if (mounted.current) {
          // Filter out excluded categories
          let newCategories = categories

          if (exclude && exclude.length) {
            newCategories = newCategories
              .filter(c => !exclude.includes(c.category))
          }

          setCategories(newCategories)
          setLoading(false)
        }
      })
  }, [exclude])

  useEffect(() => {
    var size = mobile ? 4 : (tablet ? 6 : -1)
    var newFirstCategories = size > 0 ? categories.slice(0, size) : categories
    var newRemainingCategories = size > 0 ? categories.slice(size) : []
    setFirstCategories(newFirstCategories)
    setRemainingCategories(newRemainingCategories)
  }, [categories, mobile, tablet])

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleCategoryClicked = category => () => {
    const params = new URLSearchParams({
      // Intentionally blank query string
      'q': '',
      // Category name
      'ca': category.category,
      // Pebble Community slug
      'co': community.slug
    })

    const url = '/search?' + params.toString()
    navigate(url)
  }

  if (loading) {
    return null
  }

  return (
    <Section boxProps={{ py: 2 }}>
      <Typography variant="h4">
        Categories
        {remainingCategories.length > 0 &&
          <Button
            onClick={handleShowAll}
            sx={{
              color: 'secondary.main',
              float: 'right',
              mr: -2
            }}
            variant={null}
          >
            {showAll ? 'Hide all' : 'Show all'}
          </Button>
        }
      </Typography>
      <Grid container spacing={2}>
        {firstCategories && firstCategories.map(category => (
          <Grid item key={category.category} xs={mobile ? 3 : 2}>
            <CommunityCategory
              category={category}
              onClick={handleCategoryClicked(category)}
            />
          </Grid>
        ))}
      </Grid>
      {remainingCategories.length > 0 &&
        <Collapse in={showAll} timeout={300}>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
          >
            {remainingCategories && remainingCategories.map(category => (
              <Grid item key={category.category} xs={mobile ? 3 : 2}>
                <CommunityCategory
                  category={category}
                  onClick={handleCategoryClicked(category)}
                />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      }
    </Section>
  )
}
