/**
 * @fileoverview Implements a view of a single item on a Pebble order.
 */

import { useState } from 'react'

import { Image as ImageIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

/**
 * A component for displaying a single search result product.
 */
export default function AccountOrderCheckoutItem(props) {
  const { item } = props
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  if (item == null) {
    return null
  }

  return (
    <Box sx={{
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      justifyContent: 'flex-start',
      '& img, & .img': {
        alignItems: 'center',
        backgroundColor: 'common.white',
        borderRadius: theme => theme.spacing(1),
        display: 'flex',
        flexShrink: 0,
        height: '96px',
        justifyContent: 'center',
        objectFit: 'cover',
        width: '96px'
      }
    }}>
      {
        item.image_url && !imageError
          ? <img
              alt={item.title}
              onError={handleImageError}
              src={item.image_url}
            />
          : <Box className="img">
              <ImageIcon />
            </Box>
      }
      <Box sx={{
        '& .MuiTypography-body1': {
          fontSize: '1rem',
          lineHeight: '1.3'
        },
        '& .MuiTypography-body2': {
          fontSize: '0.875rem',
          lineHeight: '1.3'
        }
      }}>
        <Typography variant="body1">
          {item.title}
        </Typography>
        <Typography gutterBottom variant="body2">
          {item.variant}
        </Typography>
        <Typography variant="body2">
          ${item.price}
        </Typography>
      </Box>
    </Box>
  )
}
