/**
 * @fileoverview Implements a "why" section for the What is Pebble? page.
 */

import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function WhatIsPebbleCommunities() {
  return (
    <Section color="greyLight">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Customer shopping on their phone"
              src="/images/what-is-pebble/what-if.png"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom variant="h2">
            Communities aren't just local either
          </Typography>
          <Typography gutterBottom variant="body1">
            Pebble allows you to support the people you care about through a number of different values. Whether its black owned, sustainably sourced, fair trade or many others, we want to connect you to the communities you love and support the entrepreneurs you care about.
          </Typography>
          <Box mt={2}>
            <LinkButton
              color="secondary"
              mode="router"
              to="/community/black-owned-nwa"
            >
              Featured community
            </LinkButton>
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
