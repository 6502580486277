/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API for user operations.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

const QUERY_USER = `
query UserQuery {
  user {
    address {
      address1
      address2
      city
      company
      country
      country_code
      first_name
      last_name
      phone
      province
      province_code
      zip
    }
    admin
    cart {
      count
      quantity
      subtotal
      items {
        cartItemId
        _id
        handle
        quantity
        added
        updated
        title
        description
        variants {
          id
          title
          price
          compare_at_price
          requires_shipping
          position
          available
          inventory_quantity
          inventory_policy
          inventory_management
          image_id
        }
        images {
          id
          position
          variant_ids
          width
          height
          src
        }
        shop {
          name
          slug
        }
      }
    }
    email
    firstName
    lastName
    manager
    partner
    wishlist {
      items {
        added
        available
        handle
        id
        imageUrl
        price
        priceWhenAdded
        productId
        shop {
          name
          slug
        }
        title
        updated
        variantId
        variantTitle
      }
    }
  }
}
`;

const QUERY_USER_EXISTS = `
query UserExistsQuery($email: String) {
  userExists(email: $email) {
    success
  }
}`

const QUERY_USER_WISHLIST = `
query UserWishlistQuery {
  user {
    wishlist {
      items {
        added
        available
        handle
        id
        imageUrl
        price
        priceWhenAdded
        productId
        shop {
          name
          slug
        }
        title
        updated
        variantId
        variantTitle
      }
    }
  }
}
`;

const MUTATION_ADD_WISHLIST_ITEM = `
mutation AddWishlistItemMutation($wishlistItem: WishlistItemInput) {
  addWishlistItem(wishlistItem: $wishlistItem) {
    id
    added
    updated
    productId
    shop {
      name
      slug
    }
    title
    handle
    variantId
    variantTitle
    available
    imageUrl
    price
    priceWhenAdded
  }
}`;

const MUTATION_REMOVE_WISHLIST_ITEM = `
mutation RemoveWishlistItemMutation($wishlistItem: WishlistItemInput) {
  removeWishlistItem(wishlistItem: $wishlistItem) {
    success
  }
}`;

const queries = {
  QUERY_USER,
  QUERY_USER_EXISTS,
  QUERY_USER_WISHLIST,
  MUTATION_ADD_WISHLIST_ITEM,
  MUTATION_REMOVE_WISHLIST_ITEM,
};

export default queries;
