/**
 * @fileoverview Implements a "Credits" page for media attribution, etc.
 */

import React from 'react';
import Typography from '@mui/material/Typography';
import HelmetWrapper from './helmet-wrapper';
import Section from './section';

const credits = [
  '"<a href="https://thenounproject.com/term/store/746403/">Store</a>" by DinosoftLab from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/phone/3763005/">Phone</a>" by Dewi Tresnasih from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/find/1543918/">find</a>" by bezier master from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/find/2254257/">find</a>" by priyanka from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/puzzle/4008229/">Puzzle</a>" by Ardi Nurfiyanto from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/fees/4002869/">fees</a>" by DvM Design from <a href="http://thenounproject.com/">the Noun Project</a>',
  // '"<a href="https://thenounproject.com/term/marketplace/3456209/">marketplace</a>" by Levi from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/empty/3973819/">Empty</a>" by Chattapat from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/report/3973747/">report</a>" by Chattapat from <a href="http://thenounproject.com/">the Noun Project</a>',
  '"<a href="https://thenounproject.com/term/offline/3036654/">offline</a>" by Alice Design from <a href="http://thenounproject.com/">the Noun Project</a>',
  'Data by ToZIcon from <a href="https://thenounproject.com/browse/icons/term/data/" target="_blank" title="Data Icons">Noun Project</a>'
];

export default function Credits() {
  return (
    <>
      <HelmetWrapper
        title="Credits"
        description="Media attribution for Pebble Marketplace"
      />
      <Section maxWidth="md">
        <Typography variant="h1" gutterBottom>
          Credits
        </Typography>
        <ul>
          {credits.map((credit, index) => 
            <li
              key={index} 
              dangerouslySetInnerHTML={{ __html: credit }}
            />
          )}
        </ul>
      </Section>
    </>
  );
}
