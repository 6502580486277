/**
 * @fileoverview Signup view.
 */

import { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { handleSuccessUrl } from '../../lib/handle-success-url'
import { useUser } from '../../lib/user-context'
import LinkButton  from '../link-button'
import PebbleApi from '../../lib/pebble-api'
import analyticsEvent, { analyticsEvents } from '../../lib/analytics'

export default function Signup(props) {
  const { partner } = props
  const { loadUser } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const mounted = useRef(false)

  const [formError, setFormError] = useState(null)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const searchParams = new URLSearchParams(location.search)
  const redirectUrl = searchParams.get('r') ?? ''

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  // Grab the optional query parameter "shop"
  // If populated, this signup view is for connecting a Pebble account to the
  // specified Shopify shop domain.
  const shopifyDomain = searchParams.get('shop')

  const handleEmailChange = event => setEmail(event.target.value)
  const handleFirstNameChange = event => setFirstName(event.target.value)
  const handleLastNameChange = event => setLastName(event.target.value)
  const handlePasswordChange = event => setPassword(event.target.value)

  const handleSubmit = () => {
    // First clear out all errors
    setFormError(null)
    setEmailError(null)
    setFirstNameError(null)
    setPasswordError(null)

    let hasError = false

    if (email == null || email.trim().length === 0) {
      setEmailError('Email address is required')
      hasError = true
    }

    if (firstName == null || firstName.trim().length === 0) {
      setFirstNameError('First name is required')
      hasError = true
    }

    if (password == null || password.trim().length === 0) {
      setPasswordError('Password is required')
      hasError = true
    }

    // If there's a validation error, bail before talking to the server
    if (hasError) {
      return
    }

    setLoading(true)

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(
          '6Ldn0G4bAAAAAOHwhde8AAVfhYpiazRho5Qygg8a',
          { action: 'submit' }
        )
        // First, verify the reCAPTCHA response token through our server
        .then(token => PebbleApi.verifyRecaptcha(token))
        .then(success => {
          if (mounted.current) {
            if (success) {
              return PebbleApi
                .signup(email, firstName, lastName, password, shopifyDomain)
            }

            setLoading(false)
            setFormError("Oops! Pebble thinks you're a robot. Please try again.")
          }

          return Promise.resolve(null)
        })
        .then(response => {
          if (mounted.current && response) {
            setLoading(false)

            if (response.status === 200) {
              // 200 OK

              // Log analytics events for Google and Facebook
              analyticsEvent(analyticsEvents.sign_up, {
                method: 'email',
                user_email: email
              })

              window.fbq('track', 'CompleteRegistration')

              // Load the newly signed up user
              loadUser()

              if (shopifyDomain) {
                // Login originated from Shopify admin, so this must be in a
                // popup and should be closed to continue connecting the
                // account
                window.close()
              } else {
                // Success, so redirect to the home page
                if (partner) {
                  setLoading(true)
                  
                  PebbleApi
                    .addShop()
                    .then(status => {
                      if (status.success) {
                        handleSuccessUrl(status.successUrl, navigate)
                      } else if (redirectUrl !== '') {
                        navigate(redirectUrl)
                      } else {
                        navigate('/')
                      }
                    })
                } else if (redirectUrl !== '') {
                  navigate(redirectUrl)
                } else {
                  navigate('/')
                }
              }
            } else {
              // 422 Unprocessable Entity
              // 500 Internal Server Error

              response.json().then(body => {
                if (body.field === 'email') {
                  setEmailError(body.message)
                } else if (body.field === 'password') {
                  setPasswordError(body.message)
                } else {
                  setFormError(body.message)
                }
              })
            }
          }
        })
    })
  }

  const handlePasswordRevealMouseDown = () => setShowPassword(true)
  const handlePasswordRevealMouseUp = () => setShowPassword(false)

  return (
    <Container maxWidth="sm">
      <Box py={8}>
        <Box pb={4}>
          <Typography variant="h1">
            Sign up
          </Typography>
          {shopifyDomain && (
            <Typography variant="body1">
              Sign up for a Pebble account to connect it to the Shopify sales channel.
            </Typography>
          )}
          {partner && (
            <Typography variant="body1">
              Sign up for a Pebble Partner account.
            </Typography>
          )}
        </Box>
        {formError &&
          <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
            {formError}
          </Alert>
        }
        <TextField
          autoComplete="email"
          disabled={loading}
          error={Boolean(emailError)}
          helperText={emailError}
          inputProps={{
            autoCapitalize: 'off',
          }}
          label="Email"
          name="email"
          onChange={handleEmailChange}
          required
          sx={{ mb: 2, width: '100%' }}
          variant="outlined"
          value={email}
        />
        <TextField
          autoComplete="given-name"
          disabled={loading}
          error={Boolean(firstNameError)}
          helperText={firstNameError}
          label="First Name"
          name="given-name"
          onChange={handleFirstNameChange}
          required
          sx={{ mb: 2, width: '100%' }}
          value={firstName}
          variant="outlined"
        />
        <TextField
          autoComplete="family-name"
          disabled={loading}
          label="Last Name"
          name="family-name"
          onChange={handleLastNameChange}
          sx={{ mb: 2, width: '100%' }}
          value={lastName}
          variant="outlined"
        />
        <FormControl
          disabled={loading}
          required
          sx={{ mb: 2, width: '100%' }}
          variant="outlined"
        >
          <InputLabel
            error={Boolean(passwordError)}
            htmlFor="signup-new-password"
          >
            Password
          </InputLabel>
          <OutlinedInput
            aria-describedby="signup-new-password-error"
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  disabled={loading}
                  disableRipple
                  edge="end"
                  onMouseDown={handlePasswordRevealMouseDown}
                  onMouseUp={handlePasswordRevealMouseUp}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            error={Boolean(passwordError)}
            id="signup-new-password"
            inputProps={{
              autoCapitalize: 'off',
            }}
            label="Password *"
            name="new-password"
            onChange={handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
            value={password}
          />
          <FormHelperText id="signup-new-password-error" error>
            {passwordError}
          </FormHelperText>
        </FormControl>
        <Button
          color="primary"
          disabled={loading}
          endIcon={loading && <CircularProgress size={24} />}
          onClick={handleSubmit}
          size="large"
          sx={{ mb: 2, width: '100%' }}
        >
          Sign up
        </Button>
        <Box pt={2}>
          <Typography variant="body1" gutterBottom>
            Already have a Pebble account?
          </Typography>
          <LinkButton
            color="primary"
            size="large"
            sx={{ mb: 2, width: '100%' }}
            to={`/login${location.search}`}
            variant="outlined"
          >
            Log in
          </LinkButton>
        </Box>
      </Box>
    </Container>
  )
}
