/**
 * @fileoverview Implements an interface for interacting with the Pebble API
 * using GraphQL queries.
 */

/**
 * An model containing search parameters.
 * @typedef {object} SearchModel
 * @property {string} query - User-entered search query
 * @property {number} [latitude] - User's current geographic latitude
 * @property {number} [longitude] - User's current geographic longitude
 * @property {number} [radius] - Search radius in meters
 */

import Cache from './cache';
import { getGeolocation } from './geolocation';
import queries from './queries/queries';
import { getSearchModelInput } from './search-context';

const PEBBLE_API_ENDPOINT = '/api';
const METERS_PER_MILE = 1609.34;

/**
 * Generic helper function to send a request to the API.
 * @param {object} body - POST body to send to the API
 * @returns {Promise} Promise with JSON response
 */
const _post = (body) => {
  return fetch(PEBBLE_API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json());
};

/**
 * @function _handleResponse
 * Generic helper function for handling API responses.
 * @param {string} expectedDataField - Name of the expected data field in a
 * successful response
 * @returns {function} Reponse handler function
 */
const _handleResponse = expectedDataField => response => {
  if (response.errors) {
    console.error(response.errors);
    return [];
  }

  if (expectedDataField) {
    return response.data[expectedDataField];
  }

  return response.data;
};

const PebbleApi = {
  /**
   * @function getActivities
   * Retrieves list of activity logs from the last 24 hours.
   * @returns {Array<object>} List of activities
   */
  getActivities: pagination => {
    return _post({
      query: queries.QUERY_ACTIVITES,
      variables: pagination
    })
      .then(_handleResponse('activitiesConnection'));
  },

  /**
   * @function getCategories
   * Retrieves list of categories and subcategories.
   * @returns {Array<object>} List of categories
   */
  getCategories: async () => {
    const categories = Cache.categories.get();

    if (categories) {
      return categories;
    }

    return await _post({ query: queries.QUERY_CATEGORIES})
      .then(response => {
        Cache.categories.set(response.data.categories);
        return response.data.categories;
      });
  },

  /**
   * @function getCommunity
   * Retrieves a Pebble Community and associated data.
   * @param {string} slug - Slug of the Pebble Community
   * @returns {object} Pebble Community data object
   */
  getCommunity: async slug => {
    return _post({
      query: queries.QUERY_COMMUNITY_EXTENDED,
      variables: { slug }
    })
      // Response data has multiple objects in it: `community`,
      // `communityBrands`, and `communityFeaturedProducts`
      .then(response => response.data);
  },

  /**
   * @function getCommunityToManage
   * Retrieves a Pebble Community and associated data for a user to manage.
   * @param {string} slug - Slug of the Pebble Community
   * @returns {object} Pebble Community data object
   */
   getCommunityToManage: async slug => {
    return _post({
      query: queries.QUERY_COMMUNITY_TO_MANAGE,
      variables: { slug }
    })
      // Response data has multiple objects in it: `community`,
      // and `communityBrands`
      .then(_handleResponse());
  },

  /**
   * @function getCommunities
   * Gets all the communities that the current user manages.
   * @returns {Promise<Array<object>>} Promise for list of communities
   */
  getCommunitiesToManage: async () => {
    return _post({
      query: queries.QUERY_COMMUNITIES,
      variables: { managed: true }
    })
      .then(_handleResponse('communities'));
  },

  /**
   * @deprecated
   * Searches products. Returns a SearchResults object with this shape:
   * 
   * {
   *   shops: [],    // Array of shop objects
   *   products: [], // Array of product objects
   * }
   * 
   * @param {SearchModel} searchModel - Model with all search parameters
   * @returns {object} Object with search results
   */
  search: (searchModel) => {
    return _post({
      query: queries.QUERY_SEARCH,
      variables: { searchModel }
    })
      .then(response => response.data.search);
  },

  /**
   * @function getSearchResultsConnection
   * Searches products with the given search model.
   * @param {SearchModel} searchModel - Model with all search parameters
   * @param {object} pagination - Object with pagination data
   * @returns {Array<object>} List of search results
   */
  getSearchResultsConnection: (searchModel, pagination) => {
    return _post({
      query: queries.QUERY_SEARCH_RESULTS_CONNECTION,
      variables: {
        searchModel: getSearchModelInput(searchModel),
        ...pagination
      }
    })
      .then(_handleResponse('searchResultsConnection'));
  },

  /**
   * @function getSimilarProductsConnection
   * Finds products similar to the product with the given ID.
   * @param {string} productId - ID of the product
   * @param {object} pagination - Object with pagination data
   * @returns {Array<object>} List of search results
   */
   getSimilarProductsConnection: (productId, pagination) => {
    return _post({
      query: queries.QUERY_SIMILAR_PRODUCTS_CONNECTION,
      variables: {
        productId,
        ...pagination
      }
    })
      .then(_handleResponse('similarProductsConnection'));
  },

  /**
   * Gets recommended products.
   * @param {SearchModel} searchModel - Model with search parameters
   */
  getRecommendedProducts: searchModel => {
    const variables = {};

    if (searchModel != null && searchModel.location != null) {
      variables.lat = searchModel.location.latitude;
      variables.lon = searchModel.location.longitude;
    }
    
    return _post({
      query: queries.QUERY_RECOMMENDED,
      variables: variables
    })
  },

  /**
   * Gets recommended searches.
   * @param {string} [community] - Optional community slug by which to filter
   * results
   */
  getRecommendedSearches: (community) => {
    let searchModelPromise;

    if (community) {
      searchModelPromise = Promise.resolve({ community });
    } else {
      searchModelPromise = getGeolocation({ mode: 'cache-ip' })
        .then(geoloc => {
          const searchModel = {};

          // Include latitude and longitude from the cache if possible
          if (geoloc) {
            searchModel.latitude = geoloc.latitude;
            searchModel.longitude = geoloc.longitude;
          } else {
            // Tell server to try to use the user's location
            searchModel.useCurrentLocation = true;
          }

          return searchModel;
        });
    }

    return searchModelPromise
      .then(searchModel => {
        return _post({
          query: queries.QUERY_RECOMMENDED_SEARCHES,
          variables: { searchModel }
        });
      })
      .then(_handleResponse('recommendedSearches'));
  },

  /**
   * @function getRecommendedShops
   * Gets recommended shops.
   * @returns {Array<object>} List of shops
   */
  getRecommendedShops: () => {
    return getGeolocation({ mode: 'cache-ip' })
      .then(geoloc => {
        // Include latitude and longitude from the cache if possible
        if (geoloc) {
          const lat = geoloc.latitude;
          const lon = geoloc.longitude;

          return _post({
            query: queries.QUERY_RECOMMENDED_SHOPS,
            variables: { lat, lon }
          });
        } else {
          return Promise.resolve({ data: { recommendedShops: [] }});
        }
      })
      .then(_handleResponse('recommendedShops'));
  },

  /**
   * @function getFeaturedProducts
   * Retrieves a list of featured products for the given shop.
   * @param {string} shopSlug - Slug of the shop for which to get products
   * @returns {Promise<Array<object>>} Promise for a list of products
   */
  getFeaturedProducts: shopSlug => {
    return _post({
      query: queries.QUERY_FEATURED_PRODUCTS,
      variables: { slug: shopSlug }
    })
      .then(response => {
        if (response.errors) {
          console.error(response.errors);
          return [];
        }

        return response.data.featuredProducts
      });
  },

  /**
   * Gets similar products.
   * @param {SearchModel} searchModel - Model with search parameters
   */
  getSimilarProducts: (searchModel, pagination) => {
    const newSearchModel = {};

    if (searchModel != null && searchModel.location != null) {
      newSearchModel.latitude = searchModel.location.latitude;
      newSearchModel.longitude = searchModel.location.longitude;
      newSearchModel.radius = searchModel.radius * METERS_PER_MILE;
    }

    newSearchModel.query = searchModel.recQuery;
    
    return _post({
      query: queries.QUERY_SEARCH_RESULTS_CONNECTION,
      variables: { searchModel: newSearchModel, ...pagination }
    })
      .then(_handleResponse('searchResultsConnection'));
  },

  /**
   * Gets all products for a specific shop.
   * @param {string} slug - Shop slug
   * @returns {Promise<object>} Promise for shop's products
   */
  getProductsForShop: slug => {
    return _post({
      query: queries.QUERY_PRODUCTS_FOR_SHOP,
      variables: { searchModel: { slugs: [slug] } }
    })
      .then(response => response.data.search);
  },

  /**
   * Gets the user's current location.
   * @param {number} latitude - The user's current geolocation latitude
   * @param {number} longitude - The user's current geolocation longitude
   */
  getGeolocation: (latitude, longitude) => {
    return _post({
      query: queries.QUERY_GEOLOCATION,
      variables: {
        lat: latitude,
        lon: longitude
      }
    })
      .then(res => {
        // Store the geolocation in the cache for faster lookup next time
        Cache.setGeolocation(res.data.geolocation);
        return res.data.geolocation;
      });
  },

  /**
   * Gets the user's current location by IP.
   * @returns {object} User's geolocation
   */
  getGeolocationByIp: () => {
    return _post({
      query: queries.QUERY_GEOLOCATION_BY_IP,
      variables: {
        useIp: true
      }
    })
      .then(res => res.data.geolocation);
  },

  /**
   * Searches for locations matching the query.
   * @param {string} query - Search string
   */
  getLocations: (query) => {
    return _post({
      query: queries.QUERY_LOCATIONS,
      variables: {
        query: query
      }
    })
      .then(res => res.data.locations);
  },

  /**
   * @function getOrders
   * Retreives the current user's Pebble orders.
   * @param {Array<string>} [statuses] - Optional list of statuses by which to
   * filter the orders
   * @param {number} [first] - Optional limit
   * @param {string} [after] - Optional cursor
   * @returns {Promise<Array<object>>} Promise for a list of orders
   */
  getOrders: (statuses, first, after) => {
    return _post({
      query: queries.QUERY_ORDERS_CONNECTION,
      variables: {
        statuses,
        first,
        after
      }
    })
      .then(_handleResponse('ordersConnection'));
  },

  /**
   * @function getProductById
   * Retrieves a single product by its ID.
   * @param {string} id - ID of the product to fetch
   * @param {boolean} managed - Whether to get this product for the current
   * user to manage
   * @returns {Promise<object>} Product object
   */
  getProductById: (id, managed) => {
    return _post({
      query: queries.QUERY_PRODUCT,
      variables: { id, managed: managed ?? false }
    })
      .then(_handleResponse('product'))
  },

  /**
   * Retrieves the current user's cart.
   */
  getCart: () => {
    return _post({
      query: queries.QUERY_CART
    })
      .then(response => response.data.cart);
  },

  /**
   * Retrieves the current user's cart status.
   */
  getCartStatus: () => {
    return _post({
      query: queries.QUERY_CART_STATUS
    })
      .then(response => response.data.cart);
  },

  /**
   * Adds a product to the current user's cart by ID
   * @param {string} productId - Pebble ID of the product to add
   * @param {string} variantId - Shopify ID of the product variant to add
   * @param {number} quantity - Quantity the product variant to add
   * @returns {Promise} Promise with current number of items in the cart
   */
  addCartItem: (productId, variantId, quantity) => {
    return _post({
      query: queries.MUTATION_ADD_CART_ITEM,
      variables: {
        cartItem: {
          productId,
          variantId,
          quantity
        }
      },
    })
      .then(response => response.data.addCartItem);
  },

  /**
   * Adds multiple products to the current user's cart by ID
   * @param {Array<object>} cartItems - List of cart item objects
   * @param {string} cartItems[].productId - Pebble ID of the product to add
   * @param {string} cartItems[].variantId - Shopify ID of the product variant
   * to add
   * @param {number} cartItems[].quantity - Quantity the product variant to add
   * @returns {Promise<object>} Promise with current cart status
   */
  addCartItems: cartItems => {
    return _post({
      query: queries.MUTATION_ADD_CART_ITEMS,
      variables: { cartItems },
    })
      .then(response => response.data.addCartItems);
  },

  /**
   * Updates the quantity of a cart item in the current user's cart.
   * @param {string} cartItemId  - ID of the cart item to update
   * @param {number} qty - New quantity for the cart item in the cart
   * @returns {Promise<object>} Updated cart status
   */
  updateCartItemQuantity: (cartItemId, qty) => {
    return _post({
      query: queries.MUTATION_UPDATE_CART_ITEM_QUANTITY,
      variables: {
        id: cartItemId,
        qty: qty
      }
    })
      .then(response => response.data.updateCartItemQuantity);
  },

  /**
   * Remove a product from the current user's cart.
   * @param {string} productId  - ID of the product to remove
   * @returns {Promise} Promise with the current number of items in the cart
   */
  removeCartItem: (productId) => {
    return _post({
      query: queries.MUTATION_REMOVE_CART_ITEM,
      variables: { id: productId }
    })
      .then(response => response.data.removeCartItem);
  },

  /**
   * Gets all shops.
   */
  getShops: () => {
    return _post({
      query: queries.QUERY_SHOPS
    })
      .then(response => response.data.shops);
  },

  /**
   * Gets a single shop by its slug.
   */
  getShopBySlug: slug => {
    return _post({
      query: queries.QUERY_SHOP,
      variables: { slug: slug }
    })
      .then(response => {
        if (response.errors) {
          console.error(response.errors);
          return null;
        }
        
        return response.data.shop;
      });
  },

  /**
   * Gets the currently logged in user.
   * @returns {object} Promise for the user object
   */
  getUser: () => {
    return _post({ query: queries.QUERY_USER })
      .then(response => response.data.user);
  },

  /**
   * @function userExists
   * Determines if a user with the given email address exists.
   * @param {string} email - User's email address
   * @returns {Promise<boolean>} Whether the user exists
   */
  getUserExists: async email => {
    const response = await _post({
      query: queries.QUERY_USER_EXISTS,
      variables: { email }
    })

    return response.data.userExists.success
  },

  /**
   * Gets the wishlist of the currently logged in user.
   * @returns {object} Promise for the user object
   */
  getWishlist: () => {
    return _post({ query: queries.QUERY_USER_WISHLIST })
      .then(_handleResponse('user'));
  },

  /**
   * @function addWishlistItem
   * Adds the given item to the currently logged in user's wishlist.
   * @param {object} wishlistItem - Wishlist item to add
   * @returns {boolean} Whether the operation succeeded
   */
  addWishlistItem: wishlistItem => {
    return _post({
      query: queries.MUTATION_ADD_WISHLIST_ITEM,
      variables: { wishlistItem }
    })
      .then(_handleResponse('addWishlistItem'));
  },

  /**
   * @function removeWishlistItem
   * Removes the wishlist item with the given ID form the currently logged in
   * user's wishlist.
   * @param {object} wishlistItem - Wishlist item to remove
   * @returns {boolean} Whether the operation succeeded
   */
  removeWishlistItem: wishlistItem => {
    return _post({
      query: queries.MUTATION_REMOVE_WISHLIST_ITEM,
      variables: { wishlistItem }
    })
      .then(_handleResponse('removeWishlistItem'));
  },

  /**
   * Saves updated form details of currently logged in user.
   * @param {object} user - user object schema
   * @returns {object} Promise for success status
   */
  saveUser: (user) => {
    return _post({
      query: queries.MUTATION_SAVE_USER,
      variables: { user: user }
    })
      .then(response => response.data.saveUser.success);
  },

  /**
   * Deletes all products for the shop with the given slug.
   * @param {string} slug - Slug of the shop to delete
   * @returns {Promise} Promise with Boolean indicating success
   */
  deleteProducts: (slug) => {
    return _post({
      query: queries.MUTATION_DELETE_PRODUCTS,
      variables: { slug: slug }
    })
      .then(response => response.data.deleteProducts.success);
  },

  /**
   * Deletes a shop by its slug.
   * @param {string} slug - Slug of the shop to delete
   * @returns {Promise} Promise with Boolean indicating success
   */
  deleteShop: (slug) => {
    return _post({
      query: queries.MUTATION_DELETE_SHOP,
      variables: { slug: slug }
    })
      .then(response => response.data.deleteShop.success);
  },

  /**
   * Deletes all data.
   * @returns {Promise} Promise with Boolean indicating success
   */
  deleteAllData: () => {
    return _post({
      query: queries.MUTATION_DELETE_ALL_DATA
    })
      .then(response => response.data.deleteAllData.success);
  },
  
  /**
   * Initiates the checkout of the current user's cart.
   * @param {Array<object>} [guestCartItems] - Optional list of cart items with
   * which to initiate the checkout if the user is not logged in
   */
  initiateCheckout: (guestCartItems) => {
    return _post({
      query: queries.MUTATION_INITIATE_CHECKOUT,
      variables: { guestCartItems }
    })
      .then(response => response.data.initiateCheckout);
  },

  /**
   * Saves the shipping address during checkout.
   * @param {string} id - Pebble order ID
   * @param {object} address - Address object
   * @returns {Promise<object>} Promise for Pebble order
   */
  saveCheckoutShippingAddress: (id, address) => {
    return _post({
      query: queries.MUTATION_SAVE_CHECKOUT_SHIPPING_ADDRESS,
      variables: {
        id: id,
        address: address
      }
    })
      .then(response => response.data.saveCheckoutShippingAddress);
  },

  /**
   * Saves the delivery methods during checkout.
   * 
   * The `deliveryMethods` shape looks like this:
   * [
   *   {
   *     token: 'checkout-token',
   *     shipping_line_handle: 'shipping_rate-id'
   *     shipping_line_title: 'Shipping Rate'
   *   },
   * ]
   * 
   * @param {string} id - Pebble order ID
   * @param {Array<object>} deliveryMethods - List of mappings of checkout
   * tokens and shipping line handles
   * @returns {Promise<object>} Promise for Pebble order
   */
  saveCheckoutDeliveryMethods: (id, deliveryMethods) => {
    return _post({
      query: queries.MUTATION_SAVE_CHECKOUT_DELIVERY_METHODS,
      variables: {
        id: id,
        deliveryMethods: deliveryMethods
      }
    })
      .then(response => response.data.saveCheckoutDeliveryMethods);
  },

  /**
   * @function saveCheckoutPaymentMethod
   * Saves the delivery methods during checkout.
   * @param {string} id - Pebble order ID
   * @param {object} paymentMethod - Payment method object
   * @returns {Promise<object>} Promise for Pebble order
   */
  saveCheckoutPaymentMethod: (id, paymentMethod) => {
    return _post({
      query: queries.MUTATION_SAVE_CHECKOUT_PAYMENT_METHOD,
      variables: {
        id,
        paymentMethod,
      }
    })
      .then(_handleResponse('saveCheckoutPaymentMethod'));
  },
  
  /**
   * @function completeCheckout
   * Completes the checkout of the current user's order.
   * @param {string} id - Pebble order ID
   * @returns {Promise<boolean>} Promise with success status
   */
  completeCheckout: id => {
    return _post({
      query: queries.MUTATION_COMPLETE_CHECKOUT,
      variables: { id },
    })
      .then(response => response.data.completeCheckout.success);
  },

  /**
   * @function getTypes
   * Gets the available product types.
   * @returns {Promise<Array<string>>} Promise with list of product types
   */
  getTypes: () => {
    let types = Cache.getTypes();

    if (!types) {
      types = [
        {
          // Human-readable label for search filters
          name: 'Products',
          // Value used on the product record `type` property
          type: 'product',
          // Slug used in search URL query
          slug: 'p',
        },
        {
          name: 'Services',
          type: 'service',
          slug: 's',
        },
      ];

      Cache.setTypes(types);
    }

    return Promise.resolve(types);
  },

  /**
   * @function getVersion
   * Gets the current Pebble version.
   * @returns {Promise<object>} Promise with current version object
   */
  getVersion: () => {
    return fetch('/version', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(response => response.json());
  },

  /**
   * @function getValues
   * Gets a list of Pebble values that shops can opt into.
   * @returns {Promise<Array<object>>} Promise with list of values
   */
  getValues: async () => {
    const values = Cache.values.get();

    if (values) {
      return values;
    }

    return await _post({ query: queries.QUERY_VALUES})
      .then(response => {
        Cache.values.set(response.data.values);
        return response.data.values;
      });
  },

  /**
   * @function recommendShop
   * Recommends a shop from a customer.
   * @param {object} recommendedShop - Object with information about the shop
   * @returns {object} Status object
   */
  recommendShop: async recommendedShop => {
    return _post({
      query: queries.MUTATION_RECOMMEND_SHOP,
      variables: { recommendedShop },
    })
      .then(response => response.data.recommendShop.success);
  },

  /**
   * @function getAutocompleteOptions
   * Gets autocomplete options for the given search query.
   * @param {string} query - Search query
   * @param {number} count - Number of autocomplete options to fetch
   */
  getAutocompleteOptions: async (query, count) => {
    return _post({
      query: queries.QUERY_AUTOCOMPLETE_OPTIONS,
      variables: { query, count },
    })
      .then(_handleResponse('autocompleteOptions'));
  },

  /*********************
   * Community Manager *
   *********************/

  /**
   * @function setCommunityPlan
   * Saves the given plan to the shop with the given slug.
   * @param {string} slug - Shop slug
   * @param {string} plan - Plan slug
   * @returns {Promise<object>} Status object
   */
  setCommunityPlan: (slug, plan) => {
    return _post({
      query: queries.MUTATION_SET_COMMUNITY_PLAN,
      variables: { slug, plan }
    })
      .then(_handleResponse('setCommunityPlan'))
  },

  /**
   * @function inviteShop
   * Invites the given shop to the community with the given slug.
   * @param {object} shop - Shop to add
   * @param {string} slug - Community slug
   * @returns {Promise<boolean>} Whether the operation succeeded
   */
   inviteShop: async (shop, slug) => {
    return _post({
      query: queries.MUTATION_INVITE_SHOP,
      variables: { shop, slug }
    })
      .then(_handleResponse('inviteShop'))
  },

  /**
   * @function generateInviteLink
   * Generates an invitation link for any shop to join the given community.
   * @param {string} slug - Community slug
   * @returns {Promise<object>} Status object with invitation link
   */
  generateInviteLink: async slug => {
    return _post({
      query: queries.MUTATION_GENERATE_INVITE_LINK,
      variables: { slug }
    })
      .then(_handleResponse('generateInviteLink'))
  },

  /**
   * @function bulkInviteShopsToCommunity
   * Uploads the given file contents to bulk invite shops to the community with
   * the given slug.
   * @param {string} slug - Community slug
   * @param {string} file - Raw file contents as a string
   * @returns {Promise<object>} Status object
   */
  bulkInviteShopsToCommunity: async (slug, file) => {
    const url = `/api/bulkinvite`
    const body = new FormData()
    body.append('community', slug)
    body.append('file', file)

    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body
    })
  },

  /**
   * @function saveCommunity
   * Saves the given community
   * @param {object} community - Community object to save
   * @returns {Promise<boolean>} Whether the save succeeded
   */
  saveCommunity: async community => {
    return _post({
      query: queries.MUTATION_SAVE_COMMUNITY,
      variables: { community }
    })
      .then(response => response.data.saveCommunity.success)
  },

  /**
   * @function saveCommunityShopTags
   * Saves the given community shop tags
   * @param {string} slug - Community slug
   * @param {string} shopSlug - Shop slug
   * @param {Array<string>} tags - Community shop tags
   * @returns {Promise<boolean>} Whether the save succeeded
   */
  saveCommunityShopTags: async (slug, shopSlug, tags) => {
    return _post({
      query: queries.MUTATION_SAVE_COMMUNITY_SHOP_TAGS,
      variables: { slug, shopSlug, tags }
    })
      .then(response => response.data.saveCommunityShopTags.success)
  },

  /**
   * @function approveRequest
   * Approves the request with the given ID to join a community.
   * @param {string} id - Request ID
   * @returns {Promise<object>} Status
   */
  approveRequest: async id => {
    return _post({
      query: queries.MUTATION_APPROVE_REQUEST,
      variables: { id }
    })
      .then(_handleResponse('approveRequest'))
  },

  /**
   * @function rejectRequest
   * Rejects the request with the given ID to join a community.
   * @param {string} id - Request ID
   * @returns {Promise<object>} Status
   */
  rejectRequest: async id => {
    return _post({
      query: queries.MUTATION_REJECT_REQUEST,
      variables: { id }
    })
      .then(_handleResponse('rejectRequest'))
  },

  /**********************
   * Account Onboarding *
   **********************/

  /**
   * @function addShop
   * Adds a new shop owned by the current user.
   * @returns {Promise<object>} Status object
   */
  addShop: () => {
    return _post({ query: queries.MUTATION_ADD_SHOP })
      .then(_handleResponse('addShop'))
  },

  /**
   * @function confirmShopLinkShared
   * Confirms the shop's link was shared to complete that onboarding step.
   * @returns {Promise<object>} Status object
   */
  confirmShopLinkShared: slug => {
    return _post({
      query: queries.MUTATION_CONFIRM_SHOP_LINK_SHARED,
      variables: { slug }
    })
      .then(_handleResponse('confirmShopLinkShared'))
  },

  /**
   * @function getInvitation
   * Gets the invitation with the given ID.
   * @param {string} id - Invitation ID
   * @returns {Promise<object>} Invitation object
   */
  getInvitation: id => {
    return _post({
      query: queries.QUERY_INVITATION,
      variables: { id }
    })
      .then(_handleResponse('invitation'))
  },

  /**
   * @function useInvitation
   * Uses the invitation with the given ID.
   * @param {string} id - Invitation ID
   * @param {string} [slug] - Optional shop slug
   * @returns {Promise<object>} Invitation object
   */
  useInvitation: (id, slug) => {
    return _post({
      query: queries.MUTATION_USE_INVITATION,
      variables: { id, slug }
    })
      .then(_handleResponse('useInvitation'))
  },

  /**
   * @function setShopPlan
   * Saves the given plan to the shop with the given slug.
   * @param {string} slug - Shop slug
   * @param {string} plan - Plan slug
   * @returns {Promise<object>} Status object
   */
  setShopPlan: (slug, plan) => {
    return _post({
      query: queries.MUTATION_SET_SHOP_PLAN,
      variables: { slug, plan }
    })
      .then(_handleResponse('setShopPlan'))
  },

  /**
   * @function setShopPlatform
   * Saves the given platform to the shop with the given slug.
   * @param {string} slug - Shop slug
   * @param {string} platform - Platform slug
   * @returns {Promise<object>} Status object
   */
  setShopPlatform: (slug, platform) => {
    return _post({
      query: queries.MUTATION_SET_SHOP_PLATFORM,
      variables: { slug, platform }
    })
      .then(_handleResponse('setShopPlatform'))
  },

  /************************
   * Account Shop Manager *
   ************************/

  /**
   * @function getProductsToManage
   * Gets products for the given shop.
   * @param {string} slug - Shop slug
   * @returns {Promise<Array<object>>} List of products
   */
  getProductsToManage: slug => {
    return _post({
      query: queries.QUERY_PRODUCTS_TO_MANAGE,
      variables: { slug }
    })
      .then(_handleResponse('products'))
  },

  /**
   * @function getShopToManage
   * Gets a single shop by its slug.
   * @param {string} slug - Shop slug
   * @returns {Promise<object>} Shop object
   */
  getShopToManage: slug => {
    if (slug) {
      return _post({
        query: queries.QUERY_SHOP_TO_MANAGE,
        variables: { slug }
      })
        .then(_handleResponse('shop'))
    } else {
      // No slug provided, so instead return an empty Shop record to be used
      // to create a new shop
      return Promise.resolve({})
    }
  },

  /**
   * @function getShopToManageOnboarding
   * Gets only the onboarding info for a single shop to manage by its slug.
   * @param {string} slug - Shop slug
   * @returns {Promise<object>} Shop object
   */
  getShopToManageOnboarding: slug => {
    if (slug) {
      return _post({
        query: queries.QUERY_SHOP_TO_MANAGE_ONBOARDING,
        variables: { slug }
      })
        .then(_handleResponse('shop'))
    } else {
      // No slug provided, so instead return an empty Shop record to be used
      // to create a new shop
      return Promise.resolve({})
    }
  },

  /**
   * @function getShopsToManage
   * Gets a list of shops managed by the current user.
   * @returns {Promise<Array<object>>} List of shop objects
   */
  getShopsToManage: () => {
    return _post({ query: queries.QUERY_SHOPS_TO_MANAGE })
      .then(_handleResponse('shops'))
  },

  /**
   * @function saveProduct
   * Saves the given product.
   * @param {string} slug - Shop slug
   * @param {object} product - Product to save
   * @returns {Promise<boolean>} Whether the save succeeded
   */
  saveProduct: (slug, product) => {
    return _post({
      query: queries.MUTATION_SAVE_PRODUCT,
      variables: { slug, product }
    })
      .then(response => response.data.saveProduct.success)
  },

  /**
   * @function saveShop
   * Saves the given shop.
   * @param {object} shop - Shop object to save
   * @returns {Promise<boolean>} Whether the save succeeded
   */
  saveShop: shop => {
    return _post({
      query: queries.MUTATION_SAVE_SHOP,
      variables: { shop }
    })
      .then(_handleResponse('saveShop'))
  },

  /**
   * @function upgradeShop
   * Upgrade shop by subscribing to the given plan.
   * @param {string} slug - Shop slug
   * @param {string} plan - Plan
   */
  upgradeShop: (slug, plan) => {
    return _post({
      query: queries.MUTATION_UPGRADE_SHOP,
      variables: { slug, plan }
    })
      .then(_handleResponse('upgradeShop'))
  },

  /**
   * @function getRequestToJoinCommunity
   * Gets the necessary community and shops data in order for the user to
   * request to join the given community.
   * @param {string} slug - Community slug
   * @returns {Promise<object>} Object with community and shops data
   */
  getRequestToJoinCommunity: slug => {
    return _post({
      query: queries.QUERY_REQUEST_TO_JOIN_COMMUNITY,
      variables: { slug }
    })
      // Return `data` object containing both `community` and `shops`
      .then(_handleResponse())
  },

  /**
   * @function requestToJoinCommunity
   * Requests the given shop to join the given community.
   * @param {string} slug - Shop slug
   * @param {string} community - Community slug
   * @returns {Promise<object>} Status object
   */
  requestToJoinCommunity: (slug, community) => {
    return _post({
      query: queries.MUTATION_REQUEST_TO_JOIN_COMMUNITY,
      variables: { slug, community }
    })
      .then(_handleResponse('requestToJoinCommunity'))
  },

  /**
   * @function requestSync
   * Requests to have products synced for the given shop.
   * @param {string} slug - Shop slug
   * @returns {Promise<object>} Status object
   */
  requestSync: slug => {
    return _post({
      query: queries.MUTATION_REQUEST_SYNC,
      variables: { slug }
    })
      .then(_handleResponse('requestSync'))
  },

  /**
   * @function subscribeForRequest
   * Subscribes to community membership for a request that was approved without
   * a subscription.
   * @param {string} id - Request ID
   * @returns {Promise<object>} Status object
   */
  subscribeForRequest: id => {
    return _post({
      query: queries.MUTATION_SUBSCRIBE_FOR_REQUEST,
      variables: { id }
    })
      .then(_handleResponse('subscribeForRequest'))
  },

  /**************************
   * Authentication Methods *
   **************************/

  /**
   * Registers a new user.
   * @param {string} email - The user's email address
   * @param {string} firstName - The user's first name
   * @param {string} [lastName] - The user's last name
   * @param {string} password - The user's password
   * @param {string} [shopifyDomain] - Optional Shopify domain to which to
   * connect this Pebble account
   * @returns {Promise<object>} Server response
   */
  signup: (email, firstName, lastName, password, shopifyDomain) => {
    return fetch('/auth/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        password,
        shopifyDomain
      })
    })
  },

  /**
   * Attempts to log in a user with the given email and password.
   * @param {string} email - The user's email address
   * @param {string} password - The user's password
   * @param {string} [shopifyDomain] - Optional Shopify domain to which to
   * connect this Pebble account
   * @returns {Promise<object>} Server's response
   */
  login: (email, password, shopifyDomain) => {
    return fetch('/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        shopifyDomain
      })
    })
  },

  /**
   * Logs out the current user.
   * @returns {Promise<object>} Server response
   */
  logout: () => {
    return fetch('/auth/logout', { method: 'POST' });
  },

  /**
   * Resets the password for the user with the given email address.
   * @param {string} email - The user's email address
   * @returns {Promise<object>} Server response
   */
  resetPassword: email => {
    return fetch('/auth/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ email })
    });
  },

  /**
   * Sets a new password for the user with the given reset password token.
   * @param {string} token - The user's reset password token
   * @param {string} password - The user's new password
   * @returns {Promise<object>} Server response
   */
  setPassword: (token, password) => {
    return fetch('/auth/set', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ token, password })
    });
  },

  /**
   * @function validateToken
   * Validates the given reset password token.
   * @param {string} token - The user's reset password token
   * @returns {Promise<object>} Server response
   */
  validateToken: (token) => {
    const params = new URLSearchParams({ token });
    
    return fetch('/auth/validate?' + params.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
  },

  /**
   * @function verifyRecaptcha
   * Verifies a Google reCAPTCHA v3 response token.
   * @param {string} token - reCAPTCHA response token
   * @returns {boolean} Whether the token was verified successfully
   */
  verifyRecaptcha: token => {
    return fetch('/auth/verify-recaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ token })
    })
      .then(response => response.json())
      .then(response => response.success);
  },

  /**********************
   * Email list methods *
   **********************/

  /**
   * @function addCustomerToEmailList
   * Adds a customer as a new member to the email list.
   * @param {object} info - Object containing customer info
   * @param {string} info.email - Customer's email address
   * @param {string} [info.firstName] - Optional customer's first name
   * @param {string} [info.lastName] - Optional customer's last name
   * @param {string} [info.birthday] - Optional customer's birthday as a date
   * string or `Date` object
   * @returns {boolean} Whether adding the customer was a success
   */
  addCustomerToEmailList: info => {
    return _post({
      query: queries.MUTATION_ADD_CUSTOMER_TO_EMAIL_LIST,
      variables: { customerInfo: info },
    })
      .then(response => response.data.addCustomerToEmailList.success);
  },

  /*****************
   * Admin methods *
   *****************/
  
  /**
   * @function sendResourceFeedback
   * Sends resource feedback for the shop with the given slug.
   * @param {string} slug - Shop slug
   * @param {boolean} success - Whether the state is success (`true`) or
   * requires action (`false`)
   * @param {string} [message] - Optional message to include. Only used and
   * required if `success` is false.
   * @returns {boolean} Whether sending the resource feedback was a success
   */
  sendResourceFeedback: (slug, success, message) => {
    return _post({
      query: queries.MUTATION_SEND_RESOURCE_FEEDBACK,
      variables: { slug, success, message }
    })
      .then(response => response.data.sendResourceFeedback.success);
  },

  /**
   * @function setShopState
   * @param {string} slug - Slug of the shop to update
   * @param {string} state - New state for the shop
   * @returns {object} Promise for success status
   */
  setShopState: (slug, state) => {
    return _post({
      query: queries.MUTATION_SET_SHOP_STATE,
      variables: { slug, state }
    })
      .then(_handleResponse('setShopState'));
  },

  /**
   * @function getResourceFeedbacks
   * Gets resource feedbacks for the shop with the given slug.
   * @param {string} slug - Shop slug
   * @returns {Array<object>} List of resource feedbacks
   */
  getResourceFeedbacks: slug => {
    return _post({
      query: queries.QUERY_RESOURCE_FEEDBACKS,
      variables: { slug }
    })
      .then(response => response.data.resourceFeedbacks);
  },

  /**
   * @function syncCollections
   * Syncs collections from Shopify for the given shop.
   * @param {string} slug - Shop slug
   * @returns {boolean} Whether the sync succeeded
   */
  syncCollections: slug => {
    return _post({
      query: queries.MUTATION_SYNC_COLLECTIONS,
      variables: { slug }
    })
      .then(response => response.data.syncCollections.success);
  },

  /**
   * @function syncProducts
   * Syncs products from Shopify for the given shop.
   * @param {string} slug - Shop slug
   * @returns {boolean} Whether the sync succeeded
   */
  syncProducts: slug => {
    return _post({
      query: queries.MUTATION_SYNC_PRODUCTS,
      variables: { slug }
    })
      .then(response => response.data.syncProducts.success);
  },

  /**
   * @function reinitializeWebhooks
   * Reinitializes webhooks with Shopify for the given shop.
   * @param {string} slug - Shop slug
   * @returns {boolean} Whether the operation succeeded
   */
  reinitializeWebhooks: slug => {
    return _post({
      query: queries.MUTATION_REINITIALIZE_WEBHOOKS,
      variables: { slug }
    })
      .then(response => response.data.reinitializeWebhooks.success);
  },

  /**
   * @function createClick
   * Creates a click record.
   * @param {string} shop - Shop slug
   * @param {string} product - Product ID
   * @param {string} community - Community slug
   * @returns {Promise<boolean>} Whether the operation succeeded
   */
  createClick: (shop, product, community) => {
    return _post({
      query: queries.MUTATION_CREATE_CLICK,
      variables: { shop, product, community }
    })
      .then(response => response.data.createClick.success)
  }
};

export default PebbleApi;
