/**
 * @fileoverview Implementation of Google Maps as a React component to show
 * a single shop's location on a map.
 */

import { useEffect, useRef } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

import { Box, Skeleton } from '@mui/material'

export default function ShopMap(props) {
  const { loading, shop } = props
  const mapRef = useRef(null)

  useEffect(() => {
    if (shop != null) {
      // Initialize the Google Maps API loader
      const loader = new Loader({
        apiKey: 'AIzaSyCH05M_bOXMSenm1LJ92x5dIw7I3DdSPJ8',
        version: 'weekly'
      })

      // Load the Google Maps API
      loader.load()
        .then(() => {
          // The shop coordinates are in a GeoJSON object in an array ordered
          // longitude then latitude.
          const coords = {
            lat: shop.location.coordinates[1],
            lng: shop.location.coordinates[0]
          }

          // Then create the map
          const mapNode = mapRef.current
          const map = new window.google.maps.Map(mapNode, {
            center: coords,
            zoom: 8
          })

          // Create a marker for this shop's location
          const infoWindow = new window.google.maps.InfoWindow({
            content: `
              <style>.gm-style-iw.gm-style-iw-c {padding: 12px 15px !important; background-color: #424B5A; color: white !important;} .gm-style-iw-a * { padding: 0px !important; } .gm-style-iw-d { overflow: auto !important; max-height: none !important; } .gm-style .gm-style-iw-t::after { background: linear-gradient(45deg,rgba(66, 75, 90,1) 50%,rgba(66, 75, 90,0) 51%,rgba(66, 75, 90,0) 100%); }</style><div style="">
              <p style="margin: 0px; margin-bottom: 5px;"><b>${shop.name}</b></p>
              ${shop.address1}<br>
              ${shop.address2 != null && shop.address2.length > 0 ? shop.address2 + '<br>' : ''}
              ${shop.city}, ${shop.province} ${shop.zip}
              </div>`
          })
          
          const marker = new window.google.maps.Marker({
            position: coords,
            map: map,
            title: shop.name
          })

          marker.addListener("click", () => {
            infoWindow.open(map, marker)
          })
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [shop])

  if (loading) {
    return (
      <Skeleton sx={{ height: '350px' }} variant="rect" />
    )
  }

  if (!shop) {
    return null
  }

  return (
    <Box ref={mapRef} sx={{ height: '350px' }} />
  )
}
