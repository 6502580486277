/**
 * @fileoverview Implements a checkout view and process for users to purchase
 * the items in their cart.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HelmetWrapper from '../helmet-wrapper';
import CheckoutStepContentSkeleton from './checkout-step-content-skeleton';
import CheckoutSummarySkeleton from './checkout-summary-skeleton';
import Section from '../section';

export default function CheckoutSkeleton() {
  return (
    <>
      <HelmetWrapper
        title="Checkout"
      />
      <Section maxWidth="lg">
        <Typography variant="h1" gutterBottom>
          Checkout
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper variant="outlined">
              <CheckoutStepContentSkeleton />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <CheckoutSummarySkeleton />
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
