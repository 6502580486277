/**
 * @fileoverview Implements a view of a single order to show within the Account
 * view.
 */

import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Box, Typography } from '@mui/material'

import AccountOrderCheckout from './account-order-checkout'

dayjs.extend(utc)

export default function AccountOrder(props) {
  const { order } = props
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let newTotal = 0

    if (order) {
      // Calculate order total
      newTotal = order.shopifyCheckouts
        .reduce((grandTotal, checkout) => {
          return grandTotal + (Number(checkout.total_price) ?? 0);
        }, 0)
    }

    setTotal(newTotal)
  }, [order])

  if (!order) {
    return null
  }

  return (
    <Box sx={{
      backgroundColor: 'grey.light',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: theme => theme.spacing(2),
      boxShadow: theme =>
        `0 ${theme.spacing(1)}px ${theme.spacing(2)}px 0 rgba(0, 0, 0, 0.05)`,
      color: 'darkGrey.main',
      overflow: 'hidden',
      '& + &': {
        mt: 2
      }
    }}>
      <Box sx={{
        alignItems: 'center',
        backgroundColor: 'grey.main',
        '& .MuiTypography-root': {
          color: 'darkGrey.main'
        },
        '& .MuiTypography-h3': {
          /** @todo Temporary until global styles are updated */
          fontSize: '1.1rem',
          letterSpacing: 'normal',
          textTransform: 'none'
        },
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        justifyContent: 'flex-start',
        p: 2
      }}>
        <Box>
          <Typography gutterBottom variant="h3">
            Placed
          </Typography>
          <Typography variant="body2">
            {dayjs.utc(Number(order.placed)).format('M/D/YYYY')}
          </Typography>
        </Box>
        <Box>
          <Typography gutterBottom variant="h3">
            Order #
          </Typography>
          <Typography variant="body2">
            {order.id}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ textAlign: 'right' }}>
          <Typography gutterBottom variant="h3">
            Total
          </Typography>
          <Typography variant="body2">
            ${total.toFixed(2)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        p: 2,
        '& .MuiTypography-h4': {
          /** @todo Temporary until global styles are updated */
          fontSize: '1rem',
          letterSpacing: 'normal',
          textTransform: 'none'
        }
      }}>
        {order.shopifyCheckouts.map(checkout => (
          <AccountOrderCheckout checkout={checkout} key={checkout.token} />
        ))}
      </Box>
    </Box>
  )
}
