/**
 * @fileoverview Implements the Home page.
 */

import Categories from './categories-grid'
import Collections from './collections'
import EmailSignupCustomer from './email-signup-customer'
import FeaturedCommunity from './featured-community'
import HelmetWrapper from './helmet-wrapper'
import Hero from './home/hero'
import RecommendedSearches from './recommended-searches/recommended-searches'
import RecommendedShops from './recommended-shops/recommended-shops'

export default function Home() {
  return (
    <>
      <HelmetWrapper
        title="Shop small made easy"
        description="Search for your favorite products across small business and buy them from the comfort of your home."
      />
      <Hero />
      <FeaturedCommunity slug="nwa-girl-gang" />
      <Collections />
      <Categories id="categories" />
      <RecommendedSearches color="grey" />
      <RecommendedShops color="greyLight" />
      <EmailSignupCustomer />
    </>
  )
}
