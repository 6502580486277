/**
 * @fileoverview Implements a "how it works" section for the Partner page.
 */

import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import PartnerHowListItem from './partner-how-list-item'
import Section from '../section'

export default function PartnerHow() {
  return (
    <Section color="white">
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            How it works
          </Typography>
          <Typography color="secondary" component="h3" variant="h4" gutterBottom>
            It’s insanely easy to get started
          </Typography>
          <Typography variant="body1">
            We know you’re busy. That’s why we made getting started on Pebble quick and painless while also giving you tools to make it maintenance-free. 
          </Typography>
          <Box sx={{
            alignItems: { xs: 'center', sm: 'flex-start' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            mt: 4
          }}>
            <LinkButton
              color="secondary"
              to="/signup?r=%2Faccount"
            >
              Get started
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <PartnerHowListItem number={1} title="Sign up">
            Create your free account and add your shop info so customers can see what makes you unique.
          </PartnerHowListItem>
          <PartnerHowListItem number={2} title="Set up">
            Add your products and services so customers can discover you. You can also use one of our automated integrations to make this seamless and maintenance-free.
          </PartnerHowListItem>
          <PartnerHowListItem number={3} title="Join communities">
            Engage with the communities that matter to your business and connect with customers who feel the same.
          </PartnerHowListItem>
        </Grid>
      </Grid>
    </Section>
  )
}
