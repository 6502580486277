/**
 * @fileoverview Implements a call-to-action section for the Partner page.
 */

import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function PartnerCta() {
  return (
    <Box sx={{ position: 'relative' }}>
      <Section color="gradient1">
        <Grid container spacing={8}>
          <Grid
            item xs={12} sm={6}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: { xs: 'auto', sm: '600px' }
            }}
          >
            <Typography variant="h2" gutterBottom>
              Ready to dive in?
              <br />
              Join the anti-Amazon revolution today.
            </Typography>
            <Box sx={{
              alignItems: { xs: 'center', sm: 'flex-start' },
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
              mt: 4
            }}>
              <LinkButton
                color="secondary"
                to="/signup?r=%2Faccount"
              >
                Sign up for free
              </LinkButton>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{
          height: '600px',
          maxWidth: '50%',
          position: { xs: 'relative', sm: 'absolute' },
          right: 0,
          top: 8
        }}>
          <img
            alt="Pebble website"
            src="/images/partner/web-screenshot.png"
          />
        </Box>
      </Section>
    </Box>
  )
}
