/**
 * @fileoverview Implements a hero section for the What is Pebble? page.
 */

import { PlayCircleOutline as PlayCircleOutlineIcon } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function WhatIsPebbleHero() {
  return (
    <Section color="gradient1">
      <Grid container spacing={4}>
        <Grid
          item xs={12} md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography color="secondary" variant="h1">
            What if shopping small was as simple as Amazon?
          </Typography>
          <Typography variant="body2">
            Pebble exists to transform the way customers discover and support small businesses. We give your the tools you need to ditch Amazon and shop small.
          </Typography>
          <Box sx={{
            alignItems: { xs: 'center', sm: 'flex-start' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            mt: 4,
            '& .MuiButton-outlined': {
              borderWidth: '2px',
              padding: '4px 14px'
            }
          }}>
            <LinkButton
              color="secondary"
              mode="router"
              to="/signup"
            >
              Get started
            </LinkButton>
            <LinkButton
              endIcon={<PlayCircleOutlineIcon />}
              mode="hash"
              to="#why-customers-love-pebble"
              variant="outlined"
            >
              Why Pebble
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{
            minHeight: { xs: 'unset', sm: '498px' },
            position: 'relative',
            '& img': {
              bottom: { xs: 0, sm: '-140px' },
              position: { xs: 'relative', sm: 'absolute' },
              right: 0,
              width: { xs: '100%', sm: 'auto' }
            }
          }}>
            <img
              alt="What if shopping small was as simple as Amazon?"
              src="/images/what-is-pebble/hero.png"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: 4,
            flexWrap: 'wrap'
          }}>
            <Typography variant="body2">
              Shop goods &amp; services from our growing
              <br />
              network of small businesses
            </Typography>
            <img alt="Onyx Coffee Lab" src="/images/brands/onyx.png" />
            <img alt="Livsn" src="/images/brands/livsn.png" />
            <img alt="Markham &amp; Fitz" src="/images/brands/markham-fitz.png" />
            <img alt="Bear Hollow Kids" src="/images/brands/bear-hollow-kids.png" />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
