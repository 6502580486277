/**
 * @fileoverview Implements a checkout view and process for users to purchase
 * the items in their cart.
 */

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

export default function CheckoutStepContentSkeleton() {
  return (
    <Box p={3}>
      <Typography variant="body1" component="p">
        <Skeleton />
        <Skeleton />
        <Skeleton width="70%" />
      </Typography>
    </Box>
  );
}
