/**
 * @fileoverview Definitions of GraphQL queries and mutations to use with the
 * Pebble API.
 * - All GraphQL queries start with "QUERY_"
 * - All GraphQL mutations start with "MUTATION_"
 */

import accountCommunityManagerQueries from './account-community-manager-queries.js'
import accountOnboardingQueries from './account-onboarding-queries'
import accountShopManagerQueries from './account-shop-manager-queries'
import checkoutQueries from './checkout-queries';
import userQueries from './user-queries';

const QUERY_ACTIVITES = `
query ActivitiesQuery($first: Int, $last: Int, $after: String, $before: String) {
  activitiesConnection(first: $first, last: $last, after: $after, before: $before) {
    edges {
      cursor
      node {
        _id
        activity
        entity
        message
        severity
        timestamp
      }
    }
    totalCount
  }
}
`;

const QUERY_CATEGORIES = `
query Categories {
  categories {
    category
    subcategories
  }
}
`;

const QUERY_COMMUNITY_EXTENDED = `
query CommunityQuery($slug: String) {
  community(slug: $slug) {
    blocks {
      type
      exclude
      searchModel {
        query
      }
    }
    imageUrl
    logoUrl
    name
    slug
  }
  communityBrands(slug: $slug) {
    logo_url
    name
    slug
  }
}
`;

const QUERY_COMMUNITY_TO_MANAGE = `
query CommunityQuery($slug: String) {
  community(slug: $slug, managed: true) {
    blocks {
      type
      exclude
      searchModel {
        query
      }
    }
    imageUrl
    logoUrl
    managers
    name
    pendingPlan
    requests {
      created
      id
      shop {
        name
        slug
      }
      state
    }
    slug
    stats {
      shopViewsLast30Days {
        counts
        total
      }
      topShopsLast30Days {
        name
        slug
        total
      }
      updated
    }
    stripeSubscriptionActive
    stripeSubscriptionEnding
    tags
  }
  communityBrands(slug: $slug, managed: true) {
    _id
    address1
    address2
    city
    communities {
      availableTags
      name
      slug
      tags
    }
    email
    facebookUrl
    instagramUrl
    logo_url
    name
    phone
    primaryContact {
      email
      name
      phone
    }
    province_code
    serviceArea
    shop_owner
    slug
    state
    tikTokUrl
    twitterUrl
    zip
  }
}
`;

const QUERY_COMMUNITIES = `
query CommunitiesQuery($managed: Boolean) {
  communities(managed: $managed) {
    name
    slug
  }
}
`;

const QUERY_SEARCH_RESULTS_CONNECTION = `
query SearchResultsConnection($searchModel: SearchModelInput, $first: Int, $after: String) {
  searchResultsConnection(searchModel: $searchModel, first: $first, after: $after) {
    edges {
      cursor
      node {
        _id
        description
        handle
        imageUrl
        maxCompareAtPrice
        maxPrice
        minPrice
        score
        shop {
          name
          offers_free_shipping
          offers_local_pickup
          slug
        }
        title
        type
      }
    }
    filteredShops {
      address1
      address2
      city
      custom_location {
        postalCode
        city
        state
        stateName
      }
      location {
        coordinates
      }
      name
      province
      slug
      zip
    }
    locationShops {
      name
      slug
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
`;

const QUERY_SIMILAR_PRODUCTS_CONNECTION = `
query SimilarProductsConnectionQuery($productId: String, $first: Int, $after: String) {
  similarProductsConnection(productId: $productId, first: $first, after: $after) {
    edges {
      cursor
      node {
        _id
        description
        handle
        imageUrl
        maxPrice
        minPrice
        score
        shop {
          name
          offers_free_shipping
          offers_local_pickup
          slug
        }
        title
      }
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
`;

/**
 * Query for main search results.
 */
const QUERY_SEARCH = `
query SearchQuery($searchModel: SearchModelInput) {
  search(searchModel: $searchModel) {
    products {
      _id
      handle
      title
      price
      description
      imageUrl
      shop {
        name
        offers_free_shipping
        offers_local_pickup
        slug
      }
    }
    supplementalProducts {
      _id
      handle
      title
      price
      description
      imageUrl
      shop {
        name
        offers_free_shipping
        offers_local_pickup
        slug
      }
    }
    shops {
      address1
      address2
      city
      custom_location {
        postalCode
        city
        state
        stateName
      }
      location {
        coordinates
      }
      name
      offers_free_shipping
      offers_local_pickup
      province
      slug
      zip
    }
  }
}
`;

/**
 * Query for recommended products.
 */
const QUERY_RECOMMENDED = `
query RecommendedProductsQuery($lat: Float, $lon: Float) {
  recommendedProducts(lat: $lat, lon: $lon) {
    _id
    handle
    title
    price
    description
    imageUrl
    shop {
      name
      slug
      location {
        coordinates
      }
    }
  }
}
`;

/**
 * Query for recommended searches.
 */
const QUERY_RECOMMENDED_SEARCHES = `
query RecommendedSearches($searchModel: SearchModelInput) {
  recommendedSearches(searchModel: $searchModel) {
    query
    imageUrl
    shop {
      name
      slug
    }
  }
}
`;

/**
 * Query for recommended shops.
 */
const QUERY_RECOMMENDED_SHOPS = `
query RecommendedShops($lat: Float, $lon: Float) {
  recommendedShops(lat: $lat, lon: $lon) {
    city
    custom_location {
      city
      state
    }
    distance
    offers_free_shipping
    offers_local_pickup
    location {
      coordinates
    }
    logo_url
    name
    province_code
    slug
  }
}
`;

/**
 * Query for recommended products.
 */
const QUERY_FEATURED_PRODUCTS = `
query FeaturedProductsQuery($slug: String) {
  featuredProducts(slug: $slug) {
    _id
    description
    handle
    imageUrl
    maxCompareAtPrice
    maxPrice
    minPrice
    shop {
      offers_free_shipping
      offers_local_pickup
      name
      slug
      location {
        coordinates
      }
    }
    title
  }
}
`;

/**
 * Query for products for a specific shop by the shop slug.
 */
const QUERY_PRODUCTS_FOR_SHOP = `
query ShopProductsQuery($searchModel: SearchModelInput) {
  search(searchModel: $searchModel) {
    products {
      _id
      handle
      title
      price
      description
      imageUrl
      shop {
        name
        offers_free_shipping
        offers_local_pickup
        slug
      }
    }
    shops {
      name
      slug
      about
      address1
      address2
      city
      province
      zip
      location {
        coordinates
      }
    }
  }
}
`;

/**
 * Query for the current user's orders.
 */
const QUERY_ORDERS_CONNECTION = `
query OrdersConnection($statuses: [String!], $first: Int, $after: String) {
  ordersConnection(statuses: $statuses, first: $first, after: $after) {
    edges {
      cursor
      node {
        created
        id
        placed
        shippingAddress {
          address1
          address2
          city
          province
          zip
        }
        shopifyCheckouts {
          discount_code
          line_items {
            id
            image_url
            price
            product_id
            quantity
            title
            variant_id
            variant_title
          }
          order_status_url
          shipping_line {
            handle
            price
            title
          }
          shop {
            name
            slug
          }
          subtotal_price
          token
          total_price
          total_tax
        }
        status
        updated
      }
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
`;

/**
 * Query for a single product by its ID.
 */
const QUERY_PRODUCT = `
query ProductQuery($id: String) {
  product(id: $id) {
    _id
    handle
    title
    maxCompareAtPrice
    maxPrice
    minPrice
    description
    imageUrl
    variants {
      id
      title
      price
      compare_at_price
      requires_shipping
      position
      available
      inventory_quantity
      inventory_policy
      inventory_management
      image_id
    }
    images {
      id
      position
      variant_ids
      width
      height
      src
      srcSet
    }
    shop {
      free_shipping_threshold
      local_pickup_timeframe
      location {
        coordinates
      }
      name
      offers_free_shipping
      offers_local_pickup
      platform
      slug
      state
    }
    type
    externalUrl
  }
}
`;

/**
 * Query for all shops.
 */
const QUERY_SHOPS = `
query ShopsQuery {
  shops {
    _id
    email
    myshopify_domain
    plan
    platform
    name
    shop_owner
    slug
    state
    updated
    user {
      email
      firstName
      lastName
    }
  }
}
`;

/**
 * Query for a single shop by its slug.
 */
const QUERY_SHOP = `
query ShopQuery($slug: String) {
  shop(slug: $slug) {
    _id
    name
    slug
    about
    address1
    address2
    city
    externalUrl
    province
    zip
    country
    custom_location {
      postalCode
      city
      state
      stateName
    }
    featuredCollection {
      description
      imageUrl
      title
    }
    free_shipping_threshold
    local_pickup_timeframe
    location {
      coordinates
    }
    logo_url
    offers_free_shipping
    offers_local_pickup
  }
}
`;

/**
 * Query for geolocation based on latitude and longitude.
 */
const QUERY_GEOLOCATION = `
query GeolocationQuery($lat: Float!, $lon: Float!) {
  geolocation(lat: $lat, lon: $lon) {
    postalCode
    city
    state
    stateName
    latitude
    longitude
  }
}
`;

/**
 * Query for geolocation based on latitude and longitude.
 */
const QUERY_GEOLOCATION_BY_IP = `
query GeolocationByIpQuery($useIp: Boolean) {
  geolocation(useIp: $useIp) {
    postalCode
    city
    state
    stateName
    latitude
    longitude
  }
}
`;

/**
 * Query for locations matching the query.
 */
const QUERY_LOCATIONS = `
query LocationsQuery($query: String) {
  locations(query: $query) {
    postalCode
    city
    state
    stateName
    latitude
    longitude
  }
}
`;

/**
 * Query for the current user's cart.
 */
const QUERY_CART = `
query CartQuery {
  cart {
    count
    quantity
    subtotal
    items {
      cartItemId
      _id
      handle
      quantity
      added
      updated
      title
      description
      variants {
        id
        title
        price
        compare_at_price
        requires_shipping
        position
        available
        inventory_quantity
        inventory_policy
        inventory_management
        image_id
      }
      images {
        id
        position
        variant_ids
        width
        height
        src
      }
      shop {
        name
        slug
      }
    }
  }
}
`;

/**
 * Query for the current user's cart status.
 */
const QUERY_CART_STATUS = `
query CartStatusQuery {
  cart {
    count
    quantity
    subtotal
  }
}
`;

/**
 * Query for a shop's resource feedbacks.
 */
const QUERY_RESOURCE_FEEDBACKS = `
query ResourceFeedbacks($slug: String) {
  resourceFeedbacks(slug: $slug) {
    messages
  }
}
`;

/**
 * Query for Pebble values that a shop can opt into.
 */
const QUERY_VALUES = `
query Values {
  values {
    name
    slug
  }
}
`;

/**
 * Query for autocomplete options.
 */
const QUERY_AUTOCOMPLETE_OPTIONS = `
query AutocompleteOptions($query: String, $count: Int) {
  autocompleteOptions(query: $query, count: $count)
}
`;

/**
 * Mutation to add a product to the current user's cart.
 * @returns {object} Cart status after adding item
 */
const MUTATION_ADD_CART_ITEM = `
mutation AddCartItem($cartItem: CartItemInput) {
  addCartItem(cartItem: $cartItem) {
    quantity
    subtotal
    items {
      cartItemId
    }
  }
}
`;

/**
 * Mutation to add multiple products to the current user's cart.
 * @returns {object} Cart status after adding items
 */
const MUTATION_ADD_CART_ITEMS = `
mutation AddCartItems($cartItems: [CartItemInput]) {
  addCartItems(cartItems: $cartItems) {
    quantity
    subtotal
    items {
      cartItemId
      _id
      handle
      quantity
      added
      updated
      title
      description
      variants {
        id
        title
        price
        compare_at_price
        requires_shipping
        position
        available
        inventory_quantity
        inventory_policy
        inventory_management
        image_id
      }
      images {
        id
        position
        variant_ids
        width
        height
        src
      }
      shop {
        name
        slug
      }
    }
  }
}
`;

/**
 * Mutation to update quantity of a product in the current user's cart.
 * @returns {number} Number of items in the cart after updating the product
 */
const MUTATION_UPDATE_CART_ITEM_QUANTITY = `
mutation UpdateCartItemQuantity($id: String!, $qty: Int) {
  updateCartItemQuantity(id: $id, qty: $qty) {
    quantity
    subtotal
  }
}
`;

/**
 * Mutation to remove a product from the current user's cart.
 * @returns {number} Number of items in the cart after removing the product
 */
const MUTATION_REMOVE_CART_ITEM = `
mutation RemoveCartItem($id: String!) {
  removeCartItem(id: $id) {
    quantity
    subtotal
  }
}
`;

/**
 * Mutation to remove a product from the current user's cart.
 * @returns {number} Number of items in the cart after removing the product
 */
const MUTATION_SAVE_USER = `
mutation SaveUser($user: UserInput) {
  saveUser(user: $user) {
    success
  }
}
`;

/**
 * Mutation to delete a shop's products.
 * @returns {boolean} Whether the operation succeeded.
 */
const MUTATION_DELETE_PRODUCTS = `
mutation DeleteProducts($slug: String!) {
  deleteProducts(slug: $slug) {
    success
  }
}
`;

/**
 * Mutation to delete a shop and its products.
 * @returns {boolean} Whether the operation succeeded.
 */
const MUTATION_DELETE_SHOP = `
mutation DeleteShop($slug: String!) {
  deleteShop(slug: $slug) {
    success
  }
}
`;

/**
 * Mutation to delete all data.
 * @returns {boolean} Whether the operation succeeded.
 */
const MUTATION_DELETE_ALL_DATA = `
mutation DeleteAllData {
  deleteAllData {
    success
  }
}
`;

/**
 * Mutation to add a customer to the email list.
 * @param {object} customerInfo - Object containing customer info
 * @param {string} customerInfo.email - Customer's email address
 * @param {string} [customerInfo.firstName] - Optional customer's first name
 * @param {string} [customerInfo.lastName] - Optional customer's last name
 * @param {string} [customerInfo.birthday] - Optional customer's birthday as a
 * date string
 */
const MUTATION_ADD_CUSTOMER_TO_EMAIL_LIST = `
mutation AddCustomerToEmailList($customerInfo: CustomerInfoInput) {
  addCustomerToEmailList(customerInfo: $customerInfo) {
    success
  }
}
`;

/**
 * Mutation to send resource feedback for the shop with the given slug.
 * @param {string} slug - Shop slug
 * @param {boolean} success - Whether the state is success (`true`) or
 * requires action (`false`)
 * @param {string} [message] - Optional message to include. Only used and
 * required if `success` is false.
 * @returns {object} Status type
 */
const MUTATION_SEND_RESOURCE_FEEDBACK = `
mutation SendResourceFeedback($slug: String, $success: Boolean, $message: String) {
  sendResourceFeedback(slug: $slug, success: $success, message: $message) {
    success
  }
}
`;

/**
 * Mutation to sync collections for the given shop.
 * @param {string} slug - Shop slug
 * @returns {Status}
 */
const MUTATION_SYNC_COLLECTIONS = `
mutation SyncCollections($slug: String) {
  syncCollections(slug: $slug) {
    success
  }
}
`;

/**
 * Mutation to sync products for the given shop.
 * @param {string} slug - Shop slug
 * @returns {Status}
 */
const MUTATION_SYNC_PRODUCTS = `
mutation SyncProducts($slug: String) {
  syncProducts(slug: $slug) {
    success
  }
}
`;

/**
 * Mutation to reinitialize webhooks for the given shop.
 * @param {string} slug - Shop slug
 * @returns {Status}
 */
const MUTATION_REINITIALIZE_WEBHOOKS = `
mutation ReinitializeWebhooks($slug: String) {
  reinitializeWebhooks(slug: $slug) {
    success
  }
}
`;

const MUTATION_SET_SHOP_STATE = `
mutation setShopState($slug: String, $state: String) {
  setShopState(slug: $slug, state: $state) {
    success
  }
}
`;

/**
 * Mutation to recommend a shop
 * @param {object} recommendedShop - Object with shop information
 * @returns {Status}
 */
const MUTATION_RECOMMEND_SHOP = `
mutation RecommendShop($recommendedShop: RecommendedShopInput) {
  recommendShop(recommendedShop: $recommendedShop) {
    success
  }
}
`;

const MUTATION_GENERATE_INVITE_LINK = `
mutation GenerateInviteLinkMutation($slug: String) {
  generateInviteLink(slug: $slug) {
    success
    successUrl
  }
}
`

const MUTATION_INVITE_SHOP = `
mutation InviteShopMutation($shop: ShopInput, $slug: String) {
  inviteShop(shop: $shop, slug: $slug) {
    success
  }
}
`

const MUTATION_SAVE_COMMUNITY = `
mutation SaveCommunityMutation($community: CommunityInput) {
  saveCommunity(community: $community) {
    success
  }
}
`

const MUTATION_SAVE_COMMUNITY_SHOP_TAGS = `
mutation SaveCommunityShopTagsMutation($slug: String, $shopSlug: String, $tags: [String]) {
  saveCommunityShopTags(slug: $slug, shopSlug: $shopSlug, tags: $tags) {
    success
  }
}
`

const MUTATION_APPROVE_REQUEST = `
mutation ApproveRequestMutation($id: String) {
  approveRequest(id: $id) {
    success
  }
}
`

const MUTATION_REJECT_REQUEST = `
mutation RejectRequestMutation($id: String) {
  rejectRequest(id: $id) {
    success
  }
}
`

const MUTATION_CREATE_CLICK = `
mutation CreateClickMutation($shop: String, $product: String, $community: String) {
  createClick(shop: $shop, product: $product, community: $community) {
    success
  }
}
`

const queries = {
  QUERY_ACTIVITES,
  QUERY_AUTOCOMPLETE_OPTIONS,
  QUERY_CATEGORIES,
  QUERY_COMMUNITY_EXTENDED,
  QUERY_COMMUNITY_TO_MANAGE,
  QUERY_COMMUNITIES,
  QUERY_SEARCH,
  QUERY_SEARCH_RESULTS_CONNECTION,
  QUERY_SIMILAR_PRODUCTS_CONNECTION,
  QUERY_RECOMMENDED,
  QUERY_RECOMMENDED_SEARCHES,
  QUERY_RECOMMENDED_SHOPS,
  QUERY_FEATURED_PRODUCTS,
  QUERY_ORDERS_CONNECTION,
  QUERY_PRODUCTS_FOR_SHOP,
  QUERY_PRODUCT,
  QUERY_RESOURCE_FEEDBACKS,
  QUERY_SHOP,
  QUERY_SHOPS,
  QUERY_GEOLOCATION,
  QUERY_GEOLOCATION_BY_IP,
  QUERY_LOCATIONS,
  QUERY_CART,
  QUERY_CART_STATUS,
  QUERY_VALUES,
  MUTATION_ADD_CART_ITEM,
  MUTATION_ADD_CART_ITEMS,
  MUTATION_APPROVE_REQUEST,
  MUTATION_UPDATE_CART_ITEM_QUANTITY,
  MUTATION_REMOVE_CART_ITEM,
  MUTATION_DELETE_PRODUCTS,
  MUTATION_DELETE_SHOP,
  MUTATION_DELETE_ALL_DATA,
  MUTATION_SAVE_USER,
  MUTATION_ADD_CUSTOMER_TO_EMAIL_LIST,
  MUTATION_CREATE_CLICK,
  MUTATION_GENERATE_INVITE_LINK,
  MUTATION_INVITE_SHOP,
  MUTATION_REJECT_REQUEST,
  MUTATION_SAVE_COMMUNITY,
  MUTATION_SAVE_COMMUNITY_SHOP_TAGS,
  MUTATION_SEND_RESOURCE_FEEDBACK,
  MUTATION_SYNC_COLLECTIONS,
  MUTATION_SYNC_PRODUCTS,
  MUTATION_SET_SHOP_STATE,
  MUTATION_RECOMMEND_SHOP,
  MUTATION_REINITIALIZE_WEBHOOKS,
  ...accountCommunityManagerQueries,
  ...accountOnboardingQueries,
  ...accountShopManagerQueries,
  ...checkoutQueries,
  ...userQueries
}

export default queries
