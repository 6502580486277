/**
 * @fileoverview Implements a hero for the home page.
 */

import { Box, Container, Typography } from '@mui/material'
import { lighten } from '@mui/material/styles'

import LinkButton from '../link-button'

export default function HomeHero() {
  return (
    <Box sx={{
      alignItems: 'stretch',
      backgroundSize: 'cover',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(/images/heroes/hero1.jpg)',
      backgroundPosition: 'center center',
      display: 'flex',
      flexDirection: 'column',
      // On mobile, make the hero the height of the whole viewport minus the
      // height of the expanded navbar
      height: 'calc(100vh - 101px)',
      justifyContent: 'center',
      maxHeight: { xs: 'auto', sm: '80vh' },
      px: 0,
      py: { xs: 6, sm: 16 },
      width: '100%',
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography
          sx={{
            color: 'grey.light',
            textShadow: theme => '0 0 ' + theme.spacing(1) + ' rgba(0, 0, 0, 0.1)',
            textTransform: 'none',
            letterSpacing: 'normal'
          }}
          variant="h1"
        >
          Shop small,<br />made simple.
        </Typography>
      </Container>
      <Box sx={{ flexGrow: 1 }} />
      <Container sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography
          sx={{
            color: 'common.white',
            maxWidth: { xs: 'auto', sm: '33vw', xl: '20vw' },
            textAlign: { xs: 'center', sm: 'left' }
          }}
          variant="body2"
        >
          Pebble is your marketplace to shop small businesses all in one place. Find the products you want from the businesses you trust and care about.
        </Typography>
        <Box sx={{
          alignItems: { xs: 'center', sm: 'flex-start' },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
          mt: 4,
        }}>
          <LinkButton
            color="secondary"
            mode="router"
            to="/what-is-pebble"
          >
            Learn more
          </LinkButton>
          <LinkButton
            mode="router"
            sx={{
              borderColor: theme => lighten(theme.palette.common.white, 0.75),
              borderWidth: '2px',
              color: 'common.white',
              padding: '4px 14px',
              '&:hover': {
                backgroundColor: theme => lighten(theme.palette.secondary.main, 0.61),
                borderColor: theme => lighten(theme.palette.secondary.main, 0.61),
                borderWidth: '2px',
                padding: '4px 14px'
              }
            }}
            to="/partner"
            variant="outlined"
          >
            Partner with us
          </LinkButton>
        </Box>
      </Container>
    </Box>
  )
}
