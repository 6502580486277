/**
 * @fileoverview Implements a footer section for the app.
 * @todo Refactor into subcomponents
 */

import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Typography
} from '@mui/material'

import { useUser } from '../lib/user-context'

export default function Footer() {
  const { user } = useUser()

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        color: 'grey.main',
        textAlign: { xs: 'center', sm: 'left' },
        width: '100%',
        '& a, & a:hover, & a:active': {
          color: 'inherit'
        },
        '& h6': {
          color: 'grey.dark'
        }
      }}
    >
      <Container maxWidth="md">
        <Box mt={4} mb={1.5} textAlign="center">
          <img
            alt="Pebble Logo"
            src="/images/logo-desktop-white_2x.png"
            style={{ height: '32px' }}
          />
        </Box>
        <Box mt={2} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box my={1}>
                <Typography variant="h6">
                  You
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    p: 0,
                    '& li': {
                      pt: 0.75,
                      fontSize: theme => theme.typography.body2.fontSize
                    }
                  }}
                >
                  {user.loggedIn &&
                    <>
                      <li>
                        <MuiLink component={Link} to="/account">My account</MuiLink>
                      </li>
                      <li>
                        <MuiLink component={Link} to="/cart">My cart</MuiLink>
                      </li>
                      {user.admin &&
                        <li>
                          <MuiLink component={Link} to="/admin">Admin</MuiLink>
                        </li>
                      }
                      <li>
                        <MuiLink component={Link} to="/logout">Log out</MuiLink>
                      </li>
                    </>
                  }
                  {!user.loggedIn &&
                    <>
                      <li>
                        <MuiLink component={Link} to="/login">Log in</MuiLink>
                      </li>
                      <li>
                        <MuiLink component={Link} to="/signup">Sign up</MuiLink>
                      </li>
                    </>
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box my={1}>
                <Typography variant="h6">
                  Partners
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    p: 0,
                    '& li': {
                      pt: 0.75,
                      fontSize: theme => theme.typography.body2.fontSize
                    }
                  }}
                >
                  <li>
                    <MuiLink component={Link} to="/partner">Sell on Pebble</MuiLink>
                  </li>
                  <li>
                    <MuiLink component={Link} to="/partner/faq">Support</MuiLink>
                  </li>
                  <li>
                    <MuiLink component={Link} to="/community/nwa-girl-gang">NWA Girl Gang</MuiLink>
                  </li>
                  <li>
                    <MuiLink component={Link} to="/community/black-owned-nwa">Black-Owned NWA</MuiLink>
                  </li>
                  <li>
                    <MuiLink component={Link} to="/community/fayetteville-ar">Fayetteville, AR</MuiLink>
                  </li>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box my={1}>
                <Typography variant="h6">
                  Social
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    p: 0,
                    '& li': {
                      pt: 0.75,
                      fontSize: theme => theme.typography.body2.fontSize
                    }
                  }}
                >
                  <li>
                    <MuiLink href="https://www.instagram.com/shopwithpebble/">Instagram</MuiLink>
                  </li>
                  <li>
                    <MuiLink href="https://www.facebook.com/shopwithpebble">Facebook</MuiLink>
                  </li>
                  <li>
                    <MuiLink href="https://www.linkedin.com/company/pebble-marketplace/">LinkedIn</MuiLink>
                  </li>
                  <li>
                    <MuiLink href="https://www.youtube.com/channel/UCcXXI5M2zWpDRkzTCsTNk3g">YouTube</MuiLink>
                  </li>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box my={1}>
                <Typography variant="h6">
                  Company
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    p: 0,
                    '& li': {
                      pt: 0.75,
                      fontSize: theme => theme.typography.body2.fontSize
                    }
                  }}
                >
                  <li>
                    <MuiLink component={Link} to="/what-is-pebble">What is Pebble?</MuiLink>
                  </li>
                  <li>
                    <MuiLink component={Link} to="/blog">Blog</MuiLink>
                  </li>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{
        color: 'grey.dark',
        borderTop: theme => '1px solid ' + theme.palette.primary.light,
      }}>
        <Container maxWidth="md">
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{
                '& a + a': {
                  ml: 3
                }
              }}>
                <Typography variant="body2">
                  <MuiLink component={Link} to="/privacy">Privacy policy</MuiLink>
                  <MuiLink component={Link} to="/terms">Terms of use</MuiLink>
                  <MuiLink component={Link} to="/credits">Credits</MuiLink>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{
                textAlign: { sm: 'right' }
              }}>
                <Typography variant="body2">
                  &copy; {(new Date().getFullYear())}
                  {' '}
                  <MuiLink href="https://shopwithpebble.com">Pebble Markets, Inc.</MuiLink>
                  <br />
                  <MuiLink href="mailto:team@pebble.shop">team@pebble.shop</MuiLink>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
