/**
 * @fileoverview Implements an overlay for the Search view to indicate that the
 * system is trying to pinpoint the user's location.
 */

import PushPinIcon from '@mui/icons-material/PushPin'
import { alpha, Box, Fade, Typography } from '@mui/material'

import '../../styles/pinpointer.css'

export default function SearchPinpointingOverlay(props) {
  const { show } = props

  return (
    <Fade in={show}>
      <Box sx={{
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 10000,
        '& .MuiSvgIcon-root': {
          color: 'secondary.main'
        }
      }}>
        <PushPinIcon className="pinpointer" />
        <Typography
          sx={{
            backgroundColor: theme => alpha(theme.palette.grey.main, 0.8),
            borderRadius: theme => theme.spacing(2),
            fontWeight: 'bold',
            px: 2,
            py: 0.5
          }}
          variant="body1"
        >
          Pinpointing your location...
        </Typography>
      </Box>
    </Fade>
  )
}
