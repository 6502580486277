/**
 * @fileoverview Implements a loading skeleton for a Pebble Community.
 */

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import Section from '../section';

export default function CommunitySkeleton() {
  return (
    <Section boxProps={{ py: 2 }}>
      <Typography variant="h4">
        <Skeleton />
      </Typography>
      <Typography variant="body1">
        <Skeleton />
        <Skeleton />
        <Skeleton width={40} />
      </Typography>
    </Section>
  );
}
