/**
 * @fileoverview Implements a view for a single category for a Pebble Community
 */

import { Box, Typography } from '@mui/material'

export default function CommunityCategory(props) {
  const { category, onClick } = props
  const lowerCaseName = category.category.toLowerCase()
  const icon = `/images/categories/icons/${lowerCaseName}.png`

  return (
    <Box
      onClick={onClick}
      sx={{
        alignItems: 'center',
        backgroundColor: 'common.white',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <Box sx={{
        alignItems: 'center',
        backgroundColor: 'secondary.main',
        borderRadius: '33px',
        color: 'common.white',
        display: 'flex',
        height: '65px',
        justifyContent: 'center',
        width: '65px'
      }}>
        <img
          alt={category.category}
          src={icon}
          style={{ width: '33px' }}
        />
      </Box>
      <Typography variant="body2">
        {category.category}
      </Typography>
    </Box>
  )
}
