/**
 * @fileoverview Implements a skeleton of the checkout summary view.
 */

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

export default function CheckoutSummarySkeleton() {
  return (
    <Paper elevation={0} sx={{ backgroundColor: 'grey.main' }}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <Skeleton width="40%" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p" gutterBottom>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
