import { useEffect, useRef, useState } from 'react'

import {
  LocationOn as LocationOnIcon,
  PersonPinCircle as PersonPinCircleIcon,
  Public as PublicIcon
} from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Button,
  FormLabel,
  Popover,
  Stack,
  TextField
} from '@mui/material'

import Cache from '../../lib/cache'
import debounce from '../../lib/debounce'
import { getGeolocation } from '../../lib/geolocation'
import PebbleApi from '../../lib/pebble-api'
import { useSnackbar } from '../../lib/snackbar-context'

/**
 * Debounced function to perform a location search.
 * Defined outside a React component so that it's only defined once and the
 * debounce will work properly.
 */
const doLocationSearch = debounce(
  /**
   * Performs a location search.
   * @param {string} query - Location search query
   * @returns {Array<object>} List of location search results
   */
  (query, callback) => {
    // Retrieve the user's current location at the time of searching
    PebbleApi
      .getLocations(query)
      .then(locations => {
        if (typeof callback === 'function') {
          callback(locations)
        }
      })
  },
  500 // Debounce time in milliseconds
)

export default function FilterLocation(props) {
  const {
    useCurrentLocation,
    location,
    onChange,
  } = props
  const mounted = useRef(false)
  const { showWarning } = useSnackbar()

  const [filterLocationAnchorEl, setFilterLocationAnchorEl] = useState(null)
  const [locationName, setLocationName] = useState('')
  const [locationIcon, setLocationIcon] = useState(null)
  const [locationSearch, setLocationSearch] = useState('')
  const [locationSearchOptions, setLocationSearchOptions] = useState([])
  const [isLocationSearchLoading, setIsLocationSearchLoading] = useState(false)
  const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  useEffect(() => {
    if (useCurrentLocation) {
      setLocationName('My current location')
      setLocationIcon(<PersonPinCircleIcon />)
    } else if (location != null) {
      let { city, state, postalCode } = location

      if (city && state) {
        setLocationName(`${city}, ${state} ${postalCode}`)
      } else {
        // No human-readable properties, so attempt to look it up from cache
        const geoloc = Cache.getGeolocation(location.latitude,
          location.longitude)
        
        if (geoloc) {
          city = geoloc.city
          state = geoloc.state
          postalCode = geoloc.postalCode
          setLocationName(`${city}, ${state} ${postalCode}`)
        } else {
          // Not in the cache, so look it up from the server
          setLocationName('Loading...')

          PebbleApi
            .getGeolocation(location.latitude, location.longitude)
            .then(geoloc => {
              if (mounted.current) {
                city = geoloc.city
                state = geoloc.state
                postalCode = geoloc.postalCode
                setLocationName(`${city}, ${state} ${postalCode}`)
              }
            })
        }
      }

      setLocationIcon(<LocationOnIcon />)
    } else {
      // A null search location means searching everywhere
      setLocationName('Everywhere')
      setLocationIcon(<PublicIcon />)
    }
  }, [useCurrentLocation, location])

  useEffect(() => {
    if (locationSearch != null && locationSearch.length > 0) {
      setIsLocationSearchLoading(true)

      doLocationSearch(locationSearch, results => {
        setLocationSearchOptions(results)
        setIsLocationSearchLoading(false)
      })
    } else {
      setLocationSearchOptions([])
    }
  }, [locationSearch])

  const handleFilterLocationClick = (event) => {
    setFilterLocationAnchorEl(event.currentTarget)
  }

  const handleFilterLocationClose = () => {
    setFilterLocationAnchorEl(null)
  }

  const handleUseMyCurrentLocationClick = () => {
    setIsLoadingCurrentLocation(true)

    // Don't use the cache when the user explicitly clicks "Use my current
    // location" to force a fresh geolocation
    getGeolocation({ mode: 'api-ip' })
      .then(geoloc => {
        if (geoloc != null) {
          onChange && onChange(geoloc, true)
        } else {
          // Current location not found, so fallback to searching everywhere
          onChange && onChange(null, false)
          showWarning("Your location couldn't be found, so we're searching everywhere for you instead. Try enabling Location Services and then searching by your current location again.")
        }

        setIsLoadingCurrentLocation(false)
        handleFilterLocationClose()
      })
  }

  const handleUseEverywhereLocationClick = () => {
    onChange && onChange(null, false)
    handleFilterLocationClose()
  }

  const handleLocationSearchChange = (_, newValue, reason) => {
    if (reason !== 'reset') {
      setLocationSearch(newValue)
    }
  }

  const handleLocationSearchSelected = (_, location) => {
    if (location != null) {
      onChange && onChange(location, false)
    }

    handleFilterLocationClose()
  }

  const getLocationSearchOptionLabel = option => {
    return `${option.city}, ${option.state}`
  }

  const isFilterLocationOpen = Boolean(filterLocationAnchorEl)
  const filterLocationId = isFilterLocationOpen
    ? 'filter-location-popover'
    : undefined

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        '& label': {
          pb: 1
        }
      }}>
        <FormLabel>
          Location
        </FormLabel>
        <Button
          aria-describedby={filterLocationId}
          fullWidth
          variant="outlined"
          onClick={handleFilterLocationClick}
          startIcon={locationIcon}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            '& + &': {
              mt: 2
            }
          }}
        >
          {locationName}
        </Button>
      </Box>
      <Popover
        id={filterLocationId}
        open={isFilterLocationOpen}
        anchorEl={filterLocationAnchorEl}
        onClose={handleFilterLocationClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack spacing={2} p={2}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={isLoadingCurrentLocation ? <CircularProgress color="inherit" size={20} /> : <PersonPinCircleIcon />}
            onClick={handleUseMyCurrentLocationClick}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            Use my current location
          </Button>
          <Button
            variant="outlined"
            disableElevation
            startIcon={<PublicIcon />}
            onClick={handleUseEverywhereLocationClick}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            Search everywhere
          </Button>
          <Autocomplete
            options={locationSearchOptions}
            loading={isLocationSearchLoading}
            getOptionLabel={getLocationSearchOptionLabel}
            onChange={handleLocationSearchSelected}
            inputValue={locationSearch}
            onInputChange={handleLocationSearchChange}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Location"
                helperText="City, State or ZIP"
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLocationSearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            }
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          />
        </Stack>
      </Popover>
    </>
  )
}
