/**
 * @fileoverview Implements delivery options for a specific shop within a
 * specific Shopify Checkout.
 */

import { useCallback, useEffect, useState } from 'react'

import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'

import AddressFormatter from '../../lib/address-formatter'

const PLD_SHIPPING_RATE_ID_RE = /Pebble Local Delivery Service-PLD-/i
const PLP_SHIPPING_RATE_ID = 'Pebble Local Pickup Service-PLP-0.00'
const PLP_SHIPPING_RATE_TITLE = 'Local In-store Pickup via Pebble'

export default function CheckoutStepDeliveryShop(props) {
  const { checkout, onChange, disabled } = props
  const [deliveryMethod, setDeliveryMethod] = useState(null)
  const [shippingLineHandle, setShippingLineHandle] = useState(null)

  useEffect(() => {
    if (checkout.discount_code?.startsWith('PEBBLELOCALPICKUP_')) {
      setShippingLineHandle(PLP_SHIPPING_RATE_ID)
    } else if (checkout.shipping_line != null) {
      setShippingLineHandle(checkout.shipping_line.handle)
    }
  }, [checkout])

  useEffect(() => {
    onChange && onChange(deliveryMethod)
  }, [deliveryMethod, onChange])

  useEffect(() => {
    const newDeliveryMethod = {
      token: checkout.token,
      shipping_line_handle: shippingLineHandle,
      shipping_line_title: checkout.shipping_rates
        .find(rate => rate.id === shippingLineHandle)
        ?.title
    }
    setDeliveryMethod(newDeliveryMethod)
  }, [checkout, shippingLineHandle])

  /**
   * @function getShippingRateLabel
   * Generates a label for the given shipping rate.
   * @param {object} shippingRate - Shipping rate object
   * @returns {object} JSX object with the shipping rate label contents
   */
  const getShippingRateLabel = shippingRate => {
    let title = shippingRate.title
    let price = '$' + shippingRate.price
  
    if (shippingRate.price === '0.00') {
      price = 'Free'
    }
  
    // Determine if this is the Pebble Local Pickup option
    if (shippingRate.id === PLP_SHIPPING_RATE_ID) {
      title = PLP_SHIPPING_RATE_TITLE;

      if (checkout.shop.local_pickup_timeframe != null) {
        const days = Number(checkout.shop.local_pickup_timeframe)
        price = days >= 0 ? `Free ${days}-Day` : 'Free Same-Day'
      }
    }
  
    return (
      <span>
        {title}
        {' '}
        &mdash;
        {' '}
        <b>{price}</b>
      </span>
    )
  }
  
  /**
   * @function getShippingRateExtraInfo
   * Generates JSX for any additional information that may need to be displayed
   * for the given shipping rate, such as the address for Pebble Local Pickup.
   * @param {object} shippingRate - Shipping rate object
   * @returns {object} JSX object with extra info to display below the label
   */
  const getShippingRateExtraInfo = shippingRate => {
    // Special handling for Pebble Local Pickup shipping rate option
    /**
     * @todo Also handle manually created shipping rate for those shops that
     * can't use a carrier service.
     */
    if (shippingRate.id === PLP_SHIPPING_RATE_ID) {
      // Shop object contains address properties directly
      /** @todo Change this once multiple locations are supported */
      const address = AddressFormatter.toMultiLine(checkout.shop)
      return <p sx={{ mt: 0, ml: '31px' }}>{address}</p>
    }
    
    return null
  }

  const handleShippingLineChange = useCallback(event => {
    setShippingLineHandle(event.target.value)
  }, [])

  const itemCount = checkout.line_items
    .reduce((acc, cur) => acc + Number(cur.quantity), 0)
  const plural = itemCount > 1

  // Manually show this if the shop offers it, regardless of what Shopify
  // provides
  const localPickupRates = checkout.shop.offers_local_pickup ?
    [
      {
        id: PLP_SHIPPING_RATE_ID,
        title: PLP_SHIPPING_RATE_TITLE,
        price: '0.00'
      }
    ] :
    []

  const localDeliveryRates = checkout.shop.offersLocalDelivery ?
    checkout.shipping_rates
      .filter(rate => PLD_SHIPPING_RATE_ID_RE.test(rate.id))
    : []
  
  // Exclude Pebble Local Pickup rates from shipping rates, even if Shopify
  // provides them (e.g. manual or automatically created shipping rates, which
  // aren't needed anymore)
  const shippingRates = checkout.shipping_rates.filter(rate =>
    !PLD_SHIPPING_RATE_ID_RE.test(rate.id) && rate.id !== PLP_SHIPPING_RATE_ID
  )

  // Determine if there are no delivery methods available for this shop
  const noDeliveryMethods = localPickupRates.length === 0 &&
    localDeliveryRates.length === 0 && shippingRates.length === 0

  return (
    <Box my={2}>
      <Typography variant="h6" gutterBottom>
        {itemCount} item{plural ? 's' : ''} from {checkout.shop.name}
      </Typography>
      {noDeliveryMethods &&
        <Alert severity="error">
          No delivery methods were provided by {checkout.shop.name} for these items!
        </Alert>
      }
      {localPickupRates.length > 0 &&
        <Paper sx={{ '& + &': { mt: 1 } }} variant="outlined">
          <Box p={2}>
            <FormControl component="fieldset" disabled={disabled}>
              <FormLabel component="legend">
                Local pickup
              </FormLabel>
              <RadioGroup
                aria-label="local pickup delivery method"
                name={`delivery-method-${checkout.shop.slug}`}
                value={shippingLineHandle}
                onChange={handleShippingLineChange}
              >
                {localPickupRates.map(shipping_rate =>
                  <Box key={shipping_rate.id}>
                    <FormControlLabel
                      value={shipping_rate.id}
                      control={<Radio />}
                      label={getShippingRateLabel(shipping_rate)}
                    />
                    {getShippingRateExtraInfo(shipping_rate)}
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      }
      {localDeliveryRates.length > 0 &&
        <Paper sx={{ '& + &': { mt: 1 } }} variant="outlined">
          <Box p={2}>
            <FormControl component="fieldset" disabled={disabled}>
              <FormLabel component="legend">
                Local delivery
              </FormLabel>
              <RadioGroup
                aria-label="local delivery method"
                name={`delivery-method-${checkout.shop.slug}`}
                value={shippingLineHandle}
                onChange={handleShippingLineChange}
              >
                {localDeliveryRates.map(shipping_rate =>
                  <Box key={shipping_rate.id}>
                    <FormControlLabel
                      value={shipping_rate.id}
                      control={<Radio />}
                      label={getShippingRateLabel(shipping_rate)}
                    />
                    {getShippingRateExtraInfo(shipping_rate)}
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      }
      {shippingRates.length > 0 &&
        <Paper sx={{ '& + &': { mt: 1 } }} variant="outlined">
          <Box p={2}>
            <FormControl component="fieldset" disabled={disabled}>
              <FormLabel component="legend">
                Shipping
              </FormLabel>
              <RadioGroup
                aria-label="shipping delivery method"
                name={`delivery-method-${checkout.shop.slug}`}
                value={shippingLineHandle}
                onChange={handleShippingLineChange}
              >
                {shippingRates.map(shipping_rate =>
                  <Box key={shipping_rate.id}>
                    <FormControlLabel
                      value={shipping_rate.id}
                      control={<Radio />}
                      label={getShippingRateLabel(shipping_rate)}
                    />
                    {getShippingRateExtraInfo(shipping_rate)}
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      }
    </Box>
  )
}
