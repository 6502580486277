/**
 * @fileoverview Implements a component to share the current page. On mobile,
 * this will use native sharing functionality. On desktop, or devices with no
 * sharing functionality, it will copy the link to the clipboard.
 */

import { useCallback, useState } from 'react'

import IosShareIcon from '@mui/icons-material/IosShare'
import { IconButton } from '@mui/material'

import SnackbarAlert from './snackbar-alert'

export default function ShareButton (props) {
  const { title, text } = props

  const [showCopySuccess, setShowCopySuccess] = useState(false)
  const [showCopyFailure, setShowCopyFailure] = useState(false)

  /**
   * @function handleShareClick
   * Handles clicking the share button.
   */
  const handleShareClick = useCallback(() => {
    const url = window.location.href

    if (navigator.share) {
      // Use native share functionality
      navigator
        .share({ title, text, url })
        .catch(error => {
          console.error("Error sharing link", error)
        })
    } else if (navigator.clipboard) {
      // Copy link to clipboard
      navigator.clipboard
        .writeText(url)
        .then(
          () => {
            setShowCopySuccess(true)
            setShowCopyFailure(false)
          },
          () => {
            setShowCopyFailure(true)
            setShowCopySuccess(false)
          }
        )
    }
  }, [title, text])

  /**
   * @function closeSnackbars
   * Handles closing all snackbars.
   */
  const closeSnackbars = useCallback(() => {
    setShowCopyFailure(false)
    setShowCopySuccess(false)
  }, [])

  if (!navigator.share && !navigator.clipboard) {
    // No way to share!
    return null
  }

  return (
    <>
      <IconButton
        aria-label="share"
        color="secondary"
        onClick={handleShareClick}
      >
        <IosShareIcon />
      </IconButton>
      <SnackbarAlert
        onClose={closeSnackbars}
        open={showCopySuccess}
        severity="success"
      >
        Copied link to clipboard!
      </SnackbarAlert>
      <SnackbarAlert
        onClose={closeSnackbars}
        open={showCopyFailure}
        severity="error"
      >
        Error copying link to clipboard
      </SnackbarAlert>
    </>
  )
}
