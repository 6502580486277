import { useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse the URL query string, that
 * is, everything after "?" in the URL.
 */
export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * A custom hook that builds on useLocation to parse the URL hash, that
 * is, everything after "#" in the URL.
 */
export const useHash = () => {
  return new URLSearchParams(useLocation().hash.slice(1));
};
