/**
 * @fileoverview Implements a desktop view of search filters.
 */

import { Paper } from '@mui/material'

import Filters from './filters'

export default function DesktopFilters () {
  return (
    <Paper sx={{ backgroundColor: 'common.white' }} variant="outlined">
      <Filters />
    </Paper>
  )
}
