/**
 * @fileoverview Implements a view of a single brand for a Pebble Community.
 */

export default function CommunityBrand(props) {
  const { onClick, shop } = props

  return (
    <div
      onClick={onClick}
      sx={{
        backgroundColor: 'grey.main',
        borderRadius: theme => theme.spacing(2),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        p: 1,
        textAlign: 'center'
      }}
    >
      {shop.logo_url
        ? <img
            alt={shop.name}
            src={shop.logo_url}
            style={{
              height: '100%',
              objectFit: 'contain',
              width: '100%'
            }}
          />
        : <span>{shop.name}</span>
      }
    </div>
  )
}
