/**
 * @fileoverview Implements a robust wrapper for retrieving the user's current
 * geolocation.
 */

import dayjs from 'dayjs'

import Cache from './cache';
import PebbleApi from './pebble-api';

/**
 * Geolocation result model.
 * @typedef {object} Geolocation
 * @property {string} postalCode - Postal code (ZIP code), e.g. '98109'
 * @property {string} city - City name, e.g. 'Seattle'
 * @property {string} state - State abbreviation, e.g. 'WA'
 * @property {string} stateName - Full state name, e.g. 'Washington'
 * @property {number} latitude - Geographic coordinate latitude
 * @property {number} longitude - Geographic coordinate longitude
 * @property {string} mode - Mode used to retrieve the geolocation data
 */

/**
 * Options to be passed when calling `getGeolocation()`.
 * @typedef {object} GeolocationOptions
 * @property {string} mode - Geolocation lookup mode to use. Valid modes:
 * - 'cache' - Tries to use cache only
 * - 'api' - Tries to use Geolocation API only
 * - 'ip' - Tries to use IP address only
 * - 'api-ip' - Tries to use Geolocation API, then IP address
 * - 'cache-api' - Tries to use cache, then Geolocation API
 * - 'cache-ip' - Tries to use cache, then IP address
 * - 'cache-api-ip' - Tries to use cache, then Geolocation API, then IP address
 * @property {boolean} shouldExpire - Whether a cached geolocation should ever
 * expire
 * @property {number} expiry - Number of minutes until a cached geolocation is
 * expired
 * @property {string} cacheKey - Cache key for the geolocation data.
 * @property {string} cacheKeyExpires - Cache key for when geolocation cache
 * expires.
 * @property {boolean} verbose - Whether to log verbosely to the console. Only
 * used for debugging.
 */

/**
 * Default options
 * @type {GeolocationOptions}
 */
const DEFAULT_OPTIONS = {
  mode: 'cache-api-ip',
  shouldExpire: true,
  expiry: 1440, // 1 day in minutes
  cacheKey: 'geolocation',
  cacheKeyExpires: 'geolocation-expires',
  verbose: true,
};

/**
 * Existing promises to get geolocation for different option configurations.
 * Stored to avoid multiple attempts to get geolocation at the same time.
 */
let getGeolocationPromises = [];

/**
 * Attempts to get the user's geolocation from cache. Returns null if no cached
 * location information is available.
 * @param {GeolocationOptions} options - Options to use
 * @returns {Geolocation} User's geolocation from cache
 */
const getGeolocationFromCache = async options => {
  // First check if geolocation is already cached in session storage
  const cachedGeoloc = sessionStorage.getItem(options.cacheKey);
  const geolocExpires = sessionStorage.getItem(options.cacheKeyExpires);

  // This will be null if geolocation isn't stored at all
  if (cachedGeoloc != null) {
    let expired = false;

    // See if the geolocation is expired and should be rechecked
    if (options.shouldExpire &&
        geolocExpires != null &&
        dayjs(geolocExpires) < dayjs()) {
      expired = true;

      // Since it's expired, remove it from the cache
      sessionStorage.removeItem(options.cacheKey);
      sessionStorage.removeItem(options.cacheKeyExpires);

      options.verbose && console.info('[geolocation -> cache] Expired geolocation removed');
    }

    // Only return the cached geolocation if it's not expired, otherwise
    // continue and look it up again
    if (!expired) {
      options.verbose && console.info('[geolocation -> cache] Fresh geolocation found', cachedGeoloc);
      const parsedGeoloc = JSON.parse(cachedGeoloc);
      return { ...parsedGeoloc, mode: 'cache' };
    }
  }

  options.verbose && console.info('[geolocation -> cache] No fresh geolocation found');
  return null;
};

/**
 * Attempts to get the user's geolocation using the Geolocation API.
 * @param {GeolocationOptions} options - Options to use
 * @returns {Promise<Geolocation>} Promise for the user's geolocation
 */
const getGeolocationFromApi = async options => {
  return new Promise(async resolve => {
    if ('geolocation' in navigator) {
      // Handle a successful retrieval of the geolocation
      const handleSuccess = async position => {
        // Make a call to the API to get location from coordinates
        const geoloc = await PebbleApi
          .getGeolocation(position.coords.latitude, position.coords.longitude);
        
        options.verbose && console.info('[geolocation -> API] Geolocation found', geoloc);
      
        resolve({ ...geoloc, mode: 'api' });
      };
      
      // Handle a failure to retrieve geolocation without high accuracy
      const handleFailure = async error => {
        options.verbose && console.warn('[geolocation -> API] ', error.code, error.message);
        resolve(null);
      };

      // Options for retrieving geolocation
      // Setting a timeout prevents it from waiting indefinitely for a result
      const apiOptions = {
        enableHighAccuracy: false,
        maximumAge: 3600000, // 1 hour
        timeout: 5000 // 5 seconds. Longer than this might annoy the user
      };

      // Get the current location from the Geolocation API
      navigator.geolocation.getCurrentPosition(
        handleSuccess,
        handleFailure,
        apiOptions);
    }
  });
};

/**
 * Gets the user's geolocation based on their IP address.
 * @param {GeolocationOptions} options - Options to use
 * @returns {Geolocation} User's geolocation based on IP
 */
const getGeolocationFromIp = async options => {
  const geoloc = await PebbleApi
    .getGeolocationByIp();

  if (geoloc == null || geoloc.latitude == null || geoloc.longitude == null) {
    options.verbose && console.warn('[geolocation -> IP] Geolocation not found');
    return null;
  }

  options.verbose && console.info('[geolocation -> IP] Geolocation found', geoloc);
  return { ...geoloc, mode: 'ip' };
};

/**
 * Gets the end user's current geolocation.
 * @param {GeolocationOptions} [options] - Options to use
 * @returns {Promise<Geolocation>} Promise for the user's geolocation
 */
export const getGeolocation = async (options) => {
  // Backfill with default options
  options = {
    ...DEFAULT_OPTIONS,
    ...options
  };

  const optionsHash = JSON.stringify(options);

  // If there's an existing promise for these options, return that instead
  // if (getGeolocationPromises[optionsHash]) {
  //   return getGeolocationPromises[optionsHash];
  // }

  // Return a promise to be resolved with the geolocation
  getGeolocationPromises[optionsHash] = new Promise(async resolve => {
    const useCache = options.mode.indexOf('cache') !== -1;
    const useIp = options.mode.indexOf('ip') !== -1;
    const useApi = options.mode.indexOf('api') !== -1;

    // Helper function to cache the geolocation data and also resolve the
    // promise.
    const storeAndResolve = geoloc => {
      if (geoloc != null) {
        // Cache the result in local storage so we don't keep looking it up
        sessionStorage.setItem(options.cacheKey, JSON.stringify(geoloc));
        sessionStorage.setItem(options.cacheKeyExpires,
          dayjs().add(options.expiry, 'minutes').format());

        // Also cache separately by lat/lon for later lookup
        Cache.setGeolocation(geoloc);
      } else {
        // If it's null, clear the cache instead
        sessionStorage.removeItem(options.cacheKey);
        sessionStorage.removeItem(options.cacheKeyExpires);
      }

      // Resolve the promise with the result as well
      resolve(geoloc);
    }

    // If mode uses cache, check that first
    if (useCache) {
      options.verbose && console.info('[geolocation] Requesting geolocation from cache...');
      const cacheGeoloc = await getGeolocationFromCache(options);

      if (cacheGeoloc != null) {
        // Just resolve here, don't update the cache if it's pulled from cache
        resolve(cacheGeoloc);
        return;
      }
    }

    // If mode uses API, try API next
    if (useApi) {
      options.verbose && console.info('[geolocation] Requesting geolocation from API...');
      const apiGeoloc = await getGeolocationFromApi(options);
      
      if (apiGeoloc != null) {
        storeAndResolve(apiGeoloc);
        return;
      }
    }

    // If mode uses IP, finally try IP
    if (useIp) {
      // Only use IP address for geolocation lookup, skipping Geolocation API
      options.verbose && console.info('[geolocation] Requesting geolocation from IP...');
      const ipGeoloc = await getGeolocationFromIp(options);

      if (ipGeoloc != null) {
        storeAndResolve(ipGeoloc);
        return;
      }
    }

    // All methods failed, so resolve with null
    options.verbose && console.warn('[geolocation] No geolocation found');
    storeAndResolve(null);
  });

  return getGeolocationPromises[optionsHash];
};
