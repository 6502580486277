/**
 * @fileoverview Implements a partner section for the What is Pebble? page.
 */

import { Check as CheckIcon } from '@mui/icons-material'
import { Alert, Box, Grid, Typography } from '@mui/material'

import LinkButton from '../link-button'
import Section from '../section'

export default function WhatIsPebblePartner() {
  return (
    <Section color="white">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            variant="h1"
          >
            Small business owner?
          </Typography>
          <Typography
            color="secondary"
            component="h2"
            gutterBottom
            variant="h1"
          >
            Become a Pebble Partner.
          </Typography>
          <Typography gutterBottom variant="body1">
            Our mission is to help small businesses reach more customers.
          </Typography>
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Get set up in as little as 15 minutes
          </Alert>
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Attract more customers to your products
          </Alert>
          <Alert
            icon={<CheckIcon />}
            severity="success"
            sx={{
              color: 'darkGrey.main',
              py: 0,
              background: 'none'
            }}
          >
            Generate more revenue for your business
          </Alert>
          <Box sx={{
            mt: 2,
            '& * + *': {
              ml: 1
            }
          }}>
            <LinkButton
              color="secondary"
              mode="router"
              to="/signup/partner"
            >
              Get started
            </LinkButton>
            <LinkButton
              mode="router"
              to="/partner"
              variant="outlined"
            >
              Learn more
            </LinkButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Customer checking out on their phone"
              src="/images/partner/mission.png"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}
