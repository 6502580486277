/**
 * @fileoverview Reset Password view.
 */

import { useEffect, useState, useRef } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Skeleton from '@mui/material/Skeleton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { useUser } from '../../lib/user-context'
import HelmetWrapper from '../helmet-wrapper'
import LinkButton  from '../link-button'
import Section from '../section'
import PebbleApi from '../../lib/pebble-api'

export default function SetPassword() {
  const { loadUser } = useUser()
  const { token } = useParams()
  const navigate = useNavigate()
  const [validating, setValidating] = useState(true)
  const [validationError, setValidationError] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  // Verify the token is valid before allowing setting the password
  useEffect(() => {
    if (!token) {
      return
    }

    setValidating(true)

    PebbleApi
      .validateToken(token)
      .then(async response => {
        if (mounted.current) {
          setValidating(false)

          let body
          let message

          // Then see if we can parse it as JSON
          const contentType = response.headers.get('content-type')

          if (contentType && contentType.includes('application/json')) {
            body = await response.json()
            message = body.message
          } else {
            body = await response.text()
            message = body
          }

          if (response.status === 200) {
            // 200 OK

            setEmail(body.email)
          } else if (response.status === 401 ||
              response.status === 403 ||
              response.status === 500) {
            // 401 Unauthorized
            // 403 Forbidden
            // 500 Internal Server Error

            setValidationError(message)
          }
        }
      })
  }, [token])

  if (!token) {
    return <Navigate to="/resetpassword" />
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()

    // First clear out all errors
    setFormError(null)
    setPasswordError(null)

    let hasError = false

    if (password == null || password.trim().length === 0) {
      setPasswordError('Password is required')
      hasError = true
    }

    // If there's a validation error, bail before talking to the server
    if (hasError) {
      return
    }

    setLoading(true)

    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute('6Ldn0G4bAAAAAOHwhde8AAVfhYpiazRho5Qygg8a', { action: 'submit' })
        .then(captchaToken => {
          // First, verify the reCAPTCHA response token through our server
          PebbleApi
            .verifyRecaptcha(captchaToken)
            .then(success => {
              if (mounted.current) {
                if (!success) {
                  setLoading(false)
                  setFormError("Oops! Pebble thinks you're a robot. Please try again.")
                  return
                }
  
                PebbleApi
                  .setPassword(token, password)
                  .then(async response => {
                    if (mounted.current) {
                      setLoading(false)

                      let body
                      let message

                      // Then see if we can parse it as JSON
                      const contentType = response.headers.get('content-type')

                      if (contentType && contentType.includes('application/json')) {
                        body = await response.json()
                        message = body.message
                      } else {
                        body = await response.text()
                        message = body
                      }

                      if (response.status === 200) {
                        // 200 OK

                        // User is logged in after successfully setting a new password
                        loadUser()

                        // Success, so redirect to the home page
                        navigate('/')
                      } else if (response.status === 401 ||
                          response.status === 403) {
                        // 401 Unauthorized
                        // 403 Forbidden

                        setValidationError(message)
                      } else if (response.status === 500) {
                        // 500 Internal Server Error

                        setFormError(message)
                      }
                    }
                  })
                }
            })
        })
    })
  }

  const handlePasswordRevealMouseDown = () => {
    setShowPassword(true)
  }

  const handlePasswordRevealMouseUp = () => {
    setShowPassword(false)
  }

  if (validating) {
    return (
      <>
        <HelmetWrapper title="Set your password" />
        <Section maxWidth="sm">
          <Box pb={4}>
            <Typography variant="h1">
              Set password
            </Typography>
            <Typography variant="body1">
              <Skeleton />
            </Typography>
          </Box>
          <Skeleton sx={{ mb: 2, width: '100%' }} variant="rect" height={58} />
          <Skeleton sx={{ mb: 2, width: '100%' }} variant="rect" height={58} />
          <Skeleton variant="rect" height={42} />
        </Section>
      </>
    )
  }

  if (validationError) {
    return (
      <>
        <HelmetWrapper title="Set your password" />
        <Section maxWidth="sm">
          <Box pb={4}>
            <Typography variant="h1">
              Set password
            </Typography>
          </Box>
          <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
            {validationError}
          </Alert>
          <LinkButton
            color="primary"
            size="large"
            sx={{ mb: 2, width: '100%' }}
            to="/resetpassword"
          >
            Reset your password
          </LinkButton>
        </Section>
      </>
    )
  }

  return (
    <>
      <HelmetWrapper title="Set your password" />
      <Section maxWidth="sm">
        <Box pb={4}>
          <Typography variant="h1">
            Set password
          </Typography>
          <Typography variant="body1">
            Enter a new password for your Pebble account.
          </Typography>
        </Box>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {formError &&
            <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
              {formError}
            </Alert>
          }
          <TextField
            disabled
            label="Email"
            sx={{ mb: 2, width: '100%' }}
            value={email}
            variant="filled"
          />
          <FormControl
            disabled={loading}
            required
            sx={{ mb: 2, width: '100%' }}
            variant="outlined"
          >
            <InputLabel
              error={Boolean(passwordError) || Boolean(formError)}
              htmlFor="set-password-new-password"
            >
              New Password
            </InputLabel>
            <OutlinedInput
              aria-describedby="set-password-new-password-error"
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    disabled={loading}
                    disableRipple
                    onMouseDown={handlePasswordRevealMouseDown}
                    onMouseUp={handlePasswordRevealMouseUp}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              }
              error={Boolean(passwordError) || Boolean(formError)}
              id="set-password-new-password"
              inputProps={{
                autoCapitalize: 'off',
              }}
              label="New Password *"
              name="new-password"
              onChange={handlePasswordChange}
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
            <FormHelperText id="set-password-new-password-error" error={true}>
              {passwordError}
            </FormHelperText>
          </FormControl>
          <Button
            color="primary"
            disabled={loading}
            endIcon={loading && <CircularProgress size={24} />}
            size="large"
            sx={{ mb: 2, width: '100%' }}
            type="submit"
          >
            Set new password
          </Button>
        </form>
        <Box pt={2}>
          <LinkButton
            color="primary"
            size="large"
            sx={{ mb: 2, width: '100%' }}
            to="/resetpassword"
            variant="outlined"
          >
            Reset your password
          </LinkButton>
        </Box>
      </Section>
    </>
  )
}
 