/**
 * @fileoverview Implements a generic cache.
 */

import dayjs from 'dayjs';

export default class Cache {
  /**
   * @function get
   * Retrieves value from the cache. If the cache item has an expiration and
   * has already expired, it will be removed from the cache and `null` will be
   * returned.
   * @param {string} key - Cache key to fetch
   * @returns {any} Cache item value
   */
  static get(key) {
    // Fetch when the cache item expires, which may not be set
    const expiresKey = `${key}-expires`;
    let expiresDate = sessionStorage.getItem(expiresKey);

    // Check if the cache item has already expired
    if (expiresDate && dayjs(expiresDate) < dayjs()) {
      // Expired, so remove it from the cache
      sessionStorage.removeItem(expiresKey);
      sessionStorage.removeItem(key);
      return null;
    }

    // Fetch the cache item
    let value = sessionStorage.getItem(key);

    if (!value) {
      return null;
    }

    // Return the parsed value
    return JSON.parse(value);
  }

  /**
   * @function set
   * Stores values in the cache
   * @param {string} key - Cache item key
   * @param {any} value - Cache item value to store. Will be converted to a
   * JSON string
   * @param {number} [expiry] - Optional length of time in minutes until this
   * cache item should expire. If not given, cache item does not expire.
   */
  static set(key, value, expiry) {
    // Store the cache item itself
    sessionStorage.setItem(key, JSON.stringify(value));

    // Optionally set the expiration
    if (expiry) {
      const expiresKey = `${key}-expires`;

      // Calculate the future expiration date to store
      const expiresDate = dayjs().add(expiry, 'minutes').format();
      sessionStorage.setItem(expiresKey, expiresDate);
    }
  }
}
